import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Combinacao from './Combinacao';
import LoadingComb from './LoadingComb';
import InputMultipleRCs from './Select/InputMultipleRCs';

// Functions
import { _post } from '../../../../components/_functions/_requests';
import { setDecimalsToNumber, setDecimalsToString } from '../../../../components/_functions/_numbers';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';

class CombinacaoPage1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			combinacao_criada_key: -1,
			filters: false,
			preco_minimo: setDecimalsToString(props.preco_minimo),
		};
		this.openFilters = this.openFilters.bind(this);
		this.handlePrecoEdition = this.handlePrecoEdition.bind(this);
		this.handleMaxFornecedores = this.handleMaxFornecedores.bind(this);
		this.buscaCombinacoes = this.buscaCombinacoes.bind(this);
		this.criaCombinacao = this.criaCombinacao.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({ preco_minimo: setDecimalsToString(nextProps.preco_minimo) });
	}

	setOpcoesCompra(nova_combinacao) {
		const { opcoes_de_compra, updateQc, closeCombinacaoSideBarAfterCreate } = this.props;
		/**
		 * Loop por todos os RC's analise
		 */
		const rcs_analise = nova_combinacao.rcs_analise.map(rc_a => {
			const {
				condicoes_de_pagamento: cp,
				transporte,
				valor_transporte: vt,
				custos_adicionais: ca,
			} = rc_a.rc;
			/**
			 * Condições de pagamento
			 *  - Check se o campo está null ou vazio
			 *  - Filtra só os com valores corretos
			 */
			const cp_check = (
				cp !== null &&
				cp !== undefined &&
				cp.length > 0
			) ? [...cp] : [{ desconto: 0, forma: 0, outra_forma: '', outro_prazo: '', prazo: 0 }];
			const cp_clean = cp_check
				.filter(c => c.forma !== 0 && c.prazo !== 0)
				.map(c => ((c.desconto === null || c.desconto === undefined) ? { ...c, desconto: 0 } : c))
				.sort((a, b) => b.desconto - a.desconto);
			/**
			 * Calcula preço da soma dos itens
			 */
			let n_ativos = 0;
			const itens_total = rc_a.itens_rc_analise.reduce((total, atual) => {
				const atual_price = (atual.ativo && atual.item_de_resposta.status === 1)
					? atual.item_de_resposta.preco_unitario * atual.item_de_resposta.quantidade
					: 0;
				if (
					atual.ativo &&
					(atual.item_de_resposta.status === 1 || atual.item_de_resposta.status === 0)
				) {
					n_ativos++;
				}
				return total + atual_price;
			}, 0);
			/**
			 * Custos adicionais
			 */
			const custos_adicionais = (ca !== null && ca !== undefined) ? ca : 0;
			/**
			 * Verifica se o transporte terá custos
			 */
			const valor_transporte = (vt !== null && vt !== undefined) ? vt : 0;
			const transporte_total = transporte === 1 ? valor_transporte : 0;
			/**
			 * Verifica se já existe alguma condição selecionada
			 */
			const condicao_de_pagamento = (rc_a.condicao_de_pagamento || {}).id;
			let condicao_selecionada_id = condicao_de_pagamento !== undefined
				? condicao_de_pagamento
				: null;
			if (condicao_selecionada_id === null && cp_clean.length > 0) {
				condicao_selecionada_id = cp_clean[0].id;
			}
			const condicao_selecionada = cp_clean.filter(x => x.id === condicao_selecionada_id);
			const desconto_selecionado = condicao_selecionada.length === 1 ? condicao_selecionada[0].desconto : 0;
			/**
			 * Define menor preço
			 */
			const preco_rc = n_ativos > 0
				? itens_total + custos_adicionais
				: 0;
			const preco_selecionado_rc = cp_clean.length > 0
				? (preco_rc - ((preco_rc / 100) * desconto_selecionado)) + transporte_total
				: preco_rc;

			return {
				...rc_a,
				rc: {
					...rc_a.rc,
					condicoes_de_pagamento: cp_clean,
				},
				preco_rc,
				preco_selecionado_rc,
				condicao_selecionada_id,
			};
		});

		/**
		 * Seta o valor total da opção de compra, também das combinações
		 */
		const preco_total = rcs_analise.reduce((total_rc, atual_rc) => (
			total_rc + atual_rc.preco_selecionado_rc
		), 0);

		const by_id = {
			...opcoes_de_compra.by_id,
			[nova_combinacao.id]: {
				...nova_combinacao,
				rcs_analise,
				preco_total,
				collapsed: false,
			},
		};

		/**
		 * Ordena as combinações pelo preco_total
		 */
		const ids_combs_unsorted_pre = opcoes_de_compra.all_ids
			.filter(id => opcoes_de_compra.by_id[id].rcs_analise.length > 1)
			.map(id => ({
				id,
				preco_total: opcoes_de_compra.by_id[id].preco_total,
			}));
		const ids_combs_unsorted = [
			...ids_combs_unsorted_pre,
			{ id: nova_combinacao.id, preco_total },
		];
		const ids_combs = ids_combs_unsorted
			.sort((a, b) => a.preco_total - b.preco_total)
			.map(c => c.id);
		/**
		 * Ordena os RC's pelo preco_total
		 */
		const ids_rcs_unsorted = opcoes_de_compra.all_ids
			.filter(id => opcoes_de_compra.by_id[id].rcs_analise.length === 1)
			.map(id => ({
				id,
				preco_total: opcoes_de_compra.by_id[id].preco_total,
				completo: opcoes_de_compra.by_id[id].rcs_analise[0].rc.completo,
			}));
		const ids_rcs_sorted = ids_rcs_unsorted
			.sort((a, b) => a.preco_total - b.preco_total);
		const ids_rcs_completos = ids_rcs_sorted
			.filter(x => x.completo);
		const ids_rcs_incompletos = ids_rcs_sorted
			.filter(x => !x.completo);
		const ids_rcs = [...ids_rcs_completos, ...ids_rcs_incompletos]
			.map(c => c.id);

		const all_ids = [...ids_combs, ...ids_rcs];

		/**
		 * Atualiza redux
		 */
		updateQc(['opcoes_de_compra', 'by_id'], by_id);
		updateQc(['opcoes_de_compra', 'all_ids'], all_ids);

		/**
		 * Fecha sidebar
		 */
		closeCombinacaoSideBarAfterCreate(nova_combinacao.id);
	}

	openFilters() {
		const filters = !this.state.filters;
		this.setState({ filters });
	}

	handlePrecoEdition(e) {
		const number_values = setDecimalsToNumber(e.target.value); // numeros
		const props_value = number_values[0]; // numero
		const state_value = setDecimalsToString(number_values[1]); // string
		this.setState({ preco_minimo: state_value });
		this.props.updateState('preco_minimo', props_value);
	}

	handleMaxFornecedores(e) {
		const max_fornecedores = Number(e.target.getAttribute('data-max'));
		this.props.updateState('max_fornecedores', max_fornecedores);
	}

	buscaCombinacoes() {
		this.setState({ filters: false });
		this.props.geraOpcoesCombinacao();
	}

	criaCombinacao(comb_key) {
		this.setState({ combinacao_criada_key: comb_key });
		const { opcoes_de_combinacao, analise_id } = this.props;
		/**
		 * Cria combinação
		 */
		const params = {
			rcs_ids: (opcoes_de_combinacao[comb_key] || {}).rcs_ids || [],
			itens_de_resposta_ids: (opcoes_de_combinacao[comb_key] || {}).itens_de_resposta_ids || [],
		};
		_post(`/conaz/v2/analises/${analise_id}/opcoes_compra`, params).then(response => {
			this.setOpcoesCompra(response.data);
			// const opcoes_de_compra_id = response.data.id;
			// history.push(`/cotacao/${pedido_id}/qc/${opcoes_de_compra_id}`);
		}).catch(error => {
			this.setState({ combinacao_criada_key: -1 });
			handleRequestErrors(error);
		});
	}

	render() {
		const {
			combinacao_criada_key,
			filters,
			preco_minimo,
		} = this.state;
		const {
			calculando,
			opcoes_de_combinacao,
			menor_preco,
			max_fornecedores,
			opcoes_de_compra,
			ocs_selecionados,
			selectOC,
			selectAllOCs,
		} = this.props;

		if (calculando === 0 || calculando === 1) {
			return (
				<div styleName="bottom-part">
					<LoadingComb />
					<h4 style={{ textAlign: 'center', marginTop: '196px', marginBottom: '30px', color: '#90969e', fontSize: '16px' }}>Buscando as melhores combinações...</h4>
				</div>
			);
		}

		if (calculando === 3) {
			return (
				<div styleName="bottom-part">
					<div styleName="no-result">
						<i className="fa fa-exclamation-circle" aria-hidden="true" style={{ fontSize: '66px' }} />
						<p>Ops, aconteceu algo errado.</p>
						<button
							type="button"
							className="btn btn-secondary-conaz round-border"
							style={{ marginTop: '16px', padding: '8px 23px 9px' }}
							onClick={this.buscaCombinacoes}>
							Recarregar sugestões
						</button>
					</div>
				</div>
			);
		}

		const filters_class = !filters ? 'filters' : 'filters open';

		return (
			<div styleName="bottom-part">
				<div id="filters" styleName={filters_class}>
					<button styleName="open-button" onClick={this.openFilters}>
						<i className="fa fa-sliders fa-fw" aria-hidden="true" /> Filtros avançados
					</button>

					<label style={{ margin: '10px 0px 3px' }}>Fornecedores selecionados:</label>
					<InputMultipleRCs
						opcoes_de_compra={opcoes_de_compra}
						ocs_selecionados={ocs_selecionados}
						selectOC={selectOC}
						selectAllOCs={selectAllOCs} />

					<label style={{ margin: '15px 0 3px' }}>Preço mínimo de compra com um fornecedor:</label>
					<div styleName="currency-wrapper" style={{ maxWidth: '117px' }}>
						<input
							type="text"
							className="form-control input-md"
							value={preco_minimo}
							onChange={this.handlePrecoEdition} />
						<div styleName="placeholder">R$</div>
					</div>

					<label style={{ margin: '15px 0 3px' }}>Número máximo de fornecedores em uma combinação:</label>
					<div className="btn-group" role="group">
						<button
							type="button"
							className={max_fornecedores === 2 ? 'btn btn-primary' : 'btn btn-default'}
							styleName="btns-group"
							data-max="2"
							onClick={this.handleMaxFornecedores}>
							2
						</button>
						<button
							type="button"
							className={max_fornecedores === 3 ? 'btn btn-primary' : 'btn btn-default'}
							styleName="btns-group"
							data-max="3"
							onClick={this.handleMaxFornecedores}>
							3
						</button>
						<button
							type="button"
							className={max_fornecedores === 4 ? 'btn btn-primary' : 'btn btn-default'}
							styleName="btns-group"
							data-max="4"
							onClick={this.handleMaxFornecedores}>
							4
						</button>
						<button
							type="button"
							className={max_fornecedores === 5 ? 'btn btn-primary' : 'btn btn-default'}
							styleName="btns-group"
							data-max="5"
							onClick={this.handleMaxFornecedores}>
							5
						</button>
					</div>

					<div>
						<button
							type="button"
							className="btn btn-secondary-conaz round-border"
							style={{ marginTop: '22px', padding: '8px 23px 9px' }}
							onClick={this.buscaCombinacoes}>
							Recalcular
						</button>
					</div>
				</div>

				{opcoes_de_combinacao.length === 0 ? (
					<div styleName="no-result">
						<i className="fa fa-search" aria-hidden="true" />
						<p>Não encontramos nenhuma combinação mais barata com os filtros atuais.</p>
					</div>
				) : (
					<>
						{opcoes_de_combinacao.map((combinacao, c) => (
							<Combinacao // eslint-disable-next-line react/no-array-index-key
								key={c}
								order={c}
								combinacao={combinacao}
								criaCombinacao={this.criaCombinacao}
								menor_preco={menor_preco}
								combinacao_criada_key={combinacao_criada_key} />
						))}
					</>
				)}
			</div>
		);
	}
}

CombinacaoPage1.propTypes = {
	calculando: PropTypes.number.isRequired,
	updateState: PropTypes.func.isRequired,
	opcoes_de_combinacao: PropTypes.array.isRequired,
	menor_preco: PropTypes.number.isRequired,
	analise_id: PropTypes.number.isRequired,
	opcoes_de_compra: PropTypes.object.isRequired,
	ocs_selecionados: PropTypes.array.isRequired,
	selectOC: PropTypes.func.isRequired,
	selectAllOCs: PropTypes.func.isRequired,
	updateQc: PropTypes.func.isRequired,
	closeCombinacaoSideBarAfterCreate: PropTypes.func.isRequired,
	preco_minimo: PropTypes.number.isRequired,
	max_fornecedores: PropTypes.number.isRequired,
	geraOpcoesCombinacao: PropTypes.func.isRequired,
};

export default CSSModules(CombinacaoPage1, styles, { allowMultiple: true });

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingPage from '../_parts/_loadings/LoadingPage/LoadingPage';
import TopBar from '../_parts/TopBar/TopBar';
import LeftMenu from '../_parts/LeftMenu/LeftMenu';
import THBackspace from '../_parts/THBackspace/THBackspace';
import ModalWrapper from '../_parts/_modals/ModalWrapper/ModalWrapper';
import ModalError from '../_parts/_modals/ModalError/ModalError';

// Functions
import { handleRequestErrors } from '../_functions/_handleRequestErrors';
import { getLocalstorageData } from '../_functions/_getLocalstorageData';
import { handleUpdateState } from '../_functions/_handleUpdateState';
import { _get } from '../_functions/_requests';

class RequestsWrapperFuture extends Component {
	constructor() {
		super();
		this.state = {
			request_state: 0,
		};
		this.handleFetch = this.handleFetch.bind(this);
		this.updateWrapperState = this.updateWrapperState.bind(this);
	}

	componentDidMount() {
		this.checkAuth();
		/**
		 * Inicia as funções para request se nada foi carregado
		 */
		if (this.state.request_state === 0) {
			this.handleUrls();
		}
	}

	componentDidUpdate(prevProps) {
		/**
		 * Verifica se tem novas url's para carregar
		 */
		if (
			this.state.request_state === 2 &&
			prevProps.urls_to_fetch.length === 0 &&
			this.props.urls_to_fetch.length > 0
		) this.handleUrls();
	}

	componentWillUnmount() {
		/**
		 * Cancela os requests caso o componente seja desmontado
		 */
		if (this.cancel !== undefined) {
			this.cancel('canceled_request');
		}
	}

	checkAuth() {
		/**
		 * Verifica se o usuário está autenticado
		 * Verificação pode ser cancelada via props auth = false
		 */
		if (this.props.auth) {
			const token = getLocalstorageData('user', 'token');
			const email = getLocalstorageData('user', 'email');
			if (token === '' || token === null || email === '' || email === null) {
				this.props.history.push('/sair');
			}
		}
	}

	handleUrls() {
		/**
		 * Carrega primeiro grupo de url's
		 * e unifica com as obras, caso não tenham sido carregadas
		 */
		const urls_to_fetch = [...this.props.urls_to_fetch];
		if (urls_to_fetch.length > 0) {
			this.setState({ request_state: 1 });
			this.handleFetch(urls_to_fetch);
		} else {
			this.setState({ request_state: 2 });
		}
	}

	handleFetch(urls_to_fetch) {
		/**
		 * Promise que faz todos os requests
		 */
		const all_fetch_urls = urls_to_fetch.map(url => _get(url));
		Promise.all(all_fetch_urls).then(responses => (
			this.handleResponsesLocal(responses)
		)).catch(error => (
			this.handleError(error)
		));
	}

	handleResponsesLocal(responses) {
		const { handleResponses } = this.props;
		if (responses.length === 0) {
			this.setState({ request_state: 2 });
			return;
		}
		handleResponses(responses, this.updateWrapperState, this.handleFetch);
	}

	updateWrapperState(field, value) {
		/**
		 * Função enviada para outros componentes atualizarem o state desse componente
		 * handleUpdateState lida com valores `true` e `false`
		 */
		const _value = handleUpdateState({}, value);
		this.setState({ [field]: _value });
	}

	handleError(error) {
		const { handleError, unsetAuth } = this.props;
		/**
		 * Caso a função que trata o erro venha do outro componente (child)
		 */
		if (handleError !== null) {
			handleError(error);
			return;
		}
		/**
		 * Default, o erro é tratado aqui mesmo
		 */
		if (error.message !== 'canceled_request') {
			if (error.response && error.response.status === 403) {
				// history.push('/sair');
				unsetAuth();
			} else {
				this.setState({ request_state: 3 });
				handleRequestErrors(error);
			}
		}
	}

	render() {
		const { request_state } = this.state;
		// components visibility
		const { topbar, thbackspace_fixed } = this.props;
		// store
		const { ui, user, cotacao, match } = this.props;
		// redux funcs
		const { updateModals, updateUi, changeObra, unsetAuth, updateCotacao } = this.props;

		const { obras_by_id, obras_all_ids, obra_selected } = user;
		const { obras_menu, account_menu, modals, search_state, search, search_results } = ui;
		const { criando_cotacao } = cotacao;

		switch (request_state) {
		/* ========================================================================== *\
				Render
		\* ========================================================================== */
		case 2:
		default: {
			return (
				<div styleName="page-wrapper">
					<ModalWrapper visible={modals.error} updateModals={updateModals}>
						<ModalError />
					</ModalWrapper>
					<LeftMenu
						route_path={match.path}
						obra_selected={obra_selected}
						criando_cotacao={criando_cotacao}
						updateCotacao={updateCotacao}
						updateModals={updateModals}
						updateUi={updateUi} />
					{topbar && (
						<>
							<TopBar
								updateUi={updateUi}
								obras_menu={obras_menu}
								account_menu={account_menu}
								obras_by_id={obras_by_id}
								obras_all_ids={obras_all_ids}
								obra_selected={obra_selected}
								changeObra={changeObra}
								search_state={search_state}
								search={search}
								search_results={search_results}
								unsetAuth={unsetAuth} />
							<THBackspace fixed={thbackspace_fixed} />
						</>
					)}
					{React.cloneElement(this.props.children)}
				</div>
			);
		}

		/* ========================================================================== *\
				Loading
		\* ========================================================================== */
		case 0:
		case 1:
			return (
				<div styleName="page-wrapper" style={{ textAlign: 'center' }}>
					<ModalWrapper visible={modals.error} updateModals={updateModals}>
						<ModalError />
					</ModalWrapper>
					<LeftMenu
						route_path={match.path}
						obra_selected={obra_selected}
						criando_cotacao={criando_cotacao}
						updateCotacao={updateCotacao}
						updateModals={updateModals}
						updateUi={updateUi} />
					{topbar && (
						<>
							<TopBar
								updateUi={updateUi}
								obras_menu={obras_menu}
								account_menu={account_menu}
								obras_by_id={obras_by_id}
								obras_all_ids={obras_all_ids}
								obra_selected={obra_selected}
								changeObra={changeObra}
								search_state={search_state}
								search={search}
								search_results={search_results}
								unsetAuth={unsetAuth} />
							<THBackspace />
						</>
					)}
					<LoadingPage />
				</div>
			);

		/* ========================================================================== *\
				Error
		\* ========================================================================== */
		case 3:
			return (
				<div styleName="page-wrapper">
					<ModalWrapper visible={modals.error} updateModals={updateModals}>
						<ModalError />
					</ModalWrapper>
					<LeftMenu
						route_path={match.path}
						obra_selected={obra_selected}
						criando_cotacao={criando_cotacao}
						updateCotacao={updateCotacao}
						updateModals={updateModals}
						updateUi={updateUi} />
					{topbar && (
						<>
							<TopBar
								updateUi={updateUi}
								obras_menu={obras_menu}
								account_menu={account_menu}
								obras_by_id={obras_by_id}
								obras_all_ids={obras_all_ids}
								obra_selected={obra_selected}
								changeObra={changeObra}
								search_state={search_state}
								search={search}
								search_results={search_results}
								unsetAuth={unsetAuth} />
							<THBackspace />
						</>
					)}
					<div className="container" style={{ marginTop: '36px' }}>
						<div className="row">
							<div className="col-sm-12" styleName="error-wrapper">
								<i className="fa fa-exclamation-circle" aria-hidden="true" />
								<h3>Desculpe, tivemos um problema!</h3>
								<p>Atualize a página como descrito abaixo ou entre em contato com nossa equipe de suporte pelo chat.</p>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12" style={{ textAlign: 'center' }}>
								<div styleName="keyboards-wrapper">
									<div styleName="keyboards">
										<div styleName="type">Windows:</div>
										<div styleName="keys">
											<div styleName="key c89" style={{ width: '70px' }}><span>Ctrl</span></div>
											<div styleName="key-plus">+</div>
											<div styleName="key c89"><span>F5</span></div>
										</div>
									</div>
									<div styleName="keyboards">
										<div styleName="type">Mac OS:</div>
										<div styleName="keys">
											<div styleName="key c89" style={{ width: '70px' }}><span>&#8984;</span></div>
											<div styleName="key-plus">+</div>
											<div styleName="key c89"><span>R</span></div>
										</div>
									</div>
									<div styleName="keyboards">
										<div styleName="type">Linux:</div>
										<div styleName="keys">
											<div styleName="key c89" style={{ width: '70px' }}><span>Ctrl</span></div>
											<div styleName="key-plus">+</div>
											<div styleName="key c89"><span>F5</span></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);

		/* ========================================================================== *\
				Pedido não existe
		\* ========================================================================== */
		case 5:
			return (
				<div styleName="page-wrapper">
					<ModalWrapper visible={modals.error} updateModals={updateModals}>
						<ModalError />
					</ModalWrapper>
					<LeftMenu
						route_path={match.path}
						obra_selected={obra_selected}
						criando_cotacao={criando_cotacao}
						updateCotacao={updateCotacao}
						updateModals={updateModals}
						updateUi={updateUi} />
					{topbar && (
						<>
							<TopBar
								updateUi={updateUi}
								obras_menu={obras_menu}
								account_menu={account_menu}
								obras_by_id={obras_by_id}
								obras_all_ids={obras_all_ids}
								obra_selected={obra_selected}
								changeObra={changeObra}
								search_state={search_state}
								search={search}
								search_results={search_results}
								unsetAuth={unsetAuth} />
							<THBackspace />
						</>
					)}
					<div className="container" style={{ marginTop: '36px' }}>
						<div className="row">
							<div className="col-sm-12" styleName="error-wrapper">
								<i className="fa fa-inbox" aria-hidden="true" />
								<h3>Este pedido não existe</h3>
								<p>Desculpe, não conseguimos encontrar este pedido em nosso sistema.</p>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

RequestsWrapperFuture.propTypes = {
	auth: PropTypes.bool,
	urls_to_fetch: PropTypes.array,
	handleResponses: PropTypes.func,
	// handleSecondResponses: PropTypes.func,
	// handle_error_here: PropTypes.bool,
	handleError: PropTypes.func,
	// =========== components visibility
	topbar: PropTypes.bool,
	thbackspace_fixed: PropTypes.bool,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	// =========== funcs
	updateModals: PropTypes.func.isRequired,
	unsetAuth: PropTypes.func.isRequired,
	changeObra: PropTypes.func.isRequired,
	// =========== router
	updateCotacao: PropTypes.func,
	updateUi: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	children: PropTypes.object.isRequired,
};

RequestsWrapperFuture.defaultProps = {
	auth: true,
	urls_to_fetch: [],
	handleResponses: () => {},
	// handleSecondResponses: () => {},
	// handle_error_here: true,
	handleError: null,
	// =========== components visibility
	topbar: true,
	thbackspace_fixed: false,
	updateCotacao: () => {},
};

export default CSSModules(RequestsWrapperFuture, styles, { allowMultiple: true });

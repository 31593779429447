import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { handleRequestErrors } from '../../../components/_functions/_handleRequestErrors';
import { _post } from '../../../components/_functions/_requests';

class CancelarCotacao extends Component {
	constructor() {
		super();
		this.state = {
			confirm_descartar: false,
			descartando: 0,
		};
		this.confirmaDescartar = this.confirmaDescartar.bind(this);
		this.cancelaCotacao = this.cancelaCotacao.bind(this);
	}

	confirmaDescartar() {
		const confirm_descartar = !this.state.confirm_descartar;
		this.setState({ confirm_descartar });
		this.props.handleInsideConfirm();
	}

	cancelaCotacao() {
		this.setState({ descartando: 1 });
		const { match, updateUi, updateCotacao, history, updateModals } = this.props;
		const { id } = match.params;
		_post(`/conaz/v2/pedidos/${id}/cancelar`, {}).then(() => {
			updateUi(['tabs', 'cotacoes'], 'em_andamento');
			updateUi(['changes', 'cotacoes'], true);
			updateCotacao(['cotacao_view', 'status'], -3);
			history.push('/cotacoes');
		}).catch(error => {
			this.setState({ descartando: 3, confirm_descartar: false });
			updateModals('error', true);
			handleRequestErrors(error);
		});
	}

	render() {
		const { confirm_descartar, descartando } = this.state;
		const { pedido_status, cancelado, inside_confirm } = this.props;

		if (pedido_status >= 0 && pedido_status <= 50 && !cancelado) {
			return (
				<>
					{(!confirm_descartar && !inside_confirm) && (
						<button
							type="button"
							onClick={this.confirmaDescartar}
							data-acoes-pedido-menu>
							<i className="fa fa-trash fa-fw" aria-hidden="true" data-acoes-pedido-menu /> Cancelar cotação para todos os fornecedores
						</button>
					)}
					{(confirm_descartar && inside_confirm) && (
						<div styleName="confirm-wrapper" data-acoes-pedido-menu>
							{!descartando && (
								<p data-acoes-pedido-menu>Tem certeza que deseja cancelar esta cotação?</p>
							)}
							{descartando ? (
								<p data-acoes-pedido-menu>Descartando cotação...</p>
							) : (
								<button
									type="button"
									className="btn btn-danger-conaz round-border"
									styleName="descartar-button"
									data-acoes-pedido-menu
									onClick={this.cancelaCotacao}>
									Sim
								</button>
							)}
							{!descartando && (
								<button
									type="button"
									className="btn btn-clear-conaz round-border"
									styleName="cancelar-button"
									data-acoes-pedido-menu
									onClick={this.confirmaDescartar}>
									Não
								</button>
							)}
						</div>
					)}
				</>
			);
		}

		return null;
	}
}

CancelarCotacao.propTypes = {
	pedido_status: PropTypes.number.isRequired,
	cancelado: PropTypes.bool.isRequired,
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	inside_confirm: PropTypes.bool.isRequired,
	handleInsideConfirm: PropTypes.func.isRequired,
};

export default CSSModules(CancelarCotacao, styles, { allowMultiple: true });

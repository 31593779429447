/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import { _get } from 'components/_functions/_requests';
import { atualizaFornecedorSiengeFormat } from 'components/_functions/_editDateFormat';
import styles from './style.module.scss';

const HeaderSearchFornecedores = ({
	request_state,
	search,
	user,
	handleSearch,
	handleAtualizarFornecedores,
}) => {
	const [data_atualizacao_fornecedores, setDataAtualizacaoFornecedores] = useState('');

	useEffect(() => {
		const url = '/conaz/v2/integracoes/requisicoes/job_status?job_tipo=IMPORT_SUPPLIER_LIST';
		_get(url, { data_atualizacao_fornecedores }).then((response) => {
			localStorage.setItem('ultima_atualizacao_fornecedores', response.data.requisitado_em);
			if (localStorage.getItem('ultima_atualizacao_fornecedores') !== 'undefined') {
				setDataAtualizacaoFornecedores(localStorage.getItem('ultima_atualizacao_fornecedores'));
			}
			if (localStorage.getItem('ultima_atualizacao_fornecedores') === 'undefined') {
				setDataAtualizacaoFornecedores('');
			}
		});
	}, [localStorage.getItem('ultima_atualizacao_fornecedores'), data_atualizacao_fornecedores]);

	return (
		<div className="header">
			<div className="container">
				<div className="row">
					<div className="col-xs-12">
						<h3 className="h3-header" styleName="title-align">
							Configuração de fornecedores
							{request_state !== 10 && (
								<div>
									<button
										type="button"
										className="btn round-border"
										styleName="buttonCustom"
										onClick={() => handleAtualizarFornecedores()}>
										<i className="fa fa-refresh" aria-hidden="true" styleName="linkcustom" />
										<span styleName={data_atualizacao_fornecedores ? 'tooltip-refresh-disabled' : 'tooltip-refresh'} id="texto-tooltip"> Buscar novos fornecedores do Sienge</span>
									</button>
									{data_atualizacao_fornecedores ? <span styleName="data_atualizada_fornecedores">Atualizado em {atualizaFornecedorSiengeFormat(data_atualizacao_fornecedores)}</span> : ''}
								</div>
							)}
							{request_state === 10 && (
								<>
									<button
										type="button"
										className="btn round-border"
										styleName="buttonCustomDisabled"
										disabled>
										<i className="fa fa-refresh" aria-hidden="true" styleName="linkcustom" />
									</button>
									<span styleName="status-atualizando-lista">Atualizando...</span>
								</>
							)}
						</h3>
						<div styleName="form-row-back-button">
							<Link to="/integracao/sienge">
								<button className="btn btn-clear-conaz round-border">
									<i className="fa fa-angle-left fa-fw" aria-hidden="true" /> Voltar para o início
								</button>
							</Link>
						</div>
					</div>
				</div>

				<div className="row" styleName="mt-2">
					<div className="col-sm-6">
						{request_state !== 10 && (
							<div className="form-group">
								<div className="form-control input-md" styleName="form-control-custom">
									<i className="fa fa-search" aria-hidden="true" />
									<input
										type="text"
										className="form-control"
										styleName="form-search-custom"
										placeholder="Filtrar fornecedores por nome ou código sienge"
										value={search}
										onChange={handleSearch} />
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

HeaderSearchFornecedores.propTypes = {
	request_state: PropTypes.number.isRequired,
	handleSearch: PropTypes.func.isRequired,
	handleAtualizarFornecedores: PropTypes.func.isRequired,
	user: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
};

export default CSSModules(HeaderSearchFornecedores, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Content
import { codigos_recusa } from '../../_content/_codigos_recusa';

const RCCardNotResponded = ({ rc, infoFornecedor }) => {
	const {
		fornecedor,
		status,
		motivos_recusa,
		preco_total,
		estah_no_qc,
		usuario,
		estah_resetado,
	} = rc;
	const nome_fornecedor_view = fornecedor.nome !== '' ? fornecedor.nome : '-';

	return (
		<div styleName="rc-wrapper-not-responded">
			<button styleName="nome-fornecedor" onClick={() => infoFornecedor(fornecedor.id, usuario)}>{nome_fornecedor_view}</button>
			<div styleName="motivo">
				{status === -20 && (
					<p style={{ color: '#999' }}>&quot;{motivos_recusa.join('; ')}&quot;</p>
				)}
				{status === -30 && (
					<p style={{ color: '#999' }}>Cotação cancelada.</p>
				)}
				{((status < 40 && status >= 0) || status === 10 || estah_resetado) && (
					<p style={{ color: '#999' }}>Fornecedor ainda não respondeu à cotação.</p>
				)}
				{((status === 40 || status === 50 || status === 60) && preco_total === 0) && (
					<p style={{ color: '#999' }}>{codigos_recusa[0].value}</p>
				)}
				{(status === 40 && preco_total > 0 && !estah_no_qc) && (
					<p style={{ color: '#999' }}>Fornecedor respondeu fora do prazo e está sendo atualizado no Quadro de Cotações.</p>
				)}
			</div>
		</div>
	);
};

RCCardNotResponded.propTypes = {
	rc: PropTypes.object.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
};

export default CSSModules(RCCardNotResponded, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Hoc
import JustificativaButton from './JustificativaButton';

function PerfilFornecedorButton({
	fornecedor,
	usuario,
	rc_sendo_atualizado,
	updateModals,
	infoFornecedor,
	motivo_refinamento,
	justificativa,
}) {
	function infoFornecedorLocal() {
		infoFornecedor(fornecedor.id, usuario, updateModals);
	}

	const refinamento_recusado = (
		justificativa !== ''
		&& motivo_refinamento !== ''
	);

	const opacity = rc_sendo_atualizado
		? { opacity: '0.35' }
		: {};
	const nome_class = refinamento_recusado
		? 'nome icon-refinamento'
		: 'nome';

	return (
		<>
			{refinamento_recusado && (
				<JustificativaButton
					refinamento_recusado={refinamento_recusado}
					motivo_refinamento={motivo_refinamento}
					justificativa={justificativa} />
			)}
			<button
				type="button"
				styleName={nome_class}
				style={opacity}
				title={fornecedor.nome}
				onClick={infoFornecedorLocal}>
				{fornecedor.nome}
			</button>
		</>
	);
}

PerfilFornecedorButton.propTypes = {
	fornecedor: PropTypes.object.isRequired,
	usuario: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	rc_sendo_atualizado: PropTypes.bool,
	motivo_refinamento: PropTypes.string,
	justificativa: PropTypes.string,
};

PerfilFornecedorButton.defaultProps = {
	rc_sendo_atualizado: false,
	motivo_refinamento: '',
	justificativa: '',
};

export default CSSModules(PerfilFornecedorButton, styles, { allowMultiple: true });

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Hocs
import asyncComponent from '../../../../hoc/asyncComponent/asyncComponent';

// Components
import PerfilFornecedorButton from './PerfilFornecedorButton';
import LoadingButton from '../../../../components/_parts/_loadings/LoadingButton/LoadingButton';
import CriarCompraQCButton from './CriarCompraQCButton';

// Functions
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';
import { defineStatusCompra } from '../../../../components/_functions/_defineStatusCompra';
import { setPrazoEntregaLabel, setPrazoEntregaView } from '../../../../components/_functions/_setPrazoEntrega';

// Content
import { formas_pagamento, prazos_pagamento_full } from '../../../../components/_content/_condicoes_pagamento';

const SolicitarRefinamentoRC = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno'
	? asyncComponent(() => import('./SolicitarRefinamentoRC'))
	: () => null;

class FornecedorRC extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tooltip: false,
			toolTipRascunho: false,
		};
		this.openRC = this.openRC.bind(this);
		// this.comprarOpcao = this.comprarOpcao.bind(this);
		this.showTooltip = this.showTooltip.bind(this);
		this.hideTooltip = this.hideTooltip.bind(this);
	}

	openRC() {
		const { opcao_de_compra, openCloseRCs } = this.props;
		openCloseRCs(opcao_de_compra.rcs_analise[0].id, false);
	}

	// comprarOpcao() {
	// 	const { opcao_de_compra, goToCriarCompra } = this.props;
	// 	goToCriarCompra(opcao_de_compra.rcs_analise[0]);
	// }

	showTooltip() {
		this.setState({ tooltip: true });
	}

	hideTooltip() {
		this.setState({ tooltip: false });
	}

	handleTooltip = () => this.setState({ toolTipRascunho: true });

	render() {
		const {
			tooltip,
			toolTipRascunho,
		} = this.state;
		const {
			order,
			opcao_de_compra,
			rcs_abertos,
			infoFornecedor,
			updateModals,
			collapseOC,
			loading_compras,
			compras,
			pedido,
		} = this.props;

		const rc_a = opcao_de_compra.rcs_analise[0];
		const info_open = rcs_abertos.filter(x => x === rc_a.id).length > 0;
		/**
		 * Informações do RC analise
		 */
		const {
			preco_selecionado_rc,
		} = rc_a;
		/**
		 * Informações do RC
		 */
		const {
			fornecedor,
			completo,
			condicoes_de_pagamento,
			observacoes,
			anexos,
			usuario,
			status,
			motivo_refinamento,
			justificativa,
		} = rc_a.rc;
		/**
		 * Trata informações
		 */
		const condicao_selecionada_id = (rc_a.condicao_selecionada_id !== null && rc_a.condicao_selecionada_id !== undefined)
			? rc_a.condicao_selecionada_id
			: ((condicoes_de_pagamento || [])[0] || {}).id;
		const condicao_selecionada = condicoes_de_pagamento
			.filter(x => x.id === condicao_selecionada_id)[0] || {};
		const forma = condicao_selecionada.forma === -1
			? condicao_selecionada.outra_forma
			: formas_pagamento[(condicao_selecionada.forma || 0)].view;
		const prazo = condicao_selecionada.prazo === -1
			? condicao_selecionada.outro_prazo
			: prazos_pagamento_full[(condicao_selecionada.prazo || 0)].view;
		// const desconto = condicao_selecionada.desconto || 0;

		const price_completo = !completo ? 'valor-rc valor-incompleto' : 'valor-rc';
		const price_class = (tooltip || info_open)
			? `${price_completo} hhover`
			: price_completo;
		const mais_opcoes = condicoes_de_pagamento.length - 1;
		const mais_opcoes_label = mais_opcoes === 1 ? 'opção' : 'opções';
		/**
		 * Define a class do hover dos botões que abrem info
		 */
		const pagamento_class = (tooltip || info_open)
			? 'condicao-pagamento hhover'
			: 'condicao-pagamento';
		const caracteristica_class = (tooltip || info_open)
			? 'caracteristica hhover'
			: 'caracteristica';
		const additional_info_class = (tooltip || info_open)
			? 'additional-info hhover'
			: 'additional-info';

		/**
		 * Define a class da coluna rc
		 */
		const info_open_class = !info_open ? '' : ' oc-info-open';
		const completo_class = !completo ? ' oc-incompleto' : '';
		const fornecedor_class = `fornecedor${info_open_class}${completo_class}`;

		const number_class = !completo ? 'number number-incompleto' : 'number';

		/*
		 * Status 35 = em atualização
		 * Status 36 = pre refinamento
		 * Status 37 = refinamento
		 */
		const rc_sendo_atualizado = (status === 35 || status === 36 || status === 37);
		const rc_sendo_atualizado_texto = status === 35
			? 'Proposta está sendo atualizada pelo fornecedor.'
			: 'Correção foi solicitada ao fornecedor.';

		/**
		 * Verifica compras
		 */
		const rc_uuid = ((rc_a || {}).rc || {}).uuid || '';
		const all_compras = compras.filter(c => (
			c.rc_analise_id === rc_a.id
			|| (c.rc || {}).uuid === rc_uuid
		));
		// const compra = all_compras.length > 0
		// 	? all_compras[0] || {}
		// 	: {};
		// const status_compra = defineStatusCompra(compra);
		// const bc_views = {
		// 	cancelada: 'Cancelada',
		// 	em_analise: 'Aguardando',
		// 	em_aprovacao: 'Aguardando',
		// 	em_refinamento: 'Em refinamento',
		// 	em_edicao: 'Em edição',
		// 	rascunho: 'Comprar',
		// 	aguardando_confirmacao: 'Aguardando',
		// 	confirmada: <><i className="fa fa-check" aria-hidden="true" /> Comprado</>,
		// 	recusada_fornecedor: 'Recusada pelo fornecedor',
		// };
		// const bc_classes = {
		// 	cancelada: 'btn btn-clear-conaz round-border',
		// 	em_analise: 'btn btn-clear-conaz round-border',
		// 	em_aprovacao: 'btn btn-clear-conaz round-border',
		// 	em_refinamento: 'btn btn-clear-conaz round-border',
		// 	em_edicao: 'btn btn-clear-conaz round-border',
		// 	rascunho: 'btn btn-secondary-conaz round-border',
		// 	aguardando_confirmacao: 'btn btn-clear-conaz round-border',
		// 	confirmada: 'btn btn-clear-conaz round-border',
		// 	recusada_fornecedor: 'btn btn-clear-conaz round-border',
		// };
		// const bc_styles = {
		// 	cancelada: 'button-cancelar',
		// 	em_analise: 'button-cancelar',
		// 	em_aprovacao: 'button-cancelar',
		// 	em_refinamento: 'button-cancelar',
		// 	em_edicao: 'button-cancelar',
		// 	rascunho: 'button-criar-compra',
		// 	aguardando_confirmacao: 'button-cancelar',
		// 	confirmada: 'button-cancelar',
		// 	recusada_fornecedor: 'button-cancelar',
		// };
		// const botao_compra_class = bc_classes[status_compra] || 'btn btn-secondary-conaz round-border';
		// const botao_compra_style = bc_styles[status_compra] || 'button-criar-compra';
		// const botao_compra_view = bc_views[status_compra] || 'Comprar';

		const compras_em_rascunho = all_compras
			.filter(c => defineStatusCompra(c) === 'rascunho');

		const is_triagem =
			process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		const prazo_entrega_label = setPrazoEntregaLabel(rc_a.rc);
		const prazo_entrega_view = setPrazoEntregaView(rc_a.rc);

		const has_active_compras = all_compras
			.filter(c => {
				const _status = defineStatusCompra(c);
				return (
					_status !== 'cancelada'
					&& _status !== 'rascunho'
				);
			})
			.length > 0;
		const text = has_active_compras
			? 'Comprar novamente'
			: 'Comprar';

		/**
		 * Opção de compra colapsada
		 */
		if (opcao_de_compra.collapsed) {
			return (
				<div styleName="fornecedor oc-collapsed">
					<button
						type="button"
						styleName="collapse-button closed"
						onClick={() => collapseOC(opcao_de_compra.id)} />
					<div styleName="number">{order}</div>
					<button
						type="button"
						styleName="uncollapse-button"
						onClick={() => collapseOC(opcao_de_compra.id)}>
						{fornecedor.nome}
					</button>
				</div>
			);
		}

		return (
			<div styleName={fornecedor_class}>
				<button
					type="button"
					styleName="collapse-button"
					onClick={() => collapseOC(opcao_de_compra.id)}>
					<i className="fa fa-caret-square-o-left" aria-hidden="true" />
				</button>
				<div styleName={number_class}>{order}</div>
				{!completo && <div styleName="incompleto">Incompleto</div>}

				<PerfilFornecedorButton
					fornecedor={fornecedor}
					usuario={usuario}
					updateModals={updateModals}
					infoFornecedor={infoFornecedor}
					rc_sendo_atualizado={rc_sendo_atualizado}
					motivo_refinamento={motivo_refinamento}
					justificativa={justificativa} />

				{!rc_sendo_atualizado ? (
					<>
						<button
							id={`qc_opcao_compra_total_${order}`}
							type="button"
							data-rc-info
							styleName={price_class}
							onClick={this.openRC}
							onMouseEnter={this.showTooltip}
							onMouseLeave={this.hideTooltip}>
							{formatCurrency(preco_selecionado_rc, 2, 'R$')}
						</button>
						<button
							type="button"
							data-rc-info
							styleName={pagamento_class}
							onClick={this.openRC}
							onMouseEnter={this.showTooltip}
							onMouseLeave={this.hideTooltip}>
							{forma}: {prazo}
						</button>
						{mais_opcoes > 0 && (
							<button
								type="button"
								data-rc-info
								styleName="mais-opcoes-tag"
								onClick={this.openRC}
								onMouseEnter={this.showTooltip}
								onMouseLeave={this.hideTooltip}>
								+{mais_opcoes} {mais_opcoes_label}
							</button>
						)}
						<button
							type="button"
							data-rc-info
							styleName={caracteristica_class}
							onClick={this.openRC}
							onMouseEnter={this.showTooltip}
							onMouseLeave={this.hideTooltip}>
							<span data-rc-info>{prazo_entrega_label}:</span> {prazo_entrega_view}
						</button>
					</>
				) : (
					<div styleName="rc-sendo-atualizado">
						{rc_sendo_atualizado_texto}
					</div>
				)}

				{!info_open ? (
					<>
						{(
							(observacoes !== '' && observacoes !== null && !rc_sendo_atualizado) ||
							(anexos.length > 0 && !rc_sendo_atualizado)
						) && (
							<div styleName={additional_info_class}>
								{(observacoes !== '' && observacoes !== null) && (
									<button
										type="button"
										data-rc-info
										styleName="obs-tag"
										onClick={this.openRC}
										onMouseEnter={this.showTooltip}
										onMouseLeave={this.hideTooltip}>
										+ Observações
									</button>
								)}
								{anexos.length > 0 && (
									<button
										type="button"
										data-rc-info
										styleName="anexos-tag"
										onClick={this.openRC}
										onMouseEnter={this.showTooltip}
										onMouseLeave={this.hideTooltip}>
										<i className="fa fa-paperclip" aria-hidden="true" data-rc-info /> {anexos.length} anexo{anexos.length > 1 && 's'}
									</button>
								)}
							</div>
						)}
						{(!rc_sendo_atualizado && !is_triagem) && (
							(loading_compras === 2 || loading_compras === 3) ? (
								<>
									{compras_em_rascunho.length > 0 ? (
										<>
											<div className={toolTipRascunho ? '' : 'hidden'} styleName="tooltip">
												<p>{compras_em_rascunho.length === 1
													? 'Existe uma compra em rascunho'
													: 'Existem compras em rascunho'}.{' '}
													Você deseja prosseguir e criar uma nova compra?
												</p>
												<div>
													<CriarCompraQCButton
														oc={opcao_de_compra}
														updateModals={updateModals}
														pedido={pedido}
														has_active_compras={false} />
													<Link
														styleName="single"
														to={`/compra/${compras_em_rascunho[0].id}`}
														className="btn btn-default round-border">
														Ir para rascunho
													</Link>
												</div>
											</div>
											<button
												onClick={this.handleTooltip}
												disabled={toolTipRascunho}
												styleName={`button-criar-compra ${toolTipRascunho ? 'round-border' : ''}`}
												className={`btn ${toolTipRascunho
													? 'btn-default'
													: 'btn-secondary-conaz round-border'}`}>
												{text}
											</button>
										</>
									) : (
										<CriarCompraQCButton
											oc={opcao_de_compra}
											updateModals={updateModals}
											pedido={pedido}
											has_active_compras={has_active_compras} />
									)}
								</>
							) : (
								<button
									type="button"
									className="btn btn-secondary-conaz round-border loading"
									styleName="button-criar-compra">
									<LoadingButton />{text}
								</button>
							)
						)}

						{(!rc_sendo_atualizado && is_triagem) && (
							<SolicitarRefinamentoRC
								oc={opcao_de_compra} />
						)}
					</>
				) : (
					<div styleName="info-open-over" data-rc-info><hr data-rc-info /></div>
				)}
			</div>
		);
	}
}

FornecedorRC.propTypes = {
	order: PropTypes.number.isRequired,
	opcao_de_compra: PropTypes.object.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	collapseOC: PropTypes.func.isRequired,
	// goToCriarCompra: PropTypes.func.isRequired,
	loading_compras: PropTypes.number.isRequired,
	compras: PropTypes.array.isRequired,
	pedido: PropTypes.object.isRequired,
};

export default CSSModules(FornecedorRC, styles, { allowMultiple: true });

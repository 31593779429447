import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CombinacaoWrapper from './CombinacaoWrapper';
import LoadingButton from '../../../../components/_parts/_loadings/LoadingButton/LoadingButton';

// Functions
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';

class Combinacao extends Component {
	constructor() {
		super();
		this.handleOption = this.handleOption.bind(this);
	}

	handleOption(e) {
		e.preventDefault();
		const { order, criaCombinacao } = this.props;
		criaCombinacao(order);
	}

	render() {
		const { order, combinacao, menor_preco, combinacao_criada_key } = this.props;
		const { fornecedores, total } = combinacao;
		/**
		 * Calcula porcentagem de diferença
		 */
		// const perc = menor_preco > 0 ? Math.round((total - menor_preco) / (total / 100)) : 0.0;
		const perc = menor_preco > 0 ? Math.round(((total - menor_preco) / menor_preco) * 100) : 0.0;
		const perc_color = perc > 0 ? '#d84848' : '#7cdc4d';
		const perc_icon = perc > 0 ? 'up' : 'down';
		const perc_msg = perc > 0 ? 'mais cara' : 'mais econômica';

		const next_button_class = `next-button rcs-${fornecedores.length}`;

		return (
			<CombinacaoWrapper
				order={order}
				combinacao_criada_key={combinacao_criada_key}
				handleOption={this.handleOption}>

				<div styleName="valor-total">
					<div style={{ float: 'left' }}>{formatCurrency(total, 2, 'R$')}</div>
					{perc !== 0 && (
						<div style={{ float: 'left', marginLeft: '10px', fontSize: '13px', color: perc_color, paddingTop: '4px' }}><i className={`fa fa-caret-${perc_icon} fa-fw`} aria-hidden="true" />{Math.abs(perc)}% {perc_msg}</div>
					)}
				</div>
				{fornecedores.map((fornecedor, f) => ( // eslint-disable-next-line react/no-array-index-key
					<div key={f} styleName="rc">
						<div styleName="rc-fornecedor">
							{fornecedor.nome} - <span>{formatCurrency(fornecedor.total, 2, 'R$')}</span>
						</div>
					</div>
				))}
				<div styleName={next_button_class}>
					{(combinacao_criada_key !== -1 && order === combinacao_criada_key) ? (
						<LoadingButton />
					) : (
						<>
							<i className="fa fa-plus" aria-hidden="true" />
							<span>Ver</span>
						</>
					)}
				</div>
			</CombinacaoWrapper>
		);
	}
}

Combinacao.propTypes = {
	order: PropTypes.number.isRequired,
	combinacao: PropTypes.object.isRequired,
	criaCombinacao: PropTypes.func.isRequired,
	menor_preco: PropTypes.number.isRequired,
	combinacao_criada_key: PropTypes.number.isRequired,
};

export default CSSModules(Combinacao, styles, { allowMultiple: true });

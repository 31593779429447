import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const Welcome = ({
	page,
	total_pages,
	navigate,
	updateInstrucao,
}) => {
	const prev_page = page - 1;
	const next_page = page + 1;

	// pages
	const page_1_class = page === 1 ? 'page-1 in' : 'page-1';
	const page_2_class = page === 2 ? 'page-2 in' : 'page-2';
	const page_3_class = page === 3 ? 'page-3 in' : 'page-3';
	const page_4_class = page === 4 ? 'page-4 in' : 'page-4';
	const page_5_class = page === 5 ? 'page-5 in' : 'page-5';

	// dots
	const dot_1_class = page === 1 ? 'dot active' : 'dot';
	const dot_2_class = page === 2 ? 'dot active' : 'dot';
	const dot_3_class = page === 3 ? 'dot active' : 'dot';
	const dot_4_class = page === 4 ? 'dot active' : 'dot';
	const dot_5_class = page === 5 ? 'dot active' : 'dot';

	return (
		<div styleName="container">
			<div styleName="onboarding-wrapper">
				{prev_page > 0 && (
					<button type="button" styleName="prev-button" onClick={() => navigate(prev_page)}><i className="fa fa-angle-left" aria-hidden="true" /></button>
				)}

				{/* ================================================================== *\

					# 1

				\* ================================================================== */}
				<div styleName={page_1_class}>
					<div styleName="logo-wrapper"><img src="/images/logo-loading.png" width="160" alt="loading" /></div>
					<h3>Bem-vindo à plataforma CONAZ!</h3>
					<p>Nossa ferramente é <b>super simples</b>! Veja a seguir, em cerca de 4 minutos, como executar as principais atividades.</p>
					<p><button type="button" className="btn btn-secondary-conaz round-border" styleName="continuar-button" onClick={() => navigate(2)}>Iniciar <i className="fa fa-angle-right fa-fw" aria-hidden="true" /></button></p>
				</div>

				{/* ================================================================== *\

					# 2

				\* ================================================================== */}
				<div styleName={page_2_class}>
					<h3>Pedido de cotação</h3>
					<p>Adicione itens ao seu pedido utilizando nosso banco <b>técnico</b> e <b>padronizado</b>. Escolha seus fornecedores, além de outras informações!</p>
					{page === 2 && (
						<video width="100%" autoPlay preload controls loop>
							<source src="https://arquivos.conazweb.com.br/onboarding/Nova_cotacao_2_v2.mp4" type="video/mp4" />
						</video>
					)}
					<p><button type="button" className="btn btn-secondary-conaz round-border" styleName="continuar-button" style={{ marginTop: '18px' }} onClick={() => navigate(3)}>Continuar <i className="fa fa-angle-right fa-fw" aria-hidden="true" /></button></p>
				</div>

				{/* ================================================================== *\

					# 3

				\* ================================================================== */}
				<div styleName={page_3_class}>
					<h3>Acompanhe suas cotações</h3>
					<p>Verifique quais fornecedores já visualizaram e quais já responderam sua cotação.</p>
					{page === 3 && (
						<video width="100%" autoPlay preload controls loop>
							<source src="https://arquivos.conazweb.com.br/onboarding/Acompanhamento.mp4" type="video/mp4" />
						</video>
					)}
					<p><button type="button" className="btn btn-secondary-conaz round-border" styleName="continuar-button" style={{ marginTop: '18px' }} onClick={() => navigate(4)}>Continuar <i className="fa fa-angle-right fa-fw" aria-hidden="true" /></button></p>
				</div>

				{/* ================================================================== *\

					# 4

				\* ================================================================== */}
				<div styleName={page_4_class}>
					<h3>Decidir compra da cotação</h3>
					<p>Em <b>Cotações</b>, visualize o QC e escolha com qual fornecedor deseja <b>criar uma compra</b>.</p>
					{page === 4 && (
						<video width="100%" autoPlay preload controls loop>
							<source src="https://arquivos.conazweb.com.br/onboarding/Compra_com cotacao.mp4" type="video/mp4" />
						</video>
					)}
					<p><button type="button" className="btn btn-secondary-conaz round-border" styleName="continuar-button" style={{ marginTop: '18px' }} onClick={() => navigate(5)}>Continuar <i className="fa fa-angle-right fa-fw" aria-hidden="true" /></button></p>
				</div>

				{/* ================================================================== *\

					# 5

				\* ================================================================== */}
				<div styleName={page_5_class}>
					<h3>Registrar compra sem cotação</h3>
					<p>Deseja registrar uma compra que <b>não teve cotação</b> feita pelo sistema Conaz? Em <b>Compras</b>, você pode registrá-la rapidamente.</p>
					{page === 5 && (
						<video width="100%" autoPlay preload controls loop>
							<source src="https://arquivos.conazweb.com.br/onboarding/Registro_de_Compra.mp4" type="video/mp4" />
						</video>
					)}
					<p><button type="button" className="btn btn-secondary-conaz round-border" styleName="continuar-button" style={{ marginTop: '18px' }} onClick={updateInstrucao}>Finalizar explicação <i className="fa fa-angle-right fa-fw" aria-hidden="true" /></button></p>
				</div>

				{next_page <= total_pages && (
					<button type="button" styleName="next-button" onClick={() => navigate(next_page)}>
						<i className="fa fa-angle-right" aria-hidden="true" />
					</button>
				)}

				<div styleName="steps-dots">
					<button type="button" styleName={dot_1_class} onClick={() => navigate(1)} />
					<button type="button" styleName={dot_2_class} onClick={() => navigate(2)} />
					<button type="button" styleName={dot_3_class} onClick={() => navigate(3)} />
					<button type="button" styleName={dot_4_class} onClick={() => navigate(4)} />
					<button type="button" styleName={dot_5_class} onClick={() => navigate(5)} />
				</div>
			</div>
		</div>
	);
};

Welcome.propTypes = {
	page: PropTypes.number.isRequired,
	total_pages: PropTypes.number.isRequired,
	navigate: PropTypes.func.isRequired,
	updateInstrucao: PropTypes.func.isRequired,
};

export default CSSModules(Welcome, styles, { allowMultiple: true });

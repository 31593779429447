import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../../_loadings/LoadingButton/LoadingButton';

// Functions
import { _post_exports } from '../../../_functions/_requests';
import { handleRequestErrors } from '../../../_functions/_handleRequestErrors';

class ModalExportaExcel extends Component {
	constructor() {
		super();
		this.state = {
			exportando: 0,
			request_finished: false,
		};
		this.exportaExcel = this.exportaExcel.bind(this);
	}

	finishTimer() {
		window.clearInterval(this.request_timer);
	}

	exportaExcel() {
		this.setState({ exportando: 1, request_finished: false });
		/**
		 * Inicia timeout mínimo
		 * Caso o request termine antes o timeout prolonga o loading
		 */
		let counter = 0;
		this.request_timer = window.setInterval(() => {
			counter++;
			if (this.state.request_finished && counter >= 4) {
				this.setState({ exportando: 2 });
				this.finishTimer();
			}
		}, 500);
		/**
		 * Request
		 */
		const { analise_id } = this.props;
		_post_exports(`/v2/analises/${analise_id}/exportar`, {}).then(() => {
			this.setState({ request_finished: true });
			if (counter >= 4) {
				this.finishTimer();
				this.setState({ exportando: 2 });
			}
		}).catch(error => {
			this.finishTimer();
			this.setState({ exportando: 3, request_finished: true });
			handleRequestErrors(error);
		});
	}

	render() {
		const { exportando } = this.state;
		const { updateModals } = this.props;

		return (
			<div className="modal-content" styleName="modal-content">
				<div className="modal-body" styleName="modal-body">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => updateModals('exporta_excel', false)}>
						<span aria-hidden="true">&times;</span>
					</button>
					<div styleName="icon">
						<img src="/images/excel-icon.png" width="84" alt="" />
					</div>
					<div styleName="content">
						<h3>QC em Excel</h3>
						<p>O processo de exportação demora alguns minutos. Você receberá no seu e-mail o arquivo para download.<br /><br /><a href="https://conaz.zendesk.com/hc/pt-br/articles/360010228454-Exportei-meu-QC-em-Excel-e-quero-imprimi-lo-Como-proceder-" target="_blank" rel="noopener noreferrer">Caso queira imprimir este QC, clique aqui e acesse o artigo para uma melhor explicação.</a><br /><br /></p>
						{exportando === 3 && (
							<div className="alert alert-danger" role="alert">
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> Desculpe, aconteceu algo errado. Tente novamente.
							</div>
						)}
						{(exportando === 0 || exportando === 3) && (
							<button
								type="button"
								className="btn btn-secondary-conaz round-border"
								onClick={this.exportaExcel}>
								Enviar arquivo para e-mail
							</button>
						)}
						{exportando === 1 && (
							<button
								type="button"
								className="btn btn-secondary-conaz round-border loading"
								onClick={this.exportaExcel}>
								<LoadingButton />Enviar arquivo para e-mail
							</button>
						)}
						{exportando === 2 && (
							<p style={{ fontSize: '16px', color: '#00b147', margin: '30px 0 10px 0' }}>
								<i className="fa fa-check fa-fw" aria-hidden="true" /> Pronto!<br /> Em alguns minutos o arquivo estará no seu e-mail.
							</p>
						)}
					</div>
				</div>
			</div>
		);
	}
}

ModalExportaExcel.propTypes = {
	updateModals: PropTypes.func,
	analise_id: PropTypes.number.isRequired,
};

ModalExportaExcel.defaultProps = {
	updateModals: () => {},
};

export default CSSModules(ModalExportaExcel, styles, { allowMultiple: true });

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Hocs
import asyncComponent from '../../../../hoc/asyncComponent/asyncComponent';

// Components
import InputText from '../../_inputs/InputText/InputText';
import InputNumber from '../../_inputs/InputNumber/InputNumber';
import InputSelect from '../../_inputs/InputSelect/InputSelect';
import InputMultiple from '../../_inputs/InputMultiple/InputMultiple';
import InputUnidade from '../../_inputs/InputUnidade/InputUnidade';

// Functions
import { caracteristicaType } from '../../../_functions/_caracteristicaType';
import { buildOpcaoPreenchida } from '../../../_functions/_buildOpcaoPreenchida';
import { setDecimalsToString } from '../../../_functions/_numbers';
import { setClasses } from '../../../_functions/_setClasses';

const IETUpdateCaracteristica =
	process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno'
		? asyncComponent(() => import('./Triagem/IETUpdateCaracteristica'))
		: () => null;

class IECaracteristica extends Component {
	static focusOnElem(elem_id) {
		setTimeout(() => {
			const elem = document.getElementById(elem_id);
			if (elem) elem.focus();
		}, 60);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { item, caracteristica_id } = nextProps;
		const item_id = item.id;
		const c_id = Number(caracteristica_id);
		// Check error
		const { errors } = item.front || {};
		const error = (errors || [])
			.filter(e => e.type === 'caracteristica' && e.carac_id === c_id)
			.length > 0;
		const error_unidade = (errors || [])
			.filter(e => e.type === 'caracteristica_unidade' && e.carac_id === c_id)
			.length > 0;
		// Check outra unidade
		let outro = prevState.outro || false;
		let outra_unidade = prevState.outra_unidade || false;
		if (item.id !== prevState.item_id) {
			outro = false;
			outra_unidade = false;
		}
		return { item_id, outro, outra_unidade, error, error_unidade };
	}

	state = {
		item_id: 0,
		outro: false,
		outra_unidade: false,
		error: false,
		error_unidade: false,
	};

	componentDidMount() {
		this.checkOutro(this.props);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (!shallowequal(this.props, nextProps)) return true;
		if (!shallowequal(this.state, nextState)) return true;
		return false;
	}

	setOutroUpdateCarac = () => {
		this.setState({ outro: false });
	}

	handleOutro = () => {
		const outro = !this.state.outro;
		const {
			item_key,
			caracteristica_id,
			updateItemCaracteristica,
		} = this.props;
		const c_id = Number(caracteristica_id);
		const opcao_zerada = buildOpcaoPreenchida(
			c_id,
			'',
			'',
			null,
		);
		updateItemCaracteristica(item_key, c_id, [opcao_zerada]);
		if (outro) {
			this.constructor.focusOnElem(`resposta-${item_key}-${c_id}`);
		}
		const outra_unidade = !outro
			? false
			: this.state.outra_unidade;
		this.setState({ outro, outra_unidade });
	}

	handleOutraUnidade = () => {
		const outra_unidade = !this.state.outra_unidade;
		const {
			item_key,
			caracteristica_id,
			updateItemCaracteristica,
			caracteristica_preenchida,
		} = this.props;
		const c_id = Number(caracteristica_id);
		const opcao_zerada_unidade = caracteristica_preenchida.opcoes_preenchidas.length > 0
			? { ...caracteristica_preenchida.opcoes_preenchidas[0], unidade: '' }
			: buildOpcaoPreenchida(c_id, '', '', null);
		updateItemCaracteristica(item_key, c_id, [opcao_zerada_unidade]);
		if (outra_unidade) {
			this.constructor.focusOnElem(`caracteristica_un_${item_key}_${c_id}`);
		}
		this.setState({ outra_unidade });
	}

	updateCaracteristica = (item_id, ca_id, value) => {
		const c_id = Number(ca_id);
		const { error } = this.state;
		const { item } = this.props;
		const front = !error
			? { ...item.front }
			: {
				...item.front,
				errors: (item.front.errors || [])
					.filter(e => {
						if (e.type === 'caracteristica' && e.carac_id === c_id) {
							return false;
						}
						return true;
					}),
			};
		this.setState({ error: false });
		this.updateAll(item_id, ca_id, value, front);
	}

	updateUnidade = (item_id, ca_id, value) => {
		const c_id = Number(ca_id);
		const { error_unidade } = this.state;
		const { item } = this.props;
		const front = !error_unidade
			? { ...item.front }
			: {
				...item.front,
				errors: (item.front.errors || [])
					.filter(e => {
						if (e.type === 'caracteristica_unidade' && e.carac_id === c_id) {
							return false;
						}
						return true;
					}),
			};
		this.setState({ error_unidade: false });
		this.updateAll(item_id, ca_id, value, front);
	}

	updateAll = (item_id, ca_id, value, front) => {
		const opcao_preenchida = value[0] || {};
		// const opcoes_preenchidas = [{
		// 	...opcao_preenchida,
		// 	caracteristica_id: Number(opcao_preenchida.caracteristica_id || 0),
		// }];
		const opcoes_preenchidas = value.length === 1
			? [{
				...opcao_preenchida,
				caracteristica_id: Number(opcao_preenchida.caracteristica_id || 0),
			}]
			: [...value];

		const { item, updateFullItemById } = this.props;
		const caracteristicas_preenchidas = {
			...item.item_preenchido.caracteristicas_preenchidas,
			[ca_id]: {
				...item.item_preenchido.caracteristicas_preenchidas[ca_id],
				opcoes_preenchidas,
			},
		};
		const new_item = {
			...item,
			item_preenchido: {
				...item.item_preenchido,
				caracteristicas_preenchidas,
			},
			front,
		};
		updateFullItemById(item_id, new_item);
	}

	handleOutroAlert = () => {
		const { caracteristica_id } = this.props;
		const elem = document.getElementById(`tooltip-outro-${caracteristica_id}`);
		if (elem) {
			const className = elem.className === 'tooltip top'
				? 'tooltip top in'
				: 'tooltip top';
			elem.className = className;
		}
	}

	checkOutro(props) {
		const {
			opcoes_preenchidas,
		} = props.caracteristica_preenchida;
		const {
			opcoes,
			selecao,
			tipo_de_valor,
		} = props.caracteristica_preenchida.caracteristica;
		const caracteristica_type = caracteristicaType(
			opcoes,
			selecao,
			tipo_de_valor,
		);
		const validada = opcoes_preenchidas.length > 0
			? opcoes_preenchidas[0].validada
			: null;
		const valor = opcoes_preenchidas.length > 0
			? opcoes_preenchidas[0].valor
			: '';
		if (caracteristica_type === 'selecao' && !validada && valor !== '') {
			this.setState({ outro: true });
		}
		const unidade = opcoes_preenchidas.length > 0
			? opcoes_preenchidas[0].unidade
			: '';
		if (
			unidade !== ''
			&& opcoes.filter(x => x.unidade === unidade).length === 0
		) {
			this.setState({ outra_unidade: true });
		}
	}

	render() {
		const {
			outro,
			outra_unidade,
			error,
			error_unidade,
		} = this.state;
		const {
			item,
			item_key,
			caracteristica_id,
			caracteristica_preenchida,
			autoSave,
			show_outro,
			updateFullItemById,
			sienge_liberado,
		} = this.props;
		const {
			opcoes_preenchidas,
		} = caracteristica_preenchida;
		const {
			nome,
			opcoes,
			selecao,
			tipo_de_valor,
			visao_construtor,
			visao_fornecedor,
		} = caracteristica_preenchida.caracteristica;
		const caracteristica_type = caracteristicaType(
			opcoes,
			selecao,
			tipo_de_valor,
		);

		const opcoes_unidades = opcoes
			.filter(o => o.unidade !== '' && o.valor === '');

		const opcoes_completas = opcoes.filter(o => o.valor !== '');
		const opcao_preenchida = opcoes_preenchidas.length > 0
			? { ...opcoes_preenchidas[0] }
			: buildOpcaoPreenchida(caracteristica_id, '', '', null);
		const valor = opcoes_preenchidas.length > 0
			? opcoes_preenchidas[0].valor
			: '';
		const unidade = opcoes_preenchidas.length > 0
			? opcoes_preenchidas[0].unidade
			: '';

		const has_unidade = (
			opcoes.length > 0
			&& caracteristica_type !== 'selecao'
			&& caracteristica_type !== 'multipla_escolha'
		) || (
			caracteristica_type === 'selecao'
			&& outro
			&& opcoes.length > 0
			&& opcoes[0].unidade !== ''
		);

		const c_wrapper_classes = {
			'has-unidade2': has_unidade,
			'has-error': error,
		};
		const c_wrapper_class = setClasses('form-group fg-resposta', c_wrapper_classes);
		const c_un_wrapper_classes = {
			'has-error': error_unidade,
		};
		const c_un_wrapper_class = setClasses('form-group fg-unidade', c_un_wrapper_classes);
		const label_class = error
			? 'label has-error'
			: 'label';

		let is_obrigatorio = '';
		if (visao_construtor === 2 || visao_fornecedor === 2) {
			is_obrigatorio = <span styleName="obrigatorio-label">*</span>;
		}

		const nao_validadas = opcoes_preenchidas.filter(o => !o.validada);

		// check outra unidade
		const has_opcoes_unidade = opcoes
			.filter(o => o.valor === '' && o.unidade !== '')
			.length > 0;
		const unidade_preenchida = (opcao_preenchida || {}).unidade || '';
		const outra_unidade_preenchida = opcoes
			.filter(o => o.unidade === unidade_preenchida)
			.length === 0;
		const unidade_nova = (
			has_opcoes_unidade
			&& outra_unidade_preenchida
			&& unidade_preenchida !== ''
		);

		const need_update = (
			nao_validadas.length > 0
			&& nao_validadas[0].valor !== ''
			&& (
				caracteristica_type === 'selecao'
				|| caracteristica_type === 'multipla_escolha'
			)
		) || unidade_nova;
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';

		const valor_select = typeof valor === 'number'
			? setDecimalsToString(valor)
			: valor;

		// Verifica resposta
		const carac_de_resposta = (((
			item.item_de_resposta || {})
			.item_preenchido || {})
			.caracteristicas_preenchidas || [])
			.filter(c => c.caracteristica.id === Number(caracteristica_id))[0]
			|| {};
		const has_carac_de_resposta = carac_de_resposta.opcoes_preenchidas !== undefined;
		const valor_de_resposta = has_carac_de_resposta
			? (carac_de_resposta.opcoes_preenchidas[0] || {}).valor || ''
			: null;
		const un_de_resposta = has_carac_de_resposta
			? (carac_de_resposta.opcoes_preenchidas[0] || {}).unidade || ''
			: null;
		const resposta_eh_diferente = (
			valor_de_resposta !== valor
			|| un_de_resposta !== unidade
		);

		return (
			<div styleName="row-edition">
				{((outro || outra_unidade) && resposta_eh_diferente && !sienge_liberado) && (
					<>
						<div id={`tooltip-outro-${caracteristica_id}`} className="tooltip top" style={{ top: '-82px', left: '-31px', marginTop: '0' }} role="tooltip">
							<div className="tooltip-arrow" style={{ borderTopColor: '#F2994A', left: '6%' }} />
							<div className="tooltip-inner" style={{ backgroundColor: '#F2994A', maxWidth: '300px', textAlign: 'left' }}>
								Compras com itens que não estão associados ao sistema Conaz passam por um processo de validação e isso pode atrasar o envio aos fornecedores
							</div>
						</div>
						<i
							className="fa fa-exclamation-circle"
							styleName="outro-valor-warning-icon"
							aria-hidden="true"
							onMouseEnter={this.handleOutroAlert}
							onMouseLeave={this.handleOutroAlert} />
					</>
				)}
				{(need_update && is_triagem) && (
					<IETUpdateCaracteristica
						item={item}
						caracteristica_id={caracteristica_id}
						opcoes_preenchidas={opcoes_preenchidas}
						unidade_nova={unidade_nova}
						autoSave={autoSave}
						updateFullItemById={updateFullItemById}
						setOutroUpdateCarac={this.setOutroUpdateCarac} />
				)}
				<div className={c_wrapper_class}>
					<label styleName={label_class}>{nome} {is_obrigatorio}</label>
					{caracteristica_type === 'texto' && (
						<InputText
							type="caracteristica"
							field_id={`resposta-${item_key}-${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={this.updateCaracteristica}
							handleOutro={this.handleOutro}
							autoSave={autoSave}
							zerar_erros={false} />
					)}
					{caracteristica_type === 'numero' && (
						<InputNumber
							type="caracteristica"
							field_id={`resposta-${item_key}-${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={this.updateCaracteristica}
							autoSave={autoSave}
							zerar_erros={false} />
					)}
					{(caracteristica_type === 'selecao' && !outro) && (
						<InputSelect
							type="caracteristica"
							item_key={item_key}
							field={caracteristica_id}
							default_value={`${valor_select} ${unidade}`}
							opcoes={opcoes_completas}
							updateFunction={this.updateCaracteristica}
							handleOutro={this.handleOutro}
							autoSave={autoSave}
							show_outro={show_outro}
							zerar_erros={false} />
					)}
					{/* (caracteristica_type === 'selecao' && outro) && */}
					{(
						caracteristica_type === 'selecao'
						&& outro
						&& tipo_de_valor === 'texto'
					) && (
						<InputText
							type="caracteristica"
							field_id={`resposta-${item_key}-${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={this.updateCaracteristica}
							outro={outro}
							handleOutro={this.handleOutro}
							autoSave={autoSave}
							zerar_erros={false} />
					)}
					{(
						caracteristica_type === 'selecao'
						&& outro
						&& tipo_de_valor === 'numero'
					) && (
						<InputNumber
							type="caracteristica"
							field_id={`resposta-${item_key}-${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={this.updateCaracteristica}
							outro={outro}
							handleOutro={this.handleOutro}
							autoSave={autoSave}
							zerar_erros={false} />
					)}
					{caracteristica_type === 'multipla_escolha' && (
						<InputMultiple
							type="caracteristica"
							item_key={item_key}
							field={caracteristica_id}
							opcoes={opcoes_completas}
							opcoes_selecionadas={opcoes_preenchidas}
							updateFunction={this.updateCaracteristica}
							autoSave={autoSave}
							tipo_de_valor={tipo_de_valor}
							zerar_erros={false} />
					)}
				</div>

				{(
					opcoes.length > 0
					&& caracteristica_type !== 'selecao'
					&& caracteristica_type !== 'multipla_escolha'
					&& !outra_unidade
					// form-group-unidades
				) && (
					<div className={c_un_wrapper_class}>
						<label><br /></label>
						<InputUnidade
							type="unidade-caracteristica"
							field_id={`caracteristica_un_${item_key}_${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={unidade}
							opcao_preenchida={opcao_preenchida}
							opcoes={opcoes_unidades}
							updateFunction={this.updateUnidade}
							handleOutraUnidade={this.handleOutraUnidade}
							autoSave={autoSave}
							show_outra_unidade={show_outro}
							zerar_erros={false} />
					</div>
				)}

				{(
					caracteristica_type === 'selecao'
					&& outro
					&& opcoes.length > 0
					&& opcoes[0].unidade !== ''
				) && (
					<div className={c_un_wrapper_class}>
						<label><br /></label>
						<InputText
							type="unidade-caracteristica"
							field_id={`caracteristica_un_${item_key}_${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={unidade}
							opcao_preenchida={opcao_preenchida}
							updateFunction={this.updateUnidade}
							outro={outra_unidade}
							handleOutro={this.handleOutraUnidade}
							autoSave={autoSave}
							zerar_erros={false} />
					</div>
				)}

				{(
					outra_unidade
					&& (
						caracteristica_type === 'texto'
						|| caracteristica_type === 'numero'
					)
				) && (
					<div className={c_un_wrapper_class}>
						<label><br /></label>
						<InputText
							type="unidade-caracteristica"
							field_id={`caracteristica_un_${item_key}_${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={unidade}
							opcao_preenchida={opcao_preenchida}
							updateFunction={this.updateUnidade}
							outro={outra_unidade}
							handleOutro={this.handleOutraUnidade}
							autoSave={autoSave}
							zerar_erros={false} />
					</div>
				)}
			</div>
		);
	}
}

IECaracteristica.propTypes = {
	item: PropTypes.object.isRequired,
	item_key: PropTypes.number.isRequired,
	caracteristica_id: PropTypes.string.isRequired,
	caracteristica_preenchida: PropTypes.object.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	updateItemCaracteristica: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
	sienge_liberado: PropTypes.bool.isRequired,
	show_outro: PropTypes.bool,
};

IECaracteristica.defaultProps = {
	show_outro: true,
};

export default CSSModules(IECaracteristica, styles, { allowMultiple: true });

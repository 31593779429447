import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const FornecedorReview = ({
	fornecedor,
	// cliente_id,
	inclui_link_edicao,
}) => {
	const origin = window.location.href;
	let edicao_forn_base_url = 'https://crud-fornecedores.conazweb.com.br';
	if (
		origin.indexOf('http://localhost') !== -1 ||
		origin.indexOf('c-f2b2-t.firebaseapp.com') !== -1 ||
		origin.indexOf('ct-f2b2-t.firebaseapp.com') !== -1
	) {
		edicao_forn_base_url = 'https://fi-f1b2-t.firebaseapp.com';
	} else if (
		origin.indexOf('c-f2b2-d.firebaseapp.com') !== -1 ||
		origin.indexOf('ct-f2b2-d.firebaseapp.com') !== -1 ||
		origin.indexOf('demonstracao.conazweb.com.br') !== -1
	) {
		edicao_forn_base_url = 'https://fi-f1b2-d.firebaseapp.com';
	}

	// const h3_class = cliente_id === fornecedor.cadastrado_por
	// 	? { marginBottom: '5px' }
	// 	: {};
	// <h3 style={h3_class}>

	return (
		<>
			{inclui_link_edicao ? (
				<h3>
					<a href={`${edicao_forn_base_url}/app/fornecedores/${fornecedor.id}`} target="_blank" rel="noopener noreferrer">{fornecedor.nome}</a>
				</h3>
			) : (
				<h3>{fornecedor.nome}</h3>
			)}
			<div styleName="fornecedor-title-stars">
				<i className="fa fa-star" aria-hidden="true" />
				<i className="fa fa-star" aria-hidden="true" />
				<i className="fa fa-star" aria-hidden="true" />
				<i className="fa fa-star" aria-hidden="true" />
				<i className="fa fa-star" aria-hidden="true" />
				<span>Em período de coleta de avaliações</span>
			</div>
		</>
	);
};

FornecedorReview.propTypes = {
	fornecedor: PropTypes.object.isRequired,
	// cliente_id: PropTypes.number,
	inclui_link_edicao: PropTypes.bool.isRequired,
};

// FornecedorReview.defaultProps = {
// 	cliente_id: -1,
// };

export default CSSModules(FornecedorReview, styles, { allowMultiple: true });

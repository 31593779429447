import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { formatCurrency } from '../../_functions/_formatCurrency';
import { editMomentDateFormat } from '../../_functions/_editDateFormat';
import { defineStatusPedido } from '../../_functions/_defineStatusPedido';

const areEqual = (prevProps, nextProps) => prevProps !== nextProps;

const CotacaoRow = ({
	cotacao,
	titulo,
	codigo,
	data_entrega_desejada,
	prazo_de_resposta,
	ultima_atualizacao,
	valor_total,
	fornecedor_vencedor,
	// preco_medio,
	quantidade_rcs,
	quantidade_rcs_completos,
}) => {
	const compareDates = date => {
		if (moment(date).isBefore(new Date())) return true;
		return false;
	};

	const status = defineStatusPedido(cotacao);

	let cotacao_link = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno'
		? `/cotacao/nova/${codigo}`
		: `/cotacao/${codigo}`;
	const status_class = `status s-${status}`;
	let label_text = '';

	switch (status) {
	case 'cancelado':
		label_text = 'Cancelada';
		break;
	case 'rascunho':
		label_text = 'Rascunho';
		cotacao_link = `/cotacao/nova/${codigo}`;
		break;
	case 'refinamento':
		label_text = 'Refinamento solicitado';
		cotacao_link = `/cotacao/nova/${codigo}`;
		break;
	case 'em_edicao':
		label_text = 'Em Edição';
		cotacao_link = `/cotacao/nova/${codigo}`;
		break;
	case 'envio_solicitado':
		label_text = 'Envio solicitado';
		cotacao_link = `/cotacao/nova/${codigo}/revisao`;
		break;
	case 'qc_disponivel':
		label_text = 'QC disponível para compra';
		break;
	case 'compras_aguardando_confirmacao':
		label_text = 'Compra(s) aguardando confirmação';
		break;
	case 'finalizado':
		label_text = <span>Compra efetuada <i className="fa fa-check fa-fw" aria-hidden="true" /></span>;
		break;
	case 'revisao_conaz':
	case 'aguardando_cotacoes':
	default:
		label_text = 'Aguardando cotações';
	}

	const prazo_de_resposta_view = (prazo_de_resposta !== null && prazo_de_resposta !== '')
		? editMomentDateFormat(prazo_de_resposta)
		: '';
	const data_entrega_desejada_view = (data_entrega_desejada !== null && data_entrega_desejada !== '')
		? editMomentDateFormat(data_entrega_desejada)
		: '';
	const ultima_atualizacao_view = (ultima_atualizacao !== null && ultima_atualizacao !== '')
		? editMomentDateFormat(ultima_atualizacao)
		: '';
	const prazo_de_resposta_view_nao_formatada = (prazo_de_resposta !== null && prazo_de_resposta !== '')
		? (prazo_de_resposta)
		: '';
	const data_entrega_desejada_view_nao_formatada = (data_entrega_desejada !== null && data_entrega_desejada !== '')
		? (data_entrega_desejada)
		: '';
	const valor_compras_class = status === 'compras_aguardando_confirmacao'
		? 'valor-compras vc60'
		: 'valor-compras';
	const valor_total_view = (valor_total !== 0 && valor_total !== null)
		? <div styleName={valor_compras_class}>{formatCurrency(valor_total, 2, 'R$')}</div>
		: <em>Sem registro</em>;
	const fornecedor_vencedor_view = (fornecedor_vencedor !== '' && fornecedor_vencedor !== null)
		? fornecedor_vencedor
		: <em>Sem registro</em>;

	const label_atraso_bool = compareDates(data_entrega_desejada)
		&& status === 'qc_disponivel'
		&& data_entrega_desejada_view !== '';

	const label_atraso_bool_prazo = compareDates(prazo_de_resposta)
		&& status === 'qc_disponivel'
		&& prazo_de_resposta_view !== '';
	return (
		<Link to={cotacao_link} styleName="cotacao-row-a">
			<div styleName="cotacao-row">
				<i className="fa fa-folder-o" aria-hidden="true" />
				<div styleName="titulo">
					<p>{titulo}</p>
					<span styleName="s-codgido">
						#{codigo}
						{
							label_atraso_bool
							&& (
								<div styleName="atraso-label">
									<p>Em atraso</p>
								</div>
							)
						}
					</span>
				</div>
				<div styleName={status_class}>
					<div styleName="status-title">{label_text}</div>
					<div styleName="progress-bar">
						<div styleName={`progress-0 s-${status}`} />
						<div styleName={`progress-1 s-${status}`} />
						<div styleName={`progress-2 s-${status}`} />
						<div styleName={`progress-3 s-${status}`} />
						<div styleName={`progress-4 s-${status}`} />
					</div>
				</div>
				{(
					status === 'envio_solicitado' ||
					status === 'revisao_conaz' ||
					status === 'aguardando_cotacoes' ||
					status === 'refinamento' ||
					status === 'em_edicao' ||
					status === ('qc_disponivel' && prazo_de_resposta_view)
				) && (
					<div styleName="info-adicional first">
						<div styleName="info">Prazo da cotação</div>
						{prazo_de_resposta_view}
					</div>
				)}
				{status === 'revisao_conaz' && (
					<div styleName="info-adicional">
						<div styleName="info">Respostas / Solicitações</div>
						Buscando fornecedores
					</div>
				)}
				{status === 'aguardando_cotacoes' && (
					<div styleName="info-adicional">
						<div styleName="info">Respostas / Solicitações</div>
						{quantidade_rcs_completos} / {quantidade_rcs}
					</div>
				)}
				{status === 'qc_disponivel' && (
					<>
						{data_entrega_desejada_view !== '' && prazo_de_resposta_view_nao_formatada < data_entrega_desejada_view_nao_formatada && label_atraso_bool_prazo && (
							<div styleName="info-adicional first">
								<div styleName={label_atraso_bool ? 'info atraso' : 'info'}>Data de entrega</div>
								<span styleName={label_atraso_bool ? 'info atraso' : 'info em_dia'}>{data_entrega_desejada_view}</span>
							</div>
						)}

						{data_entrega_desejada_view !== '' && !label_atraso_bool && !label_atraso_bool_prazo && (
							<div styleName="info-adicional first">
								<div styleName={label_atraso_bool ? 'info atraso' : 'info'}>Prazo da cotação</div>
								<span styleName={label_atraso_bool ? 'info atraso ' : 'info em_dia'}>{prazo_de_resposta_view}</span>
							</div>
						)}
						{data_entrega_desejada_view && (
							<div styleName="info-adicional">
								<div styleName="info">Respostas / Solicitações</div>
								{quantidade_rcs_completos} / {quantidade_rcs}
							</div>
						)}
					</>
				)}
				{(
					status === 'compras_aguardando_confirmacao' ||
					status === 'finalizado'
				) && (
					<>
						<div styleName="info-adicional first">
							<div styleName="info">Total das compras</div>
							{valor_total_view}
						</div>
						<div styleName="info-adicional">
							<div styleName="info">Fornecedor</div>
							{fornecedor_vencedor_view}
						</div>
					</>
				)}
				{(status === 'rascunho' && ultima_atualizacao_view !== '') && (
					<div styleName="info-adicional first">
						<div styleName="info">Última edição</div>
						{ultima_atualizacao_view}
					</div>
				)}
			</div>
		</Link>
	);
};

CotacaoRow.propTypes = {
	cotacao: PropTypes.object.isRequired,
	codigo: PropTypes.number.isRequired,
	titulo: PropTypes.string,
	data_entrega_desejada: PropTypes.string,
	prazo_de_resposta: PropTypes.string,
	valor_total: PropTypes.number,
	fornecedor_vencedor: PropTypes.string,
	ultima_atualizacao: PropTypes.string,
	// preco_medio: PropTypes.number,
	quantidade_rcs: PropTypes.number,
	quantidade_rcs_completos: PropTypes.number,
};

CotacaoRow.defaultProps = {
	titulo: '',
	data_entrega_desejada: '',
	prazo_de_resposta: '',
	valor_total: 0,
	fornecedor_vencedor: '',
	ultima_atualizacao: '',
	// preco_medio: 0,
	quantidade_rcs: null,
	quantidade_rcs_completos: null,
};

export default memo(CSSModules(CotacaoRow, styles, { allowMultiple: true }), areEqual);

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Alerts from '../../components/_parts/Alerts/Alerts';
import CreateCotacaoButton from '../../components/_parts/CreateCotacaoButton/CreateCotacaoButton';
import Tab from './subcomponents/Tab';
import Pagination from './subcomponents/Pagination';
import CotacaoRow from '../../components/_parts/CotacaoRow/CotacaoRow';
import LoadingPage from '../../components/_parts/_loadings/LoadingPage/LoadingPage';

// Functions
// import { defineStatusPedido } from '../../components/_functions/_defineStatusPedido';

const Cotacoes = ({
	/* state */
	/* props */ ui, cotacoes, cotacao, user, match,
	/* propf */ updateModals, updateUi, reportErrors, updateCotacao,
	/* funcs */ handleChangeTab, handleChangePage,
}) => {
	const { obra_selected } = user;
	const { tabs, alerts } = ui;
	const { criando_cotacao } = cotacao;

	const { cotacoes: tab } = tabs;
	const { result, page } = cotacoes[tab];
	const cotacoes_on_page = [...result];
	// let divisao_em_andamento = false;

	return (
		<>
			<Alerts alerts={alerts} updateUi={updateUi} />

			{/* Header */}
			<div className="header fixed">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-6">
							{!!alerts.escolhe_cotacao.visible && (
								<div styleName="alert-selecionar-cotacao">Selecione abaixo qual cotação deseja efetuar a compra</div>
							)}
							<h3 className="h3-header">Cotações</h3>
						</div>
						<div className="col-xs-12 col-sm-6" styleName="create-cotacao-button-wrapper">
							<CreateCotacaoButton
								obra_selected={obra_selected}
								reportErrors={reportErrors}
								criando_cotacao={criando_cotacao}
								updateCotacao={updateCotacao}
								updateModals={updateModals}
								route_path={match.path} />
						</div>
					</div>
					<Tab
						tab={tab}
						cotacoes={cotacoes}
						handleChangeTab={handleChangeTab}
					/>
				</div>
			</div>

			<div className="container" styleName="container-list">
				<div className="row">
					<div className="col-xs-12">
						{cotacoes[`RS_${tab}`] === 2 || cotacoes[`RS_${tab}`] === 3
							? cotacoes_on_page.map(ct => {
								const _CotacaoRow = (
									<CotacaoRow
										key={ct.id}
										cotacao={ct}
										codigo={ct.id}
										titulo={ct.titulo}
										data_entrega_desejada={ct.data_de_entrega_desejada}
										prazo_de_resposta={ct.prazo_de_resposta}
										valor_total={ct.valor_total}
										fornecedor_vencedor={ct.vencedor}
										ultima_atualizacao={ct.ultima_atualizacao}
										quantidade_rcs={ct.qtd_rcs}
										quantidade_rcs_completos={ct.qtd_rcs_completos}
										updateUi={updateUi} />
								);

								/**
								 * Divisao entre cotações em andamento
								 */
								// if (i > 0 && i < 20) {
								// 	const status_anterior = defineStatusPedido(cotacoes_on_page[i - 1]);
								// 	const status_atual = defineStatusPedido(ct);
								// 	if (
								// 		(
								// 			status_anterior === 'refinamento' ||
								// 			status_anterior === 'envio_solicitado' ||
								// 			status_anterior === 'em_edicao'
								// 		) &&
								// 		status_atual !== 'refinamento' &&
								// 		status_atual !== 'envio_solicitado' &&
								// 		status_atual !== 'em_edicao' &&
								// 		!divisao_em_andamento &&
								// 		tab !== 'canceladas'
								// 	) {
								// 		divisao_em_andamento = true;
								// 		return (
								// 			<React.Fragment key={ct.id}>
								// 				<div styleName="divisao-em-andamento">
								// 					<div styleName="divisao-line" />
								// 				</div>
								// 				{_CotacaoRow}
								// 			</React.Fragment>
								// 		);
								// 	}
								// }

								/**
							 * Lista normalmente
							 */
								return _CotacaoRow;
							})
							: <LoadingPage />}
					</div>
				</div>

				{cotacoes_on_page.length === 0 && (cotacoes[`RS_${tab}`] === 2 || cotacoes[`RS_${tab}`] === 3) && (
					<div className="row">
						<div className="col-xs-12">
							<div styleName="empty-wrapper">
								<i className="fa fa-inbox" aria-hidden="true" />
								<h4>Nenhum pedido nesta seção.</h4>
							</div>
						</div>
					</div>
				)}

				{cotacoes_on_page.length > 0
				&& page.total > 20
				&& <Pagination
					requestState={cotacoes[`RS_${tab}`]}
					activePage={page.number}
					totalItens={page.total}
					per_page={page.per_page}
					handleChangePage={handleChangePage}
				/>}

				<div className="row">
					<div className="col-xs-12">
						<p><br /></p><p><br /></p>
					</div>
				</div>
			</div>
		</>
	);
};

Cotacoes.propTypes = {
	// =========== local
	handleChangeTab: PropTypes.func.isRequired,
	handleChangePage: PropTypes.func.isRequired,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	cotacoes: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	// =========== funcs
	updateUi: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
};

export default CSSModules(Cotacoes, styles, { allowMultiple: true });

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Loading from '../../../components/_parts/_loadings/Loading/Loading';
import ModalPortal from '../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalConfirm from '../../../components/_parts/_modals/ModalConfirm/ModalConfirm';

// Functions
import { _post } from '../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../components/_functions/_handleRequestErrors';
import { defineStatusPedido } from '../../../components/_functions/_defineStatusPedido';

class EditarCotacao extends Component {
	state = {
		editando: 0,
		error_modal: false,
	};

	openModalAlert = () => {
		const { openConfirmEdicao, handleMenu } = this.props;
		openConfirmEdicao();
		handleMenu();
	}

	sendEditar = () => {
		this.setState({ editando: 1 });
		const { handleInsideConfirm, match, history, updateModals } = this.props;
		handleInsideConfirm();
		_post(`/conaz/v2/pedidos/${match.params.id}/iniciar_edicao`, {}).then(() => {
			history.replace(`/cotacao/nova/${match.params.id}`);
		}).catch(error => {
			const conaz_error = ((
				error.response || {})
				.data || {})
				.message || '';

			if (conaz_error === 'Apenas os responsáveis da compra podem editá-la.') {
				this.handleErrorModal();
				return;
			}

			updateModals('error', true);
			handleRequestErrors(error);
		});
	}

	handleErrorModal = () => {
		const error_modal = !this.state.error_modal;
		this.setState({ error_modal });
	}

	render() {
		const { editando, error_modal } = this.state;
		const { inside_confirm, cotacao } = this.props;
		const status = defineStatusPedido(cotacao);

		if (editando === 1) {
			return (
				<button style={{ position: 'relative', margin: '5px 0 11px', color: '#a1b0bf' }} data-acoes-pedido-menu>
					<Loading /> Aguarde...
				</button>
			);
		}

		if (status === 'revisao_conaz' && !inside_confirm) {
			return (
				<>
					{error_modal && (
						<ModalPortal>
							<ModalWrapperPortal closeModal={this.handleErrorModal}>
								<ModalConfirm
									title="Este pedido possui já compras ativas, logo não pode ser editado."
									message={<></>}
									button_type="secondary-conaz"
									button_text="Ok, entendi"
									cancelar_button={false} />
							</ModalWrapperPortal>
						</ModalPortal>
					)}
					<button
						type="button"
						onClick={this.sendEditar}
						data-acoes-pedido-menu>
						<i className="fa fa-pencil fa-fw" aria-hidden="true" data-acoes-pedido-menu /> Editar cotação
					</button>
				</>
			);
		}

		if ((status === 'aguardando_cotacoes' || status === 'qc_disponivel') && !inside_confirm) {
			return (
				<button
					type="button"
					onClick={this.openModalAlert}
					data-acoes-pedido-menu>
					<i className="fa fa-pencil fa-fw" aria-hidden="true" data-acoes-pedido-menu /> Editar cotação
				</button>
			);
		}

		return null;
	}
}

EditarCotacao.propTypes = {
	openConfirmEdicao: PropTypes.func.isRequired,
	handleMenu: PropTypes.func.isRequired,
	inside_confirm: PropTypes.bool.isRequired,
	handleInsideConfirm: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
	cotacao: PropTypes.object.isRequired,
};

export default CSSModules(EditarCotacao, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';

// Components
import Rating from '../Rating';

// Style
import {
	Div,
	CloseButton,
	EditButton,
	Title,
	LabelTitle,
	RowInfo,
	CommentBlock,
	RowRating,
} from './ModalEvaluationResume.style';

const ModalEvaluationResume = props => {
	const {
		closeModal,
		compra,
		handleEvaluation,
	} = props;

	const {
		avaliacao_fornecedor_estrelas,
		avaliacao_fornecedor_comentario,
		avaliacao_fornecedor_respostas,
		avaliacao_fornecedor,
		avaliacao_fornecedor_status_comentario,
	} = compra;

	const estrelas = ((avaliacao_fornecedor || {}).detalhe || {}).estrelas || avaliacao_fornecedor_estrelas;
	const respostas = ((avaliacao_fornecedor || {}).detalhe || {}).respostas || avaliacao_fornecedor_respostas;
	const comentario = ((avaliacao_fornecedor || {}).detalhe || {}).comentario || avaliacao_fornecedor_comentario || '';
	const status_comentario = ((avaliacao_fornecedor || {}).detalhe || {}).status_comentario || avaliacao_fornecedor_status_comentario || null;

	const handleEdition = () => {
		closeModal();
		handleEvaluation();
	};

	const criterios = [
		{
			text: 'A entrega foi realizada dentro do prazo?',
			success: '',
			failed: '',
		},
		{
			text: 'Os documentos (NFs, boletos, etc) foram entregues corretamente?',
			success: '',
			failed: '',
		},
		{
			text: 'Os materiais entregues estavam de acordo com a solicitação?',
			success: '',
			failed: '',
		},
		{
			text: 'Os materiais estavam em boas condições de uso?',
			success: '',
			failed: '',
		},
	];

	respostas.forEach(({ tipo_questao, resposta }) => {
		if (resposta) criterios[tipo_questao - 1].success = 'disabled';
		else criterios[tipo_questao - 1].failed = 'disabled';
	});

	const commentAvaliation = status => {
		if (status === 2) return <CommentBlock>{comentario}</CommentBlock>;
		if (status === 3) return <CommentBlock>Comentário reprovado pela análise.</CommentBlock>;
		return <CommentBlock>Comentário enviado para análise.</CommentBlock>;
	};

	const nome_fornecedor = compra.nome_fornecedor || compra.fornecedor.nome;

	return (
		<Div>
			<EditButton
				onClick={handleEdition}
				className="btn-link">
				Editar avaliação
			</EditButton>
			<CloseButton
				className="close"
				onClick={closeModal}>
				<i className="fa fa-times" />
			</CloseButton>
			<Title>
				Sua avaliação da entrega referente à compra {compra.titulo} com {nome_fornecedor}
			</Title>
			<LabelTitle>
				Como foi sua experiência com o fornecedor {nome_fornecedor} ?
			</LabelTitle>
			<RowRating><Rating rate={estrelas} lock /><span>{estrelas}.0</span></RowRating>
			<LabelTitle>Critérios de avaliação</LabelTitle>
			{criterios.map(({ text, success, failed }) => (
				<RowInfo key={text}>
					<span>{text}</span>
					<span className={success}><i className="fa fa-times" /></span>
					<span className={failed}><i className="fa fa-check" /></span>
				</RowInfo>
			))}
			{((status_comentario !== 2 && status_comentario !== null) || comentario !== '') && (
				<>
					<LabelTitle>Seu comentário sobre a entrega do fornecedor:</LabelTitle>
					{commentAvaliation(status_comentario)}
				</>
			)}
		</Div>
	);
};

ModalEvaluationResume.propTypes = {
	closeModal: PropTypes.func.isRequired,
	handleEvaluation: PropTypes.func.isRequired,
	compra: PropTypes.object.isRequired,
};

export default ModalEvaluationResume;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';

// CSS
import CSSModules from 'react-css-modules';
import { Button, Modal } from 'antd';
import styles from './style.module.sass';

// Componentes
import Fornecedor from '../Fornecedor';
import Transporte from '../Transporte';
import Pagamento from '../Pagamento';
import DadosObra from '../DadosObra';
import Anexos from '../Anexos';
import TipoCompra from '../TipoCompra';
import Observacoes from '../Observacoes';
import OpenItensMobile from '../OpenItensMobile';
import ResumoActions from '../ResumoActions/ResumoActions';
import ModalPortal from '../../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalExportaCompras from '../ModalExportaCompras/ModalExportaCompras';
import ModalImpressaoCompras from '../ModalImpressao/ModalImpressao';
import AlertaEdicaoModal from '../AlertaEdicao/AlertaEdicao';
import DataDeRegistro from '../DataDeRegistro';
import ModalConfirm from '../../../../components/_parts/_modals/ModalConfirm/ModalConfirm';
import ModalNotificaSobreCompra from '../../../../components/_parts/_modals/ModalNotificaDecisor/ModalNotificaSobreCompra';
import Tooltip from '../../../../components/_parts/_tooltips/ButtonToolTip/ButtonToolTip';
import BtnExportSienge from '../ExportSiengeSteps/BtnExportSienge';
import CompraRowButtons from '../../../../components/_parts/CompraRowButtons';
import CompraRowAvaliacao from '../../../../components/_parts/CompraRowNova/CompraRowAvaliacao';
// import InfoExportacaoSienge from './InfoExportacaoSienge';
import ExportStatus from './ExportStatus';
import IconeExclamacao from '../../../../icons/icone_exclamacao.svg';

// Functions
import { _put, _post } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';
import { defineStatusCompra } from '../../../../components/_functions/_defineStatusCompra';

// Avaliacoes
import { avaliacao_fornecedor } from '../../../../components/ModalRating/avaliacoes';

class InfoGerais extends Component {
	constructor(props) {
		super(props);
		let copia = false;
		if (props.dadosCotacao.observacao || props.dadosCotacao.observacao) copia = true;

		(props.dadosCotacao.itens_de_pedido || []).forEach(item => {
			if (item.observacoes) copia = true;
			if (item.observacoes_internas) copia = true;
		});

		this.state = {
			alerta_edicao: '',
			alerta_cancelamento: '',
			edit: false,
			modal_exportacao: false,
			modal_impressao: false,
			newTitle: props.nova_compra.titulo || '',
			toggleDelete: false,
			motivo_alert: false,
			confirmando_cancelamento: 0,
			cancelar_modal: false,
			motivo_cancelamento: '',
			loading: false,
			visible: false,
			statusDelete: 0,
			modal_notificacao: false,
			copia,
			error_message: '',
		};
		this.titleInput = React.createRef();
	}

	closeAlertaEdicao = () => this.setState({ alerta_edicao: '' });

	closeAlertaCancelamento = () => this.setState({ alerta_cancelamento: '' });

	handleTitulo = () => {
		this.props.handleFixError('titulo');
		this.setState({ edit: !this.state.edit }, () => {
			this.titleInput.current.focus();
		});
	};

	handleChangeInput = ({ target }) => this.setState({ newTitle: target.value });

	handleDeleteConfirm = () => this.setState({
		toggleDelete: !this.state.toggleDelete,
		statusDelete: 0,
	});

	handleDelete = () => {
		const { nova_compra, history, updateUi } = this.props;
		this.setState({ statusDelete: 1 });
		_post(`/conaz/v2/compras/${nova_compra.id}/cancelar`, {})
			.then(() => {
				updateUi(['changes', 'compras'], true);
				if (nova_compra.rc.uuid) {
					history.push(`/cotacao/${nova_compra.pedido_id}/qc`);
				} else {
					history.push('/compras');
				}
			})
			.catch(error => {
				handleRequestErrors(error);
			});
	};

	handleSave = () => {
		const { nova_compra, updateComprasRaw } = this.props;
		const compra = {
			...nova_compra,
			titulo: this.state.newTitle,
		};
		updateComprasRaw({ nova_compra: compra });
		this.setState({ edit: false });
		_put(`/conaz/v2/compras/${nova_compra.id}`, {
			titulo: this.state.newTitle,
		}).catch(error => {
			handleRequestErrors(error);
		});
	};

	handleMotivo = ({ target: { value } }) => {
		this.setState({ motivo_cancelamento: value, motivo_alert: false });
	}


	showModalEdicaoCompra = () => {
		this.setState({
			visible: true,
		});
	};

	handleCancelEdicao = () => {
		this.setState({ visible: false });
	};

	handleEdicao = () => {
		const {
			nova_compra,
			updateUi,
			redirectAfterConcluir,
		} = this.props;
		this.setState({ loading: true });
		_post(`/conaz/v2/compras/${nova_compra.id}/iniciar_edicao`, {}).then(() => {
			updateUi(['changes', 'compras'], true);
			redirectAfterConcluir(nova_compra.id);
			this.setState({ loading: false });
		}).catch(error => {
			this.setState({ alerta_edicao: error.response.data.message }, () => {
				handleRequestErrors(error);
			});
		});
	};

	handleCancelarModal = () => {
		// usado em AprovarAction component
		const { cancelar_modal, confirmando_cancelamento } = this.state;
		// const { nova_compra } = this.props;
		// const { usuario_construtor, aprovada_por } = nova_compra;
		// const email_construtor = usuario_construtor.email || '';
		// const email_aprovada = aprovada_por.email || '';
		// const user_email = getUserLocalstorageData('email') || '';

		const confirmando = !cancelar_modal
			? confirmando_cancelamento
			: 0;
		this.setState({
			cancelar_modal: !cancelar_modal,
			confirmando_cancelamento: confirmando,
		});
	};

	cancelaCompra = () => {
		const { motivo_cancelamento } = this.state;
		if (motivo_cancelamento === '') {
			this.setState({ motivo_alert: true });
			return;
		}
		this.setState({ confirmando_cancelamento: 1 });
		const { nova_compra, redirectAfterConcluir } = this.props;
		const params = { motivo_cancelamento };
		_post(`/conaz/v2/compras/${nova_compra.id}/cancelar`, params)
			.then(() => redirectAfterConcluir())
			.catch(error => {
				const conaz_error = ((
					error.response || {})
					.data || {})
					.message || '';

				if (conaz_error === 'Apenas os responsáveis da compra podem cancelá-la.') {
					this.setState({
						confirmando_cancelamento: 3,
						error_message: conaz_error,
					});
					return;
				}

				this.setState({
					confirmando_cancelamento: 3,
					error_message: 'Desculpe, aconteceu algo errado. Tente novamente.',
				});
				handleRequestErrors(error);
			});
	};

	handleModalNoticacao = () => {
		this.setState({ modal_notificacao: !this.state.modal_notificacao });
	}

	handleExportacao = () => {
		const { modal_exportacao } = this.state;
		this.props.updateModals('exporta_compras', !modal_exportacao);
	};

	handleModalExportacao = () => {
		this.setState({ modal_exportacao: !this.state.modal_exportacao });
	}

	handleModalImpressao = () => {
		this.setState({ modal_impressao: !this.state.modal_impressao });
	}

	render() {
		const {
			nova_compra,
			nova_compra: {
				// valor_total,
				obra: { id: obra_selected },
				is_editable,
				status_front,
				titulo,
				registro = false,
			},
			redirectAfterConcluir,
			updateComprasRaw,
			infoFornecedor,
			autoSaveStart,
			handleNovoFornecedorModal,
			handleModalFornecedor,
			handleModalCp,
			handleFixError,
			user,
			errors,
			is_mobile,
			number_of_items,
			openItensMobile,
			logged_sienge,
			itens,
			updateItemById,
			updateItensRaw,
			saveFornecedorSiengePosterior,
			notificaUsuarioAposExportacao,
		} = this.props;
		const {
			edit,
			newTitle,
			toggleDelete,
			statusDelete,
			cancelar_modal,
			confirmando_cancelamento,
			motivo_cancelamento,
			motivo_alert,
			modal_notificacao,
			modal_exportacao,
			modal_impressao,
			alerta_edicao,
			alerta_cancelamento,
			copia,
			error_message,
			visible,
			loading,
		} = this.state;

		const { obras_by_id, obras_all_ids } = user;

		const status = defineStatusCompra(nova_compra);
		const title =
			status === 'confirmada' && !registro
				? 'Aviso: Esta ação não garante o cancelamento do fornecedor'
				: 'Cancelar esta compra?';
		const message =
			status === 'confirmada' && !registro ? (
				<p>
					O fornecedor já realizou a confirmação da venda.{' '}
					<b>
						É importante realizar um contato com o mesmo para
						alinhar a situação
					</b>
					. Deseja cancelar a compra no sistema mesmo assim?
					<br />
					<br />
					Descreva abaixo o motivo do cancelamento:
				</p>
			) : (
				<p>Descreva abaixo o motivo do cancelamento:</p>
			);
		const button_text =
			status === 'confirmada'
				? 'Sim, cancelar mesmo assim'
				: 'Sim, cancelar';

		const ModalCancelar = (
			<ModalPortal>
				<ModalWrapperPortal closeModal={this.handleCancelarModal}>
					<ModalConfirm
						title={title}
						message={message}
						confirmando={confirmando_cancelamento}
						confirmFunc={this.cancelaCompra}
						button_type="danger-conaz"
						button_text={button_text}
						cancel_button_text="Manter"
						is_mobile={is_mobile}
						tem_motivo
						motivo={motivo_cancelamento}
						motivo_alert={motivo_alert}
						handleMotivo={this.handleMotivo}
						modal_width="md"
						error_message={error_message}
					/>
				</ModalWrapperPortal>
			</ModalPortal>
		);

		const ModalComunicacao = modal_notificacao ? (
			<ModalPortal>
				<ModalWrapperPortal closeModal={this.handleModalNoticacao}>
					<ModalNotificaSobreCompra
						compra_id={nova_compra.id}
						obra_id={obra_selected}
						obra_nome={user.obras_by_id[obra_selected].nome}
						updateModals={this.handleModalNoticacao} />
				</ModalWrapperPortal>
			</ModalPortal>
		) : null;

		const ModalImpressao = modal_impressao ? (
			<ModalPortal>
				<ModalWrapperPortal closeModal={this.handleModalImpressao}>
					<ModalImpressaoCompras items={itens} compra={nova_compra} closeModal={this.handleModalImpressao} />
				</ModalWrapperPortal>
			</ModalPortal>
		) : null;

		const ModalExportacao = modal_exportacao ? (
			<ModalPortal>
				<ModalWrapperPortal closeModal={this.handleModalExportacao}>
					<ModalExportaCompras compra_id={nova_compra.id} closeModal={this.handleModalExportacao} />
				</ModalWrapperPortal>
			</ModalPortal>
		) : null;

		const AlertaEdicao = alerta_edicao ? (
			<ModalPortal>
				<ModalWrapperPortal closeModal={this.closeAlertaEdicao}>
					<AlertaEdicaoModal text={alerta_edicao} closeModal={this.closeAlertaEdicao} />
				</ModalWrapperPortal>
			</ModalPortal>
		) : null;

		const AlertaCancelamento = alerta_cancelamento ? (
			<ModalPortal>
				<ModalWrapperPortal closeModal={this.closeAlertaCancelamento}>
					<AlertaEdicaoModal text={alerta_cancelamento} closeModal={this.closeAlertaCancelamento} />
				</ModalWrapperPortal>
			</ModalPortal>
		) : null;

		const titulo_view = !edit ? (
			<button
				type="button"
				styleName={`titulo-input-fake ${errors.titulo ? 'error' : ''}`}
				onClick={this.handleTitulo}>
				{titulo ? (
					<span>{titulo}</span>
				) : (
					<em style={{ color: '#8c9295' }}>Sem título</em>
				)}
			</button>
		) : (
			<input
				type="text"
				styleName="titulo-input"
				value={newTitle}
				onChange={this.handleChangeInput}
				onKeyDown={e => e.key === 'Enter' && this.handleSave()}
				ref={this.titleInput}
				onBlur={this.handleSave} />
		);

		const compra_id_row_class = !is_editable
			? 'compra-id-row no-edit'
			: 'compra-id-row';

		const nome_fornecedor = (nova_compra.fornecedor || {}).nome || '';
		const avaliacao = avaliacao_fornecedor(nome_fornecedor);

		return (
			<>
				<Modal
					className="modal-edicao-compra"
					zIndex={3000}
					maskClosable={false}
					visible={visible}
					title={
						<div style={{ display: 'flex', margin: 0, marginBottom: -40, padding: 0, borderBottom: 0, backgroundColor: '#FFF' }}>
							<img src={IconeExclamacao} alt="Atencao para a edição" style={{ width: 20 }} />  <p style={{ marginBottom: 0, marginLeft: 10, color: '#F11919', fontWeight: '700', fontFamily: 'sans-serif', fontSize: 15 }}> Atenção para edição de Compra</p>
						</div>
					}
					onCancel={this.handleCancelEdicao}
				>
					<div styleName="container-edicao-compra">
						<p styleName="descricao-edicao-compra">
							Você está prestes a editar uma compra que já foi concluída em nosso sistema.
							Isso afetará sua compra e o fornecedor participante será notificado.</p>
						<strong styleName="confirmacao-edicao-compra">Tem certeza que deseja realizar uma edição dessa compra?</strong>
						<div styleName="popup-edicao-compra">
							<button loading={false} onClick={this.handleCancelEdicao} styleName="button-cancela-edicao-compra">
								Cancelar edição
							</button>
							{' '} {' '}
							{loading === true ? (
								<Button
									loading={loading}
									onClick={this.handleEdicao}
									styleName="button-edicao-compra"
								>
									Seguir com edição
								</Button>
							) : (
								<button
									loading={loading}
									onClick={this.handleEdicao}
									styleName="button-edicao-compra"
								>
									Seguir com edição
								</button>
							)}
						</div>
					</div>
				</Modal>

				{cancelar_modal && ModalCancelar}
				{ModalComunicacao}
				{ModalImpressao}
				{ModalExportacao}
				{AlertaEdicao}
				{AlertaCancelamento}
				<div styleName="info-row">
					<div styleName={compra_id_row_class}>
						<div styleName="titulo-compra">Compra #{nova_compra.id}</div>
						<div styleName="titulo-buttons">
							<button
								styleName="titleActions comments"
								onClick={this.handleModalNoticacao}
								type="button">
								<i className="fa fa-comments" />
								<div styleName="action-tooltip">
									<Tooltip
										top="70%"
										margin_left="-125px"
										width="150px"
										styleArrow={{ top: '-0.5%', marginLeft: '38%', transform: 'rotate(180deg)' }}
										text="Notificar outros usuários" />
								</div>
							</button>
							{status_front === 'rascunho' && (
								<span style={{ position: 'relative', display: 'inline-block' }}>
									<button
										type="button"
										styleName="titleActions trash"
										onClick={this.handleDeleteConfirm}>
										<i className="fa fa-trash" />
										<div styleName="action-tooltip">
											<Tooltip
												top="70%"
												margin_left="-125px"
												width="150px"
												styleArrow={{ top: '-0.5%', marginLeft: '38%', transform: 'rotate(180deg)' }}
												text="Descartar rascunho da compra" />
										</div>
									</button>
									{toggleDelete && (
										<div styleName="alert-wrapper">
											<div styleName="message">
												<div styleName="confirm-wrapper">
													<p>Deseja Descartar esta compra?</p>
													<button
														type="button"
														className="btn btn-danger-conaz round-border"
														styleName="descartar-button"
														onClick={this.handleDelete}>
														{statusDelete === 0 ? (
															'Descartar'
														) : (
															<span styleName="descarte">
																Descartando
																<span>.</span>
																<span>.</span>
																<span>.</span>
															</span>
														)}
													</button>
													<button
														type="button"
														className="btn btn-clear-conaz round-border"
														styleName="cancelar-button"
														onClick={
															this.handleDeleteConfirm
														}>
														Manter
													</button>
												</div>
											</div>
										</div>
									)}
								</span>
							)}
							{(status_front !== 'rascunho' && status_front !== 'cancelada') && (
								<button
									type="button"
									styleName="titleActions ban"
									onClick={this.handleCancelarModal}>
									<i className="fa fa-ban" />
									<div styleName="action-tooltip">
										<Tooltip
											top="70%"
											margin_left="-125px"
											width="150px"
											styleArrow={{ top: '-0.5%', marginLeft: '38%', transform: 'rotate(180deg)' }}
											text="Cancelar pedido da compra" />
									</div>
								</button>
							)}
							{(
								status_front !== 'em_edicao'
								&& status_front !== 'rascunho'
								&& status_front !== 'em_refinamento'
								&& status_front !== 'cancelada'
								&& status_front !== 'finalizada'
							) && (
								<button type="button" onClick={this.showModalEdicaoCompra} styleName="titleActions pencil">
									<i className="fa fa-pencil" />
									<div styleName="action-tooltip">
										<Tooltip
											top="70%"
											margin_left="-125px"
											width="150px"
											styleArrow={{ top: '-0.5%', marginLeft: '38%', transform: 'rotate(180deg)' }}
											text="Editar compra" />
									</div>
								</button>
							)}
							<button type="button" styleName="titleActions excel" onClick={this.handleModalImpressao}>
								<i className="fa fa-print" />
								<div styleName="action-tooltip">
									<Tooltip
										top="70%"
										margin_left="-125px"
										width="150px"
										styleArrow={{ top: '-0.5%', marginLeft: '38%', transform: 'rotate(180deg)' }}
										text="Gerar impressão da compra" />
								</div>
							</button>
							<button type="button" styleName="titleActions excel" onClick={this.handleModalExportacao}>
								<i className="fa fa-file-excel-o" />
								<div styleName="action-tooltip">
									<Tooltip
										top="70%"
										margin_left="-125px"
										width="150px"
										styleArrow={{ top: '-0.5%', marginLeft: '38%', transform: 'rotate(180deg)' }}
										text="Gerar planilha Excel da compra" />
								</div>
							</button>
							{copia && (
								<button type="button" styleName="titleActions copy" onClick={handleModalCp}>
									<i className="fa fa-copy" />
									<div styleName="action-tooltip">
										<Tooltip
											top="70%"
											margin_left="-125px"
											width="150px"
											styleArrow={{ top: '-0.5%', marginLeft: '38%', transform: 'rotate(180deg)' }}
											text="Copiar informações da cotação" />
									</div>
								</button>
							)}
							<BtnExportSienge
								compra={nova_compra}
								logged_sienge={logged_sienge}
								itens={itens}
								user={user}
								updateItemById={updateItemById}
								updateItensRaw={updateItensRaw}
								redirectAfterConcluir={redirectAfterConcluir}
								notificaUsuarioAposExportacao={notificaUsuarioAposExportacao}
							/>
						</div>
						<ExportStatus compra={nova_compra} />
					</div>

					<div styleName="titulo">
						{/* <InfoExportacaoSienge compra={nova_compra} /> */}
						{/* <label>Título</label> */}
						{is_editable ? (
							titulo_view
						) : (
							<span style={{ fontSize: '18px' }}>{titulo}</span>
						)}
					</div>

					<CompraRowButtons
						compra={nova_compra}
						avaliacao={avaliacao}
						location="compra"
					/>
					<CompraRowAvaliacao
						compra={nova_compra}
						location="compra"
					/>

					<ResumoActions
						compra={nova_compra}
						updateComprasRaw={updateComprasRaw}
						user={user}
						cancelaCompra={this.handleCancelarModal}
						redirectAfterConcluir={redirectAfterConcluir}
						cancelarCompraModal={this.cancelarCompraModal}
					/>
				</div>
				<OpenItensMobile
					number_of_items={number_of_items}
					openItensMobile={openItensMobile} />
				<Fornecedor
					nova_compra={nova_compra}
					saveFornecedorSiengePosterior={saveFornecedorSiengePosterior}
					updateComprasRaw={updateComprasRaw}
					infoFornecedor={infoFornecedor}
					handleNovoFornecedorModal={handleNovoFornecedorModal}
					autoSaveStart={autoSaveStart}
					obras_by_id={user.obras_by_id}
					obra_selected={user.obra_selected}
					errors={errors}
					handleFixError={handleFixError}
					handleModalFornecedor={handleModalFornecedor} />
				<Pagamento
					nova_compra={nova_compra}
					autoSaveStart={autoSaveStart}
					updateComprasRaw={updateComprasRaw}
					handleFixError={handleFixError}
					errors={errors} />
				<Transporte
					compra={nova_compra}
					autoSaveStart={autoSaveStart}
					updateComprasRaw={updateComprasRaw}
					handleFixError={handleFixError}
					is_mobile={is_mobile}
					errors={errors} />
				<DadosObra
					compra={nova_compra}
					obras_by_id={obras_by_id}
					obras_all_ids={obras_all_ids}
					autoSaveStart={autoSaveStart}
					updateComprasRaw={updateComprasRaw} />
				<Anexos
					compra={nova_compra} />
				<TipoCompra
					compra={nova_compra}
					errors={errors}
					autoSaveStart={autoSaveStart}
					updateComprasRaw={updateComprasRaw} />
				<DataDeRegistro
					compra={nova_compra}
					errors={errors}
					autoSaveStart={autoSaveStart}
					handleFixError={handleFixError}
					updateComprasRaw={updateComprasRaw} />
				<Observacoes
					compra={nova_compra}
					autoSaveStart={autoSaveStart}
					updateComprasRaw={updateComprasRaw} />
			</>
		);
	}
}

InfoGerais.propTypes = {
	nova_compra: PropTypes.object.isRequired,
	dadosCotacao: PropTypes.object.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	is_mobile: PropTypes.bool.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
	handleNovoFornecedorModal: PropTypes.func.isRequired,
	handleModalCp: PropTypes.func.isRequired,
	handleModalFornecedor: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	handleFixError: PropTypes.func.isRequired,
	redirectAfterConcluir: PropTypes.func.isRequired,
	number_of_items: PropTypes.number.isRequired,
	openItensMobile: PropTypes.func.isRequired,
	logged_sienge: PropTypes.bool,
	itens: PropTypes.object.isRequired,
	updateItemById: PropTypes.func.isRequired,
	updateItensRaw: PropTypes.func.isRequired,
	saveFornecedorSiengePosterior: PropTypes.func.isRequired,
	notificaUsuarioAposExportacao: PropTypes.func.isRequired,
};

InfoGerais.defaultProps = {
	logged_sienge: false,
};

export default CSSModules(InfoGerais, styles, { allowMultiple: true });

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Functions
import { handleRequestErrors } from '../../components/_functions/_handleRequestErrors';
import { _post } from '../../components/_functions/_requests';
import { getUserLocalstorageData } from '../../components/_functions/_getUserLocalstorageData';

function createCompraHoc(Comp) {
	class PP extends Component {
		state = {
			criando: 0,
		};

		createCompra = (oc, pedido, updateModals) => {
			const { history } = this.props;

			this.setState({ criando: 1 });
			const obra_selected = getUserLocalstorageData('obra');
			const params = {
				obra_id: obra_selected,
			};

			// RC analise
			const rc_analise_id = (((oc || {}).rcs_analise || [])[0] || {}).id || null;
			if (rc_analise_id !== null) {
				params.rc_analise_id = rc_analise_id;
				// params.tipo = 4;
			}
			// RC
			const rc_uuid = ((((oc || {}).rcs_analise || [])[0] || {}).rc || {}).uuid || null;
			if (rc_uuid !== null && rc_analise_id === null) {
				params.rc_uuid = rc_uuid;
				// params.tipo = 4;
			}
			// Pedido
			const pedido_id = (pedido || {}).id || null;
			if (rc_uuid === null && rc_analise_id === null && pedido_id !== null) {
				params.pedido_id = pedido_id;
				// params.tipo = 5;
			}
			// Copia titulo do pedido
			const pedido_titulo = (pedido || {}).titulo || '';
			if (pedido_titulo !== '') {
				params.titulo = pedido_titulo;
			}
			// Sem RC e RC analise
			// if (rc_uuid === null && rc_analise_id === null && pedido_id === null) {
			// 	params.tipo = 1;
			// }

			// Mensagem dos itens da analise para mostrar quais foram removidos
			// let observacoes = '';
			// if (rc_analise_id !== null && pedido_id !== null) {
			// 	const itens_rc_analise = (((oc || {}).rcs_analise || [])[0] || {}).itens_rc_analise || [];
			// 	observacoes = this.setMsgItensRemovidos(itens_rc_analise, pedido);
			// 	params.observacoes = observacoes;
			// }

			_post('/conaz/v2/compras/', params).then(response => {
				history.push(`/compra/nova/${response.data.id}`);
			}).catch(error => {
				this.setState({ criando: 3 });
				updateModals('error', true);
				handleRequestErrors(error);
			});
		}

		render() {
			return (
				<Comp
					{...this.state}
					{...this.props}
					createCompra={this.createCompra} />
			);
		}
	}

	PP.propTypes = {
		history: PropTypes.object.isRequired,
	};

	return withRouter(PP);
}

export default createCompraHoc;

/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import { notification, Button, Modal, message as messageAntd } from 'antd';
import styles from './style.module.sass';
// Components
// import ExportSteps from './ExportSteps';
import UnidadesCheck from './UnidadesCheck';
import ExportErrorViews from './ExportErrorViews';
import LoadingButton from '../../../../components/_parts/_loadings/LoadingButton/LoadingButton';

// Functions
import { compareUnidades } from '../../../../components/_functions/_compareUnidades';
import { _put, _post } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';
import { sendSlack } from '../../../../components/_functions/_sendSlack';
import { defineStatusCompra } from '../../../../components/_functions/_defineStatusCompra';

import * as Actions from '../../../../store/exportStatus/actions';

const CheckQuantidades = ({
	itens,
	// setStep,
	setExportingError,
	updateItensRaw,
	updateItemById,
	closeModal,
	notificaUsuarioAposExportacao,
	compra,
	redirectAfterConcluir,
}) => {
	const dispatch = useDispatch();

	const [itens_to_ajust, setItensToAjust] = useState([]);
	const [quantidades_ok, setQuantidadesOk] = useState(false);
	const [validation, setValidation] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [request, setRequest] = useState(0);
	const [analise_confirmation, setAnaliseConfirmation] = useState(false);
	const [enviando, setEnviando] = useState(0);

	function enviaAlertSlack() {
		const is_prod = (window.location.href.indexOf('portal.conazweb.com.br') !== -1);
		if (is_prod) {
			const compra_id = compra.id || 0;
			const compra_email = compra.usuario_construtor.email || '';
			const message = `Exportação da compra *${compra_id} [${compra_email}]* foi finalizada com sucesso! :gustavo_thug_life:`;
			sendSlack('#exportações-sienge', 'Exportação Sienge', message, ':gustavo_thug_life:');
		}
	}

	function concluirCompra() {
		if (!compra.fornecedor_integrado.ativo) {
			Modal.warning({
				width: 650,
				zIndex: 3000,
				className: 'style_modal-content__lCupE',
				title: 'Não foi possível exportar sua compra',
				content: (
					<div>
						<span>O fornecedor selecionado para esse compra não está associado à um fornecedor do Sienge.</span>
						<br /> <br />
						<span>Para prosseguir com a exportação, clique no fornecedor escolhido e associe-o à um fornecedor do Sienge.</span>
					</div>
				),
				okText: 'Fechar',
				onOk() {},
			});
			return false;
		}
		enviaAlertSlack();

		const status_compra = defineStatusCompra(compra);
		const compra_ja_enviada = (
			status_compra !== 'rascunho'
			&& status_compra !== 'em_edicao'
		);

		if (compra_ja_enviada) {
			dispatch(Actions.getExportRequest(compra.id));
			redirectAfterConcluir();
			return;
		}

		const mensagemModal = () => (
			<>
				<div styleName="modal-content">
					{notification.open({
						type: 'info',
						message: 'Finalizando Compra',
						description:
      'Aguarde, em instantes a compra será finalizada!',
						duration: 10,
					})}
				</div>
			</>
		);

		mensagemModal();
		const force = analise_confirmation;
		const params = { force };

		_post(`/conaz/v2/compras/${compra.id}/concluir`, params)
			.then(() => {
				dispatch(Actions.getExportRequest(compra.id));
				redirectAfterConcluir();
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					notificaUsuarioAposExportacao();
					dispatch(Actions.getExportRequest(compra.id));
					return;
				}
				if (err.response.data.message === 'Compra necessita análise') {
					setAnaliseConfirmation(true);
					setEnviando(0);
					Modal.warning({
						width: 650,
						zIndex: 3000,
						style: { height: '100%' },
						className: 'style_modal-content__lCupE',
						title: <h4><b>Análise Necessária</b></h4>,
						content: (
							<div styleName="row">
								<div styleName="alteracoes-alert">
									<p>Esta compra precisará passar por uma análise da Conaz (<a href="https://conaz.zendesk.com/hc/pt-br/categories/115000062953-Construtor" target="_blank" rel="noopener noreferrer"><i className="fa fa-question-circle" /></a>) porque possui itens ou características não associadas (destacadas com o símbolo <i className="fa fa-exclamation-circle" styleName="outro-valor-warning-icon" />). Isso pode atrasar o envio aos fornecedores.<br /><br /> Deseja enviar mesmo assim?</p>
								</div>
							</div>
						),
						okText: 'Enviar mesmo assim',
						onOk() {
							mensagemModal();
							const params_force = {
								force: true,
							};
							_post(`/conaz/v2/compras/${compra.id}/concluir`, params_force)
								.then(() => {
									dispatch(Actions.getExportRequest(compra.id));
									redirectAfterConcluir();
								});
						},
						okCancel() {},
						cancelText: 'Cancelar',
					});
				}
				setRequest(3);
				handleRequestErrors(err);
			});
	}

	if (request === 3) {
		return <ExportErrorViews error_type={12} />;
	}

	function saveQuantidadeSienge(itens_compra) {
		setExportingError(false);
		const { all_ids, by_id } = itens_compra;
		const all_puts = [];

		for (let i = 0; i < all_ids.length; i++) {
			const item_id = all_ids[i];
			const item = by_id[item_id];
			if (item !== undefined) {
				const quantidade_no_swi = item.quantidade_no_swi || 0;
				const params = {
					quantidade_no_swi,
					force: true,
				};
				all_puts.push(
					_put(`/conaz/v2/itens_de_compra/${item_id}`, params),
					closeModal(),
				);
			}
		}

		Promise.all(all_puts)
			.then(() => {
				concluirCompra();
			})
			.catch(err => {
				// DONE Erro ao salvar quantidades do Sienge
				setError(0);
				setExportingError(true);
				handleRequestErrors(err);
			});
	}

	function validaQuantidades() {
		const { all_ids, by_id } = itens;

		const empty_items = all_ids.filter(item_id => {
			const item = by_id[item_id] || {};
			const quantidade_no_swi = item.quantidade_no_swi || 0;
			return quantidade_no_swi === 0 && !item.excluido;
		});

		if (empty_items.length > 0) {
			setValidation(true);
			setLoading(false);
			return;
		}

		// setItensToAjust([]);
		setQuantidadesOk(true);
		saveQuantidadeSienge(itens);
	}

	function checkUnidades(itens_to_ajust_new, itens_compra) {
		if (itens_to_ajust_new.length > 0) {
			setItensToAjust(itens_to_ajust_new);
			setExportingError(true);
			return;
		}

		saveQuantidadeSienge(itens_compra);
	}

	function setQuantidades() {
		const { all_ids, by_id } = itens;
		const itens_to_ajust_new = [];

		const _by_id = all_ids.reduce((result, current_id) => {
			const item = by_id[current_id] || {};

			// unidades
			const solicitacao_sienge_id = item.pedido_no_swi || '';
			const unidade_conaz = item.unidade || '';
			const unidade_sienge = solicitacao_sienge_id === ''
				? (item.item_integrado || {}).unidade || ''
				: item.pre_unidade || '';
			const unidades_iguais = compareUnidades(
				unidade_conaz,
				unidade_sienge,
			);

			// quantidades
			const quantidade_conaz = item.quantidade || 0;
			const quantidade_no_swi = item.quantidade_no_swi || 0;
			const quantidade_updated = unidades_iguais
				? quantidade_conaz
				: quantidade_no_swi;

			// itens_to_ajust
			if (
				!unidades_iguais
				&& !quantidades_ok
				&& !item.excluido
			) {
				itens_to_ajust_new.push(current_id);
			}

			const current = {
				...item,
				quantidade_no_swi: quantidade_updated,
			};
			return { ...result, [current_id]: current };
		}, {});

		const itens_compra = {
			all_ids,
			by_id: _by_id,
		};
		updateItensRaw({ itens_compra });
		checkUnidades(itens_to_ajust_new, itens_compra);
	}

	function checkIfNeedQuantidades() {
		if (itens_to_ajust.length > 0) {
			validaQuantidades();
			return;
		}

		setQuantidades();
	}

	useEffect(() => {
		setQuantidades();
	}, []);

	// DONE Erro ao salvar quantidades do Sienge
	if (error === 0) {
		return <ExportErrorViews />;
	}

	// if (itens_to_ajust.length === 0) {
	// 	return 	<ExportSteps current={2} />;
	// }

	const { by_id } = itens;

	return (
		<>
			<div styleName="titles">
				<div styleName="icon">
					<img src="/images/logo-sienge.png" width="74" alt="" />
				</div>
				<h3>Exportar compra para o Sienge</h3>
			</div>
			<div styleName="content">
				{itens_to_ajust.length > 0 && (
					<>
						<p style={{ marginTop: '14px' }}>Não foi possível identificar a unidade de alguns insumos. É preciso preencher o campo quantidade e, se necessário, realizar a conversão:</p>
						{itens_to_ajust.map(item_id => {
							const item = by_id[item_id] || {};

							return (
								<UnidadesCheck
									key={item_id}
									item={item}
									updateItemById={updateItemById}
								/>
							);
						})}
					</>
				)}
				{analise_confirmation && (
					<div styleName="row">
						<div styleName="alteracoes-alert">
							<h4><i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" style={{ color: '#ffd700' }} /> <b>Análise Necessária</b></h4>
							<p>Esta compra precisará passar por uma análise da Conaz (<a href="https://conaz.zendesk.com/hc/pt-br/categories/115000062953-Construtor" target="_blank" rel="noopener noreferrer"><i className="fa fa-question-circle" /></a>) porque possui itens ou características não associadas (destacadas com o símbolo <i className="fa fa-exclamation-circle" styleName="outro-valor-warning-icon" />). Isso pode atrasar o envio aos fornecedores.<br /><br /> Deseja enviar mesmo assim?</p>
						</div>
					</div>
				)}
				<div styleName="buttons">
					{loading ? (
						<button
							type="button"
							className="btn btn-secondary-conaz round-border loading"
							onClick={() => {
								setLoading(true);
								checkIfNeedQuantidades();
							}}
						>
							<LoadingButton />
							<span>Concluir e exportar</span>
						</button>
					) : (
						<button
							type="button"
							className="btn btn-secondary-conaz round-border"
							onClick={() => {
								setLoading(true);
								checkIfNeedQuantidades();
							}}
						>
							<span>Concluir e exportar</span>
						</button>
					)}
				</div>
				{validation && (
					<div className="alert alert-danger" styleName="alert-danger" role="alert">
						<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" />{' '}
						Preencha corretamente todas as quantidades.
					</div>
				)}
			</div>
		</>
	);
};

CheckQuantidades.propTypes = {
	itens: PropTypes.object.isRequired,
	// setStep: PropTypes.func.isRequired,
	setExportingError: PropTypes.func.isRequired,
	updateItensRaw: PropTypes.func.isRequired,
	updateItemById: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	compra: PropTypes.object.isRequired,
	notificaUsuarioAposExportacao: PropTypes.func.isRequired,
	redirectAfterConcluir: PropTypes.func.isRequired,
};

export default CSSModules(CheckQuantidades, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import FornecedoresTopRow from '../_shared/FornecedoresTopRow/FornecedoresTopRow';
import ItensLeftColumn from '../_shared/ItensLeftColumn/ItensLeftColumn';
import RCs from '../_shared/RCs/RCs';
import CombinacaoSideBar from './CombinacaoSideBar/CombinacaoSideBar';
import LeftModalWrapper from '../../../components/_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalFornecedorPerfil from '../../../components/_parts/_modals/LeftModalFornecedor/LeftModalFornecedorPerfil';
import ModalWrapper from '../../../components/_parts/_modals/ModalWrapper/ModalWrapper';
import ModalExportaExcel from '../../../components/_parts/_modals/ModalExportaExcel/ModalExportaExcel';
import ModalItemAnaliseMobile from '../../../components/_parts/_modals/ModalItemAnaliseMobile/ModalItemAnaliseMobile';

// Functions
import { defineStatusCompra } from '../../../components/_functions/_defineStatusCompra';

const QcView = ({
	// =========== hoc
	infoFornecedor,
	perfil_fornecedor,
	rcs_abertos,
	mobile,
	item_analise_mobile_modal,
	openItemAnaliseOnMobile,
	// =========== state
	qc_disponivel,
	window_height,
	scroll_left,
	sidebar_combinacao,
	combinacao_criada_id,
	loading_compras,
	enviando_qc_triagem,
	validar_rc,
	// =========== props
	ui,
	qc,
	user,
	match,
	history,
	// =========== props funcs
	updateQc,
	updateModals,
	reportErrors,
	// =========== local funcs
	openCloseRCs,
	openCombinacaoSideBar,
	closeCombinacaoSideBarAfterCreate,
	deleteOCFromProps,
	changeCondicaoPagamento,
	collapseOC,
	goToCriarCompra,
	enviaQCTriagem,
	menor_preco,
}) => {
	const { obra_selected, obras_by_id } = user;
	const { modals } = ui;
	const {
		analise_id,
		cotacao,
		itens_analise,
		opcoes_de_compra,
		compras,
	} = qc;
	const qc_class = !sidebar_combinacao ? 'qc' : 'qc with-modal';
	const only_1_item = itens_analise.all_ids.length <= 1;
	/**
	 * Item selecionado no mobile para abrir no modal
	 */
	const item_selected_mobile_modal =
		itens_analise.by_id[item_analise_mobile_modal.id] || {};
	const item_selected_order = item_analise_mobile_modal.order || -1;
	/**
	 * Filtra as compras que devem bloquear botões
	 */
	const _compras = compras.filter(compra => {
		const status = defineStatusCompra(compra);
		return (
			status !== 'cancelada'
			// && status !== 'recusada_fornecedor'
		);
	});

	// cliente_id
	const eh_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
	const cliente_id = !eh_triagem
		? ((obras_by_id[obra_selected] || {}).cliente || {}).id
		: 10;

	if (qc_disponivel === 2) {
		return (
			<div className="container" style={{ marginTop: '36px' }}>
				<div className="row">
					<div className="col-sm-12 error-wrapper">
						<i className="fa fa-inbox" aria-hidden="true" />
						<h3>Quadro de cotações não está disponível</h3>
						<p>Desculpe, este QC não está em nosso sistema.</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<ModalWrapper
				visible={modals.exporta_excel}
				updateModals={updateModals}>
				<ModalExportaExcel analise_id={analise_id} />
			</ModalWrapper>
			<ModalWrapper
				visible={modals.item_analise_mobile}
				updateModals={updateModals}>
				<ModalItemAnaliseMobile
					item_order={item_selected_order}
					item_selected_mobile_modal={item_selected_mobile_modal} />
			</ModalWrapper>
			<LeftModalWrapper
				visible={modals.fornecedor}
				updateModals={updateModals}>
				<LeftModalFornecedorPerfil
					perfil_fornecedor={perfil_fornecedor}
					route_path={match.path}
					reportErrors={reportErrors}
					cliente_id={cliente_id}
				/>
			</LeftModalWrapper>
			<div styleName="wrapper">
				{sidebar_combinacao && (
					<CombinacaoSideBar
						openCombinacaoSideBar={openCombinacaoSideBar}
						closeCombinacaoSideBarAfterCreate={
							closeCombinacaoSideBarAfterCreate
						}
						opcoes_de_compra={opcoes_de_compra}
						pedido_id={cotacao.codigo}
						analise_id={analise_id}
						history={history}
						updateQc={updateQc}
						only_1_item={only_1_item} />
				)}
				<div styleName="barras-fixas">
					<ItensLeftColumn
						cotacao={cotacao}
						opcoes_de_compra={opcoes_de_compra}
						itens_analise={itens_analise}
						window_height={window_height}
						scroll_left={scroll_left}
						openCombinacaoSideBar={openCombinacaoSideBar}
						updateModals={updateModals}
						mobile={mobile}
						openItemAnaliseOnMobile={openItemAnaliseOnMobile} />
					<FornecedoresTopRow
						opcoes_de_compra={opcoes_de_compra}
						rcs_abertos={rcs_abertos}
						openCloseRCs={openCloseRCs}
						deleteOCFromProps={deleteOCFromProps}
						infoFornecedor={infoFornecedor}
						updateModals={updateModals}
						cotacao_codigo={cotacao.codigo}
						pedido={cotacao}
						collapseOC={collapseOC}
						combinacao_criada_id={combinacao_criada_id}
						goToCriarCompra={goToCriarCompra}
						loading_compras={loading_compras}
						compras={_compras}
						changeCondicaoPagamento={changeCondicaoPagamento} />
				</div>
				<div styleName={qc_class}>
					<div styleName="relative-wrapper">
						<RCs
							window_height={window_height}
							opcoes_de_compra={opcoes_de_compra}
							itens_analise={itens_analise}
							rcs_abertos={rcs_abertos}
							openCloseRCs={openCloseRCs}
							changeCondicaoPagamento={changeCondicaoPagamento}
							mobile={mobile}
							updateModals={updateModals}
							openItemAnaliseOnMobile={openItemAnaliseOnMobile}
							enviaQCTriagem={enviaQCTriagem}
							pedido_status={cotacao.status}
							menor_preco={menor_preco}
							validar_rc={validar_rc}
							enviando_qc_triagem={enviando_qc_triagem} />
					</div>
				</div>
			</div>
		</>
	);
};

QcView.propTypes = {
	// =========== hoc
	perfil_fornecedor: PropTypes.object.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	mobile: PropTypes.bool.isRequired,
	item_analise_mobile_modal: PropTypes.object.isRequired,
	validar_rc: PropTypes.object,
	openItemAnaliseOnMobile: PropTypes.func.isRequired,
	// =========== local
	qc_disponivel: PropTypes.number.isRequired,
	window_height: PropTypes.number.isRequired,
	scroll_left: PropTypes.number.isRequired,
	sidebar_combinacao: PropTypes.bool.isRequired,
	combinacao_criada_id: PropTypes.number.isRequired,
	loading_compras: PropTypes.number.isRequired,
	enviando_qc_triagem: PropTypes.number.isRequired,
	// =========== local funcs
	openCloseRCs: PropTypes.func.isRequired,
	openCombinacaoSideBar: PropTypes.func.isRequired,
	closeCombinacaoSideBarAfterCreate: PropTypes.func.isRequired,
	deleteOCFromProps: PropTypes.func.isRequired,
	changeCondicaoPagamento: PropTypes.func.isRequired,
	collapseOC: PropTypes.func.isRequired,
	goToCriarCompra: PropTypes.func.isRequired,
	enviaQCTriagem: PropTypes.func.isRequired,
	// =========== store
	ui: PropTypes.object.isRequired,
	qc: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	// =========== funcs
	updateQc: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	menor_preco: PropTypes.object.isRequired,
};

QcView.defaultProps = {
	validar_rc: {},
};

export default CSSModules(QcView, styles, { allowMultiple: true });

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import IEComentarios from './IEComentarios';
import InfoItem from './Integracao/InfoItem/InfoItem';

// Functions
import { formatCurrency } from '../../../_functions/_formatCurrency';
import { calcValorItem } from '../_subfunctions/calcValorItem';

class ItemBlocked extends Component {
	static filterCaracs(obrigatorias, cliente_associou, item_preenchido) {
		if (!cliente_associou) return [];
		const {
			caracteristicas_preenchidas,
			caracteristicas_preenchidas_all_ids,
		} = item_preenchido;
		const caracteristicas = (caracteristicas_preenchidas_all_ids || [])
			.filter(ca_id => {
				const carac = caracteristicas_preenchidas[ca_id];
				const { visao_construtor, visao_fornecedor } = carac.caracteristica;
				if (obrigatorias) {
					return visao_construtor === 2 || visao_fornecedor === 2;
				}
				return (visao_construtor === 1 && visao_fornecedor !== 2)
					|| (visao_fornecedor === 1 && visao_construtor !== 2);
			})
			.filter((el, i, a) => i === a.indexOf(el))
			.sort((a, b) => {
				const ordem_a = caracteristicas_preenchidas[a].caracteristica.ordem;
				const ordem_b = caracteristicas_preenchidas[b].caracteristica.ordem;
				return ordem_a - ordem_b;
			});
		return caracteristicas;
	}

	closeEdition = () => {
		this.props.openItemEdition();
	}

	render() {
		const {
			item_on_edition_id,
			itens,
			updateItemById,
			updateFullItemById,
			autoSave,
			logged_sienge,
			obra_id,
			client_id,
		} = this.props;
		const { by_id } = itens;
		const item_on_edition = by_id[item_on_edition_id] || {};

		if (item_on_edition.id !== undefined) {
			const {
				cliente_associou,
				informacoes_adicionais,
				front,
				observacoes_internas,
				pre_detalhe,
			} = item_on_edition;
			const item_preenchido = item_on_edition.item_preenchido || {};
			const { caracteristicas_preenchidas, item } = item_preenchido;
			const nome = !cliente_associou
				? item_on_edition.pre_nome
				: (item || {}).nome;

			const {
				pre_quantidade,
				quantidade,
				unidade,
			} = item_on_edition;
			const preco_unitario = item_on_edition.preco_unitario || 0;
			const desconto = item_on_edition.desconto || 0;
			const valores = calcValorItem(item_on_edition);
			const { total } = valores;
			const observacoes = !cliente_associou
				? pre_detalhe
				: informacoes_adicionais;

			/**
			 * Separa características obrigatórias
			 */
			const caracteristicas_obrigatorias = this.constructor
				.filterCaracs(true, cliente_associou, item_preenchido);
			/**
			 * Separa características não-obrigatórias
			 */
			const caracteristicas_nao_obrigatorias = this.constructor
				.filterCaracs(false, cliente_associou, item_preenchido);

			return (
				<div styleName="full-content">
					<InfoItem
						item_on_edition={item_on_edition}
						obra_id={obra_id}
						client_id={client_id}
						updateFullItemById={updateFullItemById}
						logged_sienge={logged_sienge}
						autoSave={autoSave}
					/>

					<div styleName="content">
						<button
							type="button"
							className="close"
							styleName="close-x"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.closeEdition}>
							<span aria-hidden="true">&times;</span>
						</button>
						<div styleName="nome">
							<button
								type="button"
								styleName="nome-button"
								onClick={this.closeEdition}>
								{front.key}. {nome}
							</button>
						</div>

						{(
							!cliente_associou
							&& process.env.REACT_APP_CONAZ_PROJECT !== 'construtor-interno'
						) && (
							<div styleName="alert-fora-cpic">
								<i className="fa fa-exclamation-circle" aria-hidden="true" />
								Compras com itens que não estão associados ao sistema Conaz passam por um processo de validação e isso pode atrasar o envio aos fornecedores.
							</div>
						)}

						<div styleName="row-edition">
							<IEComentarios
								item_id={item_on_edition.id}
								observacoes_internas={observacoes_internas}
								updateFunction={updateItemById}
								autoSave={autoSave}
								maxLength={1000} />
						</div>

						<div styleName="row-edition">
							<div styleName="form-group-resposta has-unidade">
								<div className="form-group">
									<label styleName="label full-width">
										Quantidade
									</label>
									{!cliente_associou ? pre_quantidade : quantidade} {unidade}
								</div>
							</div>
						</div>
						<div styleName="row-edition">
							<div styleName="form-group-resposta valor-unitario">
								<div className="form-group">
									<label styleName="label full-width">
										Valor unitário
									</label>
									{formatCurrency(preco_unitario, 2, 'R$')}
								</div>
							</div>
							<div styleName="form-group-resposta desconto">
								<div className="form-group">
									<label styleName="label full-width">
										Desconto unitário
									</label>
									{desconto} %
								</div>
							</div>
							<div styleName="form-group-resposta valor-total">
								<div className="form-group">
									<label styleName="label full-width">Total</label>
									<p styleName="total-view" style={{ paddingTop: '0' }}>
										{formatCurrency(total, 2, 'R$')}
									</p>
								</div>
							</div>
						</div>

						{caracteristicas_obrigatorias.length > 0 && (
							<div styleName="row-edition">
								<div styleName="optional-section">
									<div styleName="title">Características obrigatórias</div>
									<div styleName="division" />
								</div>
							</div>
						)}

						{caracteristicas_obrigatorias.map(ca_id => {
							const c = caracteristicas_preenchidas[ca_id] || {};
							const c_valor = (c.opcoes_preenchidas[0] || {}).valor || '-';
							const c_unidade = (c.opcoes_preenchidas[0] || {}).unidade || '';

							return (
								<div key={ca_id} styleName="row-edition">
									<div className="form-group">
										<label styleName="label full-width">
											{c.caracteristica.nome}
										</label>
										{c_valor} {c_unidade}
									</div>
								</div>
							);
						})}

						<div styleName="row-edition">
							<div styleName="optional-section">
								<div styleName="title">Opcionais</div>
								<div styleName="division" />
							</div>
						</div>

						{caracteristicas_nao_obrigatorias.map(ca_id => {
							const c = caracteristicas_preenchidas[ca_id] || {};
							const c_valor = (c.opcoes_preenchidas[0] || {}).valor || '-';
							const c_unidade = (c.opcoes_preenchidas[0] || {}).unidade || '';

							return (
								<div key={ca_id} styleName="row-edition">
									<div className="form-group">
										<label styleName="label full-width">
											{c.caracteristica.nome}
										</label>
										{c_valor} {c_unidade}
									</div>
								</div>
							);
						})}

						<div styleName="row-edition">
							<div className="form-group">
								<label styleName="label full-width">Observações</label>
								{observacoes !== ''
									? observacoes
									: <em style={{ color: '#97999b' }}>Sem observações</em>
								}
							</div>
						</div>
					</div>
				</div>
			);
		}

		return null;
	}
}

ItemBlocked.propTypes = {
	openItemEdition: PropTypes.func.isRequired,
	item_on_edition_id: PropTypes.number.isRequired,
	itens: PropTypes.object.isRequired,
	client_id: PropTypes.number.isRequired,
	obra_id: PropTypes.number.isRequired,
	updateItemById: PropTypes.func.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
	logged_sienge: PropTypes.bool.isRequired,
};

export default CSSModules(ItemBlocked, styles, { allowMultiple: true });

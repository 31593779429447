import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CreateCompraButton from '../../components/_parts/CreateCompraButton/CreateCompraButton';

const EscolheCompra = ({
	updateModals,
	selecionaCotacaoAlert,
}) => (
	<div styleName="initial-question-wrapper">
		<div className="container">
			<div className="row">
				<div className="col-xs-12">
					<h3 className="h3-header">Você possui uma cotação para esta compra?</h3>
				</div>
			</div>
			<div className="row">
				<div className="col-xs-12 col-md-6">
					<p><br /><br /></p>
					<CreateCompraButton
						button_type="primary"
						button_text="Prosseguir sem cotação"
						updateModals={updateModals}
					/>
					<p><br />Criar uma nova compra sem utilizar uma cotação recebida pelo sistema.<br /><br /></p>
				</div>
				<div className="col-xs-12 col-md-6">
					<p><br /><br /></p>
					<Link
						to="/cotacoes"
						className="btn btn-secondary-conaz round-border"
						onClick={selecionaCotacaoAlert}>
						Escolher cotação
					</Link>
					<p><br />Criar uma nova compra a partir de uma cotação recebida pelo sistema Conaz.<br /><br /></p>
				</div>
			</div>
		</div>
	</div>
);

EscolheCompra.propTypes = {
	selecionaCotacaoAlert: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
};

export default CSSModules(EscolheCompra, styles, { allowMultiple: true });

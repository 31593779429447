
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { _post } from '../../components/_functions/_requests';
import { getLocalstorageData } from '../../components/_functions/_getLocalstorageData';

class AlterarSenha extends Component {
	constructor() {
		super();
		this.state = {
			senha_atual: '',
			senha: '',
			senha_novamente: '',
			success: true,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
		this.voltar = this.voltar.bind(this);
		this.redirecionaParaRecuperacaoSenha = this.redirecionaParaRecuperacaoSenha.bind(this);
	}

	UNSAFE_componentWillMount() {
		const token = getLocalstorageData('user', 'token');
		if (token === '' || token === null) this.props.history.push('/sair');
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Conaz';
	}

	handleEdition(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	voltar() {
		this.props.history.goBack();
	}

	redirecionaParaRecuperacaoSenha() {
		localStorage.removeItem('user');
		this.props.history.push('/esqueci-minha-senha');
	}

	handleSubmit(e) {
		e.preventDefault();
		const { senha, senha_novamente, senha_atual } = this.state;
		this.setState({ infoMsg: '' });

		if (senha === senha_novamente) {
			_post('/conaz/v2/auth/construtor/alterar_senha', { nova_senha: senha, senha_antiga: senha_atual })
				.then(() => {
					this.setState({ success: true, infoMsg: 'Senha alterada com sucesso!' });
				})
				.catch(error => {
					if (!error.response || error.response.status === 500) {
						this.setState({ success: false, infoMsg: 'Ops... aconteceu algo errado. \nClique em Criar e tente novamente.' });
					} else {
						this.setState({ success: false, infoMsg: error.response.data.message });
					}
				});
		} else {
			this.setState({ success: false, infoMsg: 'As senhas não correspondem!' });
		}
	}

	render() {
		const { infoMsg, success } = this.state;
		const error_class = !success ? 'form-group has-error' : 'form-group';

		return (
			<div styleName="login-page-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-md-12" style={{ textAlign: 'center' }}>
							<div styleName="logo-wrapper">
								<img
									src="/images/logotipo_original.png"
									width="230"
									alt="" />
							</div>

							<div styleName="form-wrapper">
								<h3 styleName="h3-margin">Alterar senha</h3>
								<form onSubmit={this.handleSubmit}>
									<div className={error_class}>
										<label
											styleName="label"
											htmlFor="senha_atual">
											Senha atual
										</label>
										<input
											type="password"
											className="form-control"
											id="senha_atual"
											name="senha_atual"
											required
											onChange={this.handleEdition} />
									</div>
									<div className={error_class}>
										<label htmlFor="senha">Nova senha</label>
										<input
											type="password"
											className="form-control"
											id="senha"
											name="senha"
											required
											onChange={this.handleEdition} />
									</div>
									<div className={error_class}>
										<label
											styleName="label"
											htmlFor="senha">
											Confirmar nova senha
										</label>
										<input
											type="password"
											className="form-control"
											id="senha_novamente"
											name="senha_novamente"
											required
											onChange={this.handleEdition} />
									</div>
									{ infoMsg && (
										<div className={`alert alert-${success ? 'success' : 'danger'}`} styleName="mensagem" role="alert">
											<i className={`fa fa-${success ? 'check' : 'exclamation-triangle fa-fw'}`} aria-hidden="true" /> {infoMsg}
										</div>
									)}

									<button
										type="submit"
										className="btn btn-primary btn-lg btn-block"
										styleName="button-margin">
										Alterar
									</button>
									<p
										style={{ textAlign: 'center', marginTop: '20px' }}>
										<Link
											to="#"
											onClick={this.voltar}>
											Voltar
										</Link>
									</p>
									<p
										style={{ textAlign: 'center', marginTop: '20px' }}>
										<Link
											to="#"
											onClick={this.redirecionaParaRecuperacaoSenha}>
											Esqueci minha senha
										</Link>
									</p>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AlterarSenha.propTypes = {
	history: PropTypes.object.isRequired,
};

export default CSSModules(AlterarSenha, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingPage from '../../../components/_parts/_loadings/LoadingPage/LoadingPage';
import LoadingButton from '../../../components/_parts/_loadings/LoadingButton/LoadingButton';

const editObra = (obra_id) => {
	const obraDiv = document.getElementById(obra_id);
	obraDiv.getElementsByTagName('input')[0].style.display = 'block';
	obraDiv.getElementsByTagName('p')[0].style.display = 'none';
};

const verificacaoEdicao = (obra) => {
	if (!obra.edicao && obra.id_no_swi) {
		return true;
	}
	return false;
};

const SiengeObras = ({
	// =========== state
	loading,
	obras,
	logging_state,
	infoMsg,
	salvando,
	// =========== store
	// =========== local funcs
	handleSubmit,
	handleEdition,
	handleEnter,
	// =========== prop funcs
}) => {
	if (loading === 1) {
		return <LoadingPage />;
	}

	return (
		<>
			{/* Header */}
			<div className="header">
				<div className="container">
					<div className="row">
						<div className="col-xs-12">
							<div styleName="center-position">
								<div styleName="card-login">
									<h3 className="h3-header">Conectar obras do Sienge</h3>
									<div styleName="form-row-back-button">
										<Link to="/integracao/sienge">
											<button className="btn btn-clear-conaz round-border">
												<i className="fa fa-angle-left fa-fw" aria-hidden="true" /> Voltar para o início
											</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div styleName="center-position p-top">
					<div styleName="card-login">
						{obras.map((obra) => (
							<div key={obra.id} id={obra.obra_id}>
								<div className="form-group" id={obra.id}>
									<label>{obra.nome}</label>
									<p style={{ display: verificacaoEdicao(obra) ? 'block' : 'none' }}>
										<span styleName="sub-title-item">Código no Sienge:</span> {obra.id_no_swi}
										<button type="button" onClick={() => { editObra(obra.id); }} className="btn btn-clear-conaz round-border" styleName="btn-pencil">
											<i className="fa fa-pencil fa-fw" aria-hidden="true" />
										</button>
									</p>
									<input
										style={{ display: verificacaoEdicao(obra) ? 'none' : 'block' }}
										type="text"
										className="form-control input-md"
										placeholder="Código da obra no Sienge"
										onChange={handleEdition}
										value={obra.id_no_swi}
										id={obra.id}
										name={obra.nome}
										onKeyUp={salvando === 1 ? null : handleEnter}
										maxLength={100} />
								</div>
								<hr styleName="hrCustom" />
							</div>
						))}
						{logging_state === 1 && (
							<div className="alert alert-danger" role="alert">
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> {infoMsg}
							</div>
						)}
						<div styleName="align-btns-center">
							<div styleName="form-row-back-button m-2">
								<Link to="/integracao/sienge">
									<button className="btn btn-clear-conaz round-border">
										<i className="fa fa-angle-left fa-fw" aria-hidden="true" />Voltar
									</button>
								</Link>
							</div>


							{salvando === 1 ? (
								<button
									type="button"
									styleName="m-2"
									className="btn btn-secondary-conaz round-border loading">
									<LoadingButton /> Salvar
								</button>
							) : (
								<button
									type="button"
									styleName="m-2"
									className="btn btn-secondary-conaz round-border"
									onClick={handleSubmit}>
									Salvar
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

SiengeObras.propTypes = {
	loading: PropTypes.number.isRequired,
	infoMsg: PropTypes.string.isRequired,
	logging_state: PropTypes.number.isRequired,
	salvando: PropTypes.number.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleEdition: PropTypes.func.isRequired,
	handleEnter: PropTypes.func.isRequired,
	obras: PropTypes.array.isRequired,
};

export default CSSModules(SiengeObras, styles, { allowMultiple: true });

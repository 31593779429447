// export const attachHiChat = (nome, email, cliente) => {
// 	window.hiChatCallback = () => {
// 		window.Hi.Chat.fillSurvey(
// 			`nome=${nome}&email=${email}&cliente=${cliente}`,
// 		);
// 	};
// 	const e = document.createElement('script');
// 	const t = document.getElementsByTagName('script')[0];
// 	e.async = 1;
// 	e.id = 'hi-chat-script';
// 	e.src = 'https://chat.directtalk.com.br/static/hi-chat/chat.js?widgetId=aed31a24-bd2d-4bc8-b1f8-250c55a78854&callback=hiChatCallback';
// 	t.parentNode.insertBefore(e, t);
// };

/* eslint--disable */
// export const attachHiChat = (nome, email, cliente) => {
// 	(function(h,i,b,o,t) {
// 		if (window.HiBot !== undefined) return true;
// 		var s = i.createElement('script');
// 		s.src = b + o;
// 		s.async = true;
// 		s.id = t;
// 		i.body.appendChild(s);
// 	})(window, document, '//cdn-bot.hiplatform.com/dtbot.js?token=ef8dcce3-6a9e-459b-b9da-1aed1d82f974', `&widget=true&tab=true&top=40&text=Posso%20ajudar%3F&textcolor=ffffff&bgcolor=0181b6&from=right&widgetType=box&iconId=&initParams=%7B%22nome_usuario%22%3A%22${nome}%22%2C%22email%22%3A%22${email}%22%2C%22cliente%22%3A%22${cliente}%22%7D&automatic=true&automaticBg=true`, 'dtbot-script');
// };

export const attachHiChat = (nome, email, cliente) => {
	const e = document.createElement('script');
	const t = document.getElementsByTagName('script')[0];
	e.async = 1;
	e.id = 'hi-widget';
	e.src = `https://www11.directtalk.com.br/clientes/custom/Conaz/widget.min.js?nome_usuario=${nome}&email=${email}&cliente=${cliente}`;
	t.parentNode.insertBefore(e, t);
};

export const setZIndexHiChat = () => {
	let retries = 0;
	const timer = setInterval(() => {
		retries++;
		const elem = document.getElementById('dtbot-iframe');
		if (elem) {
			elem.style.zIndex = 1900;
			clearInterval(timer);
		}
		if (retries >= 40) {
			clearInterval(timer);
		}
	}, 1000);
};

/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import { useDispatch, useSelector } from 'react-redux';
import { defineStatusCompra } from 'components/_functions/_defineStatusCompra';
import { _get } from 'components/_functions/_requests';
import styles from './style.module.scss';

import ExportadaSienge from '../../../icons/exportada_sienge_status.svg';

function StatusExportSienge({ compra, exportStatus }) {
	const dispatch = useDispatch();
	const { data } = useSelector(state => state.exportStatus);

	const [statusCompra, setStatusCompra] = useState([]);

	const [status_exportacao, setStatusExportacao] = useState([]);

	useEffect(() => {
		setStatusCompra(defineStatusCompra(compra));
	}, []);

	useEffect(() => {
		if (data) {
			setStatusExportacao(data.job_status);
		}
		if (!data) {
			setStatusExportacao('');
		}
	}, [data]);

	useEffect(() => {
		setStatusExportacao(exportStatus);
	}, [exportStatus]);

	const verificaStatus = () => {
		if (status_exportacao) {
			return (
				status_exportacao.job_status === 'FINISHED' ? (
					<>
						Exportada para Sienge
						<div styleName="exportada_icon">
							<img src={ExportadaSienge} alt="Sucesso na exportação" styleName="icone_status_sucesso" />
							<p styleName="status_sucesso_exportacao"> {' '} Exportada com sucesso</p>
						</div>
					</>
				) : status_exportacao && status_exportacao.job_status === 'ERROR' ? (
					<>
						Exportada para Sienge
						<p styleName="status_falha_exportacao">	X Falha na exportação</p>
					</>
				) : ''
			);
		}
		if (!status_exportacao) {
			return (
				<div />
			);
		}
	};

	useEffect(() => {
		verificaStatus();
	}, [status_exportacao]);

	return (
		<>
			{statusCompra !== 'rascunho' ? (
				<>
					<div styleName="status_export_sienge">
						{status_exportacao && verificaStatus()}
					</div>
				</>
			) : ''
			}
		</>
	);
}

StatusExportSienge.propTypes = {
	compra: PropTypes.object.isRequired,
	exportStatus: PropTypes.object.isRequired,
};

export default CSSModules(StatusExportSienge, styles, { allowMultiple: true });

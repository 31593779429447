import React from 'react';
import Proptypes from 'prop-types';
// import { Link } from 'react-router-dom';

// Style
import {
	Container,
	Header,
	Title,
	Faturamento,
	Resumo,
	Fornecedor,
	Observacoes,
	ItemBlock,
	HR,
	HrText,
} from './Impressao.style';

const Impressao = ({ compra }) => {
	const {
		compra_id,
		cotacao_id,
		condicao_de_pagamento,
		desconto,
		dt_emitida,
		dt_entrega,
		items,
		transporte,
		titulo,
		valor_transporte,
		custos_adicionais,
		total_item,
		subtotal,
		total,
		observacoes,
		observacoes_internas,
		fornecedor,
		faturamento,
		obra_endereco,
	} = compra;

	return (
		<Container>
			<Header>
				<Title>{titulo}</Title>
				<div className="header-left">
					<p>Compra #{compra_id} {cotacao_id && (
						<>
							<span>|</span>
							{`${' '}Pedido de cotação #${cotacao_id}`}
						</>
					)}</p>
				</div>
				<div className="header-right">
					<p>Data de emissão:</p>
					<span>{dt_emitida}</span>
				</div>
			</Header>
			<Faturamento>
				<p>Empresa para faturamento:</p>
				<span>{faturamento.nome}</span>
				<HR size="2" />
				<p>CNPJ:</p>
				<span>{faturamento.doc}</span>
				<HR size="2" />
				<p>Endereço da obra:</p>
				<span>{obra_endereco}</span>
			</Faturamento>
			<Resumo>
				<span>Resumo da compra</span>
				<div>
					<p>Subtotal dos itens:<b> {subtotal}</b></p>
					<p>Desconto geral: <b>{desconto}%</b></p>
					<p>Total dos itens: <b>{total_item}</b></p>
					<HR size="3" />
					<p>Transporte: <b>{valor_transporte}</b></p>
					<p>Custos adicionais: <b>{custos_adicionais}</b></p>
				</div>
				<p>Total: <span>{total}</span></p>
			</Resumo>
			<Fornecedor>
				<p>Empresa fornecedora:</p>
				<span>{fornecedor.nome}</span>
				<HR size="2" />
				<p>CNPJ:</p>
				<span>{fornecedor.doc}</span>
				<HR size="2" />
				<p>Condição de pagamento:</p>
				<span>{condicao_de_pagamento}</span>
				<HR size="2" />
				<p>Data de entrega:</p>
				<span>{dt_entrega}</span>
				<HR size="2" />
				<p>Transporte:</p>
				<span>{transporte}</span>
			</Fornecedor>
			{(observacoes || observacoes_internas) && (
				<>
					<HR size="2" />
					<Observacoes>
						{observacoes && (
							<>
								<p>Observações:</p>
								<span>{observacoes}</span>
								<HR />
							</>
						)}
						{observacoes_internas && (
							<>
								<p>Observações internas:</p>
								<span>{observacoes_internas}</span>
							</>
						)}
					</Observacoes>
				</>
			)}
			<HrText>Itens ({items.length}):</HrText>
			<HR full />
			{items.map((item, i) => (
				<ItemBlock key={item.id}>
					<div className={`item-left ${item.status === -1 || item.excluido ? 'strikethrough' : ''}`}>
						<div>{i + 1}. {item.nome}</div>
						{item.status === -1 && <div className="retirado">Fornecedor não possui este item</div>}
						{item.excluido && <div className="retirado">Item excluído da compra</div>}
						{item.caracteristicas.map(c => (
							<p key={c.valor}><span>{c.caracteristica}:</span> {c.valor}</p>
						))}
						{item.observacoes && <p><span>Observações:</span> {item.observacoes}</p>}
						{/* {item.observacoes_internas && <p>Observações internas: {item.observacoes_internas}</p>} */}
					</div>
					<div className={`item-right ${item.status === -1 ? 'strikethrough' : ''}`}>
						<div>{item.qtd} {item.un}</div>
						<p>Valor unitário <b>{item.preco}</b></p>
						<p>Desconto unitário <b>{item.desconto}%</b></p>
						<hr />
						<p>Total <span>{item.total}</span></p>
					</div>
				</ItemBlock>
			))}
		</Container>
	);
};

Impressao.propTypes = {
	compra: Proptypes.object.isRequired,
};

export default Impressao;

/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../../store/withStore';

// Components
import QcHoc from '../QcHoc';
import RequestsWrapperFuture from '../../../components/RequestsWrapper/RequestsWrapperFuture';
import QcView from './QcView';

// Functions
import { setRowsHeightsQc } from '../../../components/_functions/_setRowsHeightsQc';
import { _get, _post } from '../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../components/_functions/_handleRequestErrors';
import { defineStatusPedido } from '../../../components/_functions/_defineStatusPedido';
import { setUserLocalstorageData } from '../../../components/_functions/_getUserLocalstorageData';

class QcViewContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Seta as url's para carregar através do componente RequestsWrapper
			 */
			urls_to_fetch: [
				`/conaz/v2/pedidos/${props.match.params.id}?fields=anexos,codigo,compras,criado_em,data_de_entrega_desejada,enviado_em,id,obra,observacoes,observacoes_conaz,prazo_de_resposta,status,tipo,titulo,ultima_atualizacao,ultimo_envio_qc,usuario,ultimo_qc_anexo`,
				`/conaz/v2/analises/?pedido_id=${props.match.params.id}&fields=id,itens_analise,visualizado,opcoes_de_compra.id,opcoes_de_compra.oculta,opcoes_de_compra.rascunho`,
			],
			qc_disponivel: 1,
			sidebar_combinacao: false,
			combinacao_criada_id: 0,
			loading_compras: 0,
			enviando_qc_triagem: 0,
		};
		this.setSizesOnChanges = this.setSizesOnChanges.bind(this);
		this.handleResponses = this.handleResponses.bind(this);
		this.carregaCompras = this.carregaCompras.bind(this);
		this.openCombinacaoSideBar = this.openCombinacaoSideBar.bind(this);
		this.closeCombinacaoSideBarAfterCreate = this.closeCombinacaoSideBarAfterCreate.bind(this);
		this.collapseOC = this.collapseOC.bind(this);
		this.deleteOCFromProps = this.deleteOCFromProps.bind(this);
		this.changeCondicaoPagamento = this.changeCondicaoPagamento.bind(this);
		this.goToCriarCompra = this.goToCriarCompra.bind(this);
		this.enviaQCTriagem = this.enviaQCTriagem.bind(this);
	}

	changeObra(props) {
		const { qc, user, changeObra } = props;
		const cotacao_view = qc.cotacao || {};
		const cotacao_obra_id = (cotacao_view.obra || {}).id;
		const isCotacaoLoaded = cotacao_view.obra !== undefined;
		const isDifferentObra = user.obra_selected !== cotacao_obra_id;
		if (isCotacaoLoaded && isDifferentObra) {
			changeObra(cotacao_obra_id, false);
			setUserLocalstorageData('obra', cotacao_obra_id);
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.setSizesOnChanges);
		const { match, handleDimensions } = this.props;
		// Set page title
		document.title = `QC #${match.params.id} - Conaz`;
		// Ajusta tamanho dos elementos da página
		handleDimensions();
		// Change obra
		this.changeObra(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.changeObra(nextProps);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.setSizesOnChanges);
		const { updateQc, updateModals } = this.props;
		updateQc(['itens_analise'], {
			by_id: {},
			all_ids: [],
		});
		updateQc(['opcoes_de_compra'], {
			by_id: {},
			all_ids: [],
		});
		updateModals('exporta_excel', false);
		updateModals('item_analise_mobile', false);
	}

	setSizesOnChanges() {
		/**
		 * Ajusta tamanho dos elementos da página
		 */
		const { qc } = this.props;
		const itens_analise = qc.itens_analise.all_ids.map(id => qc.itens_analise.by_id[id]);
		setRowsHeightsQc(itens_analise);
	}

	handleResponses(responses, updateWrapperState) {
		const { history, updateQc, setItensAnalise, handleScroll } = this.props;
		const cotacao = { ...responses[0].data };
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		/**
		 * QC Analise não existe
		 */
		if (responses[1].data.length === 0) {
			this.setState({ qc_disponivel: 2 });
			updateWrapperState('request_state', 2);
			return;
		}
		/**
		 * Verifica status da cotação e redireciona caso seja rascunho
		 * Mostra mensagem que o pedido não existe caso status esteja errado
		 */
		const status = defineStatusPedido(cotacao);
		if (
			status === 'rascunho' ||
			status === 'refinamento' ||
			status === 'em_edicao' ||
			status === 'envio_solicitado'
		) {
			history.push(`/cotacao/nova/${cotacao.codigo}`);
			return;
		}
		const status_allowed = !is_triagem
			? [
				'qc_disponivel',
				'compras_aguardando_confirmacao',
				'finalizado',
			] : [
				'revisao_conaz',
				'aguardando_cotacoes',
				'qc_disponivel',
				'compras_aguardando_confirmacao',
				'finalizado',
			];
		if (status_allowed.filter(x => x === defineStatusPedido(cotacao)).length === 0) {
			this.setState({ qc_disponivel: 2 });
			updateWrapperState('request_state', 2);
			return;
		}

		updateQc(['cotacao'], cotacao);

		setItensAnalise(responses, updateWrapperState, updateQc, null, this.setSizesOnChanges, this.carregaCompras);
		/**
		 * Zera as url's no RequestWrapper para prevenir novas requests
		 * Finaliza o loading e mostra o conteúdo
		 */
		this.setState({ urls_to_fetch: [] });
		// updateWrapperState('request_state', 2);
		/**
		 * Scroll addEventListener
		 */
		document.getElementById('qc_scroll').addEventListener('scroll', handleScroll);
		/**
		 * Seta os tamanhos das linhas
		 */
		setRowsHeightsQc(responses[1].data[0].itens_analise);
		/**
		 * Carrega compras
		 */
		// this.carregaCompras(responses[1].data[0]);
	}

	carregaCompras(analise, all_ids) {
		/**
		 * Nenhuma opção de compra
		 */
		if (
			analise.opcoes_de_compra.length === 0 ||
			all_ids.length === 0
		) {
			this.setState({ qc_disponivel: 2 });
			return;
		}
		/**
		 * Carrega compras
		 */
		this.setState({ loading_compras: 1 });
		const { qc, updateQc, updateModals } = this.props;
		const { id } = qc.cotacao;

		_get(`/conaz/v2/compras/?pedido_id=${id}`).then(response => {
			const compras = [...response.data]
				.map(c => ({ ...c, rc_id: c.rc.uuid !== undefined ? c.rc.uuid : 0 }));
			updateQc(['compras'], compras);
			this.setState({ loading_compras: 2 });
			this.checkPrimeiraVisualizacao(analise);
		}).catch(error => {
			this.setState({ loading_compras: 3 });
			updateModals('error', true);
			handleRequestErrors(error);
		});
	}

	/* eslint-disable */
	buscaOpcoesCombinacao(analise) {
		const pedido_id = (analise || {}).pedido_id || null;
		const params = {
			rcs_ignorados: [],
			limite_fornecedores: 3,
			compra_minima: 200,
		};
		// _get(`/conaz/v2/analises/gerar_combinacao/${pedido_id}`, params).then(response => {
		// 	/**
		// 	 * Verifica se existe alguma combinação atraente
		// 	 * para o construtor
		// 	 * 
		// 	 */
		// 	console.log(response.data);
		// 	this.setState({ opcoes_de_combinacao: response.data });
		// }).catch(error => {
		// 	handleRequestErrors(error);
		// });
	}
	/* eslint-enable */

	checkPrimeiraVisualizacao(analise) {
		const analise_id = (analise || {}).id || null;
		const visualizado = (analise || {}).visualizado || false;
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		if (!visualizado && analise_id !== null && !is_triagem) {
			_post(`/conaz/v2/analises/${analise_id}/registrar_visualizacao`, {}).then(() => {
				/**
				 * Carrega sugestoes de combinações
				 * para mostrar possibilidades
				 */
				this.buscaOpcoesCombinacao();
			}).catch(error => {
				handleRequestErrors(error);
			});
		}
	}

	openCombinacaoSideBar() {
		const sidebar_combinacao = !this.state.sidebar_combinacao;
		this.setState({ sidebar_combinacao });
		setTimeout(() => {
			this.props.handleDimensions();
		}, 0);
	}

	closeCombinacaoSideBarAfterCreate(combinacao_criada_id) {
		const sidebar_combinacao = !this.state.sidebar_combinacao;
		this.setState({ sidebar_combinacao, combinacao_criada_id });
		setTimeout(() => {
			this.props.handleDimensions();
			this.setSizesOnChanges();
			document.getElementById('qc_scroll').scrollLeft = 0;
		}, 0);
		/**
		 * Some com a mensagem de sucesso
		 */
		setTimeout(() => {
			this.setState({ combinacao_criada_id: 0 });
		}, 5000);
	}

	collapseOC(oc_id) {
		const { qc, updateQc } = this.props;
		const collapsed = !qc.opcoes_de_compra.by_id[oc_id].collapsed;
		const by_id = {
			...qc.opcoes_de_compra.by_id,
			[oc_id]: {
				...qc.opcoes_de_compra.by_id[oc_id],
				collapsed,
			},
		};
		updateQc(['opcoes_de_compra', 'by_id'], by_id);
		/**
		 * Ajusta tamanho dos elementos da página
		 */
		setTimeout(() => this.setSizesOnChanges(), 0);
	}

	deleteOCFromProps(oc_id) {
		const { qc, updateQc } = this.props;
		const all_ids = [...qc.opcoes_de_compra.all_ids]
			.filter(x => x !== Number(oc_id));
		updateQc(['opcoes_de_compra', 'all_ids'], all_ids);
		/**
		 * Ajusta tamanho dos elementos da página
		 */
		setTimeout(() => this.setSizesOnChanges(), 0);
	}

	changeCondicaoPagamento(oc_id, rca_id, condicao_id) {
		const { calcTotalRC, qc, updateQc } = this.props;
		calcTotalRC(oc_id, rca_id, condicao_id, qc, updateQc);
	}

	goToCriarCompra(rc_analise) {
		const { qc, updateCompras, history } = this.props;
		const criar_compra = {
			pedido: qc.cotacao,
			rc: {},
			rc_analise,
			type: 'rc_analise',
		};
		updateCompras(['criar_compra'], criar_compra);
		history.push('/compras/nova');
	}

	enviaQCTriagem() {
		const { qc } = this.props;
		if (process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno') {
			this.setState({ enviando_qc_triagem: 1 });
			_post(`/conaz/v2/pedidos/${qc.cotacao.id}/enviar_qc`, {}).then(() => {
				this.setState({ enviando_qc_triagem: 2 });
				alert('QC enviado com sucesso!'); // eslint-disable-line
			}).catch(error => {
				const error_status = ((error || {}).response || {}).status;
				const error_conaz = (((error || {}).response || {}).data || {}).message || '';
				if (error_status === 400 && error_conaz !== '') {
					this.setState({ enviando_qc_triagem: 0 });
					alert(error_conaz); // eslint-disable-line
					return;
				}
				this.setState({ enviando_qc_triagem: 3 });
				handleRequestErrors(error);
			});
		}
	}

	render() {
		return (
			<RequestsWrapperFuture
				urls_to_fetch={this.state.urls_to_fetch}
				handleResponses={this.handleResponses}
				{...this.props}>

				<QcView
					{...this.state}
					{...this.props}
					openCombinacaoSideBar={this.openCombinacaoSideBar}
					closeCombinacaoSideBarAfterCreate={this.closeCombinacaoSideBarAfterCreate}
					deleteOCFromProps={this.deleteOCFromProps}
					changeCondicaoPagamento={this.changeCondicaoPagamento}
					collapseOC={this.collapseOC}
					goToCriarCompra={this.goToCriarCompra}
					enviaQCTriagem={this.enviaQCTriagem} />

			</RequestsWrapperFuture>
		);
	}
}

QcViewContainer.propTypes = {
	// =========== hoc
	setItensAnalise: PropTypes.func.isRequired,
	handleDimensions: PropTypes.func.isRequired,
	handleScroll: PropTypes.func.isRequired,
	calcTotalRC: PropTypes.func.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	cotacoes: PropTypes.object.isRequired,
	compras: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	qc: PropTypes.object.isRequired,
	// =========== funcs
	updateQc: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	updateCompras: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	changeObra: PropTypes.func.isRequired,
	unsetAuth: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
const _QcViewContainer = withStore(connect(mapStateToProps, mapDispatchToProps)(QcViewContainer));
export default QcHoc(_QcViewContainer);

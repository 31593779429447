/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
// import InitialLoading from './InitialLoading';
import ExportErrorViews from './ExportErrorViews';

const StartExportSienge = ({
	logged_sienge,
	auto_export,
	setStep,
	closeFunc,
	itens,
	setExportingError,
}) => {
	const [error, setError] = useState(false);

	function checkSemIntegrado() {
		const { all_ids, by_id } = itens;

		const sem_integrado = all_ids.filter(item_id => {
			const item = by_id[item_id] || {};
			if (item.excluido) return false;
			const integrado_id = (item.item_integrado || {}).id || null;
			return integrado_id === null;
		});

		if (sem_integrado.length > 0) {
			setError(true);
			setExportingError(true);
			return;
		}

		setStep(2);
	}

	useEffect(() => {
		if (auto_export && logged_sienge) {
			checkSemIntegrado();
		}
	}, [auto_export]);

	if (!logged_sienge) {
		return (
			<ExportErrorViews error_type={1} />
		);
	}

	if (error) {
		return (
			<ExportErrorViews error_type={2} />
		);
	}

	if (!auto_export) {
		return (
			<>
				{checkSemIntegrado()}
			</>
		);
	}

	// return <InitialLoading />;
};

StartExportSienge.propTypes = {
	logged_sienge: PropTypes.bool.isRequired,
	auto_export: PropTypes.bool.isRequired,
	setStep: PropTypes.func.isRequired,
	closeFunc: PropTypes.func.isRequired,
	setExportingError: PropTypes.func.isRequired,
	itens: PropTypes.object.isRequired,
};

export default CSSModules(StartExportSienge, styles, { allowMultiple: true });

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import ModalPortal from '../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalConfirm from '../../../components/_parts/_modals/ModalConfirm/ModalConfirm';
import EditarCotacao from './EditarCotacao';
import CopiarPedido from './CopiarPedido';
import NotificarPessoas from './NotificarPessoas';
import CancelarCotacao from './CancelarCotacao';
import DescancelarCotacao from './DescancelarCotacao';

// Functions
import { defineStatusPedido } from '../../../components/_functions/_defineStatusPedido';
import { _post } from '../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../components/_functions/_handleRequestErrors';

class AcoesMenu extends Component {
	constructor() {
		super();
		this.state = {
			acoes_menu: false,
			inside_confirm: false, // confirm de algum dos itens do menu
			confirm_edicao: false,
			confirmando_edicao: 0,
			error_message: 'Desculpe, aconteceu algo errado. Tente novamente.',
		};
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.handleMenu = this.handleMenu.bind(this);
		this.handleInsideConfirm = this.handleInsideConfirm.bind(this);
		this.openConfirmEdicao = this.openConfirmEdicao.bind(this);
		this.confirmEdicao = this.confirmEdicao.bind(this);
	}

	componentDidMount() {
		window.addEventListener('mouseup', this.handleMouseUp);
	}

	componentWillUnmount() {
		window.removeEventListener('mouseup', this.handleMouseUp);
	}

	handleMouseUp(e) {
		if (!e.target.getAttribute('data-acoes-pedido-menu')) {
			this.setState({ acoes_menu: false, inside_confirm: false });
		}
	}

	handleMenu() {
		const acoes_menu = !this.state.acoes_menu;
		this.setState({ acoes_menu });
		if (!acoes_menu) {
			this.setState({ inside_confirm: false });
		}
	}

	handleInsideConfirm() {
		const inside_confirm = !this.state.inside_confirm;
		this.setState({ inside_confirm });
	}

	openConfirmEdicao() {
		const confirm_edicao = !this.state.confirm_edicao;
		this.setState({ confirm_edicao });
	}

	confirmEdicao() {
		this.setState({ confirmando_edicao: 1 });
		const { match, history } = this.props;
		_post(`/conaz/v2/pedidos/${match.params.id}/iniciar_edicao`, {}).then(() => {
			history.replace(`/cotacao/nova/${match.params.id}`);
		}).catch(error => {
			this.setState({ confirmando_edicao: 3 });

			const conaz_error = ((
				error.response || {})
				.data || {})
				.message || '';

			if (conaz_error === 'Pedido possui compras ativas') {
				this.setState({ error_message: 'Este pedido possui já compras ativas, logo não pode ser editado.' });
			}

			handleRequestErrors(error);
		});
	}

	render() {
		const {
			acoes_menu,
			inside_confirm,
			confirm_edicao,
			confirmando_edicao,
			error_message,
		} = this.state;
		const {
			pedido_status,
			cancelado,
			match,
			history,
			updateUi,
			updateModals,
			updateCotacao,
			reloadPageChangeStatus,
			cotacao,
		} = this.props;

		/**
		 * Seta id para mostrar onboarding
		 * de edição de pedido
		 */
		const status = defineStatusPedido(cotacao);
		const id_onboarding = (
			status === 'revisao_conaz' ||
			status === 'aguardando_cotacoes' ||
			status === 'qc_disponivel'
		) ? 'acoes_edicao_onboarding' : '';

		return (
			<div styleName="acoes-cotacao-wrapper">
				{confirm_edicao && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={this.openConfirmEdicao}>
							<ModalConfirm
								title="Importante"
								message={<p>Quando se inicia a edição, todos os fornecedores são notificados da sua alteração, e os que já haviam respondido anteriormente precisam confirmar novamente as suas propostas.<br /><br />Tem certeza que deseja esta edição?<br /><br /><a href="https://conaz.zendesk.com/hc/pt-br/articles/360011093193" target="_blank" rel="noopener noreferrer">Clique aqui para mais informações.</a></p>}
								confirmando={confirmando_edicao}
								error_message={error_message}
								confirmFunc={this.confirmEdicao}
								button_type="secondary-conaz"
								button_text="Quero editar" />
						</ModalWrapperPortal>
					</ModalPortal>
				)}
				<button
					id={id_onboarding}
					type="button"
					styleName="btn-cancel-cotacao"
					data-acoes-pedido-menu
					onClick={this.handleMenu}>
					Ações <i className="fa fa-caret-down fa-fw" aria-hidden="true" data-acoes-pedido-menu />
				</button>
				{!!acoes_menu && (
					<div styleName="menu-wrapper" data-acoes-pedido-menu>
						<div styleName="message" data-acoes-pedido-menu>
							<DescancelarCotacao
								cancelado={cancelado}
								match={match}
								updateUi={updateUi}
								updateModals={updateModals}
								inside_confirm={inside_confirm}
								handleInsideConfirm={this.handleInsideConfirm}
								reloadPageChangeStatus={reloadPageChangeStatus} />

							<EditarCotacao
								openConfirmEdicao={this.openConfirmEdicao}
								handleMenu={this.handleMenu}
								inside_confirm={inside_confirm}
								pedido_status={pedido_status}
								cancelado={cancelado}
								handleInsideConfirm={this.handleInsideConfirm}
								match={match}
								history={history}
								updateModals={updateModals}
								cotacao={cotacao} />

							<CopiarPedido
								match={match}
								history={history}
								updateUi={updateUi}
								updateModals={updateModals}
								inside_confirm={inside_confirm}
								handleInsideConfirm={this.handleInsideConfirm} />

							<NotificarPessoas
								updateModals={updateModals}
								handleMenu={this.handleMenu}
								inside_confirm={inside_confirm} />

							<CancelarCotacao
								pedido_status={pedido_status}
								cancelado={cancelado}
								match={match}
								history={history}
								updateUi={updateUi}
								updateModals={updateModals}
								updateCotacao={updateCotacao}
								inside_confirm={inside_confirm}
								handleInsideConfirm={this.handleInsideConfirm} />
						</div>
					</div>
				)}
			</div>
		);
	}
}

AcoesMenu.propTypes = {
	pedido_status: PropTypes.number.isRequired,
	cancelado: PropTypes.bool.isRequired,
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	reloadPageChangeStatus: PropTypes.func.isRequired,
	cotacao: PropTypes.object.isRequired,
};

export default CSSModules(AcoesMenu, styles, { allowMultiple: true });

import moment from 'moment';
// import _ from 'lodash';
// import 'moment/locale/pt-br';

export const editDateFormat = date => {
	if (date === null) return '';
	const day = date.toString().split(' ')[2];
	const mon = (date._d.getMonth() + 1).toString().length === 1 ? `0${date._d.getMonth() + 1}` : date._d.getMonth() + 1;
	const year = date.toString().split(' ')[3];
	return `${year}-${mon}-${day}`;
};

export const editMomentDateFormat = (date, format) => {
	switch (format) {
	default:
	case 'simple':
		return moment(date).year() === moment().year()
			? moment(date).locale('pt-BR').format('D [de] MMMM')
			: moment(date).locale('pt-BR').format('D [de] MMM [de] YYYY');
	case 'default':
		return moment(date).utcOffset(moment().utcOffset()).format('L');
	case 'hour':
		return `${moment(date).format('DD/MM/YY')} - ${moment(date).utcOffset(moment().utcOffset()).format('HH:MM')}`;
	}
};

export const editEnviadoEmFormat = date => {
	if (date) {
		const utcOffset = moment().utcOffset();
		const local_hour = moment(date).utcOffset(utcOffset).format('HH:mm');
		const enviado_em_local = moment(date).format('YYYY-MM-DD');
		const data_hora_resposta_split = enviado_em_local.split('T');
		const enviado_em_view =
			moment(data_hora_resposta_split[0]).year() === moment().year()
				? moment(data_hora_resposta_split[0]).locale('pt-BR').format('D [de] MMMM')
				: moment(data_hora_resposta_split[0]).locale('pt-BR').format('D [de] MMM [de] YYYY');
		return [enviado_em_view, local_hour];
	}
	return ['', ''];
};

export const atualizaFornecedorSiengeFormat = date => {
	if (date) {
		const utcOffset = moment().utcOffset();
		const local_hour = moment(date).utcOffset(utcOffset).format(' HH:mm');
		const enviado_em_local = moment(date).format('YYYY-MM-DD');
		const data_hora_resposta_split = enviado_em_local.split('T');
		const enviado_em_view =
			moment(data_hora_resposta_split[0]).year() === moment().year()
				? moment(data_hora_resposta_split[0]).locale('pt-BR').format('DD/MM/YYYY ')
				: moment(data_hora_resposta_split[0]).locale('pt-BR').format('DD/MM/YYYY ');
		return [enviado_em_view, local_hour];
	}
	return ['', ''];
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Redux
import { buildMapStateToProps } from '../../../../store/reduxDispatchs';
import { defineStatusCompra } from '../../../../components/_functions/_defineStatusCompra';

// Componentes
import ModalExportSienge from './ModalExportSienge';
import Tooltip from '../../../../components/_parts/_tooltips/ButtonToolTip/ButtonToolTip';

function BtnExportSienge({ compra,
	logged_sienge,
	itens,
	user,
	updateItensRaw,
	updateItemById,
	redirectAfterConcluir,
	notificaUsuarioAposExportacao }) {
	const { status, data } = useSelector(state => state.exportStatus);

	const [export_modal, setExportModal] = useState(false);
	const [client_has_sienge, setClienteHasSienge] = useState(false);
	const [statusExportacao, setStatusExportacao] = useState([]);

	useEffect(() => {
		const clienteSienge = user.sienge_liberado || false;
		setClienteHasSienge(clienteSienge);
	}, []);

	useEffect(() => {
		if (data) {
			setStatusExportacao(data);
		}
		if (!data) {
			setStatusExportacao('');
		}
		if (data && data.message === 'Nenhuma requisição encontrada') {
			return setStatusExportacao(null);
		}
	}, [data]);

	const openModal = () => {
		setExportModal(true);
	};

	const closeModals = () => {
		setExportModal(false);
	};

	const compra_status = defineStatusCompra(compra);
	const exportado_em = compra.exportado_em || null;
	const exportada_status = (compra.swi_cotacao || {}).status || 0;
	const compra_ja_exportada = (
		exportado_em !== null
			|| exportada_status === 10
			|| compra_status === 'rascunho'
			|| compra_status === 'em_edicao'
			|| compra_status === 'cancelada'
	);

	if (!client_has_sienge || compra_ja_exportada) {
		return null;
	}

	return (
		<>
			{export_modal && (
				<ModalExportSienge
					closeModal={closeModals}
					logged_sienge={logged_sienge}
					compra={compra}
					itens={itens}
					swi_cotacao={compra.swi_cotacao}
					cliente_id={user.cliente_id}
					sienge_liberado={user.sienge_liberado || false}
					redirectAfterConcluir={redirectAfterConcluir}
					updateItensRaw={updateItensRaw}
					updateItemById={updateItemById}
					notificaUsuarioAposExportacao={notificaUsuarioAposExportacao}
				/>
			)}
			{status && statusExportacao && (statusExportacao.job_status === 'RUNNING' || statusExportacao.job_status === 'FINISHED' || statusExportacao.job_status !== 'ERROR') ? (
				<button
					type="button"
					styleName="titleActionsDisabled share"
					disabled
				>
					<i className="fa fa-upload" />
					<strong>Exportar para Sienge</strong>
					<div styleName="action-tooltip">
						<Tooltip
							top="70%"
							margin_left="-125px"
							width="150px"
							styleArrow={{ top: '-0.5%', marginLeft: '38%', transform: 'rotate(180deg)' }}
							text="Exportar compra para o Sienge"
						/>
					</div>
				</button>
			) : (
				<button
					type="button"
					disabled={(compra.swi_cotacao.cotacao_id && status !== 'ERROR') || (data && status !== 'ERROR' && status && data.erro_descricao !== null && data.erro_motivo !== null && data.id !== null && data.job_id !== null && data.job_status !== null && data.passos_finalizados !== null)}
					styleName={(compra.swi_cotacao.cotacao_id && status !== 'ERROR') || (data && status !== 'ERROR' && status) ? 'titleActionsDisabled share' : 'titleActions share'}
					onClick={() => openModal()}
				>
					<i className="fa fa-upload" />
					<strong>Exportar para Sienge</strong>
					<div styleName="action-tooltip">
						<Tooltip
							top="70%"
							margin_left="-125px"
							width="150px"
							styleArrow={{ top: '-0.5%', marginLeft: '38%', transform: 'rotate(180deg)' }}
							text="Exportar compra para o Sienge"
						/>
					</div>
				</button>
			)}



		</>
	);
}


BtnExportSienge.propTypes = {
	compra: PropTypes.object.isRequired,
	logged_sienge: PropTypes.bool.isRequired,
	itens: PropTypes.object.isRequired,
	user: PropTypes.shape({
		sienge_liberado: PropTypes.bool,
		cliente_id: PropTypes.number,
	}).isRequired,
	updateItensRaw: PropTypes.func.isRequired,
	updateItemById: PropTypes.func.isRequired,
	redirectAfterConcluir: PropTypes.func.isRequired,
	notificaUsuarioAposExportacao: PropTypes.func.isRequired,
};

const _BtnExportSienge = CSSModules(BtnExportSienge, styles, { allowMultiple: true });
const mapStateToProps = props => buildMapStateToProps(props, ['user']);
export default connect(mapStateToProps, null)(_BtnExportSienge);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import createCompraHoc from '../../../hocs/createCompraHoc/createCompraHoc';
import LoadingButton from '../_loadings/LoadingButton/LoadingButton';

class CriarCompraButton extends Component {
	criaCompra = () => {
		const { oc, pedido, updateModals, createCompra } = this.props;
		createCompra(oc, pedido, updateModals);
	}

	render() {
		const { criando, style_class, html } = this.props;
		const button_class = criando === 1
			? 'btn btn-secondary-conaz round-border loading'
			: 'btn btn-secondary-conaz round-border';
		const on_click_func = criando === 1
			? null
			: this.criaCompra;
		const button_html = criando === 1
			? <><LoadingButton />{html}</>
			: html;

		return (
			<button
				type="button"
				className={button_class}
				styleName={style_class}
				onClick={on_click_func}>
				{button_html}
			</button>
		);
	}
}

CriarCompraButton.propTypes = {
	oc: PropTypes.object.isRequired,
	pedido: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
	createCompra: PropTypes.func.isRequired,
	style_class: PropTypes.string.isRequired,
	criando: PropTypes.number.isRequired,
	html: PropTypes.string,
};

CriarCompraButton.defaultProps = {
	html: 'Criar Compra',
};

const _CriarCompraButton = CSSModules(CriarCompraButton, styles, { allowMultiple: true });
export default createCompraHoc(_CriarCompraButton);

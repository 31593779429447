/* eslint class-methods-use-this: 0 */
import React, { Component } from 'react';

// Functions
import { _get, _put } from '../../components/_functions/_requests';
import { handleRequestErrors } from '../../components/_functions/_handleRequestErrors';

function QcHoc(Comp) {
	return class PP extends Component {
		constructor(props) {
			super(props);
			this.state = {
				window_height: 600,
				mobile: window.innerWidth < 768,
				scroll_left: 0,
				perfil_fornecedor: {},
				rcs_abertos: [],
				item_analise_mobile_modal: {
					order: -1,
					id: -1,
				},
				menor_preco: {},
			};
			this.handleMouseUp = this.handleMouseUp.bind(this);
			this.setItensAnalise = this.setItensAnalise.bind(this);
			this.handleDimensions = this.handleDimensions.bind(this);
			this.handleScroll = this.handleScroll.bind(this);
			this.calcTotalRC = this.calcTotalRC.bind(this);
			this.infoFornecedor = this.infoFornecedor.bind(this);
			this.openCloseRCs = this.openCloseRCs.bind(this);
			this.openItemAnaliseOnMobile = this.openItemAnaliseOnMobile.bind(this);
		}

		componentDidMount() {
			window.addEventListener('resize', this.handleDimensions);
			window.addEventListener('mouseup', this.handleMouseUp);
		}

		componentWillUnmount() {
			window.removeEventListener('resize', this.handleDimensions);
			window.removeEventListener('mouseup', this.handleMouseUp);
		}

		setItensAnalise(
			responses,
			updateWrapperState,
			updateQc,
			comb_to_edit,
			setSizesOnChanges,
			carregaCompras,
		) {
			updateQc(['compras'], []);
			const { id, itens_analise } = responses[1].data[0];
			/**
			 * Salva id da análise
			 */
			updateQc(['analise_id'], id);
			/**
			 * Trata os itens do pedido
			 */
			const itens_all_ids_unsorted = [];
			const itens_by_id = itens_analise.reduce((result, current) => {
				itens_all_ids_unsorted.push({
					id: current.id,
					ordem: current.ordem,
				});
				return { ...result, [current.id]: { ...current } };
			}, {});
			const itens_all_ids = [...itens_all_ids_unsorted]
				.sort((a, b) => a.ordem - b.ordem)
				.map(c => c.id);
			updateQc(['itens_analise'], {
				by_id: itens_by_id,
				all_ids: itens_all_ids,
			});
			updateWrapperState('request_state', 2);
			this.buscaOpcoesCompra(
				responses,
				updateWrapperState,
				updateQc,
				comb_to_edit,
				setSizesOnChanges,
				carregaCompras,
			);
		}

		setOpcoesCompra(
			responses,
			opcoes_de_compra,
			updateWrapperState,
			updateQc,
			comb_to_edit,
			setSizesOnChanges,
			carregaCompras,
		) {
			/**
			 * Trata os RC's (opções de compra)
			 */
			const ids_combs_unsorted = [];
			const ids_rcs_unsorted = [];
			const menor_preco = {};
			// const validar_rc = [];

			// const is_triagem =
			// 	process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';

			const by_id = opcoes_de_compra.reduce((result, current) => {
				/**
				 * Loop por todos os RC's analise
				 */
				const rcs_analise = current.rcs_analise.map(rc_a => {
					const {
						condicoes_de_pagamento: cp,
						transporte,
						valor_transporte: vt,
						custos_adicionais: ca,
						// uuid,
					} = rc_a.rc;
					const { itens_rc_analise } = rc_a;
					// if (is_triagem) {
					// 	const valid_rc = _post(`/conaz/v2/rcs/${uuid}/validar_rc`);
					// 	validar_rc.push(valid_rc);
					// }

					// menor preço qc
					if (Object.entries(menor_preco).length === 0) {
						itens_rc_analise.forEach(item => {
							const { item_analise_id } = item;
							const {
								preco_unitario,
								quantidade,
								desconto = 0,
							} = item.item_de_resposta;

							// sub total
							const sub_total = preco_unitario * quantidade;

							// desconto
							const valor_desconto = sub_total * (desconto / 100);

							menor_preco[item_analise_id] = (sub_total - valor_desconto) || 0;
						});
					} else {
						itens_rc_analise
							.filter(item => (
								item.item_de_resposta.status === 1
								&& item.ativo
							))
							.forEach(item => {
								const { item_analise_id } = item;
								const {
									preco_unitario,
									quantidade,
									desconto = 0,
								} = item.item_de_resposta;

								// sub_total
								const sub_total = preco_unitario * quantidade;

								// desconto
								const valor_desconto = sub_total * (desconto / 100);

								// preço total
								const precoTotal = (sub_total - valor_desconto) || 0;

								if (
									precoTotal < menor_preco[item_analise_id]
									|| menor_preco[item_analise_id] === 0
								) {
									menor_preco[item_analise_id] = precoTotal;
								}
							});
					}

					this.setState({ menor_preco });
					/**
					 * Condições de pagamento
					 *  - Check se o campo está null ou vazio
					 *  - Filtra só os com valores corretos
					 */
					const cp_check =
						cp !== null && cp !== undefined && cp.length > 0
							? [...cp]
							: [
								{
									desconto: 0,
									forma: 0,
									outra_forma: '',
									outro_prazo: '',
									prazo: 0,
								},
							];
					const cp_clean = cp_check
						.filter(c => c.forma !== 0 && c.prazo !== 0)
						.map(c => (c.desconto === null || c.desconto === undefined
							? { ...c, desconto: 0 }
							: c))
						.sort((a, b) => b.desconto - a.desconto);
					/**
					 * Calcula preço da soma dos itens
					 */
					let n_ativos = 0;
					const itens_total = rc_a.itens_rc_analise.reduce(
						(total, atual) => {
							// sub_total
							const sub_total = (atual.ativo && atual.item_de_resposta.status === 1)
								? atual.item_de_resposta.preco_unitario * atual.item_de_resposta.quantidade
								: 0;
							// desconto
							const desconto = atual.item_de_resposta.desconto || 0;
							const valor_desconto = (sub_total / 100) * desconto;
							// total
							const atual_price = (sub_total - valor_desconto) || 0;

							if (
								atual.ativo
								&& (
									atual.item_de_resposta.status === 1
									|| atual.item_de_resposta.status === 0
								)
							) {
								n_ativos++;
							}

							return total + atual_price;
						},
						0,
					);
					/**
					 * Custos adicionais
					 */
					const custos_adicionais =
						ca !== null && ca !== undefined ? ca : 0;
					/**
					 * Verifica se o transporte terá custos
					 */
					const valor_transporte =
						vt !== null && vt !== undefined ? vt : 0;
					const transporte_total =
						transporte === 1 ? valor_transporte : 0;
					/**
					 * Verifica se já existe alguma condição selecionada
					 */
					const condicao_de_pagamento = (
						rc_a.condicao_de_pagamento || {}
					).id;
					let condicao_selecionada_id =
						condicao_de_pagamento !== undefined
							? condicao_de_pagamento
							: null;
					if (
						condicao_selecionada_id === null &&
						cp_clean.length > 0
					) {
						condicao_selecionada_id = cp_clean[0].id;
					}
					const condicao_selecionada = cp_clean
						.filter(x => x.id === condicao_selecionada_id);
					const desconto_selecionado = condicao_selecionada.length === 1
						? condicao_selecionada[0].desconto
						: 0;
					/**
					 * Define menor preço
					 */
					const preco_rc = n_ativos > 0
						? itens_total
						: 0;
					const total_com_desconto = preco_rc - (preco_rc * desconto_selecionado) / 100;
					const preco_selecionado_rc = total_com_desconto + transporte_total + custos_adicionais;

					return {
						...rc_a,
						rc: {
							...rc_a.rc,
							condicoes_de_pagamento: cp_clean,
						},
						preco_rc,
						preco_selecionado_rc,
						condicao_selecionada_id: condicao_de_pagamento,
					};
					// }).sort((a, b) => a.preco_selecionado_rc - b.preco_selecionado_rc);
				});
				/**
				 * Seta o valor total da opção de compra, também das combinações
				 */
				const preco_total = rcs_analise.reduce(
					(total_rc, atual_rc) => total_rc + atual_rc.preco_selecionado_rc,
					0,
				);

				/**
				 * Add para array com preco_total para ordenar depois
				 */
				switch (current.rcs_analise.length) {
				case 0:
					// Não adiciona caso não tenha rc análise
					break;
				case 1:
					ids_rcs_unsorted.push({
						id: current.id,
						preco_total,
						completo: current.rcs_analise[0].rc.completo,
					});
					break;
				default:
					ids_combs_unsorted.push({
						id: current.id,
						preco_total,
					});
				}

				return {
					...result,
					[current.id]: {
						...current,
						rcs_analise,
						preco_total,
						collapsed: false,
					},
				};
			}, {});

			let all_ids = [];
			if (comb_to_edit === null) {
				/**
				 * Ordena as combinações pelo preco_total
				 */
				const ids_combs = ids_combs_unsorted
					.sort((a, b) => a.preco_total - b.preco_total)
					.map(c => c.id);
				/**
				 * Ordena os RC's pelo preco_total
				 */
				const ids_rcs_sorted = ids_rcs_unsorted.sort(
					(a, b) => a.preco_total - b.preco_total,
				);
				const ids_rcs_completos = ids_rcs_sorted.filter(
					x => x.completo,
				);
				const ids_rcs_incompletos = ids_rcs_sorted.filter(
					x => !x.completo,
				);
				const ids_rcs = [
					...ids_rcs_completos,
					...ids_rcs_incompletos,
				].map(c => c.id);
				all_ids = [...ids_combs, ...ids_rcs];
			} else {
				/**
				 * Salva apenas a combinação que será editada
				 */
				all_ids = [comb_to_edit];
			}

			/**
			 * Atualiza redux
			 */
			updateQc(['opcoes_de_compra'], {
				by_id,
				all_ids,
			});
			setSizesOnChanges();
			/**
			 * Carrega compras
			 */
			if (carregaCompras !== undefined) {
				carregaCompras(responses[1].data[0], all_ids);
			}

			// NOTE preço unitário invalido
			// if (is_triagem) {
			// 	Promise.all(validar_rc).then(promise_resp => {
			// 		let aux = {};
			// 		promise_resp.forEach(({ data }) => {
			// 			aux = { ...aux, ...data };
			// 		});
			// 		this.validar_rc(aux);
			// 	});
			// }
		}

		validar_rc = items => {
			Object.entries(items).forEach(([key, value]) => {
				const elem = document.getElementById(`div_alerta_preco_errado_${key}`);
				if (value.preco_unitario.valido === 'false' && elem) elem.classList.remove('hidden');
			});
		}

		buscaOpcoesCompra(
			responses,
			updateWrapperState,
			updateQc,
			comb_to_edit,
			setSizesOnChanges,
			carregaCompras,
		) {
			setSizesOnChanges();
			/**
			 * Filtra as opções ocultas
			 */
			const is_triagem =
				process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
			let opcoes_to_get =
				comb_to_edit === null
					? responses[1].data[0].opcoes_de_compra
						.filter(x => !x.oculta && !x.rascunho)
					: responses[1].data[0].opcoes_de_compra
						.filter(x => x.id === comb_to_edit);
			if (is_triagem && comb_to_edit === null) {
				opcoes_to_get = responses[1].data[0].opcoes_de_compra
					.filter(x => !x.rascunho);
			} else if (is_triagem && comb_to_edit !== null) {
				opcoes_to_get = responses[1].data[0].opcoes_de_compra || [];
			}

			if (opcoes_to_get.length === 0) {
				updateWrapperState('request_state', 3);
				return;
			}

			const all_gets = opcoes_to_get
				.map(opcao => `id=${opcao.id}`)
				.join('&');
			const url = `/conaz/v2/opcao_compra/?${all_gets}`;

			// Promise.all(all_gets).then(responses_ocs => {
			_get(url).then(responses_ocs => {
				const opcoes_de_compra = !is_triagem
					? responses_ocs.data
						// .map(res => res.data)
						.filter(x => x.rcs_analise.length > 0)
						.filter(x => (x.rcs_analise[0].rc || {}).estah_no_qc)
					: responses_ocs.data
						// .map(res => res.data)
						.filter(x => x.rcs_analise.length > 0);
				this.setOpcoesCompra(
					responses,
					opcoes_de_compra,
					updateWrapperState,
					updateQc,
					comb_to_edit,
					setSizesOnChanges,
					carregaCompras,
				);
			}).catch(error => {
				updateWrapperState('request_state', 3);
				handleRequestErrors(error);
			});
		}

		handleMouseUp(e) {
			const onboarding = document.getElementsByClassName(
				'inmplayer-popover-button-next inmplayer-button',
			);
			if (
				!e.target.getAttribute('data-rc-info') &&
				this.state.rcs_abertos.length > 0 &&
				onboarding.length === 0
			) {
				this.setState({ rcs_abertos: [] });
			}
		}

		handleDimensions() {
			/**
			 * Seta alturas
			 * Barra dos fornecedores:						176
			 * Barra superior (busca, obras e menu):		60;
			 *
			 * Valor final igual:
			 * Barra dos fornecedores + barra superior + 1 de espaço adicional
			 */
			const barra_fornecedores = 176;
			const barra_superior = 60;
			const all = barra_fornecedores + barra_superior + 1;
			this.setState({ window_height: window.innerHeight - all });
			/**
			 * Set width da barra dos fornecedores
			 * ao abrir sidebar para criar combinações
			 */
			const fornecedores_wrapper = document.getElementById(
				'fornecedores_wrapper',
			);
			const sidebar_combinacao_width =
				(document.getElementById('combinacao_side_bar') || {})
					.offsetWidth || 0;
			if (fornecedores_wrapper) {
				fornecedores_wrapper.style.width = `${window.innerWidth -
					sidebar_combinacao_width}px`;
			}
			/**
			 * Check mobile
			 */
			const mobile = window.innerWidth < 768;
			this.setState({ mobile });
		}

		handleScroll(e) {
			const scroll_left = e.target.scrollLeft;
			const scroll_top = e.target.scrollTop;
			document.getElementById(
				'scroll_fornecedores',
			).scrollLeft = scroll_left;
			document.getElementById('scroll_itens').scrollTop = scroll_top;
			/**
			 * Mantém os RC's abertos no topo durante o scroll vertical
			 */
			const rcs_abertos = document.querySelectorAll('[data-rc-aberto]');
			for (let i = 0; i < rcs_abertos.length; i++) {
				rcs_abertos[i].style.top = `${scroll_top}px`;
			}
			/**
			 * Coloca sombra na barra lateral caso scroll tenha andado
			 */
			if (
				(this.state.scroll_left === 0 && scroll_left > 0) ||
				(this.state.scroll_left > 0 && scroll_left === 0)
			) {
				this.setState({ scroll_left });
			}
		}

		calcTotalRC(oc_id, rca_id, condicao_id, qc, updateQc) {
			/**
			 * Pega o id da opcao de compra que será editada
			 */
			const oc = qc.opcoes_de_compra.by_id[oc_id];

			const rcs_analise = oc.rcs_analise.map(rc_a => {
				/**
				 * Retorna RC's não selecionados
				 */
				if (rc_a.id !== Number(rca_id)) return rc_a;
				/**
				 * Edita RC analise selecionado
				 */
				const {
					condicoes_de_pagamento: cp,
					transporte,
					valor_transporte: vt,
					custos_adicionais: ca,
				} = rc_a.rc;
				/**
				 * Calcula preço da soma dos itens
				 */
				let n_ativos = 0;
				const itens_total = rc_a.itens_rc_analise.reduce(
					(total, atual) => {
						// sub_total
						const sub_total = (atual.ativo && atual.item_de_resposta.status === 1)
							? atual.item_de_resposta.preco_unitario * atual.item_de_resposta.quantidade
							: 0;
						// desconto
						const desconto = atual.item_de_resposta.desconto || 0;
						const valor_desconto = (sub_total / 100) * desconto;
						// total
						const atual_price = (sub_total - valor_desconto) || 0;

						if (
							atual.ativo
							&& (
								atual.item_de_resposta.status === 1
								|| atual.item_de_resposta.status === 0
							)
						) {
							n_ativos++;
						}

						return total + atual_price;
					},
					0,
				);
				/**
				 * Custos adicionais
				 */
				const custos_adicionais =
					ca !== null && ca !== undefined ? ca : 0;
				/**
				 * Verifica se o transporte terá custos
				 */
				const valor_transporte =
					vt !== null && vt !== undefined ? vt : 0;
				const transporte_total =
					transporte === 1 ? valor_transporte : 0;
				/**
				 * Verifica se já existe alguma condição selecionada
				 */
				const condicao_selecionada_id = Number(condicao_id);
				const condicao_selecionada = cp.filter(
					x => x.id === condicao_selecionada_id,
				);
				const desconto_selecionado =
					condicao_selecionada.length === 1
						? condicao_selecionada[0].desconto
						: 0;
				/**
				 * Define menor preço
				 */
				const preco_rc = n_ativos > 0
					? itens_total
					: 0;
				const total_com_desconto = preco_rc - (preco_rc * desconto_selecionado) / 100;
				const preco_selecionado_rc = total_com_desconto + transporte_total + custos_adicionais;

				return {
					...rc_a,
					preco_rc,
					preco_selecionado_rc,
					condicao_selecionada_id,
				};
			});

			/**
			 * Seta o valor total da opção de compra, também das combinações
			 */
			const preco_total = rcs_analise.reduce(
				(total_rc, atual_rc) => total_rc + atual_rc.preco_selecionado_rc,
				0,
			);

			/**
			 * Atualiza redux
			 */
			const by_id = {
				...qc.opcoes_de_compra.by_id,
				[oc_id]: {
					...qc.opcoes_de_compra.by_id[oc_id],
					rcs_analise,
					preco_total,
				},
			};
			updateQc(['opcoes_de_compra', 'by_id'], by_id);
			this.putCondicaoPagamento(rca_id, condicao_id);
		}

		putCondicaoPagamento(rca_id, condicao_id) {
			/**
			 * Atualiza condição selecionada no backend
			 */
			const params = {
				condicao_de_pagamento_id: Number(condicao_id),
			};
			_put(`/conaz/v2/rcs_analise/${rca_id}`, params);
		}

		infoFornecedor(fornecedor_id, vendedor_selecionado, updateModals) {
			const perfil_fornecedor = {
				vendedor_id: fornecedor_id,
				vendedor_selecionado,
			};
			this.setState({ perfil_fornecedor });
			updateModals('fornecedor', true);
		}

		openCloseRCs(id) {
			const is_open =
				this.state.rcs_abertos.filter(x => x === Number(id)).length > 0;
			const rcs_abertos = !is_open
				? [...this.state.rcs_abertos, Number(id)]
					.filter((el, i, a) => i === a.indexOf(el))
				: this.state.rcs_abertos
					.filter(x => x !== Number(id));
			this.setState({ rcs_abertos });
			/**
			 * Atualiza o scroll do RC aberto
			 */
			setTimeout(() => {
				const scroll_top = document.getElementById('qc_scroll')
					.scrollTop;
				const rcs_abertos_elems = document.querySelectorAll(
					'[data-rc-aberto]',
				);
				for (let i = 0; i < rcs_abertos_elems.length; i++) {
					rcs_abertos_elems[i].style.top = `${scroll_top}px`;
				}
			}, 20);
		}

		openItemAnaliseOnMobile(order, id, updateModals) {
			this.setState({
				item_analise_mobile_modal: {
					order,
					id,
				},
			});
			updateModals('item_analise_mobile', true);
		}

		render() {
			return (
				<Comp
					{...this.state}
					{...this.props}
					setItensAnalise={this.setItensAnalise}
					handleDimensions={this.handleDimensions}
					handleScroll={this.handleScroll}
					calcTotalRC={this.calcTotalRC}
					infoFornecedor={this.infoFornecedor}
					openCloseRCs={this.openCloseRCs}
					menor_preco={this.state.menor_preco}
					openItemAnaliseOnMobile={this.openItemAnaliseOnMobile} />
			);
		}
	};
}

export default QcHoc;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

// Components
import LayoutWrapper from '../../components/LayoutWrapper';
import Cotacoes from './Cotacoes';

const CotacoesContainer = props => {
	const {
		cotacoes,
		ui,
		getInitialCotacoes,
		getCotacoes,
		updateUi,
		user,
	} = props;
	const { fetching, current_tab } = cotacoes;
	const { tabs } = ui;
	const { obra_selected } = user;

	const loadCotacoes = () => {
		getInitialCotacoes(obra_selected, tabs.cotacoes);
		updateUi(['changes', 'cotacoes'], false);
	};

	const resetAlert = () => {
		if (ui.alerts.escolhe_cotacao.visible) {
			setTimeout(() => {
				const alerts = {
					...ui.alerts,
					escolhe_cotacao: { visible: false, message: '' },
				};
				updateUi(['alerts'], alerts);
			}, 12000);
		}
	};

	useEffect(() => {
		// google analytics
		const { pathname, search } = window.location;
		window.ga('set', 'page', pathname + search);
		window.ga('send', 'pageview');
		// page title
		document.title = 'Cotações - Conaz';
		// alerts
		resetAlert();
	}, []);

	useEffect(() => {
		// request
		if (obra_selected !== 0) loadCotacoes();
	}, [obra_selected]);

	// useLayoutEffect(() => {
	// 	if (fetching === 20) {
	// 		getInitialCotacoes(obra_selected, 'finalizados');
	// 		getInitialCotacoes(obra_selected, 'cancelados');
	// 	}
	// }, [fetching]);

	const handleChangeTab = ({ target }) => {
		const tab = target.getAttribute('data-tab');
		if (current_tab !== tab && cotacoes[`RS_${tab}`] === 0) {
			getCotacoes(obra_selected, tab, 1);
		}
		updateUi(['tabs', 'cotacoes'], tab);
		window.scroll(0, 0);
	};

	const handleChangePage = ({ selected }) => {
		if (cotacoes[tabs.cotacoes].page.number !== (selected + 1)) {
			getCotacoes(obra_selected, tabs.cotacoes, selected + 1);
			window.scroll(0, 0);
		}
	};

	return (
		<LayoutWrapper
			fetching={fetching}
			thbackspace_fixed
			{...props}>

			<Cotacoes
				{...props}
				handleChangeTab={handleChangeTab}
				handleChangePage={handleChangePage}
			/>
		</LayoutWrapper>
	);
};

CotacoesContainer.propTypes = {
	cotacoes: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateCotacoesList: PropTypes.func.isRequired,
	updateCotacoes: PropTypes.func.isRequired,
	getInitialCotacoes: PropTypes.func.isRequired,
	getCotacoes: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(CotacoesContainer));

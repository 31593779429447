import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const TabAvaliacoes = ({
	vendedores,
}) => {
	const _vendedores = ((vendedores[0] || {}).usuario || {}).id !== undefined
		? vendedores.map(v => v.usuario)
		: vendedores;

	return (
		<>
			<div styleName="stars-message">
				<i className="fa fa-star" aria-hidden="true" />
				<i className="fa fa-star" aria-hidden="true" />
				<i className="fa fa-star" aria-hidden="true" />
				<i className="fa fa-star" aria-hidden="true" />
				<i className="fa fa-star" aria-hidden="true" />
				<p>Estamos em período de coleta de avaliações. Avalie suas compras e possibilite que os fornecedores tenham avaliações a serem demonstradas.</p>
			</div>

			<div styleName="avaliacao-title">Critérios avaliados</div>
			<div styleName="criterios-wrapper">
				<div styleName="criterio">Entregas realizadas no prazo</div>
				<div styleName="criterio">Documentos entregues corretamente</div>
				<div styleName="criterio">Vendas de acordo com o solicitado</div>
				<div styleName="criterio">Materiais com boas condições de uso</div>
			</div>

			<div styleName="avaliacao-title">Média geral dos vendedores</div>
			{_vendedores
				.filter(v => v.nome !== null && v.nome !== '')
				.map(vendedor => (
					<div styleName="avaliacao-vendedor" key={vendedor.id}>
						{vendedor.nome}
						<i className="fa fa-star" aria-hidden="true" />
						<span>Em período de coleta de avaliações</span>
					</div>
				))
			}
		</>
	);
};

TabAvaliacoes.propTypes = {
	vendedores: PropTypes.array.isRequired,
};

export default CSSModules(TabAvaliacoes, styles, { allowMultiple: true });

import React from 'react';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const LoadingComb = () => (
	<div styleName="loader-wrapper">
		<div styleName="loader">
			<i styleName="loader__tile loader__tile__1" />
			<i styleName="loader__tile loader__tile__2" />
			<i styleName="loader__tile loader__tile__3" />
			<i styleName="loader__tile loader__tile__4" />
			<i styleName="loader__tile loader__tile__5" />
			<i styleName="loader__tile loader__tile__6" />
			<i styleName="loader__tile loader__tile__7" />
			<i styleName="loader__tile loader__tile__8" />
			<i styleName="loader__tile loader__tile__9" />
		</div>
	</div>
);

export default CSSModules(LoadingComb, styles, { allowMultiple: true });

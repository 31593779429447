import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const CompraRowFornecedor = ({ compra }) => {
	const { fornecedor } = compra;
	const fornecedor_nome = (fornecedor !== '' && fornecedor !== undefined)
		? fornecedor.nome
		: <em style={{ color: '#bdbdbd' }}>Nenhum fornecedor registrado</em>;

	return (
		<div styleName="fornecedor">
			Fornecedor
			<p>{fornecedor_nome}</p>
		</div>
	);
};

CompraRowFornecedor.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraRowFornecedor, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CompraRowStatus from './CompraRowStatus';

// Functions
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';
import { editEnviadoEmFormat } from '../../_functions/_editDateFormat';
import { formatCurrency } from '../../_functions/_formatCurrency';

const CompraRowTopInfo = ({ compra }) => {
	const { valor_total } = compra;
	const status = defineStatusCompra(compra);
	const compra_id = compra.id || 0;
	const titulo = compra.titulo || '';
	const criado_em = compra.criado_em || '';
	const realizada_em_view = criado_em !== ''
		? editEnviadoEmFormat(criado_em)[0]
		: '';
	const realizada_view = (status === 'aguardando_confirmacao' || status === 'confirmada')
		? <>Realizada em <span styleName="data">{realizada_em_view}<br styleName="status-line-break" /></span></>
		: '';
	const preco_class = `preco-fechado ${status}`;

	return (
		<>
			<div styleName="titulo">
				<span style={{ color: '#aeb1b7' }}>#{compra_id}</span> - {titulo}
				<p>
					{realizada_view}
					<CompraRowStatus compra={compra} />
				</p>
			</div>
			<div styleName={preco_class}>
				{formatCurrency(valor_total, 2, 'R$')}
			</div>
		</>
	);
};

CompraRowTopInfo.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraRowTopInfo, styles, { allowMultiple: true });

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import RCRowDetails from './RCRowDetails';

// Functions
import { formatCurrency } from '../../_functions/_formatCurrency';

class CombRowDetails extends Component {
	constructor(props) {
		super(props);
		this.expandRcs = this.expandRcs.bind(this);
	}

	expandRcs() {
		const { oc, openCloseRCs } = this.props;
		openCloseRCs(`oc${oc.id}`);
	}

	render() {
		const {
			oc,
			order,
			loading_compras,
			rcs_abertos,
			openCloseRCs,
			compras,
		} = this.props;

		const preco_combinacao = oc.rcs_analise
			.reduce((result, current) => result + current.preco_selecionado_rc, 0);

		/**
		* Verifica se existe compra na combinação
		*/
		const comb_has_compra = oc.rcs_analise
			.filter(rc_a => {
				const all_compras = compras.filter(c => c.rc_analise_id === rc_a.id);
				return all_compras.length > 0;
			})
			.length > 0;

		const is_expanded = !comb_has_compra
			? rcs_abertos.filter(x => x === `oc${oc.id}`).length > 0
			: true;

		const comb_wrapper_class = !is_expanded ? 'comb-wrapper' : 'comb-wrapper expanded';

		return (
			<div className="col-xs-12">
				<div styleName={comb_wrapper_class}>
					<div styleName="order multi">C{order}</div>
					<div styleName="fornecedor">
						<div styleName="nome comb">Combinação {order}</div>
						{!is_expanded && oc.rcs_analise.map(rc_a => (
							<React.Fragment key={rc_a.id}>
								<div styleName="dot-list">•</div>
								<button
									type="button"
									styleName="nome-list"
									onClick={() => this.props.infoFornecedor(rc_a.rc.fornecedor.id, rc_a.rc.usuario)}>
									{rc_a.rc.fornecedor.nome}
								</button>
							</React.Fragment>
						))}
					</div>
					<div styleName="valor-condicao">
						<h3 styleName="preco"><b>{formatCurrency(preco_combinacao, 2, 'R$')}</b></h3>
					</div>
					{(!is_expanded && !comb_has_compra) && (
						<button
							className="btn btn-grey-conaz round-border"
							styleName="button-criar-compras"
							onClick={this.expandRcs}>
							Ver opções de compra
						</button>
					)}
					{(!!is_expanded && !comb_has_compra) && (
						<button
							className="btn btn-clear-conaz round-border"
							styleName="button-criar-compras"
							onClick={this.expandRcs}>
							Fechar
						</button>
					)}
				</div>

				{!!is_expanded && oc.rcs_analise.map(rc_a => {
					const oc_novo = {
						...oc,
						rcs_analise: [{ ...rc_a }],
					};

					return (
						<RCRowDetails
							key={rc_a.id}
							order={0}
							oc={oc_novo}
							cotacao={this.props.cotacao}
							compras={compras}
							updateCompras={this.props.updateCompras}
							updateModals={this.props.updateModals}
							qc_foi_visto={this.props.qc_foi_visto}
							alertVerPdf={this.props.alertVerPdf}
							infoFornecedor={this.props.infoFornecedor}
							history={this.props.history}
							openAnexosFornecedor={this.props.openAnexosFornecedor}
							loading_compras={loading_compras}
							rcs_abertos={rcs_abertos}
							openCloseRCs={openCloseRCs}
							in_comb />
					);
				})}

				<div styleName="comb-wrapper-cards-1" />
				<div styleName="comb-wrapper-cards-2" />
			</div>
		);
	}
}

CombRowDetails.propTypes = {
	order: PropTypes.number.isRequired,
	oc: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	compras: PropTypes.array,
	updateCompras: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	qc_foi_visto: PropTypes.bool.isRequired,
	alertVerPdf: PropTypes.func.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	openAnexosFornecedor: PropTypes.func.isRequired,
	loading_compras: PropTypes.number.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
};

CombRowDetails.defaultProps = {
	compras: [],
};

const _CombRowDetails = CSSModules(CombRowDetails, styles, { allowMultiple: true });
export default withRouter(_CombRowDetails);

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

import ToolTip from '../../_errors/ToolTip/ToolTip';

import { emailValidation } from '../../../_functions/_emailValidation';
import { titleCase } from '../../../_functions/_titleCase';
import { maskPhone } from '../../../_functions/_maskPhone';
import { validaTelefone } from '../../../_functions/_validaTelefone';

class CadastrarFornecedor extends React.Component {
	static titleHasRestrictChars(value) {
		return value.match(/[&\\#,+@$~^%'":/|*?<>{}]/g);
	}

	constructor() {
		super();
		this.state = {
			nome_empresa: '',
			telefones: [{ numero: '', tipo: 1 }],
			email: '',
			nome_vendedor: '',
			site: '',
			errors: {
				nome_empresa: false,
				nome_empresa_invalido: false,
				telefones: [],
				email: false,
				nome_vendedor: false,
			},
		};

		this.updateState = this.updateState.bind(this);
		this.preAdicionaNovoFornecedor = this.preAdicionaNovoFornecedor.bind(this);
		this.handleEditionTelefones = this.handleEditionTelefones.bind(this);
		this.removerTelefone = this.removerTelefone.bind(this);
		this.adicionarTelefone = this.adicionarTelefone.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.pre_nome_fornecedor !== '' && this.state.nome_empresa !== nextProps.pre_nome_fornecedor) {
			this.setState({ nome_empresa: nextProps.pre_nome_fornecedor });
		}
	}

	clearErrors() {
		this.setState({
			errors: {
				nome_empresa: false,
				nome_empresa_invalido: false,
				telefones: [],
				email: false,
				nome_vendedor: false,
			},
		});
	}

	updateState(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
		this.clearErrors();
	}

	handleEditionTelefones(idx, value) {
		const { telefones } = this.state;
		telefones[idx].numero = maskPhone(value);
		this.setState({ telefones });
		this.clearErrors();
	}

	removerTelefone(idx) {
		const { telefones } = this.state;
		telefones.splice(idx, 1);
		this.setState({ telefones });
		this.clearErrors();
	}

	adicionarTelefone() {
		const { telefones } = this.state;
		telefones.push({ numero: '', tipo: 1 });
		this.setState({ telefones });
		this.clearErrors();
	}

	validarFornecedorVendedor() {
		const { nome_empresa, telefones, email } = this.state;
		const email_validation = emailValidation(email);

		if (nome_empresa === '') {
			this.setState({ errors: { ...this.state.errors, nome_empresa: true } });
			document.getElementById('novo_fornecedor_nome_empresa').focus();
			return false;
		}
		if (this.constructor.titleHasRestrictChars(nome_empresa)) {
			this.setState({ errors: { ...this.state.errors, nome_empresa_invalido: true } });
			document.getElementById('novo_fornecedor_nome_empresa').focus();
			return false;
		}
		if (!email_validation) {
			this.setState({ errors: { ...this.state.errors, email: true } });
			document.getElementById('novo_fornecedor_email').focus();
			return false;
		}

		let telefones_validos = true;
		const telefones_errors = this.state.errors.telefones;
		telefones.forEach((telefone, idx) => {
			if (!validaTelefone(telefone.numero)) {
				telefones_errors[idx] = true;
				telefones_validos = false;
				document.getElementsByClassName('novo_fornecedor_telefone')[idx].focus();
			}
		});
		this.setState({ errors: { ...this.state.errors, telefones: telefones_errors } });

		if (!telefones_validos) {
			return false;
		}

		return true;
	}

	preAdicionaNovoFornecedor() {
		if (this.validarFornecedorVendedor()) {
			const { nome_empresa, telefones, email, nome_vendedor, site } = this.state;

			const fornecedor_novo = {
				nome: nome_empresa.toUpperCase(),
				site,
			};

			const _telefones = telefones.filter(tel => tel.numero !== '');
			const usuario_novo = {
				nome: titleCase(nome_vendedor),
				email,
				telefones: _telefones.map(tel => ({ ...tel, numero: tel.numero.replace(/\D/g, '') })),
			};

			this.props.cadastrarFornecedorFunc(fornecedor_novo, usuario_novo);
		}
	}

	render() {
		const chars_blocked = '& / # , + @ $ ~ ^ % \' " : | * ? < > { } \\';
		const {
			salvando_novo_fornecedor,
			inclui_site,
			fornecedor_ja_existe,
			fornecedor_existente,
			cancelaFornecedorExistente,
		} = this.props;
		const {
			nome_empresa,
			telefones,
			email,
			nome_vendedor,
			site,
			errors,
		} = this.state;

		return (
			<>
				<h3>Novo fornecedor</h3>
				<div styleName="form-novo-fornecedor-wrapper">
					{errors.nome_empresa && (
						<ToolTip
							message="O nome da empresa é obrigatório"
							display="top"
							margin_top="-32px"
						/>
					)}
					{errors.nome_empresa_invalido && (
						<ToolTip
							message={`O nome não pode conter os caracteres a seguir: ${chars_blocked}`}
							display="top"
							margin_top="-32px"
						/>
					)}
					<label>Nome da empresa <span styleName="obrigatorio">*</span></label>
					<input
						type="text"
						name="nome_empresa"
						className="form-control input-md"
						id="novo_fornecedor_nome_empresa"
						value={nome_empresa}
						maxLength="100"
						onChange={this.updateState}
					/>
					{errors.email && (
						<ToolTip
							message="O e-mail para contato é obrigatório"
							display="top"
							margin_top="-35px"
						/>
					)}
					<label>E-mail <span styleName="obrigatorio">*</span></label>
					<input
						type="text"
						name="email"
						className="form-control input-md"
						id="novo_fornecedor_email"
						maxLength="80"
						value={email}
						onChange={this.updateState}
					/>
					{telefones.map((tel, idx) => (
						// eslint-disable-next-line react/no-array-index-key
						<React.Fragment key={idx}>
							{errors.telefones[idx] && (
								<ToolTip
									message="Telefone inválido"
									display="top"
									margin_top="-34px"
									margin_left="40px" />
							)}
							<label>Telefone {idx === 0 && <span styleName="obrigatorio">*</span>}</label>
							<input
								type="text"
								name="telefone"
								className="form-control input-md novo_fornecedor_telefone"
								styleName="telefone"
								value={tel.numero}
								onChange={e => this.handleEditionTelefones(idx, e.target.value)}
							/>
							{idx > 0 && (
								<button
									type="button"
									className="btn btn-link"
									style={{ fontSize: '16px' }}
									onClick={() => this.removerTelefone(idx)}>
									<i className="fa fa-trash" aria-hidden="true" />
								</button>
							)}
						</React.Fragment>
					))}
					<div>
						<button
							type="button"
							className="btn btn-link"
							styleName="novo-telefone-button"
							onClick={this.adicionarTelefone}>
							<i className="fa fa-plus fa-fw" aria-hidden="true" /> adicionar novo telefone
						</button>
					</div>
					<label>Nome do vendedor</label>
					<input
						type="text"
						name="nome_vendedor"
						className="form-control input-md"
						value={nome_vendedor}
						maxLength="60"
						onChange={this.updateState}
					/>
					{inclui_site && (
						<>
							<label>Site</label>
							<input
								type="text"
								name="site"
								className="form-control input-md"
								value={site}
								maxLength="100"
								onChange={this.updateState}
							/>
						</>
					)}
					<button
						type="button"
						className="btn btn-secondary-conaz round-border"
						styleName={salvando_novo_fornecedor ? 'btn-adicionar-salvando' : 'btn-adicionar'}
						onClick={this.preAdicionaNovoFornecedor}
						disabled={salvando_novo_fornecedor}>
						{salvando_novo_fornecedor ? 'Salvando fornecedor...' : 'Salvar e selecionar'}
					</button>
				</div>
				{fornecedor_ja_existe && (
					<div className="alert alert-warning" role="alert" style={{ marginTop: '25px', marginBottom: '0', paddingBottom: '5px' }}>
						{fornecedor_existente.nome !== undefined ? (
							<>
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" style={{ color: '#ffd700' }} /> Já existe um fornecedor com este e-mail: <b>{fornecedor_existente.nome}</b><br />
								<button type="button" className="btn btn-link" data-field="alert_apenas_compra_externa" data-value="false" onClick={this.preAdicionaFornecedorExistente}>Usar este fornecedor</button>
								<button type="button" className="btn btn-link" data-field="alert_apenas_compra_externa" data-value="false" onClick={cancelaFornecedorExistente}>Cancelar</button>
							</>
						) : (
							<>
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" style={{ color: '#ffd700' }} /> Já existe um fornecedor com este e-mail<br />
								<button type="button" className="btn btn-link" data-field="alert_apenas_compra_externa" data-value="false" onClick={cancelaFornecedorExistente}>Entendi!</button>
							</>
						)}
					</div>
				)}
			</>
		);
	}
}

CadastrarFornecedor.propTypes = {
	salvando_novo_fornecedor: PropTypes.bool.isRequired,
	cadastrarFornecedorFunc: PropTypes.func.isRequired,
	pre_nome_fornecedor: PropTypes.string.isRequired,
	inclui_site: PropTypes.bool,
	fornecedor_ja_existe: PropTypes.bool.isRequired,
	fornecedor_existente: PropTypes.object,
	cancelaFornecedorExistente: PropTypes.func.isRequired,
};

CadastrarFornecedor.defaultProps = {
	inclui_site: false,
	fornecedor_existente: {},
};

export default CSSModules(CadastrarFornecedor, styles, { allowMultiple: true });

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Hocs
import asyncComponent from '../../../../hoc/asyncComponent/asyncComponent';

// Components
import PerfilFornecedorButton from './PerfilFornecedorButton';
import LoadingButton from '../../../../components/_parts/_loadings/LoadingButton/LoadingButton';
import CriarCompraQCCButton from './CriarCompraQCCButton';

// Functions
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';
import { defineStatusCompra } from '../../../../components/_functions/_defineStatusCompra';
import { setPrazoEntregaLabel, setPrazoEntregaView } from '../../../../components/_functions/_setPrazoEntrega';

// Content
import { formas_pagamento, prazos_pagamento_full } from '../../../../components/_content/_condicoes_pagamento';

const SolicitarRefinamentoRC = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno'
	? asyncComponent(() => import('./SolicitarRefinamentoRC'))
	: () => null;

class FornecedorRCComb extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tooltip: -1,
			toolTipRascunho: false,
			alert_compra_parcial: 0,
		};
		this.openRC = this.openRC.bind(this);
		this.showTooltip = this.showTooltip.bind(this);
		this.hideTooltip = this.hideTooltip.bind(this);
		this.removeAlertCompraParcial = this.removeAlertCompraParcial.bind(this);
	}

	componentDidMount() {
		setTimeout(() => {
			this.checkCondicoesCompraParcial();
		}, 10);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.alertCompraParcial(nextProps);
	}

	checkCondicoesCompraParcial() {
		const { alert_compra_parcial } = this.state;
		const { opcao_de_compra, rc_a, changeCondicaoPagamento } = this.props;
		const condicoes_de_pagamento = (rc_a.rc || {}).condicoes_de_pagamento || [];
		const condicao_selecionada_id = rc_a.condicao_selecionada_id || null;

		const condicoes_with_index = condicoes_de_pagamento
			.map((c, i) => ({ ...c, i }));

		/**
		 * Nenhuma condição aceita compra parcial
		 */
		const zero_aceita_parcial = condicoes_de_pagamento
			.filter(c => c.aceita_parcial !== 0).length === 0;
		if (zero_aceita_parcial && alert_compra_parcial === 0) {
			this.setState({ alert_compra_parcial: 1 });
			return;
		}

		/**
		 * Nenhuma condição aceita depende
		 */
		const zero_aceita_depende = condicoes_de_pagamento
			.filter(c => c.aceita_parcial === 2).length === 0;
		const first_parcial_sim = condicoes_with_index
			.filter(c => c.aceita_parcial === 1)[0];
		const first_parcial_sim_id = (first_parcial_sim || {}).id !== undefined
			? (first_parcial_sim || {}).id
			: null;
		if (
			zero_aceita_depende &&
			first_parcial_sim_id !== null &&
			condicao_selecionada_id === null
		) {
			changeCondicaoPagamento(
				opcao_de_compra.id,
				rc_a.id,
				first_parcial_sim_id,
			);
			return;
		}

		/**
		 * Pelo menos 1 aceita mas com valor mínimo
		 */
		const first_parcial_depende = condicoes_with_index
			.filter(c => c.aceita_parcial === 2)[0];
		const first_parcial_depende_index = (first_parcial_depende || {}).i !== undefined
			? (first_parcial_depende || {}).i
			: -1;
		const first_parcial_sim_index = (first_parcial_sim || {}).i !== undefined
			? (first_parcial_sim || {}).i
			: -1;
		if (
			first_parcial_depende_index !== -1 &&
			first_parcial_depende_index < first_parcial_sim_index &&
			condicao_selecionada_id === null
		) {
			changeCondicaoPagamento(
				opcao_de_compra.id,
				rc_a.id,
				condicoes_de_pagamento[first_parcial_depende_index].id,
			);
		}

		this.alertCompraParcial(this.props);
	}

	alertCompraParcial(props) {
		const { alert_compra_parcial } = this.state;
		const { rc_a } = props;
		const condicoes_de_pagamento = (rc_a.rc || {}).condicoes_de_pagamento || [];
		const condicao_selecionada_id = rc_a.condicao_selecionada_id || null;

		const condicao_selecionada = condicao_selecionada_id !== null
			? condicoes_de_pagamento.filter(x => x.id === condicao_selecionada_id)[0]
			: condicoes_de_pagamento[0];


		const { aceita_parcial } = (condicao_selecionada || {});
		const compra_minima = (condicao_selecionada || {}).compra_minima || 0;

		/**
		 * Adicionar alerta caso não aceite compra parcial
		 */
		if (
			alert_compra_parcial === 0 &&
			aceita_parcial === 0
		) {
			this.setState({ alert_compra_parcial: 1 });
			return;
		}
		/**
		 * Remove alerta
		 */
		if (
			alert_compra_parcial === 1 &&
			aceita_parcial === 1
		) {
			this.setState({ alert_compra_parcial: 0 });
			return;
		}
		/**
		 * Adicionar alerta caso não aceite compra parcial
		 */
		if (
			alert_compra_parcial === 0 &&
			aceita_parcial === 2 &&
			compra_minima > rc_a.preco_selecionado_rc
		) {
			this.setState({ alert_compra_parcial: 1 });
			return;
		}
		/**
		 * Remove alerta
		 */
		if (
			alert_compra_parcial === 1 &&
			aceita_parcial === 2 &&
			compra_minima < rc_a.preco_selecionado_rc
		) {
			this.setState({ alert_compra_parcial: 0 });
		}
	}

	openRC(e) {
		const { openCloseRCs } = this.props;
		const rc_id = Number(e.currentTarget.getAttribute('data-id'));
		openCloseRCs(rc_id, false);
	}

	showTooltip(e) {
		const rca_id = Number(e.currentTarget.getAttribute('data-id'));
		this.setState({ tooltip: rca_id });
	}

	hideTooltip() {
		this.setState({ tooltip: -1 });
	}

	removeAlertCompraParcial() {
		this.setState({ alert_compra_parcial: 2 });
	}

	handleTooltip = () => this.setState({ toolTipRascunho: true })

	render() {
		const {
			tooltip,
			toolTipRascunho,
			alert_compra_parcial,
			// modal_nao_aceita_parcial,
		} = this.state;
		const {
			rc_a,
			r,
			order,
			opcao_de_compra,
			rcs_abertos,
			edition_mode,
			infoFornecedor,
			updateModals,
			cotacao_codigo,
			salvaCombinacao,
			salvando_combinacao,
			loading_compras,
			compras,
			selectOCLocal,
			confirmDelete,
			itens_faltando,
			delete_confirm,
			oc_is_selected,
			comb_has_compra,
			pedido,
		} = this.props;

		const { rcs_analise } = opcao_de_compra;

		const info_open = rcs_abertos.filter(x => x === rc_a.id).length > 0;

		/**
		* Informações do RC
		*/
		const {
			fornecedor,
			condicoes_de_pagamento,
			usuario,
			status,
			motivo_refinamento,
			justificativa,
		} = rc_a.rc;
		/**
		* Trata informações
		*/
		const condicao_selecionada_id = (rc_a.condicao_selecionada_id !== null && rc_a.condicao_selecionada_id !== undefined)
			? rc_a.condicao_selecionada_id
			: condicoes_de_pagamento[0].id;
		const condicao_selecionada = condicoes_de_pagamento
			.filter(x => x.id === condicao_selecionada_id)[0];
		const forma = condicao_selecionada.forma === -1
			? condicao_selecionada.outra_forma
			: formas_pagamento[(condicao_selecionada.forma || 0)].view;
		const prazo = condicao_selecionada.prazo === -1
			? condicao_selecionada.outro_prazo
			: prazos_pagamento_full[(condicao_selecionada.prazo || 0)].view;
		// const desconto = condicao_selecionada.desconto || 0;
		const price_class = (tooltip === rc_a.id || info_open)
			? 'valor-rc hhover'
			: 'valor-rc';
		const mais_opcoes = condicoes_de_pagamento.length - 1;
		const mais_opcoes_label = mais_opcoes === 1 ? 'opção' : 'opções';
		/**
		* Define a class do hover dos botões que abrem info
		*/
		const pagamento_class = (tooltip === rc_a.id || info_open)
			? 'condicao-pagamento hhover'
			: 'condicao-pagamento';
		const caracteristica_class = (tooltip === rc_a.id || info_open)
			? 'caracteristica hhover'
			: 'caracteristica';

		/**
		* Define a class da coluna rc
		*/
		const comb_first = (r === 0)
			? ' comb-first-col'
			: '';
		const comb_last = (r === rcs_analise.length - 1)
			? ' comb-last-col'
			: '';
		const comb_middle = (comb_first === '' && comb_last === '')
			? ' comb-middle-col'
			: '';
		const col_class = `${comb_first}${comb_last}${comb_middle}`;
		const fornecedor_info_open_class = !info_open ? '' : ' oc-info-open';
		const fornecedor_class = !oc_is_selected
			? `fornecedor in-combination${col_class}${fornecedor_info_open_class}`
			: `fornecedor in-combination${col_class}${fornecedor_info_open_class} c-selected`;
		const info_open_class = !oc_is_selected
			? 'info-open-over'
			: 'info-open-over oc-selected';

		const compra_btn_class = r === 0 ? 'button-criar-compra' : 'button-criar-compra rights';

		/**
		* Proposta sendo atualizada pelo fornecedor
		*/
		const rc_sendo_atualizado = status === 35 || status === 36 || status === 37;

		/**
		* Verifica compras
		*/
		const rc_uuid = ((rc_a || {}).rc || {}).uuid || '';
		const all_compras = compras.filter(c => (
			c.rc_analise_id === rc_a.id
			|| (c.rc || {}).uuid === rc_uuid
		));
		// const compra = all_compras.length > 0
		// 	? all_compras[0] || {}
		// 	: {};
		// const status_compra = defineStatusCompra(compra);
		// const bc_views = {
		// 	cancelada: 'Cancelada',
		// 	em_analise: 'Aguardando',
		// 	em_aprovacao: 'Aguardando',
		// 	em_refinamento: 'Em refinamento',
		// 	em_edicao: 'Em edição',
		// 	rascunho: 'Comprar',
		// 	aguardando_confirmacao: 'Aguardando',
		// 	confirmada: <><i className="fa fa-check" aria-hidden="true" /> Comprado</>,
		// 	recusada_fornecedor: 'Recusada pelo fornecedor',
		// };
		// const bc_classes = {
		// 	cancelada: 'btn btn-clear-conaz round-border',
		// 	em_analise: 'btn btn-clear-conaz round-border',
		// 	em_aprovacao: 'btn btn-clear-conaz round-border',
		// 	em_refinamento: 'btn btn-clear-conaz round-border',
		// 	em_edicao: 'btn btn-clear-conaz round-border',
		// 	rascunho: 'btn btn-secondary-conaz round-border',
		// 	aguardando_confirmacao: 'btn btn-clear-conaz round-border',
		// 	confirmada: 'btn btn-clear-conaz round-border',
		// 	recusada_fornecedor: 'btn btn-clear-conaz round-border',
		// };
		// const bc_styles = {
		// 	cancelada: 'button-cancelar',
		// 	em_analise: 'button-cancelar',
		// 	em_aprovacao: 'button-cancelar',
		// 	em_refinamento: 'button-cancelar',
		// 	em_edicao: 'button-cancelar',
		// 	rascunho: 'button-criar-compra',
		// 	aguardando_confirmacao: 'button-cancelar',
		// 	confirmada: 'button-cancelar',
		// 	recusada_fornecedor: 'button-cancelar',
		// };
		// const botao_compra_class = bc_classes[status_compra] || 'btn btn-secondary-conaz round-border';
		// const botao_compra_style = bc_styles[status_compra] || 'button-criar-compra';
		// const botao_compra_view = bc_views[status_compra] || 'Comprar';

		const compras_em_rascunho = all_compras
			.filter(c => defineStatusCompra(c) === 'rascunho');

		const is_triagem =
			process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		const prazo_entrega_label = setPrazoEntregaLabel(rc_a.rc);
		const prazo_entrega_view = setPrazoEntregaView(rc_a.rc);

		const has_active_compras = all_compras
			.filter(c => {
				const _status = defineStatusCompra(c);
				return (
					_status !== 'cancelada'
					&& _status !== 'rascunho'
				);
			})
			.length > 0;
		const text = has_active_compras
			? 'Comprar novamente'
			: 'Comprar';

		/**
		 * Check compra parcial
		 */
		// const aceita_parcial = condicoes_de_pagamento
		// 	.filter(c => c.aceita_parcial !== 0).length > 0;

		return (
			<>
				<div key={rc_a.id} styleName={fornecedor_class}>
					{r === 0 && <div styleName="number number-comb">C{order}</div>}
					<PerfilFornecedorButton
						fornecedor={fornecedor}
						usuario={usuario}
						updateModals={updateModals}
						infoFornecedor={infoFornecedor}
						rc_sendo_atualizado={rc_sendo_atualizado}
						motivo_refinamento={motivo_refinamento}
						justificativa={justificativa} />

					{!rc_sendo_atualizado ? (
						<>
							<button
								type="button"
								styleName={price_class}
								onClick={this.openRC}
								data-rc-info
								data-id={rc_a.id}
								onMouseEnter={this.showTooltip}
								onMouseLeave={this.hideTooltip}>
								{rc_a.preco_rc !== 0
									? formatCurrency(rc_a.preco_selecionado_rc, 2, 'R$')
									: formatCurrency(0, 2, 'R$')}
							</button>
							{(alert_compra_parcial === 1 && edition_mode && !info_open) && (
								<button
									type="button"
									styleName="nao-aceita-parcial-alert"
									onClick={this.removeAlertCompraParcial}>
									Fornecedor não aceita compra parcial com este valor
									<i className="fa fa-times-circle" aria-hidden="true" />
								</button>
							)}
							<button
								type="button"
								styleName={pagamento_class}
								onClick={this.openRC}
								data-rc-info
								data-id={rc_a.id}
								onMouseEnter={this.showTooltip}
								onMouseLeave={this.hideTooltip}>
								{forma}: {prazo}
							</button>
							{mais_opcoes > 0 && (
								<button
									type="button"
									styleName="mais-opcoes-tag rc-comb"
									onClick={this.openRC}
									data-rc-info
									data-id={rc_a.id}
									onMouseEnter={this.showTooltip}
									onMouseLeave={this.hideTooltip}>
									+{mais_opcoes} {mais_opcoes_label}
								</button>
							)}
							<button
								type="button"
								styleName={caracteristica_class}
								onClick={this.openRC}
								data-rc-info
								data-id={rc_a.id}
								onMouseEnter={this.showTooltip}
								onMouseLeave={this.hideTooltip}>
								<span data-rc-info>{prazo_entrega_label}:</span> {prazo_entrega_view}
							</button>
						</>
					) : (
						<>
							{status === 35 ? (
								<div styleName="rc-sendo-atualizado">
									Proposta está sendo atualizada pelo fornecedor.
								</div>
							) : (
								<div styleName="rc-sendo-atualizado">
									Correção foi solicitada ao fornecedor.
								</div>
							)}
						</>
					)}

					{info_open && (
						<div styleName={info_open_class} data-rc-info><hr data-rc-info /></div>
					)}

					{(r === 0 && edition_mode && !delete_confirm && !salvando_combinacao && !info_open) && (
						<>
							{!opcao_de_compra.rascunho ? (
								<button
									id="salvar_comb_voltar_btn"
									className="btn btn-success round-border"
									styleName="button-criar"
									onClick={salvaCombinacao}>
									Salvar e voltar para QC
								</button>
							) : (
								<button
									id="salvar_comb_voltar_btn"
									className="btn btn-success round-border"
									styleName="button-criar"
									onClick={salvaCombinacao}>
									Adicionar ao QC
								</button>
							)}
							{!!opcao_de_compra.rascunho && (
								<button
									className="btn btn-clear-conaz round-border"
									styleName="button-cancelar"
									style={{ left: '165px' }}
									onClick={confirmDelete}>
									Descartar
								</button>
							)}
						</>
					)}
					{(r === 0 && edition_mode && !delete_confirm && !!salvando_combinacao) && (
						<button
							className="btn btn-success round-border"
							styleName="button-criar"
							style={{ width: '120px' }}>
							<LoadingButton />&nbsp;
						</button>
					)}

					{(
						r === 0 &&
						!info_open &&
						!oc_is_selected &&
						!edition_mode &&
						!delete_confirm &&
						!itens_faltando &&
						!comb_has_compra &&
						(loading_compras === 2 || loading_compras === 3)
					) && (
						<>
							<button
								className="btn btn-grey-conaz round-border"
								styleName="button-selecionar"
								onClick={selectOCLocal}>
								Criar compras
							</button>
							<Link
								to={`/cotacao/${cotacao_codigo}/qc/${opcao_de_compra.id}`}
								className="btn btn-clear-conaz round-border"
								styleName="button-editar-comb">
								<i className="fa fa-pencil" aria-hidden="true" />
							</Link>
							<button
								className="btn btn-clear-conaz round-border"
								styleName="button-apagar-comb"
								onClick={confirmDelete}>
								<i className="fa fa-trash" aria-hidden="true" />
							</button>
						</>
					)}

					{r === 0 && !edition_mode && (loading_compras === 0 || loading_compras === 1) && (
						<button
							className="btn btn-grey-conaz round-border loading"
							styleName="button-selecionar">
							<LoadingButton />Criar compras
						</button>
					)}

					{(r === 0 && oc_is_selected && !edition_mode) && (
						<button
							className="btn btn-clear-conaz round-border"
							styleName="button-cancelar"
							style={{ left: '116px' }}
							onClick={selectOCLocal}>
							Cancelar
						</button>
					)}

					{!is_triagem ? (
						<>
							{((oc_is_selected && !edition_mode && !rc_sendo_atualizado) || !!comb_has_compra) && (
								(loading_compras === 2 || loading_compras === 3) ? (
									<>
										{compras_em_rascunho.length > 0 ? (
											<>
												<div className={toolTipRascunho ? '' : 'hidden'} styleName="tooltip">
													<span>{compras_em_rascunho.length === 1
														? 'Existe uma compra em rascunho'
														: 'Existem compras em rascunho'}.{' '}
														Você deseja prosseguir e criar uma nova compra?
													</span>
													<div>
														<CriarCompraQCCButton
															oc={opcao_de_compra}
															updateModals={updateModals}
															rc_a={rc_a}
															pedido={pedido}
															has_active_compras={false} />
														<Link
															styleName="comb"
															to={`/compra/${compras_em_rascunho[0].id}`}
															className="btn btn-default round-border">
															Ir para rascunho
														</Link>
													</div>
												</div>
												<button
													onClick={this.handleTooltip}
													disabled={toolTipRascunho}
													styleName={`button-criar-compra ${toolTipRascunho ? 'round-border' : ''}`}
													className={`btn ${toolTipRascunho
														? 'btn-default'
														: 'btn-secondary-conaz round-border'}`}>
													{text}
												</button>
											</>
										) : (
											<CriarCompraQCCButton
												oc={opcao_de_compra}
												updateModals={updateModals}
												rc_a={rc_a}
												pedido={pedido}
												has_active_compras={has_active_compras} />
										)}
									</>
								) : (
									<button
										type="button"
										className="btn btn-secondary-conaz round-border loading"
										styleName={compra_btn_class}>
										<LoadingButton />{text}
									</button>
								)
							)}
						</>
					) : (
						<>
							{!rc_sendo_atualizado && (
								<SolicitarRefinamentoRC
									oc={opcao_de_compra} />
							)}
						</>
					)}

					{(r === 0 && itens_faltando && !delete_confirm && !edition_mode) && (
						<>
							<Link
								to={`/cotacao/${cotacao_codigo}/qc/${opcao_de_compra.id}`}
								className="btn btn-clear-conaz round-border"
								styleName="button-cancelar">
								Editar combinação
							</Link>
							<button
								className="btn btn-danger-conaz round-border"
								styleName="button-apagar"
								style={{ left: '181px' }}
								onClick={confirmDelete}>
								Apagar
							</button>
						</>
					)}
				</div>
			</>
		);
	}
}

FornecedorRCComb.propTypes = {
	rc_a: PropTypes.object.isRequired,
	r: PropTypes.number.isRequired,
	order: PropTypes.number.isRequired,
	opcao_de_compra: PropTypes.object.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
	edition_mode: PropTypes.bool.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	cotacao_codigo: PropTypes.string.isRequired,
	// goToCriarCompra: PropTypes.func.isRequired,
	salvaCombinacao: PropTypes.func.isRequired,
	salvando_combinacao: PropTypes.number.isRequired,
	loading_compras: PropTypes.number.isRequired,
	compras: PropTypes.array.isRequired,
	changeCondicaoPagamento: PropTypes.func.isRequired,
	selectOCLocal: PropTypes.func.isRequired,
	confirmDelete: PropTypes.func.isRequired,
	itens_faltando: PropTypes.bool.isRequired,
	delete_confirm: PropTypes.bool.isRequired,
	oc_is_selected: PropTypes.bool.isRequired,
	comb_has_compra: PropTypes.bool.isRequired,
	pedido: PropTypes.object.isRequired,
};

export default CSSModules(FornecedorRCComb, styles, { allowMultiple: true });

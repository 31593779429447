import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../../store/withStore';

// Components
import QcHoc from '../QcHoc';
import RequestsWrapperFuture from '../../../components/RequestsWrapper/RequestsWrapperFuture';
import QcCombEdition from './QcCombEdition';

// Functions
import { setRowsHeightsQc } from '../../../components/_functions/_setRowsHeightsQc';
import { _put, _post, _delete } from '../../../components/_functions/_requests';
import { defineStatusPedido } from '../../../components/_functions/_defineStatusPedido';

class QcCombEditionContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Seta as url's para carregar através do componente RequestsWrapper
			 */
			urls_to_fetch: [
				`/conaz/v2/pedidos/${props.match.params.id}?fields=anexos,codigo,criado_em,data_de_entrega_desejada,enviado_em,id,obra,observacoes,observacoes_conaz,prazo_de_resposta,status,tipo,titulo,ultima_atualizacao,ultimo_envio_qc,usuario,ultimo_qc_anexo`,
				`/conaz/v2/analises/?pedido_id=${props.match.params.id}`,
			],
			qc_disponivel: 1,
			combinacao_salva: 2,
			salvando_combinacao: 0,
			itens_faltando: [],
		};
		this.setSizesOnChanges = this.setSizesOnChanges.bind(this);
		this.handleResponses = this.handleResponses.bind(this);
		this.selectItem = this.selectItem.bind(this);
		this.changeCondicaoPagamento = this.changeCondicaoPagamento.bind(this);
		this.finalizaCombinacao = this.finalizaCombinacao.bind(this);
		this.deleteOCFromProps = this.deleteOCFromProps.bind(this);
		this.salvaCombinacao = this.salvaCombinacao.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.setSizesOnChanges);
		const { match, handleDimensions } = this.props;
		/**
		 * Seta page title
		 */
		document.title = `QC #${match.params.id} - Conaz`;
		/**
		 * Ajusta tamanho dos elementos da página
		 */
		handleDimensions();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.setSizesOnChanges);
		const { updateQc, updateModals } = this.props;
		updateQc(['itens_analise'], {
			by_id: {},
			all_ids: [],
		});
		updateQc(['opcoes_de_compra'], {
			by_id: {},
			all_ids: [],
		});
		updateModals('exporta_excel', false);
		updateModals('item_analise_mobile', false);
	}

	setSizesOnChanges() {
		/**
		 * Ajusta tamanho dos elementos da página
		 */
		const { qc } = this.props;
		const itens_analise = qc.itens_analise.all_ids.map(id => qc.itens_analise.by_id[id]);
		setRowsHeightsQc(itens_analise);
	}

	handleResponses(responses, updateWrapperState) {
		const { history, match, updateQc, setItensAnalise, handleScroll } = this.props;
		const cotacao = { ...responses[0].data };
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		const comb_to_edit = Number(match.params.comb_id);
		/**
		 * QC Analise não existe ou
		 * Combinação não existe
		 */
		const combination_exist = responses[1].data[0].opcoes_de_compra
			.filter(x => x.id === comb_to_edit).length > 0;
		if (
			responses[1].data.length === 0 ||
			!combination_exist
		) {
			this.setState({ qc_disponivel: 2 });
			updateWrapperState('request_state', 2);
			return;
		}
		/**
		 * Verifica status da cotação e redireciona caso seja rascunho
		 * Mostra mensagem que o pedido não existe caso status esteja errado
		 * Redireciona caso ainda não esteja no QC
		 */
		const status = defineStatusPedido(cotacao);
		if (
			status === 'rascunho' ||
			status === 'refinamento' ||
			status === 'em_edicao' ||
			status === 'envio_solicitado'
		) {
			history.push(`/cotacao/nova/${cotacao.codigo}`);
			return;
		}
		const status_allowed = !is_triagem
			? [
				'qc_disponivel',
				'compras_aguardando_confirmacao',
				'finalizado',
			] : [
				'revisao_conaz',
				'aguardando_cotacoes',
				'qc_disponivel',
				'compras_aguardando_confirmacao',
				'finalizado',
			];
		if (status_allowed.filter(x => x === defineStatusPedido(cotacao)).length === 0) {
			this.setState({ qc_disponivel: 2 });
			updateWrapperState('request_state', 2);
			return;
		}

		updateQc(['cotacao'], cotacao);

		setItensAnalise(responses, updateWrapperState, updateQc, comb_to_edit, this.setSizesOnChanges);

		/**
		 * Zera as url's no RequestWrapper para prevenir novas requests
		 * Finaliza o loading e mostra o conteúdo
		 */
		this.setState({ urls_to_fetch: [] });
		/**
		 * Scroll addEventListener
		 */
		document.getElementById('qc_scroll').addEventListener('scroll', handleScroll);
		/**
		 * Seta os tamanhos das linhas
		 */
		setRowsHeightsQc(responses[1].data[0].itens_analise);
	}

	changeCondicaoPagamento(oc_id, rca_id, condicao_id) {
		const { calcTotalRC, qc, updateQc } = this.props;
		calcTotalRC(oc_id, rca_id, condicao_id, qc, updateQc);
	}

	selectItem(item_analise_id, item_id) {
		const { salvando_combinacao } = this.state;
		const { qc, match, updateQc } = this.props;
		/**
		 * Não deixa editar itens se combinação estiver sendo salva
		 */
		if (salvando_combinacao === 1) return;
		/**
		 * Pega o id da combinação que será editada
		 */
		const comb_to_edit = Number(match.params.comb_id);
		const oc = qc.opcoes_de_compra.by_id[comb_to_edit];
		/**
		 * Loop por todos os RC's analise
		 */
		const rcs_analise = oc.rcs_analise.map(rc_a => {
			const {
				condicoes_de_pagamento: cp,
				transporte,
				valor_transporte: vt,
				custos_adicionais: ca,
			} = rc_a.rc;
			/**
			 * Ativa e desativa os itens
			 */
			const itens_rc_analise = rc_a.itens_rc_analise.map(item => {
				if (item.item_analise_id !== Number(item_analise_id)) {
					return item;
				}
				if (item.id !== Number(item_id)) {
					return { ...item, ativo: false };
				}
				return { ...item, ativo: !item.ativo };
			});
			/**
			 * Calcula preço da soma dos itens
			 */
			let n_ativos = 0;
			const itens_total = itens_rc_analise.reduce((total, atual) => {
				// sub_total
				const sub_total = (atual.ativo && atual.item_de_resposta.status === 1)
					? atual.item_de_resposta.preco_unitario * atual.item_de_resposta.quantidade
					: 0;
				// desconto
				const desconto = atual.item_de_resposta.desconto || 0;
				const valor_desconto = (sub_total / 100) * desconto;
				// total
				const atual_price = (sub_total - valor_desconto) || 0;

				if (atual.ativo && atual.item_de_resposta.status === 1) {
					n_ativos++;
				}

				return total + atual_price;
			}, 0);
			/**
			 * Custos adicionais
			 */
			const custos_adicionais = (ca !== null && ca !== undefined) ? ca : 0;
			/**
			 * Verifica se o transporte terá custos
			 */
			const valor_transporte = (vt !== null && vt !== undefined) ? vt : 0;
			const transporte_total = transporte === 1 ? valor_transporte : 0;
			/**
			 * Verifica se já existe alguma condição selecionada
			 */
			const condicao_de_pagamento = (rc_a.condicao_de_pagamento || {}).id;
			let condicao_selecionada_id = condicao_de_pagamento !== undefined
				? condicao_de_pagamento
				: null;
			if (condicao_selecionada_id === null && cp.length > 0) {
				condicao_selecionada_id = cp[0].id;
			}
			const condicao_selecionada = cp.filter(x => x.id === condicao_selecionada_id);
			const desconto_selecionado = condicao_selecionada.length === 1 ? condicao_selecionada[0].desconto : 0;
			/**
			 * Define menor preço
			 */
			const preco_rc = n_ativos > 0
				? itens_total
				: 0;
			const total_com_desconto = preco_rc - (preco_rc * desconto_selecionado) / 100;
			const preco_selecionado_rc = total_com_desconto + transporte_total + custos_adicionais;

			return {
				...rc_a,
				itens_rc_analise,
				preco_rc,
				preco_selecionado_rc,
				condicao_selecionada_id,
			};
		});

		/**
		 * Seta o valor total da opção de compra, também das combinações
		 */
		const preco_total = rcs_analise.reduce((total_rc, atual_rc) => (
			total_rc + atual_rc.preco_selecionado_rc
		), 0);

		/**
		 * Atualiza redux
		 */
		const by_id = {
			...qc.opcoes_de_compra.by_id,
			[comb_to_edit]: {
				...qc.opcoes_de_compra.by_id[comb_to_edit],
				rcs_analise,
				preco_total,
			},
		};
		updateQc(['opcoes_de_compra', 'by_id'], by_id);
		// this.autoSaveStart();
	}

	autoSaveStart(validacao = false) {
		this.setState({ combinacao_salva: 0 });
		window.clearTimeout(this.save_timeout);
		this.save_timeout = window.setTimeout(() => this.salvaCombinacao(validacao), 3000);
	}

	finalizaCombinacao() {
		const { history, match } = this.props;
		if (this.state.combinacao_salva === 2) {
			history.push(`/cotacao/${match.params.id}/qc`);
			return;
		}
		this.setState({ salvando_combinacao: 1 });
		this.autoSaveStart(true);
	}

	deleteOCFromProps(oc_id) {
		const { qc, updateQc, history } = this.props;
		const all_ids = [...qc.opcoes_de_compra.all_ids]
			.filter(x => x !== Number(oc_id));
		history.push(`/cotacao/${qc.cotacao.codigo}/qc`);
		updateQc(['opcoes_de_compra', 'all_ids'], all_ids);
	}

	ativaCombinacao() {
		const { qc, match, history } = this.props;
		const comb_to_edit = Number(match.params.comb_id);
		const oc = qc.opcoes_de_compra.by_id[comb_to_edit];
		if (oc.rascunho) {
			const params = { rascunho: false };
			_put(`/conaz/v2/opcao_compra/${oc.id}`, params).then(() => {
				history.push(`/cotacao/${match.params.id}/qc`);
			}).catch(error => {
				console.log(error); // eslint-disable-line
				this.setState({ salvando_combinacao: 3 });
			});
		} else {
			history.push(`/cotacao/${match.params.id}/qc`);
		}
	}

	deleteRCs(rcs_to_delete, rcs_to_maintain) {
		if (rcs_to_delete.length === 0) {
			this.ativaCombinacao();
			return;
		}
		/**
		 * Cria deletes para RCs sem itens
		 */
		const apagar_comb = rcs_to_maintain.length === 1;
		const delete_urls = rcs_to_delete.map(rca_id => _delete(`/conaz/v2/rcs_analise/${rca_id}`));
		/**
		 * Apaga combinação porque só sobrou um RC
		 */
		if (apagar_comb) {
			const { qc, match, history } = this.props;
			const comb_to_edit = Number(match.params.comb_id);
			_delete(`/conaz/v2/opcao_compra/${qc.opcoes_de_compra.by_id[comb_to_edit].id}`).then(() => {
				history.push(`/cotacao/${match.params.id}/qc`);
			}).catch(() => {
				history.push(`/cotacao/${match.params.id}/qc`);
			});
			return;
		}
		/**
		 * Apaga RCs sem itens
		 */
		Promise.all(delete_urls).then(() => {
			this.ativaCombinacao();
		}).catch(error => {
			console.log(error); // eslint-disable-line
			this.setState({ salvando_combinacao: 3 });
		});
	}

	salvaItens(itens_ativos, rcs_to_delete, rcs_to_maintain) {
		/**
		 * Salva os itens ativos
		 */
		const params = { ativo: true };
		const itens_urls = Object.keys(itens_ativos)
			.map(item_id => _post(`/conaz/v2/itens_rc_analise/${itens_ativos[item_id]}/ativar`, params));
		Promise.all(itens_urls).then(() => {
			this.deleteRCs(rcs_to_delete, rcs_to_maintain);
			// if (validacao) {
			// 	this.deleteRCs(rcs_to_delete);
			// 	return;
			// }
			// const combinacao_salva = this.state.combinacao_salva === 1 ? 2 : 0;
			// this.setState({ combinacao_salva, salvando_combinacao: 2 });
			// this.hideAutoSaveAlert();
		}).catch(error => {
			console.log(error); // eslint-disable-line
			this.setState({ salvando_combinacao: 3 });
		});
	}

	salvaCombinacao(validacao) {
		this.setState({ salvando_combinacao: 1, combinacao_salva: 1 });
		const { qc, match } = this.props;
		const comb_to_edit = Number(match.params.comb_id);
		const oc = qc.opcoes_de_compra.by_id[comb_to_edit];

		/**
		 * Loop por todos os RC's analise
		 */
		const rcs_to_maintain = [];
		const rcs_to_delete = [];
		const itens_ativos = {};
		const all_itens = {};
		for (let r = 0; r < oc.rcs_analise.length; r++) {
			const rc_a = oc.rcs_analise[r];
			let n_ativos = 0;
			for (let i = 0; i < rc_a.itens_rc_analise.length; i++) {
				const item = rc_a.itens_rc_analise[i];
				/**
				 * Add para um array para depois buscar
				 * itens que não foram selecionados
				 */
				all_itens[item.item_analise_id] = {
					order: i + 1,
					item_analise_id: item.item_analise_id,
				};

				if (item.ativo) {
					itens_ativos[item.item_analise_id] = item.id;
					n_ativos++;
				}
			}
			if (n_ativos === 0) {
				/**
				 * RC vazio (zerado, sem itens)
				 */
				rcs_to_delete.push(rc_a.id);
			} else {
				rcs_to_maintain.push(rc_a.id);
			}
		}

		/**
		 * Identifica se algum item não está com nenhum selecionado
		 */
		const itens_faltando = Object.keys(all_itens)
			.filter(item_analise_id => itens_ativos[item_analise_id] === undefined)
			.map(item_analise_id => all_itens[item_analise_id]);
		if (itens_faltando.length > 0 && validacao) {
			this.setState({ itens_faltando, salvando_combinacao: 0 });
			this.props.updateModals('itens_comb_qc', true);
			return;
		}

		/**
		 * Salva os itens ativos
		 */
		this.salvaItens(itens_ativos, rcs_to_delete, rcs_to_maintain);
	}

	hideAutoSaveAlert() {
		window.clearTimeout(this.alert_timeout);
		this.alert_timeout = window.setTimeout(() => {
			this.setState({ salvando_combinacao: 0 });
		}, 2500);
	}

	render() {
		return (
			<RequestsWrapperFuture
				urls_to_fetch={this.state.urls_to_fetch}
				handleResponses={this.handleResponses}
				{...this.props}>

				<QcCombEdition
					{...this.state}
					{...this.props}
					selectItem={this.selectItem}
					changeCondicaoPagamento={this.changeCondicaoPagamento}
					finalizaCombinacao={this.finalizaCombinacao}
					deleteOCFromProps={this.deleteOCFromProps}
					salvaCombinacao={this.salvaCombinacao} />

			</RequestsWrapperFuture>
		);
	}
}

QcCombEditionContainer.propTypes = {
	// =========== hoc
	setItensAnalise: PropTypes.func.isRequired,
	handleDimensions: PropTypes.func.isRequired,
	handleScroll: PropTypes.func.isRequired,
	calcTotalRC: PropTypes.func.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	cotacoes: PropTypes.object.isRequired,
	compras: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	qc: PropTypes.object.isRequired,
	// =========== funcs
	updateQc: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	changeObra: PropTypes.func.isRequired,
	unsetAuth: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
const _QcCombEditionContainer = withStore(connect(mapStateToProps, mapDispatchToProps)(QcCombEditionContainer));
export default QcHoc(_QcCombEditionContainer);

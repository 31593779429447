import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { editMomentDateFormat } from '../../_functions/_editDateFormat';

const CompraRowEntrega = ({ compra }) => {
	const { data_de_entrega, transporte } = compra;
	const data_title = transporte !== undefined && transporte === 2
		? 'Data de retirada'
		: 'Data de entrega';
	const data_entrega_view = (data_de_entrega !== '' && data_de_entrega !== null)
		? editMomentDateFormat(data_de_entrega)
		: <em styleName="em">Sem registro</em>;

	return (
		<div styleName="entrega">
			{data_title}
			<p>{data_entrega_view}</p>
		</div>
	);
};

CompraRowEntrega.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraRowEntrega, styles, { allowMultiple: true });

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class NotificarPessoas extends Component {
	constructor(props) {
		super(props);
		this.openNotificacoes = this.openNotificacoes.bind(this);
	}

	openNotificacoes() {
		const { updateModals, handleMenu } = this.props;
		updateModals('notifica_decisor', true);
		handleMenu();
	}

	render() {
		const { inside_confirm } = this.props;

		if (!inside_confirm) {
			return (
				<button
					type="button"
					data-acoes-pedido-menu
					onClick={this.openNotificacoes}>
					<i className="fa fa-comments fa-fw" aria-hidden="true" data-acoes-pedido-menu /> Notificar pessoas
				</button>
			);
		}

		return null;
	}
}

NotificarPessoas.propTypes = {
	updateModals: PropTypes.func.isRequired,
	handleMenu: PropTypes.func.isRequired,
	inside_confirm: PropTypes.bool.isRequired,
};

export default CSSModules(NotificarPessoas, styles, { allowMultiple: true });

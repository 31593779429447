import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

// Components
import RequestsWrapperFuture from '../../components/RequestsWrapper/RequestsWrapperFuture';
import CompraV3 from './CompraV3';

// Functions
import { transformItens } from './subfunctions/_transformItens';
import { getLocalstorageData, setLocalstorageData } from '../../components/_functions/_getLocalstorageData';
import { _get, _post, _put } from '../../components/_functions/_requests';
import { lockBody } from '../../components/_functions/_lockBody';
import { handleRequestErrors } from '../../components/_functions/_handleRequestErrors';
import { defineStatusCompra, compraIsEditable } from '../../components/_functions/_defineStatusCompra';
import { prepareItensToGet, prepareSearchUrl } from '../../components/_functions/_handleInfoIntegracoes';
import { refatoraListaSiengeItens } from '../Integracao/SiengeItens/subfunctions/_refatoraListaSiengeItens';

class CompraV3Container extends Component {
	static postVendedor(vendedor, cliente_id) {
		const params_usuario = {
			nome: vendedor.nome !== '' ? vendedor.nome : '',
			email: vendedor.email,
			telefones: vendedor.telefones,
			cadastrado_por: cliente_id,
			status: -10,
			origem_cadastro: 3,
		};
		return _post('/conaz/v2/usuarios_fornecedor/', params_usuario);
	}

	constructor(props) {
		super(props);
		this.state = {
			is_mobile: window.innerWidth < 1000,
			itens_mobile_open: false,
			alertaCopia: false, /* state de alerta para copiar dados */
			urls_to_fetch: [`/conaz/v2/compras/${props.match.params.id}`],
			fornecedor_pre_selecionado: {},
			salvando_selecao_fornecedor: false,
			itens_width: 600,
			dadosCotacao: {},
			form_novo_fornecedor: false,
			fornecedor_ja_existe: false,
			fornecedor_nome: '',
			loading_request: false,
			errors: {},
			envio_side_bar: false,
			compra_salva: true,
			salvando: 0,
			timer: 0,
			infoCp_modal: false,
			error_edicao: false,
			fornecedor_integrado: {},
			export_sienge_modal: false,
		};
	}

	componentDidMount() {
		const { match, updateUi } = this.props;
		// Google Analytics
		const { pathname, search } = window.location;
		window.ga('set', 'page', pathname + search);
		window.ga('send', 'pageview');
		// Page title
		document.title = `#${match.params.id} - Compra - Conaz`;
		// Força reload das compras para atualizar mudanças
		updateUi(['changes', 'compras'], true);
		// larguras
		window.addEventListener('resize', this.handleDimensions);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		/**
		 * Verifica se mudou o código da cotação
		 * se mudou envia as novas url's para o wrapper carregá-las TODO verificar se tem nova compra e chamar action
		 */
		if (this.props.match.params.id !== nextProps.match.params.id) {
			this.setState({
				urls_to_fetch: [`/conaz/v2/compras/${nextProps.match.params.id}`],
			});
			document.title = `#${nextProps.match.params.id} - Compra - Conaz`;
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleDimensions);
		lockBody(false);
		const { updateModals, updateItensRaw } = this.props;
		updateModals('fornecedor', false);
		const itens_compra = {
			by_id: {},
			all_ids: [],
		};
		updateItensRaw({ itens_compra });
		this.clearAlert();
	}

	handleExportSiengeModal = (value) => {
		const export_sienge_modal = typeof value !== 'boolean'
			? !this.state.export_sienge_modal
			: value;
		this.setState({ export_sienge_modal });
	}

	notificaUsuarioAposExportacao = () => {
		this.setState({ export_sienge_modal: false });
		this.props.updateModals('notifica_decisor', true);
	}

	handleModalErroEdicao = () => {
		this.setState({ error_edicao: !this.state.error_edicao });
	}

	handleModalCp = () => this.setState({ infoCp_modal: !this.state.infoCp_modal });

	checkAlert = () => {
		const { compras } = this.props;
		const { alert } = compras;
		const message = (alert || {}).message || '';
		const has_alert = message !== '';

		if (has_alert) {
			this.alert_timeout = window.setTimeout(() => {
				this.clearAlert();
			}, 9000);
		}
	}

	clearAlert = () => {
		window.clearTimeout(this.alert_timeout);
		const { updateComprasRaw } = this.props;
		const alert = {
			color: '',
			message: '',
		};
		updateComprasRaw({ alert });
	}

	handleDimensions = () => {
		const itens_elem = document.getElementById('itens_list_wrapper');
		const itens_width = itens_elem ? itens_elem.offsetWidth - 10 : 600;
		const is_mobile = window.innerWidth < 1000;
		const itens_mobile_open = !is_mobile
			? false
			: this.state.itens_mobile_open;
		this.setState({ itens_width, is_mobile, itens_mobile_open });
	};

	updateState = (field, value) => {
		this.setState({ [field]: value });
	};

	redirectAfterConcluir = id => {
		const { history, match } = this.props;
		if (id) {
			history.push(`/compra/nova/${id}`);
			this.setState({
				urls_to_fetch: [`/conaz/v2/compras/${match.params.id}`],
				envio_side_bar: false,
			});
			return;
		}
		history.push(`/compra/${match.params.id}`);
		this.setState({
			urls_to_fetch: [`/conaz/v2/compras/${match.params.id}`],
			envio_side_bar: false,
			export_sienge_modal: false,
		});
	}

	redirectAfterAskAproval = () => {
		const { updateComprasRaw, history, match } = this.props;
		const alert = {
			color: 'green',
			message: 'Compra enviada para aprovação com sucesso!',
		};
		updateComprasRaw({ alert });
		history.push(`/compra/${match.params.id}`);
		this.setState({
			urls_to_fetch: [`/conaz/v2/compras/${match.params.id}`],
			envio_side_bar: false,
			export_sienge_modal: false,
		});
	}

	handleEnvioSideBar = () => {
		const envio_side_bar = !this.state.envio_side_bar;
		this.setState({ envio_side_bar });
		const lock = envio_side_bar === true;
		lockBody(lock);
	};

	handleFixError = field => {
		const { errors } = this.state;
		delete errors[field];
		this.setState({ errors });
	};

	openItensMobile = () => {
		this.setState({ itens_mobile_open: !this.state.itens_mobile_open });
	}

	handleModalFornecedor = () => {
		this.setState({ loading_request: true }, () => {
			this.props.updateModals('fornecedor', true);
		});
	};

	infoFornecedor = fornecedor => {
		this.setState({
			fornecedor_pre_selecionado: fornecedor,
			form_novo_fornecedor: false,
			fornecedor_nome: '',
			fornecedor_ja_existe: fornecedor.ja_existe || false,
			loading_request: false,
		});
	};

	handleNovoFornecedorModal = fornecedor_nome => {
		this.setState({ form_novo_fornecedor: true, fornecedor_nome });
		this.props.updateModals('fornecedor', true);
	};

	handleFornecedorSelecionado = data => {
		if (!data) this.setState({ salvando_selecao_fornecedor: true });
		else this.setState({ salvando_selecao_fornecedor: false });
	};

	handleResponses = (responses, updateWrapperState) => {
		const { updateComprasRaw } = this.props;
		const _nova_compra = (responses[0] || {}).data || {};

		// Compra nao existe
		const error_message = (_nova_compra || {}).message || '';
		const error_type = (_nova_compra || {}).type || '';
		if (
			_nova_compra.id === undefined &&
			error_type === 'ResourceError' &&
			error_message !== ''
		) {
			this.setState({ urls_to_fetch: [] });
			updateWrapperState('request_state', 2);
			return;
		}

		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		const status_front = defineStatusCompra(_nova_compra);
		const is_editable = compraIsEditable(_nova_compra, is_triagem);
		const nova_compra = {
			..._nova_compra,
			status_front,
			is_editable,
		};

		// Compra ok
		const itens_de_compra =
			((responses[0] || {}).data || {}).itens_de_compra || [];
		const itens_transformed = transformItens(itens_de_compra);
		const itens_compra = {
			by_id: itens_transformed.by_id,
			all_ids: itens_transformed.all_ids,
		};
		updateComprasRaw({ nova_compra });

		const alertaCopia = getLocalstorageData('alertaCopia') || undefined;

		if (nova_compra.pedido_id && (alertaCopia || alertaCopia === undefined)) {
			setLocalstorageData('alertaCopia', true);
			_get(`/conaz/v2/pedidos/${nova_compra.pedido_id}?fields=observacoes,observacoes_internas,
				itens_de_pedido.id,itens_de_pedido.observacoes,itens_de_pedido.observacoes_internas`)
				.then(({ data }) => this.setState({ dadosCotacao: { ...data } }));
		}

		this.setState({ urls_to_fetch: [] });
		this.carregaInfoIntegracoes(itens_compra, updateWrapperState);
	}

	carregaInfoIntegracoes = (itens_compra, updateWrapperState) => {
		const itens_array = _.values(itens_compra.by_id);
		const ids_to_get = prepareItensToGet(itens_array);

		/**
		 * Mostra compra caso não tenha nenhum GET
		 */
		if (ids_to_get.length === 0) {
			this.endRequests(itens_compra, updateWrapperState);
			return;
		}

		const search_url = prepareSearchUrl(ids_to_get);
		_get(search_url).then(response => {
			const itens_integrados = response.data.result || [];
			this.addItensIntegrados(itens_integrados, itens_compra, updateWrapperState);
		}).catch(error => {
			updateWrapperState('request_state', 2);
			handleRequestErrors(error);
		});
	}

	addItensIntegrados = (itens_integrados, itens_compra, updateWrapperState) => {
		const { all_ids, by_id } = itens_compra;
		const _integrados = refatoraListaSiengeItens(itens_integrados);
		const itens_com_associacao = {};
		all_ids.forEach(item_id => {
			const item = by_id[item_id] || {};
			const id_no_swi_detalhe = item.id_no_swi_detalhe || '';
			const integrado = id_no_swi_detalhe !== ''
				? _.find(
					_integrados,
					a => (
						a.id_no_swi === item.id_no_swi
						&& (a.detalhe || {}).id_no_swi === id_no_swi_detalhe
					),
				) || {}
				: _.find(
					_integrados,
					_item => _item.id_no_swi === item.id_no_swi && _item.detalhe === null,
				) || {};
			item.item_integrado = integrado;
			itens_com_associacao[item_id] = item;
		});
		const _itens_compra = {
			by_id: itens_com_associacao,
			all_ids,
		};

		this.endRequests(_itens_compra, updateWrapperState);
	}

	endRequests = (itens_compra, updateWrapperState) => {
		const { updateItensRaw } = this.props;
		updateItensRaw({ itens_compra });
		updateWrapperState('request_state', 2);
		this.handleDimensions();
		this.checkAlert();
		this.checkLogged();
	}

	checkLogged = () => {
		const { compras, updateComprasRaw } = this.props;
		const { nova_compra } = compras;
		const cliente_id = ((nova_compra.obra || {}).cliente || {}).id;
		const url = `/conaz/v2/integracoes/credenciais?swi=1&cliente_id=${cliente_id}`;
		_get(url).then(response => {
			const logged_sienge = (response.data || []).length > 0;
			const compra = {
				...nova_compra,
				logged_sienge,
			};
			updateComprasRaw({ nova_compra: compra });
		});
	}

	checarSeExisteVendedorEmail = (email, cliente_id) => _get(
		`/conaz/v2/usuarios_fornecedor/?email=${email}&cadastrado_por=${cliente_id}`,
	).then(({ data }) => {
		if (data.length > 0) {
			const fornecedor_existente =
				data[0].fornecedores[0] !== undefined
					? data[0].fornecedores[0].fornecedor
					: {};
			this.setState({
				// fornecedor_ja_existe: true,
				fornecedor_existente,
				vendedor_existente: data[0],
			});
			return true;
		}
		return false;
	});

	handleCadastrarVendedor = (fornecedor, vendedor) => {
		this.setState({ salvando_selecao_fornecedor: true });
		const cliente_id = this.obterClienteIdCadastro();

		return this.checarSeExisteVendedorEmail(vendedor.email, cliente_id)
			.then(response_check => {
				if (!response_check) {
					return this.constructor
						.postVendedor(vendedor, cliente_id)
						.then(({ data: response_vendedor }) => {
							this.postFornecedorUsuario(fornecedor, response_vendedor);
							return response_vendedor;
						});
				}
				return null;
			})
			.catch(error => {
				this.setState({
					errors: { ...this.state.errors, salvando_fornecedor: true },
					salvando_selecao_fornecedor: false,
				});
				handleRequestErrors(error);
			});
	};

	handleCadastrarFornecedorVendedor = (fornecedor, vendedor) => {
		this.setState({ salvando_selecao_fornecedor: true });
		const cliente_id = this.obterClienteIdCadastro();

		return this.checarSeExisteVendedorEmail(vendedor.email, cliente_id)
			.then(response_check => {
				if (!response_check) {
					return this.postFornecedor(fornecedor, cliente_id).then(
						({ data: response_fornecedor }) => this.constructor
							.postVendedor(vendedor, cliente_id)
							.then(({ data: response_vendedor }) => {
								this.postFornecedorUsuario(
									response_fornecedor,
									response_vendedor,
								);
								return { vendedor: response_vendedor, fornecedor: response_fornecedor };
							}),
					);
				}
				throw new Error('Fornecedor Existente');
			})
			.catch(error => {
				this.setState({
					errors: { ...this.state.errors, salvando_fornecedor: true },
					salvando_selecao_fornecedor: false,
				});
				handleRequestErrors(error);
			});
	};

	postFornecedor = (fornecedor, cliente_id) => {
		const novo_fornecedor = {
			nome: fornecedor.nome,
			site: fornecedor.site,
			cadastrado_por: cliente_id,
			status: -10,
		};

		return _post('/conaz/v2/fornecedores/', novo_fornecedor).catch(error => {
			if (
				error.response &&
				error.response.data &&
				error.response.data.message &&
				error.response.data.message.indexOf('Fornecedor com nome') !==
				-1
			) {
				this.setState({
					fornecedor_ja_existe: true,
					fornecedor_existente: {},
					vendedor_existente: {},
				});
			}
			throw error;
		});
	};

	postFornecedorUsuario = (fornecedor, vendedor) => _post(`/conaz/v2/fornecedores/${fornecedor.id}/usuarios/${vendedor.id}`, {
		tipo_associacao: 4,
	});

	obterClienteIdCadastro = () => {
		const { obra_selected, obras_by_id } = this.props.user;
		return obras_by_id[obra_selected].cliente.id;
	};

	saveFornecedorSienge = (fornecedor_integrado, opt = null) => {
		const { compras: { nova_compra }, updateComprasRaw } = this.props;
		const fornecedor_ja_existe = opt === null
			? this.state.fornecedor_ja_existe
			: opt;

		const params = { force: true };
		params.fornecedor_integrado_id = fornecedor_integrado.id || null;

		if (fornecedor_ja_existe) {
			_put(`/conaz/v2/compras/${nova_compra.id}`, params).then(({ data }) => {
				this.setState({ fornecedor_integrado });
				const _nova_compra = {
					...nova_compra,
					fornecedor_integrado: { ...data.fornecedor_integrado },
				};
				updateComprasRaw({ nova_compra: _nova_compra });
				this.setState({ fornecedor_integrado: {} });
			});
		} else {
			this.setState({
				fornecedor_integrado: params.fornecedor_integrado_id === null
					? { id: null }
					: fornecedor_integrado,
			});
		}
	}

	saveFornecedorSiengePosterior = () => {
		const { updateComprasRaw } = this.props;
		const { compras: { nova_compra }, fornecedor_integrado } = this.state;
		const params = { fornecedor_integrado_id: fornecedor_integrado.id || null };
		_put(`/conaz/v2/compras/${nova_compra.id}`, params).then(({ data }) => {
			const _nova_compra = {
				...nova_compra,
				fornecedor_integrado: { ...data.fornecedor_integrado },
			};
			updateComprasRaw({ nova_compra: _nova_compra });
		});
	}

	autoSaveStart = () => {
		this.setState({ compra_salva: false });
		window.clearTimeout(this.save_info_timeout);
		this.save_info_timeout = window.setTimeout(() => {
			this.autoSaveInfo();
		}, 2000);
	};

	autoSaveInfo = () => {
		this.setState({ salvando: 1 });
		const { compras: { nova_compra }, updateComprasRaw, updateModals } = this.props;

		const {
			obra,
			rc,
			fornecedor,
			pedido_id,
			titulo,
			condicao_de_pagamento,
			data_de_entrega,
			tipo,
			transporte_proprio,
			transporte,
		} = nova_compra;

		const custos_check = nova_compra.custos_adicionais || 0;
		const transporte_check = nova_compra.valor_transporte || 0;
		// SECTION Informações gerais da compra
		const { id: obra_id } = obra;
		const tem_cotacao = pedido_id !== null;

		// SECTION Informações de condição de pagamento
		const condicao_de_pagamento_id = condicao_de_pagamento.id || null;
		const custos_adicionais =
			custos_check.toString().indexOf(',') === -1
				? Number(custos_check.toString().replace(/[,]/g, '.'))
				: Number(custos_check.toString());

		// SECTION Informações de transporte
		const valor_transporte =
			transporte_check.toString().indexOf(',') === -1
				? Number(transporte_check.toString().replace(/[,]/g, '.'))
				: Number(transporte_check.toString());
		// const data_de_entrega = dt_entrega || moment().format('YYYY-MM-DD');

		// SECTION Informações de comentários
		const observacoes = nova_compra.observacoes || '';
		const observacoes_internas = nova_compra.observacoes_internas || '';

		const data_de_decisao = nova_compra.data_de_decisao || null;
		const _data_de_decisao = data_de_decisao === null
			? moment().format('YYYY-MM-DD')
			: moment(data_de_decisao).format('YYYY-MM-DD');

		// SECTION Monta objeto com os parametros a serem salvos no servidor
		const params = {
			titulo,
			tipo,
			condicao_de_pagamento_id,
			custos_adicionais,
			data_de_decisao: _data_de_decisao,
			transporte,
			valor_transporte,
			data_de_entrega,
			observacoes,
			observacoes_internas,
		};

		// tem rc
		if (!rc.uuid) {
			const { fornecedor_id, usuario_id } = fornecedor;
			const { fornecedor_integrado: { id: fornecedor_integrado_id } } = this.state;
			if (fornecedor_id !== undefined) params.fornecedor_id = fornecedor_id;
			if (usuario_id !== undefined) params.usuario_id = usuario_id;
			if (params.fornecedor_id && fornecedor_integrado_id !== undefined) {
				this.setState({ fornecedor_integrado: {} });
				params.fornecedor_integrado_id = fornecedor_integrado_id;
			}
		}

		// obra
		if (!tem_cotacao) {
			params.obra_id = obra_id;
		}

		// transporte_proprio
		if (transporte_proprio !== null) {
			params.transporte_proprio = transporte_proprio;
		}

		return _put(`/conaz/v2/compras/${nova_compra.id}`, params)
			.then(({ data: response_compra }) => {
				if (params.fornecedor_id) {
					// const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
					// const status_front = defineStatusCompra(response_compra);
					// const is_editable = compraIsEditable(response_compra, is_triagem);

					// const _nova_compra = {
					// 	...response_compra,
					// 	logged_sienge: nova_compra.logged_sienge,
					// 	status_front,
					// 	is_editable,
					// };
					const _nova_compra = {
						...nova_compra,
						fornecedor: response_compra.fornecedor,
						fornecedor_integrado: response_compra.fornecedor_integrado,
						usuario_fornecedor: response_compra.usuario_fornecedor,
					};
					delete _nova_compra.fornecedor_id;
					delete _nova_compra.usuario_id;
					delete _nova_compra.obra_id;
					delete _nova_compra.fornecedor_integrado_id;

					updateComprasRaw({ nova_compra: _nova_compra });
				}
				this.setState({ compra_salva: true, salvando: 2 });
				setTimeout(() => this.setState({ salvando: 0 }), 2500);
			})
			.catch(error => {
				this.setState({ compra_salva: true, salvando: 0 });

				const conaz_error = ((
					error.response || {})
					.data || {})
					.message || '';

				if (conaz_error === 'Apenas os responsáveis da compra podem editá-la.') {
					this.setState({ error_edicao: true });
					return;
				}

				updateModals('error', true);
				handleRequestErrors(error);
			});
	};

	handleAlertaCp = () => setLocalstorageData('alertaCopia', false);

	render() {
		const { urls_to_fetch } = this.state;
		const {
			handleResponses,
			handleValidacao,
			handleFixError,
			checkInfoCotacao,
			infoFornecedor,
			autoSaveStart,
			autoSaveInfo,
			saveFornecedorSienge,
			saveFornecedorSiengePosterior,
		} = this;

		return (
			<RequestsWrapperFuture
				urls_to_fetch={urls_to_fetch}
				handleResponses={handleResponses}
				{...this.props}>
				<CompraV3
					{...this.state}
					{...this.props}
					handleEnvioSideBar={this.handleEnvioSideBar}
					handleModalCp={this.handleModalCp}
					saveFornecedorSienge={saveFornecedorSienge}
					saveFornecedorSiengePosterior={saveFornecedorSiengePosterior}
					infoFornecedor={infoFornecedor}
					autoSaveStart={autoSaveStart}
					autoSaveInfo={autoSaveInfo}
					handleValidacao={handleValidacao}
					handleFixError={handleFixError}
					handleAlertaCp={this.handleAlertaCp}
					handleFornecedorSelecionado={this.handleFornecedorSelecionado}
					handleNovoFornecedorModal={this.handleNovoFornecedorModal}
					handleCadastrarFornecedorVendedor={this.handleCadastrarFornecedorVendedor}
					checkInfoCotacao={checkInfoCotacao}
					handleModalFornecedor={this.handleModalFornecedor}
					handleCadastrarVendedor={this.handleCadastrarVendedor}
					updateState={this.updateState}
					redirectAfterConcluir={this.redirectAfterConcluir}
					redirectAfterAskAproval={this.redirectAfterAskAproval}
					openItensMobile={this.openItensMobile}
					handleModalErroEdicao={this.handleModalErroEdicao}
					handleExportSiengeModal={this.handleExportSiengeModal}
					notificaUsuarioAposExportacao={this.notificaUsuarioAposExportacao} />
			</RequestsWrapperFuture>
		);
	}
}

CompraV3Container.propTypes = {
	// =========== store
	cotacao: PropTypes.object.isRequired,
	cotacoes: PropTypes.object.isRequired,
	compras: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	itens: PropTypes.object.isRequired,
	// =========== funcs
	updateUi: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	changeObra: PropTypes.func.isRequired,
	unsetAuth: PropTypes.func.isRequired,
	updateItens: PropTypes.func.isRequired,
	updateItensRaw: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(CompraV3Container));

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// itens shape
import itens_shape from './types';

// Components
import ModalPortal from '../_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../_modals/ModalPortal/ModalWrapperPortal';
import ModalConfirm from '../_modals/ModalConfirm/ModalConfirm';
import SideBar from '../_modals/SideBar/SideBar';
import SearchItens from './SearchItens/SearchItens';
import ItemEdition from './ItemEdition/ItemEdition';
import ItemBlocked from './ItemEdition/ItemBlocked';
import Header from './Header';
import Lista from './Lista';

// Functions
import { buildItem } from './_subfunctions/buildItem';
import { lockBody } from '../../_functions/_lockBody';
import { _put } from '../../_functions/_requests';
import { handleRequestErrors } from '../../_functions/_handleRequestErrors';
import { setClasses } from '../../_functions/_setClasses';

class Itens extends Component {
	state = {
		heights: {
			wrapper: 500,
			itens: 400,
		},
		fixed_heights: {
			header: 50,
		},
		collapsed_items: [],
		item_on_edition_id: 0,
		search_side_bar: false,
		itens_to_save: [],
		error_edicao: false,
	};

	componentDidMount() {
		this._mounted = true;
		window.addEventListener('resize', this.handleDimensions);
		this.handleDimensions();
		// this.checkLogged();
	}

	componentWillUnmount() {
		this._mounted = false;
		window.removeEventListener('resize', this.handleDimensions);
		lockBody(false);
	}

	handleDimensions = () => {
		// Seta alturas
		const { header } = this.state.fixed_heights;
		const barra_superior = 61;

		let wrapper = window.innerHeight - (barra_superior + 83) - 73;
		if (window.innerWidth < 1000) {
			wrapper = window.innerHeight - (barra_superior + 72);
		}
		if (window.innerWidth < 768) {
			wrapper = window.innerHeight - (barra_superior + 132);
		}


		const itens = wrapper - header;
		const heights = {
			wrapper,
			itens,
		};
		// Update state
		this.setState({ heights });
	}

	updateState = (field, value) => {
		this.setState({ [field]: value });
	}

	handleModalErroEdicao = () => {
		this.setState({ error_edicao: !this.state.error_edicao });
	}

	openItemEdition = (item_id = 0) => {
		const { item_on_edition_id } = this.state;
		const new_id = item_id !== item_on_edition_id ? item_id : 0;
		this.setState({
			item_on_edition_id: new_id,
			search_side_bar: false,
		});
		const lock = new_id !== 0;
		lockBody(lock);
	};

	closeItemEdition = () => {
		this.setState({ item_on_edition_id: 0 });
		lockBody(false);
	};

	openItensSearch = () => {
		const { search_side_bar } = this.state;
		lockBody(!search_side_bar);
		this.setState({
			search_side_bar: !search_side_bar,
			item_on_edition_id: 0,
		});
		setTimeout(() => {
			const search_input = document.getElementById(
				'itens_search_input_2',
			);
			if (search_input) search_input.focus();
		}, 60);
	};

	closeAll = () => {
		lockBody(false);
		this.setState({
			search_side_bar: false,
			item_on_edition_id: 0,
		});
	};

	autoSave = item_id => {
		const { itens_to_save } = this.state;
		const itens_ids = [...itens_to_save, item_id].filter(
			(el, i, a) => i === a.indexOf(el),
		);
		this.setState({ itens_to_save: itens_ids });
		window.clearTimeout(this.save_timeout);
		this.save_timeout = window.setTimeout(() => {
			this.autoSaveItens(itens_ids);
		}, 3000);
	};

	autoSaveItens(itens_to_save) {
		const {
			itens,
			updateModals,
		} = this.props;
		const { by_id } = itens;

		if (this._mounted) {
			this.setState({ itens_to_save: [] });
		}

		const all_puts = [];
		for (let i = 0; i < itens_to_save.length; i++) {
			const item_id = itens_to_save[i];
			const item = by_id[item_id];
			if (item !== undefined) {
				const item_data = buildItem(item);
				all_puts.push(
					_put(`/conaz/v2/itens_de_compra/${item_id}`, item_data),
				);
			}
		}

		Promise.all(all_puts)
			.then(responses => {
				this.updateItensAfterSave(responses);
			})
			.catch(error => {
				const conaz_error = ((
					error.response || {})
					.data || {})
					.message || '';

				if (conaz_error === 'Apenas os responsáveis da compra podem editá-la.') {
					this.setState({ error_edicao: true });
					return;
				}

				updateModals('error', true);
				handleRequestErrors(error);
			});
	}

	updateItensAfterSave(responses) {
		const { itens, updateItensRaw, updateStateCompra } = this.props;
		const { by_id, all_ids } = itens;
		const _by_id = { ...by_id };

		for (let i = 0; i < responses.length; i++) {
			const item = responses[i].data;
			if (item.editado !== by_id[item.id].editado) {
				_by_id[item.id].editado = item.editado;
			}
		}

		const itens_compra = {
			all_ids,
			by_id: _by_id,
		};
		updateItensRaw({ itens_compra });

		updateStateCompra('salvando', 2);
		setTimeout(() => updateStateCompra('salvando', 0), 2500);
	}

	render() {
		const {
			heights,
			fixed_heights,
			collapsed_items,
			item_on_edition_id,
			search_side_bar,
			error_edicao,
		} = this.state;
		const {
			is_mobile,
			itens_mobile_open,
			itens_width,
			itens,
			client_id,
			obra_id,
			is_editable,
			compra_has_rc,
			updateItensRaw,
			updateItemById,
			updateFullItemById,
			updateItemCaracById,
			deleteItem,
			populares,
			openItensMobile,
			updateModals,
			logged_sienge,
			user,
		} = this.props;

		const side_bar_visible = item_on_edition_id !== 0;

		const itens_style = !is_mobile
			? {
				width: `${itens_width}px`,
				height: `${heights.wrapper}px`,
			}
			: {
				height: `${heights.wrapper}px`,
			};

		const itens_wrapper_classes = {
			over: side_bar_visible || search_side_bar,
			open: itens_mobile_open,
		};
		const itens_wrapper_class = setClasses('itens-fixed-wrapper', itens_wrapper_classes);

		return (
			<>
				{error_edicao && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={this.handleModalErroEdicao}>
							<ModalConfirm
								title="Esta compra não pode ser editada"
								message={<p>Apenas os responsáveis da compra podem editá-la.</p>}
								button_type="secondary-conaz"
								button_text="Entendi!"
								cancelar_button={false} />
						</ModalWrapperPortal>
					</ModalPortal>
				)}

				{side_bar_visible && (
					<ModalPortal>
						<SideBar openSideBar={this.openItemEdition} visible>
							{is_editable ? (
								<ItemEdition
									openItemEdition={this.openItemEdition}
									item_on_edition_id={item_on_edition_id}
									obra_id={obra_id}
									client_id={client_id}
									itens={itens}
									user={user}
									updateItemById={updateItemById}
									updateFullItemById={updateFullItemById}
									updateItemCaracById={updateItemCaracById}
									autoSave={this.autoSave}
									deleteItem={deleteItem}
									logged_sienge={logged_sienge} />
							) : (
								<ItemBlocked
									openItemEdition={this.openItemEdition}
									item_on_edition_id={item_on_edition_id}
									itens={itens}
									user={user}
									obra_id={obra_id}
									client_id={client_id}
									updateItemById={updateItemById}
									updateFullItemById={updateFullItemById}
									autoSave={this.autoSave}
									logged_sienge={logged_sienge} />
							)}
						</SideBar>
					</ModalPortal>
				)}

				{search_side_bar && (
					<ModalPortal>
						<SideBar openSideBar={this.openItensSearch} visible>
							<div styleName="search-content">
								<button
									type="button"
									className="close"
									styleName="close-x"
									data-dismiss="modal"
									aria-label="Close"
									onClick={this.openItensSearch}>
									<span aria-hidden="true">&times;</span>
								</button>
								<button
									type="button"
									styleName="back-button"
									onClick={this.openItensSearch}>
									<i className="fa fa-reply" aria-hidden="true" />
									Voltar para compra
								</button>
								<SearchItens
									type="itens_compra"
									item_on_edition_id={item_on_edition_id}
									openItemEdition={this.openItemEdition}
									updateItensRaw={updateItensRaw}
									populares={populares}
									autoSave={this.autoSave} />
							</div>
						</SideBar>
					</ModalPortal>
				)}

				{(side_bar_visible || search_side_bar) && (
					<div
						styleName="itens-block-modal"
						role="none"
						onClick={this.closeAll} />
				)}

				<div styleName={itens_wrapper_class} style={itens_style}>
					<div id="itens_compra_wrapper" styleName="itens-wrapper">
						<Header
							height={fixed_heights.header}
							itens={itens}
							collapsed_items={collapsed_items}
							updateState={this.updateState}
							openItensSearch={this.openItensSearch}
							is_editable={is_editable}
							openItensMobile={openItensMobile} />

						<Lista
							height={heights.itens}
							itens={itens}
							user={user}
							collapsed_items={collapsed_items}
							updateState={this.updateState}
							openItemEdition={this.openItemEdition}
							closeItemEdition={this.closeItemEdition}
							item_on_edition_id={item_on_edition_id}
							compra_has_rc={compra_has_rc}
							deleteItem={deleteItem}
							is_editable={is_editable}
							updateModals={updateModals}
							handleModalErroEdicao={this.handleModalErroEdicao} />
					</div>
				</div>
			</>
		);
	}
}

Itens.propTypes = {
	is_mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired,
	obra_id: PropTypes.number.isRequired,
	client_id: PropTypes.number.isRequired,
	itens_mobile_open: PropTypes.bool.isRequired,
	itens_width: PropTypes.number.isRequired,
	itens: itens_shape,
	is_editable: PropTypes.bool,
	compra_has_rc: PropTypes.bool,
	updateItensRaw: PropTypes.func.isRequired,
	updateItemById: PropTypes.func.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	updateItemCaracById: PropTypes.func.isRequired,
	deleteItem: PropTypes.func.isRequired,
	populares: PropTypes.any.isRequired,
	openItensMobile: PropTypes.func.isRequired,
	updateStateCompra: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	logged_sienge: PropTypes.bool,
};

Itens.defaultProps = {
	itens: {},
	is_editable: true,
	compra_has_rc: false,
	logged_sienge: false,
};

export default CSSModules(Itens, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const StatusBar = ({ status, cancelado }) => {
	let icons_1 = 'icons';
	let icons_2 = 'icons';
	let icons_3 = 'icons';
	let text_1 = 'text';
	let text_2 = 'text';
	let text_3 = 'text';

	switch (status) {
	default: case 0: case 15: case 20: case 30: case 40:
		icons_1 = 'icons active';
		text_1 = 'text active';
		break;
	case 50:
		icons_1 = 'icons done';
		icons_2 = 'icons active';
		text_1 = 'text done';
		text_2 = 'text active';
		break;
	case 60: case 70:
		icons_1 = 'icons done';
		icons_2 = 'icons done';
		icons_3 = 'icons active';
		text_1 = 'text done';
		text_2 = 'text done';
		text_3 = 'text active';
	}

	if (!cancelado) {
		return (
			<div styleName="status-wrapper">
				<div styleName="icons-wrapper">
					<div styleName={icons_1}>
						<div styleName="icon"><i className="fa fa-check" aria-hidden="true" /></div>
						<div styleName="connection" />
					</div>
					<div styleName={icons_2}>
						<div styleName="icon"><i className="fa fa-check" aria-hidden="true" /></div>
						<div styleName="connection" />
					</div>
					<div styleName={icons_3}>
						<div styleName="icon"><i className="fa fa-check" aria-hidden="true" /></div>
					</div>
				</div>
				<div styleName="texts-wrapper">
					<div styleName={text_1}>
						Aguardando cotações
					</div>
					<div styleName={text_2}>
						Aguardando decisão da compra
					</div>
					<div styleName={text_3}>
						Compra efetuada
					</div>
				</div>
			</div>
		);
	}

	return (
		<div styleName="status-wrapper">
			<div styleName="icons-wrapper">
				<div styleName="icons canceled">
					<div styleName="icon"><i className="fa fa-ban" aria-hidden="true" /></div>
				</div>
			</div>
			<div styleName="texts-wrapper">
				<div styleName="text canceled">
					Cotação cancelada
				</div>
			</div>
		</div>
	);
};

StatusBar.propTypes = {
	status: PropTypes.number.isRequired,
	cancelado: PropTypes.bool.isRequired,
};

export default CSSModules(StatusBar, styles, { allowMultiple: true });

/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import { Modal } from 'antd';
import styles from './style.module.scss';

// Components
import Loading from '../_loadings/Loading/Loading';

// Functions
import { formatCurrency } from '../../_functions/_formatCurrency';
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';

class SearchResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		};
		this.handleLink = this.handleLink.bind(this);
		this.modalObraDesativada = this.modalObraDesativada.bind(this);
	}

	componentDidMount() {
		setTimeout(() => this.setState({ show: true }), 60);
	}

	handleLink(type, id, status) {
		const { updateUi, history } = this.props;
		updateUi(['search'], '');
		updateUi(['search_results'], { pedidos: [], compras: [] });
		const link = (status === -40 || status === -50) ? `/${type}/nova/${id}` : `/${type}/${id}`;
		history.push(link);
	}

	modalObraDesativada() {
		Modal.error({
			width: 600,
			zIndex: 3000,
			title: 'Esta obra foi desativada.',
			content: (
				<div>
					<span>Para visualizar informações de suas cotações e compras de obras inativas, entre em contato com o suporte através do chat ou diretamente com o seu gerente de conta.</span>
				</div>
			),
			onOk() {
			},
			okText: 'Fechar',
			okCancel: false,
		});
	}


	render() {
		const { search_results, search_state } = this.props;
		const { show } = this.state;
		const wrapper_class = show ? 'search-results in' : 'search-results';

		return (
			<div styleName={wrapper_class}>
				{search_state === 1 && (
					<div styleName="results-blocks"><Loading /></div>
				)}
				{(search_state === 2 && search_results.pedidos.length === 0 && search_results.compras.length === 0) && (
					<div styleName="results-blocks">
						Nenhum resultado encontrado!
					</div>
				)}
				{(search_state === 2 && search_results.pedidos.length > 0) && (
					<div styleName="results-blocks">
						<h4>Cotações</h4>
						{search_results.pedidos.map(cotacao => {
							let status_view = '';
							switch (cotacao.status) {
							case -40:
								status_view = <div styleName="label s-40">Rascunho</div>;
								break;
							case -35:
								status_view = <div styleName="label s-35">Refinamento solicitado</div>;
								break;
							case -5:
								status_view = <div styleName="label s-5">Envio solicitado</div>;
								break;
							case 10:
								status_view = <div styleName="label s10">Em Edição</div>;
								break;
							default: case 0: case 30: case 40: case 45:
								status_view = <div styleName="label s0">Aguardando cotações</div>;
								break;
							case 50:
								status_view = <div styleName={`label s${cotacao.status}`}>QC disponível para compra</div>;
								break;
							case 60:
								status_view = <div styleName={`label s${cotacao.status}`}>Compra(s) aguardando confirmação</div>;
								break;
							case 70:
								(cotacao.obra.desativada !== true) ? (
									status_view = <div styleName={`label s${cotacao.status}`}>Compra efetuada</div>
								) : status_view = <div styleName="label desativada">Obra desativada</div>;
							}

							if (cotacao.em_aprovacao) {
								status_view = <div styleName="label s-5">Envio solicitado</div>;
							}

							if (cotacao.em_refinamento) {
								status_view = <div styleName="label s-35">Refinamento solicitado</div>;
							}

							if (cotacao.cancelado) {
								status_view = <div styleName="label s-30">Cancelada</div>;
							}

							return (
								(cotacao.obra.desativada !== true ? (
									<button
										key={cotacao.id}
										type="button"
										styleName="result"
										onClick={() => this.handleLink('cotacao', cotacao.codigo, cotacao.status)}>
										<i className="fa fa-folder-o" aria-hidden="true" />
										<div styleName="titulo">
											<p>{cotacao.titulo}</p>
											#{cotacao.codigo}
										</div>
										<div styleName="status">
											{status_view}
										</div>
									</button>
								) : (
									<button
										key={cotacao.id}
										type="button"
										styleName="result"
										onClick={() => this.modalObraDesativada()}>
										<i className="fa fa-folder-o" aria-hidden="true" />
										<div styleName="titulo">
											<p>{cotacao.titulo}</p>
											#{cotacao.codigo}
										</div>
										<div styleName="status">
											{status_view}
										</div>
									</button>
								))
							);
						})}
					</div>
				)}
				{(search_state === 2 && search_results.compras.length > 0) && (
					<div styleName="results-blocks">
						<h4>Compras</h4>
						{search_results.compras.map(compra => {
							const compra_status = defineStatusCompra(compra);

							return (
								<button
									key={compra.id}
									type="button"
									styleName="result"
									onClick={() => this.handleLink('compra', compra.id)}>
									<i className="fa fa-credit-card" aria-hidden="true" />
									<div styleName="titulo">
										<p>{compra.fornecedor.nome}</p>
										<p styleName={`preco-fechado ${compra_status}`}>{formatCurrency(compra.valor_total, 2, 'R$')}</p>
										{`#${compra.id}${compra.titulo ? ` - ${compra.titulo}` : ''}`}
									</div>
								</button>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

SearchResults.propTypes = {
	search_results: PropTypes.object.isRequired,
	search_state: PropTypes.number.isRequired,
	updateUi: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

const _SearchResults = CSSModules(SearchResults, styles, { allowMultiple: true });
export default withRouter(_SearchResults);

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import ModalPortal from '../_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../_modals/ModalPortal/ModalWrapperPortal';
import ModalEvaluationResume from '../../ModalEvaluationResume';
import ModalRating from '../../ModalRating';

// Functions
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';

// Avaliacoes
import { avaliacao_fornecedor } from '../../ModalRating/avaliacoes';

const CompraRowAvaliacao = ({ compra, location }) => {
	const [modal_avaliacao, setModalAvaliacao] = useState(false);
	const [modal_edition, setModalEdition] = useState(false);

	function handleEvaluationResume() {
		setModalAvaliacao(!modal_avaliacao);
	}

	function handleEvaluation() {
		setModalEdition(!modal_edition);
	}

	function preencheAvaliacao() {
		const nome_fornecedor = (compra.fornecedor || {}).nome || '';
		const avaliacao = avaliacao_fornecedor(nome_fornecedor);

		// const valores_anteriores = (compra.avaliacao_fornecedor || {}).detalhe || {};
		// const respostas = valores_anteriores.respostas || [];

		// // estrelas
		// // avaliacao.criterios.nota_geral.value = valores_anteriores.estrelas || null;
		// // entrega
		// const entrega_value = respostas.filter(x => x.tipo_questao === 1);
		// avaliacao.criterios.entrega.value = (entrega_value[0] || {}).resposta;
		// // boletos
		// const boletos_value = respostas.filter(x => x.tipo_questao === 2);
		// avaliacao.criterios.boletos.value = (boletos_value[0] || {}).resposta;
		// // material
		// const material_value = respostas.filter(x => x.tipo_questao === 3);
		// avaliacao.criterios.material.value = (material_value[0] || {}).resposta;
		// // danos
		// const danos_value = respostas.filter(x => x.tipo_questao === 4);
		// avaliacao.criterios.danos.value = (danos_value[0] || {}).resposta;

		return avaliacao;
	}

	const status = defineStatusCompra(compra);
	const nota_fornecedor = location === 'compra'
		? ((compra.avaliacao_fornecedor || {}).detalhe || {}).estrelas || 0
		: compra.avaliacao_fornecedor_estrelas || 0;

	const show_avaliacao = (
		status === 'finalizada'
		&& nota_fornecedor !== 0
	);

	const wrapper_class = location === 'compra'
		? 'div-avaliacao relative'
		: 'div-avaliacao';
	const nota_class = location === 'compra'
		? 'avaliacao-nota relative'
		: 'avaliacao-nota';

	if (show_avaliacao) {
		const avaliacao = preencheAvaliacao();

		return (
			<>
				{modal_edition && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={handleEvaluation}>
							<ModalRating
								closeModal={handleEvaluation}
								avaliacao={avaliacao}
								compra={compra}
								location={location}
							/>
						</ModalWrapperPortal>
					</ModalPortal>
				)}
				{modal_avaliacao && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={handleEvaluationResume}>
							<ModalEvaluationResume
								compra={compra}
								handleEvaluation={handleEvaluation}
								closeModal={handleEvaluationResume} />
						</ModalWrapperPortal>
					</ModalPortal>
				)}
				<div styleName={wrapper_class}>
					<div styleName={nota_class}>
						<i className="fa fa-star" /> {nota_fornecedor}.0 <span>sua avaliação</span>
					</div>
					<button
						type="button"
						className="btn btn-primary-conaz round-border"
						onClick={handleEvaluationResume}>
						Ver avaliação
					</button>
				</div>
			</>
		);
	}

	return null;
};

CompraRowAvaliacao.propTypes = {
	compra: PropTypes.object.isRequired,
	location: PropTypes.string.isRequired,
};

export default CSSModules(CompraRowAvaliacao, styles, { allowMultiple: true });

import React from 'react';
import IconeSugestao from '../../../../icons/icone_sugestao.svg';
import IconeExclamacao from '../../../../icons/icone_exclamacao.svg';

/* eslint-disable no-nested-ternary */
export const errorStatus = (data) => {
	const MensagemErro = () => (
		<>
			<img src={IconeExclamacao} alt="icone_exclamacao" />
			<strong>
				Falha na exportação da compra para o Sienge
			</strong>
			<br />
		</>
	);
	if (data) {
		return (
			data.erro_motivo === 'API_REQUEST_BAD_REQUEST'
			|| data.erro_motivo === 'API_REQUEST_UNAUTHORIZED'
			|| data.erro_motivo === 'API_REQUEST_PAYMENT_REQUIRED'
			|| data.erro_motivo === 'API_REQUEST_FORBIDDEN'
			|| data.erro_motivo === 'API_REQUEST_NOT_FOUND'
			|| data.erro_motivo === 'API_REQUEST_METHOD_NOT_ALLOWED'
			|| data.erro_motivo === 'API_REQUEST_NOT_ACCEPTABLE'
			|| data.erro_motivo === 'API_REQUEST_PROXY_AUTHENTICATION_REQUIRED'
			|| data.erro_motivo === 'API_REQUEST_REQUEST_TIMEOUT'
			|| data.erro_motivo === 'API_REQUEST_CONFLICT'
			|| data.erro_motivo === 'API_REQUEST_GONE'
			|| data.erro_motivo === 'API_REQUEST_LENGTH_REQUIRED'
			|| data.erro_motivo === 'API_REQUEST_PRECONDITION_FAILED'
			|| data.erro_motivo === 'API_REQUEST_REQUEST_ENTITY_TOO_LARGE'
			|| data.erro_motivo === 'API_REQUEST_REQUEST_URI_TOO_LONG'
			|| data.erro_motivo === 'API_REQUEST_UNSUPPORTED_MEDIA_TYPE'
			|| data.erro_motivo === 'API_REQUEST_REQUESTED_RANGE_NOT_SATISFIABLE'
			|| data.erro_motivo === 'API_REQUEST_EXPECTATION_FAILED'
			|| data.erro_motivo === 'API_REQUEST_MISDIRECTED_REQUEST'
			|| data.erro_motivo === 'API_REQUEST_UNPROCESSABLE_ENTITY'
			|| data.erro_motivo === 'API_REQUEST_FAILED_DEPENDENCY'
			|| data.erro_motivo === 'API_REQUEST_LOCKED'
			|| data.erro_motivo === 'API_REQUEST_FAILED_DEPENDENCY'
			|| data.erro_motivo === 'API_REQUEST_UPGRADE_REQUIRED'
			|| data.erro_motivo === 'API_REQUEST_PRECONDITION_REQUIRED'
			|| data.erro_motivo === 'API_REQUEST_TOO_MANY_REQUESTS'
			|| data.erro_motivo === 'API_REQUEST_REQUEST_HEADER_FIELDS_TOO_LARGE'
			|| data.erro_motivo === 'API_REQUEST_UNAVAILABLE_FOR_LEGAL_REASONS'
			|| data.erro_motivo === 'API_REQUEST_INTERNAL_SERVER_ERROR'
			|| data.erro_motivo === 'API_REQUEST_NOT_IMPLEMENTED'
			|| data.erro_motivo === 'API_REQUEST_BAD_GATEWAY'
			|| data.erro_motivo === 'API_REQUEST_HTTP_VERSION_NOT_SUPPORTED'
			|| data.erro_motivo === 'API_REQUEST_VARIANT_ALSO_NEGOTIATES'
			|| data.erro_motivo === 'API_REQUEST_INSUFFICIENT_STORAGE'
			|| data.erro_motivo === 'API_REQUEST_LOOP_DETECTED'
			|| data.erro_motivo === 'API_REQUEST_NOT_EXTENDED'
			|| data.erro_motivo === 'API_REQUEST_NETWORK_AUTHENTICATION_REQUIRED'
				? (
					<div styleName="erro-exportacao">
						<div styleName="mensagem-erro-fixa">
							<MensagemErro />
						</div>
						<div styleName="mensagens-erro-motivo">

							<div styleName="container-mensagens-erro-simples">
								<span styleName="mensagem-erro-simples">
									O servidor não conseguiu entender a solicitação
								</span>

								<div styleName="icone-mensagem-sienge">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>

						</div>
					</div>
				) : data.erro_motivo === 'UNKNOWN' ? (
					<div styleName="erro-exportacao">

						<div styleName="mensagens-erro-motivo">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="container-mensagens-erro-simples">
								<span styleName="mensagem-erro-simples">
									Erro desconhecido
								</span>

								<div styleName="icone-mensagem-sienge">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>

						</div>
					</div>
				) : data.erro_motivo === 'SIENGE_ITEM_DETAIL_ID_NOT_FOUND' ? (
					<div styleName="erro-exportacao">
						<div styleName="mensagem-erro-fixa">
							<MensagemErro />
						</div>
						<div styleName="mensagens-erro-motivo">

							<div styleName="container-mensagens-erro">
								<span styleName="mensagem-erro">
									Não foi possível encontrar o ID detalhe do insumo no Sienge
								</span>
								<strong styleName="frase_solucao_erro">Ações que podem te ajudar a resolver isso:</strong>
								<span styleName="mensagem-erro-2">
									<strong styleName="numero-negrito">1:</strong> Verifique se o ID detalhe do insumo realmente existe na sua tabela de insumos do Sienge.
								</span>
								<span styleName="mensagem-erro-3">
									<strong styleName="numero-negrito">2:</strong>  Caso exista, atualize sua tabela de insumos no Sienge e posteriormente no Conaz. Depois, tente exportar a compra novamente.
								</span>

								<div styleName="icone-sugestao">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>

						</div>
					</div>
				) :
					data.erro_motivo === 'SIENGE_SUPPLIER_NOT_FOUND' ? (
						<div styleName="erro-exportacao">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="mensagens-erro-motivo">

								<div styleName="container-mensagens-erro">
									<span styleName="mensagem-erro">
										Não foi possível encontrar este fornecedor no Sienge.
									</span>
									<strong styleName="frase_solucao_erro">Ações que podem te ajudar a resolver isso:</strong>
									<span styleName="mensagem-erro-2">
										<strong styleName="numero-negrito">1:</strong> Verifique se este fornecedor realmente existe na sua tabela de fornecedores do Sienge.
									</span>
									<span styleName="mensagem-erro-3">
										<strong styleName="numero-negrito">2:</strong> Caso exista, atualize os fornecedores no Sienge e posteriormente no Conaz. Depois, tente exportar a compra novamente.
									</span>

									<div styleName="icone-sugestao">
										<span styleName="mensagem-contato">
											<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
											Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
										</span>
									</div>
								</div>
							</div>
						</div>
					) : data.erro_motivo === 'INCOMPATIBLE_PAYLOAD_RECEIVED' ? (
						<div styleName="erro-exportacao">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="mensagens-erro-motivo">
								<div styleName="container-mensagens-erro-simples">
									<span styleName="mensagem-erro-simples">
										O Sienge alterou informações na integração com o Conaz e por isso a exportação está falhando.
									</span>

									<div styleName="icone-mensagem-sienge">
										<span styleName="mensagem-contato">
											<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
											Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
										</span>
									</div>
								</div>
							</div>
						</div>
					) : data.erro_motivo === 'SIENGE_QUOTATION_NOT_CREATED' ? (
						<div styleName="erro-exportacao">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="container-mensagens-erro">
								<span styleName="mensagem-erro">
									Não foi possível criar a cotação no Sienge
								</span>
								<strong styleName="frase_solucao_erro">Ações que podem te ajudar a resolver isso:</strong>
								<span styleName="mensagem-erro-2">
									<strong styleName="numero-negrito">1:</strong> O Conaz iniciou a exportação, mas algo impediu a criação da cotação no Sienge. Aguarde 15 minutos e tente exportar a compra novamente.
								</span>

								<div styleName="icone-sugestao">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>
						</div>
					) : data.erro_motivo === 'SIENGE_QUOTATION_ITEM_NOT_CREATED' ? (
						<div styleName="erro-exportacao">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="container-mensagens-erro">
								<span styleName="mensagem-erro">
									Não foi possível criar o item na cotação no Sienge
								</span>
								<strong styleName="frase_solucao_erro">Ações que podem te ajudar a resolver isso:</strong>
								<span styleName="mensagem-erro-2">
									<strong styleName="numero-negrito">1:</strong> O Conaz iniciou a exportação, conseguiu criar uma cotação no Sienge, mas algo impediu a criação dos itens. Revise os itens da compra e tente exportá-la novamente.
								</span>

								<div styleName="icone-sugestao">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>
						</div>
					) : data.erro_motivo === 'SIENGE_QUOTATION_ITEM_SUPPLIER_ID_NOT_CREATED' ? (
						<div styleName="erro-exportacao">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="container-mensagens-erro">
								<span styleName="mensagem-erro">
									Não foi possível criar o fornecedor na cotação no Sienge
								</span>
								<strong styleName="frase_solucao_erro">Ações que podem te ajudar a resolver isso:</strong>
								<span styleName="mensagem-erro-2">
									<strong styleName="numero-negrito">1:</strong> O Conaz iniciou a exportação, conseguiu criar uma cotação no Sienge com itens, mas algo impediu a criação do fornecedor. Verifique se esse fornecedor está na sua lista de fornecedores no Sienge e se foi associado corretamente na compra no Conaz.
								</span>
								<span styleName="mensagem-erro-3">
									<strong styleName="numero-negrito">2:</strong> Depois disso, tente exportar a compra novamente.
								</span>

								<div styleName="icone-sugestao">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>
						</div>
					) : data.erro_motivo === 'DUPLICATED_SIENGE_MATERIAL' && data.erro_descricao ? (
						<div styleName="erro-exportacao">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="container-mensagens-erro">
								<span styleName="mensagem-erro">
									O item {data.erro_descricao.resource_item.quotation.product_id} {data.erro_descricao.resource_item.quotation.detail_id ? `(ID detalhe ${data.erro_descricao.resource_item.quotation.detail_id})` : ''} do Sienge está duplicado. O Sienge não permite exportar itens duplicados.
								</span>
								<strong styleName="frase_solucao_erro">Ações que podem te ajudar a resolver isso:</strong>
								<span styleName="mensagem-erro-2">
									<strong styleName="numero-negrito">1:</strong> Verifique na sua lista quais são os itens duplicados.
								</span>
								<span styleName="mensagem-erro-3">
									<strong styleName="numero-negrito">2:</strong> Se os itens são realmente iguais, some as quantidades e exclua os itens duplicados.
								</span>
								<span styleName="mensagem-erro-2">
									<strong styleName="numero-negrito">3:</strong> Se os itens são diferentes, altere o código ou ID Detalhe dos itens duplicados.
								</span>
								<span styleName="mensagem-erro-3">
									<strong styleName="numero-negrito">4:</strong> Tente exportar novamente.
								</span>

								<div styleName="icone-sugestao">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>
						</div>
					) : data.erro_motivo === 'SIENGE_NEGOTIATION_NOT_CREATED' ? (
						<div styleName="erro-exportacao">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="container-mensagens-erro">
								<span styleName="mensagem-erro">
									Não foi possível criar uma negociação no Sienge.
								</span>
								<strong styleName="frase_solucao_erro">Ações que podem te ajudar a resolver isso:</strong>
								<span styleName="mensagem-erro-2">
									<strong styleName="numero-negrito">1:</strong> O Conaz iniciou a exportação, mas algo impediu a criação da negociação no Sienge. Aguarde 15 minutos e tente exportar a compra novamente.
								</span>

								<div styleName="icone-sugestao">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>
						</div>
					) : data.erro_motivo === 'SIENGE_MATERIAL_NOT_FOUND' && data.erro_descricao ? (
						<div styleName="erro-exportacao">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="container-mensagens-erro">
								<span styleName="mensagem-erro">
									Não foi possível encontrar o item {data.erro_descricao.resource_id}
								</span>
								<strong styleName="frase_solucao_erro">Ações que podem te ajudar a resolver isso:</strong>
								<span styleName="mensagem-erro-2">
									<strong styleName="numero-negrito">1:</strong> Verifique se o código do insumo realmente existe na sua tabela de insumos do Sienge.
								</span>
								<span styleName="mensagem-erro-3">
									<strong styleName="numero-negrito">2:</strong> Caso exista, atualize sua tabela de insumos no Sienge e posteriormente no Conaz. Depois, tente exportar a compra novamente.
								</span>

								<div styleName="icone-sugestao">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>
						</div>
					) : data.erro_motivo === 'PURCHASE_REQUEST_NOT_FOUND' ? (
						<div styleName="erro-exportacao">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="container-mensagens-erro">
								<span styleName="mensagem-erro">
									Não foi possível encontrar esta solicitação de compra no Sienge
								</span>
								<strong styleName="frase_solucao_erro">Ações que podem te ajudar a resolver isso:</strong>
								<span styleName="mensagem-erro-2">
									<strong styleName="numero-negrito">1:</strong> Verifique se a solicitação de compra importada ainda existe no seu Sienge.
								</span>
								<span styleName="mensagem-erro-3">
									<strong styleName="numero-negrito">2:</strong> Se não existir mais, crie uma solicitação de compra igual à importada anteriormente e tente exportar a compra novamente.
								</span>

								<div styleName="icone-sugestao">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>
						</div>
					) : data.erro_motivo === 'PURCHASE_ITEM_NOT_FOUND_IN_REQUEST' ? (
						<div styleName="erro-exportacao">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="container-mensagens-erro-simples">
								<span styleName="mensagem-erro-simples">
									Não foi possível encontrar um ou mais itens na solicitação.
								</span>

								<div styleName="icone-mensagem-sienge">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>
						</div>
					) : data.erro_motivo === 'PURCHASE_ITEM_DETAIL_ID_NOT_MATCHING_REQUEST' ? (
						<div styleName="erro-exportacao">
							<div styleName="container-mensagens-erro">
								<div styleName="mensagem-erro-fixa">
									<MensagemErro />
								</div>

								<span styleName="mensagem-erro">
									O ID detalhe da solicitação de compra é incompatível ao que está tentando exportar
								</span>
								<strong styleName="frase_solucao_erro">Ações que podem te ajudar a resolver isso:</strong>
								<span styleName="mensagem-erro-2">
									<strong styleName="numero-negrito">1:</strong> Verifique se o ID detalhe do insumo realmente existe na sua tabela de insumos do Sienge.
								</span>
								<span styleName="mensagem-erro-3">
									<strong styleName="numero-negrito">2:</strong> Caso exista, atualize sua tabela de insumos no Sienge e posteriormente no Conaz. Depois, tente exportar a compra novamente.
								</span>
							</div>
							<div styleName="icone-sugestao">
								<span styleName="mensagem-contato">
									<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
									Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
								</span>
							</div>
						</div>
					) : data.erro_motivo === 'PURCHASE_ITEM_QUANTITY_EXCEEDS_REQUEST' ? (
						<div styleName="erro-exportacao">
							<div styleName="mensagem-erro-fixa">
								<MensagemErro />
							</div>
							<div styleName="container-mensagens-erro">
								<span styleName="mensagem-erro">
									O ID detalhe da solicitação de compra é incompatível ao que está tentando exportar
								</span>
								<strong styleName="frase_solucao_erro">Ações que podem te ajudar a resolver isso:</strong>
								<span styleName="mensagem-erro-2">
									<strong styleName="numero-negrito">1:</strong> Verifique se o ID detalhe do insumo realmente existe na sua tabela de insumos do Sienge.
								</span>
								<span styleName="mensagem-erro-3">
									<strong styleName="numero-negrito">2:</strong> Caso exista, atualize sua tabela de insumos no Sienge e posteriormente no Conaz. Depois, tente exportar a compra novamente.
								</span>

								<div styleName="icone-sugestao">
									<span styleName="mensagem-contato">
										<img styleName="icone-sugestao-contato" src={IconeSugestao} alt="Sugestão entre em contato" />
										Em caso de dúvidas ou se o problema persistir, entre em contato com <a styleName="link_suporte" href="https://conaz.zendesk.com/hc/pt-br/articles/115000459694-Como-entrar-em-contato-com-o-Suporte-da-Conaz-">nosso suporte.</a>
									</span>
								</div>
							</div>
						</div>
					) : ''
		);
	}

	if (!data) {
		return (
			<></>
		);
	}
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import axios from 'axios';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import FornecedoresSearchResultList from './FornecedoresSearchResultList';

// Functions
import { debounce } from '../../_functions/_debounce';
import { getLocalstorageData } from '../../_functions/_getLocalstorageData';
import { _get } from '../../_functions/_requests';
import { searchTypeFornecedores } from '../../_functions/_searchTypeFornecedores';
import { handleRequestErrors } from '../../_functions/_handleRequestErrors';
import * as constants from './constants';

class FornecedoresSearchBar extends Component {
	handleDebounce = debounce(() => {
		const { search } = this.state;
		if (search.length > 0) {
			this.searchFornecedores(search).then(response => {
				const fornecedores_result = response.data || [];
				this.setState({ fornecedores_result, search_state: 2 });
			}).catch(error => {
				if (error.message !== 'canceled_request') {
					this.setState({ search_state: 3 });
					handleRequestErrors(error);
				}
			});
		}
	}, 800);

	constructor() {
		super();
		this.state = {
			search_state: 0,
			search: '',
			search_type: 'nome',
			fornecedores_result: [],
			columns_height: 0,
			search_fornecedor_state: 0,
		};
		this.handleSearch = this.handleSearch.bind(this);
		this.clearSearch = this.clearSearch.bind(this);
		this.blurSearch = this.blurSearch.bind(this);
		this.escClose = this.escClose.bind(this);
		this.handleDimensions = this.handleDimensions.bind(this);
		this.adicionaFornecedor = this.adicionaFornecedor.bind(this);
		this.adicionaFornecedorNaoCadastrado = this.adicionaFornecedorNaoCadastrado.bind(this);
		this.handleDebounce = this.handleDebounce.bind(this);
	}

	componentDidMount() {
		window.addEventListener('keyup', this.escClose);
		window.addEventListener('resize', this.handleDimensions);
		this.handleDimensions();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.search_fornecedores_open !== this.props.search_fornecedores_open) {
			this.setState({ search: '', search_state: 0, fornecedores_result: [] });
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (!shallowequal(this.state, nextState)) return true;
		if (!shallowequal(this.props, nextProps)) return true;
		return false;
	}

	componentWillUnmount() {
		window.removeEventListener('keyup', this.escClose);
		window.removeEventListener('resize', this.handleDimensions);
	}

	async searchFornecedores(typed) {
		try {
			const { cliente_id_cadastro } = this.props;
			const base_url = getLocalstorageData('environment', 'base_url');
			const token = getLocalstorageData('user', 'token');
			const pre_search_params = searchTypeFornecedores(typed);
			const typed_encode = encodeURIComponent(pre_search_params[1])
				.replace(/%0A/g, '');
			this.setState({ search_type: pre_search_params[0] });
			const query_cadastrado_por = constants.INCLUIR_CADASTRADO_POR_NA_BUSCA
				? `&cadastrado_por=${cliente_id_cadastro}`
				: '';
			const search_url = `${base_url}/conaz/v2/fornecedores/buscar_por_nome?nome=${typed_encode}${query_cadastrado_por}`;

			axios.defaults.headers.common.token = token;
			const { CancelToken } = axios;
			return await axios({
				method: 'get',
				url: search_url,
				cancelToken: new CancelToken(c => {
					this.cancel = c;
					return c;
				}),
			});
		} catch (err) {
			throw err;
		}
	}

	handleDimensions() {
		this.setState({ columns_height: window.innerHeight - 98 }); // 144
	}

	escClose(e) {
		if (e.keyCode === 27) this.blurSearch();
	}

	clearSearch() {
		this.setState({ search: '' });
		this.props.updateUi(['search_fornecedores_open'], false);
	}

	blurSearch() {
		document.getElementById('fornecedores_search_input').blur();
		this.clearSearch();
	}

	handleSearch(e) {
		const search = e.target.value.toString();
		if (this.cancel !== undefined) this.cancel('canceled_request');
		if (search.trim().length > 0) {
			this.setState({ search, search_state: 1 }, this.handleDebounce);
		} else {
			this.setState({ search, search_state: 0 });
		}
	}

	adicionaFornecedor(fornecedor) {
		this.setState({ search_fornecedor_state: 1 });
		const url_get = `/conaz/v2/fornecedores/${fornecedor.id}/vendedores_ativos`;
		_get(url_get).then(response => {
			this.adicionaFornecedorAfterGet(response.data);
		}).catch(error => {
			this.props.updateModals('error', true);
			handleRequestErrors(error);
		});
	}

	adicionaFornecedorAfterGet(fornecedor) {
		const { ocultar_vendedores_nao_cadastrados_pelo_construtor, cliente_id_real } = this.props;
		let vendedor_selecionado = {};
		let sem_preferencia_vendedor = true;
		_get(`/conaz/v2/usuarios_fornecedor/ultima_cotacao_cliente?fornecedor_id=${fornecedor.id}&cliente_id=${this.props.cliente_id_real}`)
			.then(response => {
				const { id, status, cadastrado_por } = response.data;
				if (id && fornecedor.usuarios.filter(fuf => fuf.usuario.id === id).length === 1) {
					if (ocultar_vendedores_nao_cadastrados_pelo_construtor) {
						if (status >= 0 || (cadastrado_por !== null && cliente_id_real.toString() === cadastrado_por.toString())) {
							vendedor_selecionado = response.data;
							sem_preferencia_vendedor = false;
						}
					} else {
						vendedor_selecionado = response.data;
						sem_preferencia_vendedor = false;
					}
				}
			})
			.catch(() => {})
			.then(() => {
				this.props.updateState('', 'form_novo_fornecedor', 'false');
				const vendedores = fornecedor.usuarios.map(u => ({
					...u.usuario,
					locais_de_entrega: u.locais_de_entrega,
					grupos: u.grupos,
					status_fornecedor_vendedor: u.status,
				}));
				const new_fornecedor = {
					...fornecedor,
					vendedores,
					vendedor_selecionado,
					sem_preferencia_vendedor,
					preenchimento_fornecedor: true,
				};
				this.props.updateState('', 'fornecedor_pre_selecionado', new_fornecedor);
				this.props.updateModals('fornecedor', true);
				this.setState({ search_fornecedor_state: 0 });
			});
	}

	adicionaFornecedorNaoCadastrado(search) {
		this.props.updateState('', 'fornecedor_pre_selecionado', {});
		this.props.updateState('', 'pre_nome_fornecedor', search);
		this.props.updateState('', 'form_novo_fornecedor', true);
		this.props.updateUi(['search_fornecedores_open'], false);
		this.props.updateModals('fornecedor', true);
	}

	render() {
		const {
			search,
			search_state,
			search_type,
			fornecedores_result,
			columns_height,
			search_fornecedor_state,
		} = this.state;
		const {
			search_fornecedores_open,
			fornecedores_all_ids,
			populares_fornecedores_all_ids,
			populares_fornecedores_by_id,
			request_state_populares,
			cliente_id_cadastro,
		} = this.props;
		const wrapper_class = search_fornecedores_open ? 'fornecedores-search-bar in' : 'fornecedores-search-bar';

		return (
			<div styleName={wrapper_class}>
				<div styleName="search-bar">
					<div styleName="input-wrapper">
						<i className="fa fa-search" aria-hidden="true" />
						<button
							type="button"
							className="close"
							styleName="clear-button-2"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.blurSearch}>
							<i className="fa fa-angle-left" aria-hidden="true" />
						</button>
						<button
							type="button"
							className="close"
							styleName="clear-button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.clearSearch}>
							<span aria-hidden="true">&times;</span>
						</button>
						<input
							id="fornecedores_search_input"
							type="text"
							placeholder="Buscar fornecedor por nome ou email"
							value={search}
							onChange={this.handleSearch}
							onKeyUp={this.escClose}
						/>
					</div>
				</div>

				<FornecedoresSearchResultList
					columns_height={columns_height}
					search_fornecedor_state={search_fornecedor_state}
					search_state={search_state}
					request_state_populares={request_state_populares}
					populares_fornecedores_all_ids={populares_fornecedores_all_ids}
					populares_fornecedores_by_id={populares_fornecedores_by_id}
					fornecedores_all_ids={fornecedores_all_ids}
					fornecedores_result={fornecedores_result}
					cliente_id_cadastro={cliente_id_cadastro}
					adicionaFornecedor={this.adicionaFornecedor}
					adicionaFornecedorNaoCadastrado={this.adicionaFornecedorNaoCadastrado}
					search={search}
					search_type={search_type}
				/>

				{(search_state === 2 && fornecedores_result.length === 0) && (
					<div styleName="search-results in">
						<div styleName="no-result-wrapper">
							<h3>{search}</h3>
							<p>Não encontramos nenhum resultado no nosso banco de dados.</p>
							<p>
								<button
									type="button"
									className="btn btn-secondary-conaz round-border"
									onClick={() => this.adicionaFornecedorNaoCadastrado(search)}>
									Cadastrar novo fornecedor
								</button>
							</p>
						</div>
					</div>
				)}
			</div>
		);
	}
}

FornecedoresSearchBar.propTypes = {
	updateState: PropTypes.func.isRequired,
	populares_fornecedores_by_id: PropTypes.object.isRequired,
	populares_fornecedores_all_ids: PropTypes.array.isRequired,
	updateUi: PropTypes.func.isRequired,
	search_fornecedores_open: PropTypes.bool.isRequired,
	updateModals: PropTypes.func.isRequired,
	request_state_populares: PropTypes.number.isRequired,
	fornecedores_all_ids: PropTypes.array.isRequired,
	cliente_id_real: PropTypes.number.isRequired,
	cliente_id_cadastro: PropTypes.number.isRequired,
	ocultar_vendedores_nao_cadastrados_pelo_construtor: PropTypes.bool.isRequired,
};

export default CSSModules(FornecedoresSearchBar, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const CompraRowAvaliacaoButton = ({
	handleEvaluation,
	location,
}) => {
	const button_class = location === 'compra'
		? 'relative-button'
		: 'absolute-button';

	return (
		<button
			type="button"
			className="btn btn-primary-conaz round-border"
			styleName={button_class}
			onClick={handleEvaluation}
		>
			Avaliar entrega
		</button>
	);
};

CompraRowAvaliacaoButton.propTypes = {
	handleEvaluation: PropTypes.func.isRequired,
	location: PropTypes.string.isRequired,
};

export default CSSModules(CompraRowAvaliacaoButton, styles, { allowMultiple: true });

import { fork, all } from 'redux-saga/effects';

import comprasSaga from './compras/sagas';
import avaliacoesSaga from './avaliacoes/sagas';
import cotacoesSaga from './cotacoes/sagas';
import userSaga from './user/sagas';
import exportStatusSaga from './exportStatus/saga';

export default function* rootSaga() {
	yield all([
		fork(comprasSaga),
		fork(avaliacoesSaga),
		fork(cotacoesSaga),
		fork(userSaga),
		fork(exportStatusSaga),
	]);
}

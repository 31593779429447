import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingPage from '../../_loadings/LoadingPage/LoadingPage';
import FornecedorReview from './FornecedorReview';
import VendedorRow from './VendedorRow';
import TabsFornecedor from '../../TabsFornecedor/TabsFornecedor';
import TabFornecedor from '../../TabsFornecedor/TabFornecedor';
import TabAvaliacoes from './TabAvaliacoes';
import TabInformacoes from './TabInformacoes';

// Functions
import { _get } from '../../../_functions/_requests';
import { handleRequestErrors } from '../../../_functions/_handleRequestErrors';
import * as constants from './constants';

class LeftModalFornecedorPerfil extends Component {
	constructor() {
		super();
		this.state = {
			selected_tab: 1,
			request_state: false,
			fornecedor: {},
			vendedor_selecionado: {
				id: 0,
			},
		};
	}

	UNSAFE_componentWillMount() {
		this.requestFornecedorFromVendedor();
		const { vendedor_selecionado } = this.props.perfil_fornecedor;
		if (
			vendedor_selecionado.id !== 0
			&& vendedor_selecionado.id !== undefined
			&& vendedor_selecionado.id !== undefined
		) {
			this.setState({ vendedor_selecionado });
		}
	}

	requestFornecedorFromVendedor() {
		const { perfil_fornecedor } = this.props;
		this.setState({ request_state: 1 });
		const fornecedor_id = perfil_fornecedor.vendedor_id;
		_get(`/conaz/v2/fornecedores/${fornecedor_id}`).then(response => {
			const response_status = (response || {}).status;
			const fornecedor = { ...response.data };
			const request_state = (fornecedor.status === -20 || response_status === 404)
				? 4
				: 2;
			this.setState({ fornecedor, request_state });
		}).catch(error => {
			this.setState({ request_state: 3 });
			handleRequestErrors(error);
		});
	}

	changeTab = (tab = 1) => {
		this.setState({ selected_tab: tab });
	}

	render() {
		const {
			selected_tab,
			request_state,
			fornecedor,
			vendedor_selecionado,
		} = this.state;
		const fornecedor_nome = (fornecedor || {}).nome || 'Este fornecedor';

		switch (request_state) {
		/* ========================================================================== *\
				Loading
		\* ========================================================================== */
		default:
		case 0:
		case 1:
			return <LoadingPage />;

		/* ========================================================================== *\
				Error
		\* ========================================================================== */
		case 3:
			return (
				<div styleName="error-wrapper">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.props.updateModals('fornecedor', false)}>
						<span aria-hidden="true">&times;</span>
					</button>
					<i className="fa fa-exclamation-circle" aria-hidden="true" />
					<h3>Desculpe, tivemos um problema!</h3>
					<p>Atualize a página apertando F5 para tentar novamente e/ou entre em contato com nossa equipe de suporte.</p>
				</div>
			);

		/* ========================================================================== *\
				Fornecedor não cadastrado (status -3)
		\* ========================================================================== */
		case 4:
			return (
				<div styleName="error-wrapper">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.props.updateModals('fornecedor', false)}>
						<span aria-hidden="true">&times;</span>
					</button>
					<img src="/images/company_placeholder.png" width="100" alt="" />
					<h3>{fornecedor_nome} ainda não está cadastrado</h3>
					<p>Este fornecedor ainda não realizou o cadastro, ou realizou com outro nome/email.</p>
				</div>
			);

		/* ========================================================================== *\
				Ok
		\* ========================================================================== */
		case 2: {
			const vendedores = fornecedor.usuarios.map(u => u.usuario);

			return (
				<div styleName="modal-content">
					<div styleName="content">
						<button
							type="button"
							className="close"
							styleName="close-button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => this.props.updateModals('fornecedor', false)}>
							<span aria-hidden="true">&times;</span>
						</button>
						<FornecedorReview
							fornecedor={fornecedor}
							observacoes_fornecedor={fornecedor.observacoes}
							exibir_status={constants.EXIBIR_STATUS_FORNECEDOR}
							inclui_link_edicao={constants.INCLUI_LINK_EDICAO}
							exibir_observacoes={constants.EXIBIR_OBSERVACOES}
							exibir_cadastrado_por={constants.EXIBIR_CADASTRADO_POR}
						/>

						<TabsFornecedor>
							<TabFornecedor
								tab_number={1}
								title="Vendedores"
								description="Selecione o vendedor de sua preferência"
								selected_tab={selected_tab}
								changeTab={this.changeTab}
							/>
							<TabFornecedor
								tab_number={2}
								title="Avaliações"
								description="Veja como você e outras construtoras avaliaram o fornecedor"
								selected_tab={selected_tab}
								changeTab={this.changeTab}
							/>
							<TabFornecedor
								tab_number={3}
								title="Informações gerais"
								description="Verifique razão social, CNPJ e endereço "
								selected_tab={selected_tab}
								changeTab={this.changeTab}
							/>
						</TabsFornecedor>

						<div styleName="tabs-bottom-wrapper">
							{selected_tab === 1 && (
								<>
									{fornecedor.usuarios.length > 0 && (
										<h4>Vendedores</h4>
									)}
									{fornecedor.usuarios.filter(v => v.nome !== null && v.nome !== '').map(v => (
										<VendedorRow
											key={v.usuario.id}
											vendedor={v.usuario}
											estah_selecionado={v.usuario.id === vendedor_selecionado.id}
										/>
									))}
								</>
							)}
							{selected_tab === 2 && (
								<TabAvaliacoes
									vendedores={vendedores}
								/>
							)}
							{selected_tab === 3 && (
								<TabInformacoes
									fornecedor={fornecedor}
									cliente_id={this.props.cliente_id}
									observacoes_fornecedor={this.state.observacoes_fornecedor}
									exibir_observacoes={constants.EXIBIR_OBSERVACOES}
									exibir_status={constants.EXIBIR_STATUS_FORNECEDOR}
									exibir_cadastrado_por={constants.EXIBIR_CADASTRADO_POR}
								/>
							)}
						</div>
					</div>
				</div>
			);
		}
		}
	}
}

LeftModalFornecedorPerfil.propTypes = {
	perfil_fornecedor: PropTypes.object.isRequired,
	updateModals: PropTypes.func,
	cliente_id: PropTypes.number.isRequired,
};

LeftModalFornecedorPerfil.defaultProps = {
	updateModals: () => {},
};

export default CSSModules(LeftModalFornecedorPerfil, styles, { allowMultiple: true });

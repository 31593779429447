import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { handleRequestErrors } from '../../../components/_functions/_handleRequestErrors';
import { _post } from '../../../components/_functions/_requests';

class DescancelarCotacao extends Component {
	constructor(props) {
		super(props);
		this.state = {
			descancelando: 0,
		};
		this.descancelarCotacao = this.descancelarCotacao.bind(this);
	}

	descancelarCotacao() {
		this.setState({ descancelando: 1 });
		const { handleInsideConfirm, match, updateUi, updateModals, reloadPageChangeStatus } = this.props;
		handleInsideConfirm();
		_post(`/conaz/v2/pedidos/${match.params.id}/descancelar`, {}).then(response => {
			updateUi(['changes', 'cotacoes'], true);
			reloadPageChangeStatus(response.data.codigo);
		}).catch(error => {
			updateModals('error', true);
			handleRequestErrors(error);
		});
	}

	render() {
		const { descancelando } = this.state;
		const { cancelado, inside_confirm } = this.props;

		if (!inside_confirm && cancelado && (descancelando === 0 || descancelando === 2)) {
			return (
				<button
					type="button"
					data-acoes-pedido-menu
					onClick={this.descancelarCotacao}>
					<i className="fa fa-reply fa-fw" aria-hidden="true" data-acoes-pedido-menu /> Descancelar cotação
				</button>
			);
		}

		if (inside_confirm && cancelado && descancelando >= 1) {
			return (
				<p style={{ margin: '10px 14px' }} data-acoes-pedido-menu>Descancelando cotação...</p>
			);
		}

		return null;
	}
}

DescancelarCotacao.propTypes = {
	cancelado: PropTypes.bool.isRequired,
	match: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	inside_confirm: PropTypes.bool.isRequired,
	handleInsideConfirm: PropTypes.func.isRequired,
	reloadPageChangeStatus: PropTypes.func.isRequired,
};

export default CSSModules(DescancelarCotacao, styles, { allowMultiple: true });

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import LoadingButton from '../../../../components/_parts/_loadings/LoadingButton/LoadingButton';

const ModalImpressao = ({ closeModal, compra, items }) => {
	const {
		id,
		titulo,
		fornecedor,
		usuario_fornecedor,
		condicao_de_pagamento,
		transporte,
		tipo,
		data_de_entrega,
	} = compra;

	const [rs, setRS] = useState(0);
	const [err, setErr] = useState('');

	const validation = () => {
		if (titulo === '' || titulo === null || titulo === undefined) {
			setRS(3);
			setErr('Título da compra inválido!');
			return false;
		}

		if (items.all_ids.length === 0) {
			setRS(3);
			setErr('Nenhum item registrado na compra!');
			return false;
		}


		const check_items = items.all_ids.map(item => {
			const {
				quantidade,
				unidade,
				preco_unitario,
				item_preenchido,
				item_de_resposta,
				excluido,
			} = items.by_id[item];

			if (!item_preenchido.item) return 'Ocorreu um problema com os items, favor verifica-los!';

			const { nome: item_nome } = item_preenchido.item;

			if ((item_de_resposta || {}).status !== -1 || !excluido) {
				if (quantidade === 0 || quantidade === null || quantidade === undefined || quantidade === '') return `Quantidade inválida no item ${item_nome}`;
				if (unidade === '' || unidade === undefined || unidade === null) return `Unidade inválida no item ${item_nome}`;
				if (!preco_unitario) return `Preço inválido no item ${item_nome}`;

				const opcoes = item_preenchido.caracteristicas_preenchidas_all_ids
					.map(carac_id => {
						const {
							caracteristica,
							opcoes_preenchidas,
						} = item_preenchido.caracteristicas_preenchidas[carac_id];

						if (
							(caracteristica.visao_fornecedor === 2 && opcoes_preenchidas.length > 0)
							|| caracteristica.visao_fornecedor !== 2
						) return true;
						return null;
					});

				if (opcoes.find(opcao => opcao === null) === null) return `Caracteristicas inválidas no item ${item_nome}`;
			}

			return '';
		});

		const item_err = check_items.find(item => item !== '');

		if (item_err) {
			setRS(3);
			setErr(item_err);
			return false;
		}

		if (!fornecedor.id) {
			setRS(3);
			setErr('Fornecedor inválido!');
			return false;
		}

		if (!usuario_fornecedor.id) {
			setRS(3);
			setErr('Vendedor inválido!');
			return false;
		}

		if (!condicao_de_pagamento.forma || condicao_de_pagamento.forma === 0) {
			setRS(3);
			setErr('Forma de pagamento inválida!');
			return false;
		}

		if (!condicao_de_pagamento.prazo || condicao_de_pagamento.prazo === 0) {
			setRS(3);
			setErr('Prazo do pagamento inválido!');
			return false;
		}

		if (transporte === null || transporte === '' || transporte === undefined) {
			setRS(3);
			setErr('Registro de transporte inválido!');
			return false;
		}

		if (!tipo || (tipo < 4 && tipo > 6)) {
			setRS(3);
			setErr('Tipo da compra inválido!');
			return false;
		}

		if (!data_de_entrega) {
			setRS(3);
			setErr('Data de entrega inválida!');
			return false;
		}
		return true;
	};

	useEffect(() => {
		if (rs === 1 && validation()) setRS(2);
		else if (rs === 2) window.open(`/compra/${id}/impressao`);
	}, [rs]);

	const handleImpressao = () => {
		setErr('');
		setRS(1);
	};

	return (
		<div className="modal-content" styleName="modal-content">
			<div className="modal-body" styleName="modal-body">
				<button
					type="button"
					className="close"
					styleName="close-button"
					data-dismiss="modal"
					aria-label="Close"
					onClick={closeModal}>
					<span aria-hidden="true">&times;</span>
				</button>
				<div styleName="icon" />
				<div styleName="content">
					<h3>Imprimir Compra</h3>
					{err && (
						<div styleName="err">
							<i className="fa fa-exclamation-triangle" />
							{` ${err}`}
						</div>
					)}
					<button
						className="btn btn-secondary-conaz round-border"
						rel="noopener noreferrer"
						target="_blank"
						onClick={handleImpressao}>
						{rs !== 1 ? 'Gerar página para impressão' : <LoadingButton />}
					</button>
				</div>
			</div>
		</div>
	);
};

ModalImpressao.propTypes = {
	closeModal: PropTypes.func.isRequired,
	compra: PropTypes.object.isRequired,
	items: PropTypes.object.isRequired,
};

export default CSSModules(ModalImpressao, styles, { allowMultiple: true });

import { bindActionCreators } from 'redux';
import * as comprasActions from './compras/actions';
import * as avaliacoesActions from './avaliacoes/actions';
import * as cotacaoActions from './cotacao/actions';
import * as cotacoesActions from './cotacoes/actions';
import * as itensActions from './itens/actions';
import * as qcActions from './qc/actions';
import * as uiActions from './ui/actions';
import * as userActions from './user/actions';
import * as exportStatusActions from './exportStatus/actions';

const actions = {
	...comprasActions,
	...avaliacoesActions,
	...cotacaoActions,
	...cotacoesActions,
	...itensActions,
	...qcActions,
	...uiActions,
	...userActions,
	...exportStatusActions,
};

export const buildMapStateToProps = (props, fields = null) => {
	if (fields === null) {
		return ({
			compras: props.compras,
			avaliacoes: props.avaliacoes,
			cotacao: props.cotacao,
			cotacoes: props.cotacoes,
			itens: props.itens,
			qc: props.qc,
			ui: props.ui,
			user: props.user,
			exportStatus: props.exportStatus,
		});
	}

	return fields.reduce((result, current) => ({
		...result,
		[current]: { ...props[current] },
	}), {});
};

export const buildmapDispatchToProps = dispatch => (
	bindActionCreators(actions, dispatch)
);

export const detailedmapDispatchToProps = (dispatch, funcs) => {
	const only_funcs = Object.keys(actions)
		.filter(a => funcs.filter(f => f === a).length > 0)
		.reduce((result, current) => ({
			...result,
			[current]: actions[current],
		}), {});

	return {
		dispatch,
		...bindActionCreators(only_funcs, dispatch),
	};
};

/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Loading from '../../components/_parts/_loadings/Loading/Loading';

// Functions
import { _get } from '../../components/_functions/_requests';

class TabItens extends Component {
	constructor() {
		super();
		this.state = {
			loading_itens: 2,
		};
		this.loadItens = this.loadItens.bind(this);
	}

	UNSAFE_componentWillMount() {
		const { itens_de_pedido } = this.props;
		if (itens_de_pedido.length === 0 || itens_de_pedido[0].id === undefined) this.loadItens();
	}

	loadItens() {
		const { id, updateCotacao } = this.props;
		this.setState({ loading_itens: 1 });
		_get(`/conaz/v2/pedidos/${id}?fields=itens_de_pedido`).then(response => {
			const itens_de_pedido = response.data.itens_de_pedido.sort((a, b) => a.ordem - b.ordem);
			updateCotacao(['cotacao_view', 'itens_de_pedido'], itens_de_pedido);
			this.setState({ loading_itens: 2 });
		}).catch(() => {
			this.setState({ loading_itens: 3 });
		});
	}

	render() {
		const { loading_itens } = this.state;
		const { itens_de_pedido } = this.props;

		if (loading_itens === 0 || loading_itens === 1) {
			return (
				<div styleName="content">
					<div className="row">
						<div className="col-sm-12" style={{ paddingBottom: '67px' }}>
							<Loading />
						</div>
					</div>
				</div>
			);
		}

		if (loading_itens === 3) {
			return (
				<div styleName="content">
					<div className="row">
						<div className="col-sm-12" style={{ paddingBottom: '30px' }}>
							Erro ao carregar os itens. <button type="button" className="btn btn-link" style={{ padding: '7px 0 9px' }} onClick={this.loadItens}>Clique aqui</button> para tentar novamente.
						</div>
					</div>
				</div>
			);
		}

		return (
			<div styleName="content">
				<div className="row">
					<div className="col-sm-12">
						{itens_de_pedido.map((item, i) => {
							const { id, item_preenchido, cliente_associou, pre_nome, quantidade, pre_quantidade,
								unidade, pre_unidade, pre_detalhe } = item;
							const _nome = !cliente_associou ? pre_nome : item_preenchido.item.nome;
							const _quantidade = !cliente_associou ? pre_quantidade : quantidade;
							const _unidade = !cliente_associou ? pre_unidade : unidade;
							const _observacoes = !cliente_associou ? pre_detalhe : item.observacoes;

							const c_ordenadas = (item_preenchido.caracteristicas_preenchidas || [])
								.filter(c => c.caracteristica.visao_construtor !== 0)
								.sort((a, b) => a.caracteristica.ordem - b.caracteristica.ordem);

							return (
								<div key={id} styleName="item-row">
									<div styleName="number">{i + 1}</div>
									<div styleName="name">{_nome}</div>
									<div styleName="properties">
										<div styleName="label">
											Quantidade
											<p>{_quantidade.toString().replace('.', ',')} {_unidade}</p>
										</div>
										{c_ordenadas.map((c, i2) => {
											const { nome } = c.caracteristica;
											const valor = c.opcoes_preenchidas.map(op => ((op.unidade !== '' && op.unidade !== null && op.unidade !== undefined) ? `${op.valor} ${op.unidade}` : op.valor)).join(', ');
											return valor !== '' ? <div key={i2} styleName="label">{nome}<p>{valor}</p></div> : false;
										})}
										{_observacoes !== '' && (
											<div styleName="label">
												Observações
												<p>{_observacoes}</p>
											</div>
										)}
										{item.observacoes_internas !== '' && (
											<div styleName="label">
												Observações internas
												<p>{item.observacoes_internas}</p>
											</div>
										)}
									</div>
									<div styleName="division" />
								</div>
							);
						})}
						<div styleName="hide-last-division" />
					</div>
				</div>
			</div>
		);
	}
}

TabItens.propTypes = {
	itens_de_pedido: PropTypes.array,
	id: PropTypes.number.isRequired,
	updateCotacao: PropTypes.func.isRequired,
};

TabItens.defaultProps = {
	itens_de_pedido: [],
};

export default CSSModules(TabItens, styles, { allowMultiple: true });

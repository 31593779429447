// Functions
import { caracteristicaType } from '../../../_functions/_caracteristicaType';

const checkOutro = (caracteristica_preenchida) => {
	const {
		opcoes_preenchidas,
	} = caracteristica_preenchida;
	const {
		opcoes,
		selecao,
		tipo_de_valor,
	} = caracteristica_preenchida.caracteristica;
	const c_type = caracteristicaType(opcoes, selecao, tipo_de_valor);
	const validada = opcoes_preenchidas.length > 0
		? opcoes_preenchidas[0].validada
		: null;
	const valor = opcoes_preenchidas.length > 0
		? opcoes_preenchidas[0].valor
		: '';
	if (c_type === 'selecao' && !validada && valor !== '') {
		// this.setState({ outro: true });
		return true;
	}
	const unidade = opcoes_preenchidas.length > 0
		? opcoes_preenchidas[0].unidade
		: '';
	if (
		unidade !== ''
		&& opcoes.filter(x => x.unidade === unidade).length === 0
	) {
		// this.setState({ outra_unidade: true });
		return true;
	}
	return false;
};

export const checkRespostaDiferente = (item, caracteristica_preenchida) => {
	const {
		opcoes_preenchidas,
	} = caracteristica_preenchida;
	const valor = opcoes_preenchidas.length > 0
		? opcoes_preenchidas[0].valor
		: '';
	const unidade = opcoes_preenchidas.length > 0
		? opcoes_preenchidas[0].unidade
		: '';
	const c_id = Number(caracteristica_preenchida.caracteristica.id);
	const carac_de_resposta = (((
		item.item_de_resposta || {})
		.item_preenchido || {})
		.caracteristicas_preenchidas || [])
		.filter(c => c.caracteristica.id === c_id)[0]
		|| {};
	const has_carac_de_resposta = carac_de_resposta.opcoes_preenchidas !== undefined;
	const valor_de_resposta = has_carac_de_resposta
		? (carac_de_resposta.opcoes_preenchidas[0] || {}).valor || ''
		: null;
	const un_de_resposta = has_carac_de_resposta
		? (carac_de_resposta.opcoes_preenchidas[0] || {}).unidade || ''
		: null;
	const resposta_eh_diferente = (
		valor_de_resposta !== valor
		|| un_de_resposta !== unidade
	);
	return resposta_eh_diferente;
};

export const checkOutroInItem = (item = {}) => {
	const { item_preenchido, unidade } = item;
	let has_outro = false;
	const caracs_ids = [];

	// Check outra unidade
	let outra_unidade = false;
	if (item_preenchido !== undefined && item_preenchido.item !== undefined) {
		const { unidades } = item_preenchido.item;
		// Verifica unidade de resposta
		const unidade_de_resposta = (item.item_de_resposta || {}).unidade || '';
		const resposta_eh_diferente = unidade_de_resposta !== unidade;
		if (
			unidades.filter(x => x === unidade).length === 0
			&& unidade !== ''
			&& resposta_eh_diferente
		) {
			outra_unidade = true;
			has_outro = true;
		}
	}

	// Check caracteristicas
	const cp = item_preenchido.caracteristicas_preenchidas || {};
	const cp_all_ids = item_preenchido.caracteristicas_preenchidas_all_ids || [];
	for (let i = 0; i < cp_all_ids.length; i++) {
		const caracteristica_preenchida = cp[cp_all_ids[i]];
		const has_outra_carac = checkOutro(caracteristica_preenchida);
		const resposta_eh_diferente = checkRespostaDiferente(item, caracteristica_preenchida);
		if (has_outra_carac && resposta_eh_diferente) {
			caracs_ids.push(i);
			has_outro = true;
		}
	}

	return {
		has_outro,
		outra_unidade,
		caracs_ids,
	};
};

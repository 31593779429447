import React, { Component } from 'react';

// Components
import LoadingPage from '../../components/_parts/_loadings/LoadingPage/LoadingPage';

const asyncComponent = importComponent => class extends Component {
	state = { component: null };

	componentDidMount() {
		importComponent().then(cmp => this.setState({ component: cmp.default }));
	}

	render() {
		const C = this.state.component;
		return C ? <C {...this.props} /> : (
			<div className="page-wrapper">
				<div className="left-menu-wrapper">
					<div className="menu-top">
						<img src="/images/conaz-simbolo_branco_small.png" width="44" alt="" />
					</div>
					<ul className="menu">
						<li className="li-new-buttons-wrapper">
							<button type="button" className="new-button" />
						</li>
						<li className="divider" />
						<li>
							<a href="/cotacoes">
								<svg x="0px" y="0px" viewBox="0 0 100 125" className="svg-cotacoes" style={{ enableBackground: 'new 0 0 100 100' }} xmlSpace="preserve" width="33" height="33" fill="#94a9c9"><g><path d="M83.5,23.1h-36L38,12H24.6c-4.8,0-8.6,3.9-8.6,8.7V25h-0.4C10.9,25,7,28.9,7,33.7v45.6c0,4.8,3.9,8.7,8.6,8.7h58.6   c4.8,0,8.6-3.9,8.6-8.7V75h0.4c4.8,0,8.6-3.9,8.6-8.7V31.9C91.9,27,88.1,23.1,83.5,23.1z M78.9,79.3c0,2.6-2.1,4.7-4.6,4.7H15.6   c-2.6,0-4.6-2.1-4.6-4.7V33.7c0-2.6,2.1-4.7,4.6-4.7h11.5l9.5,11.1h37.8c2.5,0,4.5,2.2,4.5,4.8V79.3z M87.9,66.3   c0,2.6-2.1,4.7-4.6,4.7h-0.4V44.9c0-4.9-3.8-8.8-8.5-8.8h-36L29,25h-9v-4.3c0-2.6,2.1-4.7,4.6-4.7h11.5l9.5,11.1h37.8   c2.5,0,4.5,2.2,4.5,4.8V66.3z" /></g></svg>
								Cotações
							</a>
						</li>
						<li className="divider" />
						<li>
							<a href="/compras">
								<svg width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="svg-compras"><rect x="1" y="4" width="22" height="16" rx="2" ry="2" /><line x1="1" y1="10" x2="23" y2="10" /></svg>
								Compras
							</a>
						</li>
						<li className="divider" />
					</ul>
				</div>
				<LoadingPage />
			</div>
		);
	}
};

export default asyncComponent;

export const setRowsHeightsQc = itens_analise => {
	/**
	 * Seta as alturas das linhas dos itens da direita iguais aos da esquerda
	 * Primeiro faz o loop pelos itens
	 */
	for (let i = 0; i < itens_analise.length; i++) {
		const order = i + 1;
		const item_left = document.getElementById(`item_left_${itens_analise[i].id}_${order}`);
		const btn_item_left = document.getElementById(`item_left_${itens_analise[i].id}_${order}_expand`);
		if (item_left !== undefined && item_left !== null) {
			/**
			 * Busca todos os itens nos rcs e salva a altura das linhas
			 */
			const itens_right = document.querySelectorAll(`[data-item-right="${itens_analise[i].id}"]`);
			const itens_right_heights = [];
			for (let ir = 0; ir < itens_right.length; ir++) {
				itens_right_heights.push(Math.ceil(itens_right[ir].offsetHeight) + 1);
			}
			/**
			 * Adiciona a altura do item da barra lateral esquerda
			 */
			itens_right_heights.push(Math.ceil(item_left.offsetHeight) + 1);
			/**
			 * Encontra a maior height
			 */
			const higher_height = Math.max(...itens_right_heights);
			/**
			 * Seta a maior altura do item da barra lateral esquerda
			 */
			item_left.style.minHeight = `${higher_height}px`;
			/**
			 * Seta a maior altura do botão para expandir item da barra lateral no mobile
			 */
			if (btn_item_left) {
				btn_item_left.style.minHeight = `${higher_height}px`;
			}
			/**
			 * Faz o loop nos rcs (itens da direita) e seta a maior altura
			 */
			for (let ir = 0; ir < itens_right.length; ir++) {
				itens_right[ir].style.minHeight = `${higher_height}px`;
			}
		}
	}
};

// const setRowsHeightsOld = (rcs_by_id, rcs_all_ids) => {
// 	const itens_pedido = rcs_by_id[rcs_all_ids[0]].pedido.itens_de_pedido.sort((a, b) => a.ordem - b.ordem);
// 	for (let i = 0; i < itens_pedido.length; i++) {
// 		const order = i + 1;
// 		const item_left = document.getElementById(`item_left_${itens_pedido[i].id}_${order}`);
// 		if (item_left !== undefined && item_left !== null) {
// 			const row_height = Math.ceil(item_left.offsetHeight) + 1;
// 			item_left.style.minHeight = `${row_height}px`;
// 			for (let rc = 1; rc < 40; rc++) {
// 				const item_right = document.getElementById(`item_right_${rc}_${itens_pedido[i].id}_${order}`);
// 				if (item_right !== undefined && item_right !== null) {
// 					item_right.style.minHeight = `${row_height}px`;
// 				}
// 			}
// 		}
// 	}
// };

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputUnidadeItem from '../_inputs/InputUnidadeItem/InputUnidadeItem';
import Caracteristica from '../ItemEditionColumn/Caracteristica';
// import InputTextArea from '../_inputs/InputTextArea/InputTextArea';
import AssociacaoSiengeSearchBar from '../AssociacaoSiengeSearchBar/AssociacaoSiengeSearchBar';
import AlterarItemSienge from './AlterarItemSienge';
import LoadingButton from '../_loadings/LoadingButton/LoadingButton';

// Functions
import { _post } from '../../_functions/_requests';

class ItemSiengeEditionColumn extends Component {
	state = {
		loading: false,
	};

	showTooltip = id => {
		window.clearTimeout(this.tooltip_timeout);
		this.tooltip_timeout = window.setTimeout(() => {
			if (document.getElementById(id)) document.getElementById(id).className = 'tooltip left in';
		}, 600);
	};

	hideTooltip = id => {
		window.clearTimeout(this.tooltip_timeout);
		document.getElementById(id).className = 'tooltip left';
	};

	handleApprovation = () => {
		const { item_para_edicao, updateAprovacao } = this.props;
		const url = `/conaz/v2/integracoes/itens_associados/${item_para_edicao.itens_associados[0].id}/aprovar`;
		this.setState({ loading: true });
		_post(url, {}).then(() => {
			updateAprovacao(true);
			this.setState({ loading: false });
		}).catch(() => {
			updateAprovacao(false);
			this.setState({ loading: false });
		});
	};

	handleAprovedButton = status => {
		if (status === 'pre_configurado' && !this.state.loading) {
			return (
				<button
					type="button"
					className="btn btn-secondary-conaz round-border"
					onClick={this.handleApprovation}>
					Aprovar Caracteristicas
				</button>
			);
		}
		if (status === 'pre_configurado' && this.state.loading) {
			return (
				<button
					type="button"
					className="btn btn-secondary-conaz round-border loading">
					<LoadingButton />
					Aprovar Caracteristicas
				</button>
			);
		}
		return false;
	};

	render() {
		const {
			key_item_selecionado,
			item_para_edicao,
			itens,
			saving,
			status,
		} = this.props;
		const {
			updateItem,
			updateItemCaracteristica,
			updateCotacao,
			autoSave,
		} = this.props;
		const isModalSienge = true;

		if (item_para_edicao) {
			const {
				item_integrado,
				// observacoes,
				// detalhe,
				cliente_associou,
				item_preenchido,
			} = item_para_edicao;
			const unidade = item_integrado ? item_integrado.unidade : '';
			const unidades = !cliente_associou
				? []
				: (item_preenchido.item || {}).unidades;
			const caracteristicas_preenchidas = item_preenchido
				? item_preenchido.caracteristicas_preenchidas || []
				: [];
			const caracteristicas_preenchidas_all_ids = item_preenchido
				? item_preenchido.caracteristicas_preenchidas_all_ids || []
				: [];
			/**
			 * Separa características obrigatórias
			 */
			const caracteristicas_obrigatorias = !cliente_associou
				? []
				: (caracteristicas_preenchidas_all_ids || [])
					.filter(
						ca_id => caracteristicas_preenchidas[ca_id]
							.caracteristica.visao_construtor === 2,
					)
					.sort(
						(a, b) => caracteristicas_preenchidas[a].caracteristica
							.ordem -
								caracteristicas_preenchidas[b].caracteristica
									.ordem,
					);
			/**
			 * Separa características não-obrigatórias
			 */
			const caracteristicas_nao_obrigatorias = !cliente_associou
				? []
				: (caracteristicas_preenchidas_all_ids || [])
					.filter(
						ca_id => caracteristicas_preenchidas[ca_id]
							.caracteristica.visao_construtor === 1,
					)
					.sort(
						(a, b) => caracteristicas_preenchidas[a].caracteristica
							.ordem -
								caracteristicas_preenchidas[b].caracteristica
									.ordem,
					);

			if (!cliente_associou) {
				return (
					<AssociacaoSiengeSearchBar
						item_para_edicao={this.props.item_para_edicao}
						saveItem={this.props.saveItem}
						itens={itens} />
				);
			}

			const _nome =
				(((item_para_edicao || {}).item_preenchido || {}).item || {})
					.nome || '';

			return (
				<div>
					<div
						id="item_edition_top_view"
						className="scrollbars"
						style={{ paddingBottom: '230px' }}>
						<div
							styleName="row-edition"
							style={{
								height: '23px',
								paddingTop: '3px',
								color: '#c3c6ca',
							}}>
							{saving === 1 && (
								<span>Salvando informações...</span>
							)}
							{saving === 2 && (
								<span>
									<i
										className="fa fa-check fa-fw"
										aria-hidden="true" />{' '}
									Item salvo com sucesso!
								</span>
							)}
						</div>
						<div styleName="row-edition">
							<AlterarItemSienge
								item_selecionado={key_item_selecionado}
								desassociarItem={this.props.desassociarItem}
								showTooltip={this.showTooltip}
								hideTooltip={this.hideTooltip}
								saving={saving} />
							<h3>
								<span>{_nome}</span>
							</h3>
							<br />
							{status === 'pre_configurado' && (
								<div styleName="pre-aprovado-alert">
									<i className="fa fa-check-circle" aria-hidden="true" />
									<p>Este item possui informações preenchidas através de um pedido realizado anteriormente. Aprove essas informações para usá-las em pedidos futuros.</p>
									{this.handleAprovedButton(status)}
								</div>
							)}
						</div>
						<div
							styleName="row-edition"
							style={{ padding: '0 0 12px 0' }}>
							<div styleName="optional-section">
								<div styleName="title">
									Características <b>obrigatórias</b> para a
									cotação
								</div>
								<div styleName="division" />
							</div>
						</div>
						<div styleName="row-edition">
							<div styleName="form-group-unidades">
								<div
									id={`unidade-${key_item_selecionado}-wrapper`}
									className="form-group">
									<div
										id={`tooltip-unidade-${key_item_selecionado}`}
										className="tooltip top"
										role="tooltip"
										style={{ marginTop: '-16px' }}>
										<div
											className="tooltip-arrow"
											style={{
												borderTopColor: '#ee2622',
												left: '10%',
											}} />
										<div
											className="tooltip-inner"
											style={{
												backgroundColor: '#ee2622',
											}}>
											Este campo é obrigatório
										</div>
									</div>
									<label>Unidade</label>
									<InputUnidadeItem
										field_id={`unidade-${key_item_selecionado}`}
										item_key={key_item_selecionado}
										field="unidade"
										default_value={unidade}
										opcoes={unidades}
										updateFunction={updateItem}
										autoSave={autoSave}
										show_outra_unidade={false} />
								</div>
							</div>
						</div>

						{caracteristicas_obrigatorias.map(ca_id => (
							<div key={ca_id} styleName="row-edition">
								<Caracteristica
									item_key={key_item_selecionado}
									itens={itens}
									caracteristica_id={ca_id}
									caracteristica_preenchida={
										caracteristicas_preenchidas[ca_id]
									}
									updateItem={updateItem}
									updateItemCaracteristica={
										updateItemCaracteristica
									}
									autoSave={autoSave}
									isModalSienge={isModalSienge}
									updateCotacao={updateCotacao}
									show_outro={false} />
							</div>
						))}

						{caracteristicas_nao_obrigatorias.length > 0 && (
							<div
								styleName="row-edition"
								style={{ padding: '10px 0 15px 0' }}>
								<div styleName="optional-section">
									<div styleName="title">
										Características Opcionais
									</div>
									<div styleName="division" />
								</div>
							</div>
						)}

						{caracteristicas_nao_obrigatorias.map(ca_id => (
							<div key={ca_id} styleName="row-edition">
								<Caracteristica
									item_key={key_item_selecionado}
									itens={itens}
									caracteristica_id={ca_id}
									caracteristica_preenchida={
										caracteristicas_preenchidas[ca_id]
									}
									updateItem={updateItem}
									updateItemCaracteristica={
										updateItemCaracteristica
									}
									autoSave={autoSave}
									isModalSienge={isModalSienge}
									updateCotacao={updateCotacao}
									show_outro={false} />
							</div>
						))}
						{/*
						<div styleName="row-edition">
							<div className="form-group">
								<label htmlFor={`observacoes-${key_item_selecionado}`}>Observações</label>
								<InputTextArea
									field_id={`observacoes-${key_item_selecionado}`}
									item_key={key_item_selecionado}
									field={!cliente_associou ? 'detalhe' : 'observacoes'}
									default_value={!cliente_associou ? detalhe : observacoes}
									updateFunction={updateItem}
									autoSave={autoSave}
									maxLength={1000} />
							</div>
						</div>
						*/}
					</div>
				</div>
			);
		}

		return <div>...</div>;
	}
}

ItemSiengeEditionColumn.propTypes = {
	itens: PropTypes.array.isRequired,
	key_item_selecionado: PropTypes.number.isRequired,
	item_para_edicao: PropTypes.object,
	updateItem: PropTypes.func.isRequired,
	desassociarItem: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	saveItem: PropTypes.func.isRequired,
	updateItemCaracteristica: PropTypes.func.isRequired,
	saving: PropTypes.number.isRequired,
	status: PropTypes.string.isRequired,
	updateAprovacao: PropTypes.func.isRequired,
};

ItemSiengeEditionColumn.defaultProps = {
	item_para_edicao: {},
};

export default CSSModules(ItemSiengeEditionColumn, styles, {
	allowMultiple: true,
});

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Actions
import { updateCompras, updateComprasRaw } from '../../../store/compras/actions';

// Components
import LoadingButton from '../_loadings/LoadingButton/LoadingButton';

// Functions
import { _post } from '../../_functions/_requests';
import { handleRequestErrors } from '../../_functions/_handleRequestErrors';

const CompraRowEntregaButton = ({
	compra,
	loading,
	setLoading,
	handleEvaluation,
	getUserWithAlcada,
	location,
}) => {
	const dispatch = useDispatch();
	const by_id = useSelector(store => store.compras.by_id, shallowEqual) || {};
	const nova_compra = useSelector(store => store.compras.nova_compra, shallowEqual) || {};

	function finaliza() {
		setLoading(2);
		handleEvaluation(compra);
	}

	function atualizaCompraNaListagem() {
		const _by_id = {
			...by_id,
			[compra.id]: {
				...by_id[compra.id],
				status: 50,
			},
		};
		dispatch(updateCompras(['by_id'], _by_id));
		finaliza();
	}

	function atualizaCompra() {
		const _nova_compra = {
			...nova_compra,
			status: 50,
			status_atual: 'ENTREGA_CONFIRMADA',
			status_front: 'entrega_confirmada',
		};
		dispatch(updateComprasRaw({ nova_compra: _nova_compra }));
		finaliza();
	}

	function handleEntrega() {
		setLoading(1);
		const url = `/conaz/v2/compras/${compra.id}/confirmar_entrega`;
		_post(url, {}).then(() => {
			if (location === 'compra') {
				atualizaCompra();
				return;
			}
			atualizaCompraNaListagem();
		}).catch(error => {
			const error_message = ((
				error.response || {})
				.data || {})
				.message || '';

			const sem_alcada = error_message
				.indexOf('Este usuário não pode confirmar a entrega!') !== -1;
			if (sem_alcada) {
				getUserWithAlcada();
				return;
			}

			handleRequestErrors(error);
			setLoading(3);
		});
	}

	const button_class = location === 'compra'
		? 'relative-button'
		: 'absolute-button-entrega';

	if (loading === 1) {
		return (
			<button
				type="button"
				className="btn btn-primary-conaz round-border loading"
				styleName={button_class}
			>
				Confirmar entrega do material<LoadingButton top="0" />
			</button>
		);
	}

	return (
		<>
			<button
				type="button"
				className="btn btn-primary-conaz round-border"
				styleName={button_class}
				onClick={handleEntrega}
			>
				Confirmar entrega do material
			</button>
		</>
	);
};

CompraRowEntregaButton.propTypes = {
	compra: PropTypes.object.isRequired,
	loading: PropTypes.number.isRequired,
	setLoading: PropTypes.func.isRequired,
	handleEvaluation: PropTypes.func.isRequired,
	getUserWithAlcada: PropTypes.func.isRequired,
	location: PropTypes.string.isRequired,
};

export default CSSModules(CompraRowEntregaButton, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import Itens from '../../components/_parts/Itens/Itens';
import InfoGerais from './subcomponents/InfoGerais';
import RightModalWrapper from './subcomponents/RightModalWrapper';
import RightModalFornecedor from './subcomponents/RightModalFornecedor';
import CompraStatusBar2 from './subcomponents/CompraStatusBar2';
import ModalPortal from '../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalCopiaInformacoes from '../../components/_parts/_modals/ModalCopiaInfomacoes/ModalCopiaInformacoes';
import ModalConfirm from '../../components/_parts/_modals/ModalConfirm/ModalConfirm';
import SideBar from '../../components/_parts/_modals/SideBar/SideBar';
import EnvioCompra from './subcomponents/EnvioCompra';
import ModalWrapper from '../../components/_parts/_modals/ModalWrapper/ModalWrapper';
import ModalNotificaSobreCompra from '../../components/_parts/_modals/ModalNotificaDecisor/ModalNotificaSobreCompra';
import Alert2 from '../../components/_parts/Alerts/Alert2';
import ResumoBottom from './subcomponents/ResumoBottom';
import ModalExportSienge from './subcomponents/ExportSiengeSteps/ModalExportSienge';

// functions
import { calcValorItem } from '../../components/_parts/Itens/_subfunctions/calcValorItem';

const CompraV3 = ({
	// =========== state
	is_mobile,
	itens_mobile_open,
	fornecedor_pre_selecionado,
	salvando_selecao_fornecedor,
	itens_width,
	dadosCotacao,
	fornecedor_ja_existe,
	form_novo_fornecedor,
	loading_request,
	fornecedor_nome,
	errors,
	envio_side_bar,
	history,
	compra_salva,
	salvando,
	infoCp_modal,
	error_edicao,
	fornecedor_integrado,
	export_sienge_modal,
	// =========== local funcs
	handleEnvioSideBar,
	infoFornecedor,
	handleFornecedorSelecionado,
	handleCadastrarVendedor,
	handleCadastrarFornecedorVendedor,
	handleModalCp,
	saveFornecedorSienge,
	saveFornecedorSiengePosterior,
	// handleAlertaCp,
	autoSaveStart,
	autoSaveInfo,
	handleNovoFornecedorModal,
	handleModalFornecedor,
	handleFixError,
	updateState,
	redirectAfterConcluir,
	redirectAfterAskAproval,
	openItensMobile,
	handleModalErroEdicao,
	handleExportSiengeModal,
	notificaUsuarioAposExportacao,
	// =========== store
	ui,
	itens,
	compras,
	user,
	// =========== prop funcs
	updateCompras,
	updateComprasRaw,
	updateItensRaw,
	updateItemById,
	updateUi,
	updateFullItemById,
	updateItemCaracById,
	deleteItem,
	updateModals,
	// =========== router
}) => {
	const is_triagem =
		process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
	const { modals } = ui;
	const { obras_by_id, sienge_liberado, cliente_id } = user;
	const { itens_compra, populares } = itens;
	const { nova_compra, alert } = compras;
	const ModalCp = (
		<ModalPortal>
			<ModalWrapperPortal closeModal={handleModalCp}>
				<ModalCopiaInformacoes
					compra={nova_compra}
					updateItensRaw={updateItensRaw}
					is_mobile={is_mobile}
					itens={itens.itens_compra}
					dadosCotacao={dadosCotacao}
					autoSaveInfo={autoSaveInfo}
					title="Você deseja copiar as seguintes informações do pedido:"
					button_type="secondary-conaz"
					button_text="Inserir"
					cancel_button_text="Cancelar" />
			</ModalWrapperPortal>
		</ModalPortal>
	);

	if (nova_compra.id === undefined) {
		return (
			<div className="container" style={{ marginTop: '36px' }}>
				<div className="row">
					<div className="col-sm-12 error-wrapper">
						<i className="fa fa-exclamation-circle" aria-hidden="true" />
						<h3>Esta compra não existe!</h3>
					</div>
				</div>
			</div>
		);
	}

	const obra_id = ((nova_compra || {}).obra || {}).id || 0;
	const obra_nao_existe = obras_by_id[obra_id] === undefined;
	const autosave_class = salvando === 2 ? 'auto-save visible' : 'auto-save';
	const rc_uuid = (nova_compra.rc || {}).uuid || null;
	const compra_has_rc = rc_uuid !== null;
	const logged_sienge = nova_compra.logged_sienge || false;

	// calcula valor compra
	const { by_id, all_ids } = itens_compra;
	const desconto_geral = nova_compra.condicao_de_pagamento.desconto || 0;
	const sub_total = all_ids.reduce((sum, id) => {
		const valores = calcValorItem(by_id[id]);
		const { total } = valores;
		return sum + total;
	}, 0);
	const total_itens = sub_total - (sub_total * desconto_geral) / 100;
	const { valor_transporte, custos_adicionais } = nova_compra;
	const total = total_itens + valor_transporte + custos_adicionais;

	const swi_cotacao = nova_compra.swi_cotacao || {};

	return (
		<>
			<div styleName={autosave_class}>
				<i className="fa fa-check fa-fw" /> Compra salva!
			</div>

			{/* alertaCopia && copia && alertaCp */}
			{infoCp_modal && ModalCp}

			{alert.message !== '' && (
				<Alert2 color={alert.color} mensagem={alert.message} updateComprasRaw={updateComprasRaw} />
			)}

			{error_edicao && (
				<ModalPortal>
					<ModalWrapperPortal closeModal={handleModalErroEdicao}>
						<ModalConfirm
							title="Esta compra não pode ser editada"
							message={<p>Apenas os responsáveis da compra podem editá-la.</p>}
							button_type="secondary-conaz"
							button_text="Entendi!"
							cancelar_button={false} />
					</ModalWrapperPortal>
				</ModalPortal>
			)}

			{envio_side_bar && (
				<>
					<ModalPortal>
						<SideBar openSideBar={handleEnvioSideBar} visible>
							<EnvioCompra
								compra={nova_compra}
								itens={itens_compra}
								handleEnvioSideBar={handleEnvioSideBar}
								updateItensRaw={updateItensRaw}
								updateState={updateState}
								updateModals={updateModals}
								updateComprasRaw={updateComprasRaw}
								autoSaveStart={autoSaveStart}
								compra_salva={compra_salva}
								redirectAfterConcluir={redirectAfterConcluir}
								handleExportSiengeModal={handleExportSiengeModal}
								sienge_liberado={sienge_liberado} />
						</SideBar>
					</ModalPortal>
					<div
						styleName="itens-block-modal"
						role="none"
						onClick={handleEnvioSideBar} />
				</>
			)}

			{(!is_triagem && !obra_nao_existe) && (
				<ModalWrapper
					visible={modals.notifica_decisor}
					updateModals={updateModals}>
					<ModalNotificaSobreCompra
						compra_id={nova_compra.id}
						obra_id={obra_id}
						obra_nome={obras_by_id[obra_id].nome}
						updateModals={updateModals}
						compra_valor={total}
						titulo_modal="Solicitar aprovação desta compra"
						subtitulo_modal="Selecione quem deve aprovar esta compra"
						pre_mensagem_text="solicitou sua aprovação para a compra"
						tipo="aprovacao"
						redirectAfterAskAproval={redirectAfterAskAproval} />
				</ModalWrapper>
			)}

			{export_sienge_modal && (
				<ModalExportSienge
					closeModal={handleExportSiengeModal}
					logged_sienge={logged_sienge}
					compra={nova_compra}
					itens={itens_compra}
					swi_cotacao={swi_cotacao}
					cliente_id={user.cliente_id}
					sienge_liberado={sienge_liberado}
					redirectAfterConcluir={redirectAfterConcluir}
					notificaUsuarioAposExportacao={notificaUsuarioAposExportacao}
					updateItensRaw={updateItensRaw}
					updateItemById={updateItemById}
				/>
			)}

			<RightModalWrapper
				visible={modals.fornecedor}
				updateModals={updateModals}
				salvando_selecao_fornecedor={salvando_selecao_fornecedor}>
				<RightModalFornecedor
					fornecedor={fornecedor_pre_selecionado}
					fornecedor_integrado={fornecedor_integrado}
					updateModals={updateModals}
					updateComprasRaw={updateComprasRaw}
					form_novo_fornecedor={form_novo_fornecedor}
					logged_sienge={logged_sienge}
					loading_request={loading_request}
					compra={nova_compra}
					autoSaveStart={autoSaveStart}
					autoSaveInfo={autoSaveInfo}
					salvando_selecao_fornecedor={salvando_selecao_fornecedor}
					handleFornecedorSelecionado={handleFornecedorSelecionado}
					handleCadastrarFornecedorVendedor={handleCadastrarFornecedorVendedor}
					saveFornecedorSienge={saveFornecedorSienge}
					handleCadastrarVendedor={handleCadastrarVendedor}
					fornecedor_nome={fornecedor_nome}
					fornecedor_ja_existe={fornecedor_ja_existe} />
			</RightModalWrapper>

			<CompraStatusBar2
				compra={nova_compra}
				updateUi={updateUi}
				updateCompras={updateCompras} />

			<ResumoBottom
				itens={itens_compra}
				compra={nova_compra}
				handleEnvioSideBar={handleEnvioSideBar} />

			<div styleName="itens-wrapper" id="itens_list_wrapper">
				<Itens
					is_mobile={is_mobile}
					obra_id={obra_id}
					client_id={cliente_id}
					itens_mobile_open={itens_mobile_open}
					itens_width={itens_width}
					itens={itens_compra}
					is_editable={nova_compra.is_editable}
					compra_has_rc={compra_has_rc}
					updateItensRaw={updateItensRaw}
					updateItemById={updateItemById}
					updateFullItemById={updateFullItemById}
					updateItemCaracById={updateItemCaracById}
					deleteItem={deleteItem}
					populares={populares}
					user={user}
					openItensMobile={openItensMobile}
					updateStateCompra={updateState}
					updateModals={updateModals}
					logged_sienge={logged_sienge} />
			</div>

			<div styleName="info-gerais-wrapper" id="info_gerais_compra">
				<InfoGerais
					nova_compra={nova_compra}
					updateComprasRaw={updateComprasRaw}
					updateModals={updateModals}
					info-gerais-wrapper
					updateUi={updateUi}
					dadosCotacao={dadosCotacao}
					history={history}
					infoFornecedor={infoFornecedor}
					is_mobile={is_mobile}
					saveFornecedorSiengePosterior={saveFornecedorSiengePosterior}
					handleNovoFornecedorModal={handleNovoFornecedorModal}
					handleModalCp={handleModalCp}
					redirectAfterConcluir={redirectAfterConcluir}
					autoSaveStart={autoSaveStart}
					errors={errors}
					handleFixError={handleFixError}
					handleModalFornecedor={handleModalFornecedor}
					user={user}
					number_of_items={itens_compra.all_ids.length}
					openItensMobile={openItensMobile}
					logged_sienge={logged_sienge}
					itens={itens_compra}
					updateItemById={updateItemById}
					updateItensRaw={updateItensRaw}
					notificaUsuarioAposExportacao={notificaUsuarioAposExportacao}
				/>
			</div>
		</>
	);
};

CompraV3.propTypes = {
	// =========== state
	is_mobile: PropTypes.bool.isRequired,
	itens_mobile_open: PropTypes.bool.isRequired,
	fornecedor_pre_selecionado: PropTypes.object.isRequired,
	fornecedor_integrado: PropTypes.object.isRequired,
	salvando_selecao_fornecedor: PropTypes.bool.isRequired,
	salvando: PropTypes.number.isRequired,
	infoCp_modal: PropTypes.bool.isRequired,
	itens_width: PropTypes.number.isRequired,
	form_novo_fornecedor: PropTypes.bool.isRequired,
	fornecedor_nome: PropTypes.string.isRequired,
	fornecedor_ja_existe: PropTypes.bool.isRequired,
	loading_request: PropTypes.bool.isRequired,
	errors: PropTypes.object.isRequired,
	envio_side_bar: PropTypes.bool.isRequired,
	compra_salva: PropTypes.bool.isRequired,
	dadosCotacao: PropTypes.object.isRequired,
	error_edicao: PropTypes.bool.isRequired,
	export_sienge_modal: PropTypes.bool.isRequired,
	// =========== local funcs
	handleModalCp: PropTypes.func.isRequired,
	handleEnvioSideBar: PropTypes.func.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	handleFornecedorSelecionado: PropTypes.func.isRequired,
	handleCadastrarVendedor: PropTypes.func.isRequired,
	handleCadastrarFornecedorVendedor: PropTypes.func.isRequired,
	handleNovoFornecedorModal: PropTypes.func.isRequired,
	handleModalFornecedor: PropTypes.func.isRequired,
	handleFixError: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
	saveFornecedorSienge: PropTypes.func.isRequired,
	saveFornecedorSiengePosterior: PropTypes.func.isRequired,
	autoSaveInfo: PropTypes.func.isRequired,
	updateState: PropTypes.func.isRequired,
	redirectAfterConcluir: PropTypes.func.isRequired,
	redirectAfterAskAproval: PropTypes.func.isRequired,
	openItensMobile: PropTypes.func.isRequired,
	handleModalErroEdicao: PropTypes.func.isRequired,
	handleExportSiengeModal: PropTypes.func.isRequired,
	notificaUsuarioAposExportacao: PropTypes.func.isRequired,
	// =========== store
	itens: PropTypes.object.isRequired,
	compras: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	// =========== prop funcs
	updateCompras: PropTypes.func.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	updateItensRaw: PropTypes.func.isRequired,
	updateItemById: PropTypes.func.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	updateItemCaracById: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	deleteItem: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	// =========== router
	history: PropTypes.object.isRequired,
};

export default CSSModules(CompraV3, styles, { allowMultiple: true });

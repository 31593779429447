import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Button, Modal } from 'antd';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import RCCardStatus from '../../components/_parts/RCRowDetails/RCCardStatus';
import RCRowDetails from '../../components/_parts/RCRowDetails/RCRowDetails';
import CombRowDetails from '../../components/_parts/RCRowDetails/CombRowDetails';
import RCCardNotResponded from '../../components/_parts/RCRowDetails/RCCardNotResponded';
import RCRowOutroFornecedor from '../../components/_parts/RCRowDetails/RCRowOutroFornecedor';
import SvgEmptyCar from '../../components/_parts/_svgs/SvgEmptyCar/SvgEmptyCar';
import CompraRowSimplificada from '../../components/_parts/CompraRowSimplificada/CompraRowSimplificada';

// Functions
import { defineStatusCompra } from '../../components/_functions/_defineStatusCompra';

const TabQc = ({
	ocs,
	rcs,
	compras,
	pedido_status,
	cotacao,
	rc_card_min_height,
	updateCompras,
	updateModals,
	qc_versao,
	qc_foi_visto,
	pdf_foi_aberto_alert,
	openPdf,
	alertVerPdf,
	infoFornecedor,
	openAnexosFornecedor,
	loading_compras,
	ultimo_envio_qc,
	history,
	rcs_abertos,
	openCloseRCs,
	visualiza_qc,
}) => {
	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const _compras = compras
		.filter(compra => {
			const status = defineStatusCompra(compra);
			return (
				status !== 'cancelada'
				// && status !== 'recusada_fornecedor'
			);
		});
	const compras_sem_rc = _compras
		.filter(compra => compra.rc.uuid === undefined);

	switch (pedido_status) {
	/* ================================================================== *\


		# 3 - Revisão da Conaz


	\* ================================================================== */
	case 0: case 15: case 20: case 30: {
		return (
			<div className="container" styleName="container-qc">
				<div className="row">
					<div className="col-xs-12">
						<div styleName="content-wrapper" style={{ margin: '5px 0 30px 0', background: 'none', boxShadow: 'none', textAlign: 'center' }}>
							<div styleName="qc-pdf-block" style={{ padding: '26px 0px 18px' }}>
								<SvgEmptyCar />
								{/* <i className="fa fa-cogs" aria-hidden="true" style={{ fontSize: '50px', marginBottom: '6px', color: '#cdd0d6' }}></i> */}
								<p styleName="h4" style={{ fontSize: '20px' }}>Entrando em contato com fornecedores</p>
								<p>O sistema está revisando seu pedido e procurando fornecedores para te atender!</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	/* ================================================================== *\


		# 4 - Aguardando cotações


	\* ================================================================== */
	case 40: case 45: {
		const rcs_to_show = rcs.filter(rc => {
			const status = rc.status || null;
			// return status === -20 || (status >= 0 && status <= 60);
			return (
				(
					status === -20
					&& rc.tipo_selecao_fornecedor !== 4
				) || (
					status >= 0
					&& status < 40
					&& rc.tipo_selecao_fornecedor !== 4
				) || (
					status >= 40
					&& status <= 60
				)
			);
		});

		return (
			<div className="container" styleName="container-qc">
				<div className="row">
					<div className="col-xs-12">
						<div styleName="content-wrapper" style={{ margin: '26px 0 10px 0', background: 'none', boxShadow: 'none' }}>
							<div styleName="qc-pdf-block" style={{ paddingLeft: '0' }}>
								<p styleName="h4">Acompanhe abaixo o status das respostas dos fornecedores</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					{rcs_to_show.length > 0 && rcs_to_show.map((rc, r) => {
						const order = r + 1;
						const uuid = rc.uuid || 0;

						return (
							<RCCardStatus
								key={uuid}
								order={order}
								rc={rc}
								infoFornecedor={infoFornecedor} />
						);
					})}
				</div>
				<div className="row">
					<div className="col-sm-12">
						<div styleName="title-section" style={{ marginTop: '18px' }}>
							<div styleName="title">MAIS INFORMAÇÕES</div>
							<div styleName="division" />
						</div>
					</div>
				</div>
			</div>
		);
	}

	/* ================================================================== *\


		# 5 - Aguardando sua decisão de compra
		# 6 - Aguardando confirmação do fornecedor
		# 7 - Compra finalizada


	\* ================================================================== */
	case 50: case 60: case 70: {
		/**
		 * Limpa condições de pagamento e
		 * seta menor preço
		 */
		const _ocs = ocs
			.map(oc => {
				const { condicoes_de_pagamento, preco_total } = oc.rcs_analise[0].rc;
				// check condicoes
				const condicoes_de_pagamento_check = (
					condicoes_de_pagamento !== null &&
					condicoes_de_pagamento !== undefined &&
					condicoes_de_pagamento.length > 0
				) ? [...condicoes_de_pagamento] : [{ desconto: 0, forma: 0, outra_forma: '', outro_prazo: '', prazo: 0 }];
				// limpa, remove desconto null e ordena a lista
				const condicoes_de_pagamento_clean = condicoes_de_pagamento_check
					.filter(c => c.forma !== 0 && c.prazo !== 0)
					.map(c => ((c.desconto === null || c.desconto === undefined) ? { ...c, desconto: 0 } : c))
					.sort((a, b) => b.desconto - a.desconto);
				// pega o menor preço
				let menor_preco = condicoes_de_pagamento_clean.length > 0
					? preco_total - ((preco_total / 100) * condicoes_de_pagamento_clean[0].desconto)
					: preco_total;
				const is_combinacao = oc.rcs_analise.length > 1;
				if (is_combinacao) {
					menor_preco = condicoes_de_pagamento_clean.length > 0
						? oc.rcs_analise.reduce((total, atual) => (total + atual.preco_selecionado_rc), 0)
						: preco_total;
				}
				return { ...oc, menor_preco };
			});
		/**
		 * Combinações
		 */
		const ocs_comb_list = _ocs
			.filter(oc => oc.rcs_analise.length > 1)
			.sort((a, b) => a.menor_preco - b.menor_preco);
		/**
		 * Completos
		 */
		const ocs_completos = _ocs
			.filter(oc => {
				const rc = (oc.rcs_analise[0] || {}).rc || {};
				return (
					oc.rcs_analise.length === 1
					&& (
						rc.status === 35
						|| rc.status === 36
						|| rc.status === 37
						|| rc.status === 40
						|| rc.status === 50
						|| rc.status === 60
					)
					&& !rc.estah_resetado
					&& rc.completo
					&& rc.preco_total > 0
					&& rc.estah_no_qc
				);
			})
			.sort((a, b) => a.menor_preco - b.menor_preco);
		/**
		 * Incompletos
		 */
		const ocs_incompletos = _ocs
			.filter(oc => {
				const rc = (oc.rcs_analise[0] || {}).rc || {};
				return (
					oc.rcs_analise.length === 1
					&& (
						rc.status === 35
						|| rc.status === 36
						|| rc.status === 37
						|| rc.status === 40
						|| rc.status === 50
						|| rc.status === 60
					)
					&& !rc.estah_resetado
					&& !rc.completo
					&& rc.preco_total > 0
					&& rc.estah_no_qc
				);
			})
			.sort((a, b) => a.menor_preco - b.menor_preco);
		const ocs_list = [...ocs_completos, ...ocs_incompletos];

		/**
		 * Não respondidos
		 */
		const rcs_respondidos_fora_do_qc = rcs
			.filter(rc => (
				(
					rc.status === 40
					|| rc.status === 50
					|| rc.status === 60
				)
				&& !rc.estah_no_qc
			));
		const rcs_recusados = rcs
			.filter(rc => (
				(
					rc.status === -20
					|| rc.preco_total === 0
					|| rc.preco_total === null
				)
				&& rc.status !== -10
				&& rc.status !== -30
				&& rc.tipo_selecao_fornecedor !== 4
			));
		const rcs_nao_respondidos = rcs
			.filter(rc => (
				(
					rc.status >= 0
					&& rc.status < 35
					&& rc.preco_total > 0
					&& rc.tipo_selecao_fornecedor !== 4
				)
				|| rc.estah_resetado
				|| rc.status === -30
			));
		const rcs_list_nr = [
			...rcs_respondidos_fora_do_qc,
			...rcs_recusados,
			...rcs_nao_respondidos,
		];

		const atualizado_em_view = (ultimo_envio_qc !== null && ultimo_envio_qc !== '')
			? moment(ultimo_envio_qc).locale('pt-BR').format('DD/MM/YYYY, [às] h:mm')
			: undefined;

		let ocs_order = 0;

		return (
			<div className="container" styleName="container-qc">
				{qc_versao === 2 && (
					<div className="row">
						<div className="col-xs-12">
							{pdf_foi_aberto_alert && (
								<div className="tooltip top in" role="tooltip" styleName="pa2-tooltip-top">
									<div className="tooltip-arrow" styleName="pa2-tooltip-arrow" />
									<div className="tooltip-inner" style={{ background: '#2cb223', padding: '6px 8px' }}>Veja o seu Quadro de Cotações completo antes de solicitar uma compra!</div>
								</div>
							)}
							{ visualiza_qc ?
								<Link to={`/cotacao/${cotacao.codigo}/qc`}>
									<div styleName="qc-novo-content-wrapper">
										<div styleName="qc-novo-block">
											<i className="fa fa-tasks" styleName="fa-tasks" aria-hidden="true" />
											<p styleName="h4">Quadro de Cotações Detalhado</p>
											<p>{(ultimo_envio_qc !== null && ultimo_envio_qc !== '') && <span style={{ fontSize: '13px', color: '#a7bfcb' }}>Atualizado em {atualizado_em_view}</span>}</p>
											<i
												className="fa fa-angle-right"
												styleName="fa-angle-right"
												aria-hidden="true" />
										</div>
										<i className="fa fa-angle-right" aria-hidden="true" />
										{/*
										<button
											type="button"
											className="btn btn-primary-conaz round-border"
											styleName="open-qc-novo-button">
											Visualizar <i className="fa fa-angle-right fa-fw" aria-hidden="true" />
										</button>
										*/}
									</div>
								</Link>
								:
								<>
									<Modal
										title="Usuário não autorizado"
										visible={isModalVisible}
										onOk={handleOk}
										onCancel={handleCancel}
										footer={[
											<Button key="submit" type="primary" onClick={handleOk}>
												Voltar
											</Button>,
										]}>
										<strong styleName="titulo_notificacao_acesso">Usuário não tem permissão para visualizar o Quadro de Cotação detalhado.</strong>
										<h5 styleName="contexto_notificacao_acesso"> <i className="fa fa-warning" aria-hidden="true" />Em caso de dúvidas, entre em contato com nosso suporte!</h5>
									</Modal>
									<Link onClick={showModal}>
										<div styleName="qc-novo-content-wrapper">
											<div styleName="qc-novo-block">
												<i className="fa fa-tasks" styleName="fa-tasks" aria-hidden="true" />
												<p styleName="h4">Quadro de Cotações Detalhado</p>
												<p>{(ultimo_envio_qc !== null && ultimo_envio_qc !== '') && <span style={{ fontSize: '13px', color: '#a7bfcb' }}>Atualizado em {atualizado_em_view}</span>}</p>

												<i
													className="fa fa-angle-right"
													styleName="fa-angle-right"
													aria-hidden="true" />

											</div>
											<i className="fa fa-angle-right" aria-hidden="true" />
										</div>
									</Link>
								</>
							}
						</div>
					</div>
				)}
				{qc_versao === 1 && (
					<div className="row">
						<div className="col-xs-12">
							<div styleName="content-wrapper" style={{ margin: '26px 0 30px 0' }}>
								<div className="row">
									<div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
										<div styleName="qc-pdf-block">
											<p styleName="h4">Quadro de Cotações detalhado</p>
											<p>Confira ao lado o(s) arquivo(s) enviados para este pedido.<br />{(ultimo_envio_qc !== null && ultimo_envio_qc !== '') && <small style={{ color: '#bdbdbd' }}>Atualizado em {atualizado_em_view}</small>}</p>
										</div>
									</div>
									<div className="col-xs-12 col-sm-5 col-md-5 col-lg-5" styleName="qc-pdf-buttons">
										{(!qc_foi_visto && pdf_foi_aberto_alert) && (
											<div className="tooltip top in" role="tooltip" styleName="pa-tooltip-top">
												<div className="tooltip-arrow" styleName="pa-tooltip-arrow" />
												<div className="tooltip-inner" style={{ background: '#2cb223', padding: '6px 8px' }}>Veja o seu Quadro de Cotações completo antes de solicitar uma compra!</div>
											</div>
										)}
										<button
											type="button"
											className="btn btn-primary-conaz round-border"
											styleName="open-pdf-button"
											onClick={openPdf}>
											Visualizar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{qc_versao === 0 && (
					<div className="row">
						<div className="col-sm-12">
							<div styleName="content-wrapper" style={{ margin: '26px 0 30px 0' }}>
								<div styleName="qc-pdf-block">
									<p styleName="h4">Quadro de Cotações enviado via e-mail</p>
									<p>O Quadro de Cotações deste pedido foi enviado via e-mail e não está disponível aqui.</p>
									<i className="fa fa-envelope" styleName="email-icon" aria-hidden="true" />
								</div>
							</div>
						</div>
					</div>
				)}

				{/* NOTE Olha aqui doido */}
				<div className="row">
					{(ocs_comb_list.length > 0 && qc_versao === 2) && (
						<>
							{ocs_comb_list.map((oc, o) => {
								const order = o + 1;

								return (
									<CombRowDetails
										key={oc.id}
										order={order}
										oc={oc}
										cotacao={cotacao}
										compras={_compras}
										updateCompras={updateCompras}
										updateModals={updateModals}
										qc_foi_visto={qc_foi_visto}
										alertVerPdf={alertVerPdf}
										infoFornecedor={infoFornecedor}
										history={history}
										openAnexosFornecedor={openAnexosFornecedor}
										loading_compras={loading_compras}
										rcs_abertos={rcs_abertos}
										openCloseRCs={openCloseRCs} />
								);
							})}
							<div styleName="comb-cards-division" />
						</>
					)}

					{(ocs_list.length > 0 && qc_versao !== 0) && (
						<>
							{ocs_completos.map(oc => {
								const uuid = ((oc.rcs_analise[0] || {}).rc || {}).uuid || 0;
								ocs_order++;

								return (
									<RCRowDetails
										key={uuid}
										order={ocs_order}
										oc={oc}
										cotacao={cotacao}
										compras={_compras}
										updateCompras={updateCompras}
										updateModals={updateModals}
										qc_foi_visto={qc_foi_visto}
										alertVerPdf={alertVerPdf}
										infoFornecedor={infoFornecedor}
										history={history}
										openAnexosFornecedor={openAnexosFornecedor}
										loading_compras={loading_compras}
										rcs_abertos={rcs_abertos}
										openCloseRCs={openCloseRCs} />
								);
							})}
							<div styleName="comb-cards-division" />
							{ocs_incompletos.map(oc => {
								const uuid = ((oc.rcs_analise[0] || {}).rc || {}).uuid || 0;
								ocs_order++;

								return (
									<RCRowDetails
										key={uuid}
										order={ocs_order}
										oc={oc}
										cotacao={cotacao}
										compras={_compras}
										updateCompras={updateCompras}
										updateModals={updateModals}
										qc_foi_visto={qc_foi_visto}
										alertVerPdf={alertVerPdf}
										infoFornecedor={infoFornecedor}
										history={history}
										openAnexosFornecedor={openAnexosFornecedor}
										loading_compras={loading_compras}
										rcs_abertos={rcs_abertos}
										openCloseRCs={openCloseRCs} />
								);
							})}

							<RCRowOutroFornecedor
								cotacao={cotacao}
								updateCompras={updateCompras}
								updateModals={updateModals}
								history={history} />

							{compras_sem_rc.length > 0 && (
								<div className="col-xs-12">
									<div styleName="compras-wrapper">
										<div styleName="cw-title">Compras feitas com outros fornecedores</div>
										{compras_sem_rc.map(c => (
											<CompraRowSimplificada
												key={c.id}
												compra={c}
												show_fornecedor />
										))}
									</div>
								</div>
							)}
						</>
					)}
				</div>

				{(rcs_list_nr.length > 0 && qc_versao !== 0) && (
					<div className="row">
						<div className="col-sm-12">
							<div styleName="nr">
								<div>
									<div styleName="nr-title">Não participantes</div>
									{rcs_list_nr.map(rc => {
										const uuid = rc.uuid || 0;

										return (
											<RCCardNotResponded
												key={uuid}
												rc={rc}
												min_height={rc_card_min_height}
												infoFornecedor={infoFornecedor} />
										);
									})}
								</div>
							</div>
						</div>
					</div>
				)}

				<div className="row">
					<div className="col-sm-12">
						<div styleName="title-section">
							<div styleName="title">MAIS INFORMAÇÕES</div>
							<div styleName="division" />
						</div>
					</div>
				</div>
			</div>
		);
	}
	default: return null;
	}
};

TabQc.propTypes = {
	ocs: PropTypes.array,
	rcs: PropTypes.array,
	compras: PropTypes.array,
	pedido_status: PropTypes.number,
	cotacao: PropTypes.object.isRequired,
	rc_card_min_height: PropTypes.number.isRequired,
	updateCompras: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	qc_versao: PropTypes.number.isRequired,
	ultimo_envio_qc: PropTypes.string,
	qc_foi_visto: PropTypes.bool.isRequired,
	visualiza_qc: PropTypes.bool.isRequired,
	pdf_foi_aberto_alert: PropTypes.bool.isRequired,
	openPdf: PropTypes.func.isRequired,
	alertVerPdf: PropTypes.func.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	openAnexosFornecedor: PropTypes.func.isRequired,
	loading_compras: PropTypes.number.isRequired,
	history: PropTypes.object.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
};

TabQc.defaultProps = {
	ocs: [],
	rcs: [],
	compras: [],
	pedido_status: 0,
	ultimo_envio_qc: '',
};

export default CSSModules(TabQc, styles, { allowMultiple: true });

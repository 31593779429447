import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import IEQuantidade from './IEQuantidade';
import IEUnidade from './IEUnidade';
import IEValores from './IEValores';
import IECaracteristica from './IECaracteristica';
import InputTextArea from '../../_inputs/InputTextArea/InputTextArea';
import IEButtons from './IEButtons';
import IENaoAssociado from './IENaoAssociado';
import IEComentarios from './IEComentarios';
import InfoItem from './Integracao/InfoItem/InfoItem';
// triagem
// import IETLinkEdit from './Triagem/IETLinkEdit';
import IETUpdateItem from './Triagem/IETUpdateItem';

class ItemEdition extends Component {
	static filterCaracs(obrigatorias, cliente_associou, item_preenchido) {
		if (!cliente_associou) return [];
		const {
			caracteristicas_preenchidas,
			caracteristicas_preenchidas_all_ids,
		} = item_preenchido;
		const caracteristicas = (caracteristicas_preenchidas_all_ids || [])
			.filter(ca_id => {
				const carac = caracteristicas_preenchidas[ca_id];
				const { visao_construtor, visao_fornecedor } = carac.caracteristica;
				if (obrigatorias) {
					return visao_construtor === 2 || visao_fornecedor === 2;
				}
				return (visao_construtor === 1 && visao_fornecedor !== 2)
					|| (visao_fornecedor === 1 && visao_construtor !== 2);
			})
			.filter((el, i, a) => i === a.indexOf(el))
			.sort((a, b) => {
				const ordem_a = caracteristicas_preenchidas[a].caracteristica.ordem;
				const ordem_b = caracteristicas_preenchidas[b].caracteristica.ordem;
				return ordem_a - ordem_b;
			});
		return caracteristicas;
	}

	componentDidMount() {
		setTimeout(() => {
			const { item_on_edition_id, updateItemById } = this.props;
			updateItemById(item_on_edition_id, 'adicionado', false);
		}, 5000);
	}

	closeEdition = () => {
		this.props.openItemEdition();
	}

	render() {
		const {
			item_on_edition_id,
			itens,
			updateItemById,
			updateFullItemById,
			updateItemCaracById,
			autoSave,
			openItemEdition,
			deleteItem,
			logged_sienge,
			user,
			obra_id,
			client_id,
		} = this.props;

		const { by_id } = itens;
		// console.log('here', by_id[item_on_edition_id]);
		const item_on_edition = by_id[item_on_edition_id] || {};
		// console.log('item_on_edition_id', item_on_edition_id);
		if (item_on_edition.id !== undefined) {
			const {
				cliente_associou,
				informacoes_adicionais,
				front,
				importado_planilha,
				observacoes_internas,
				pre_detalhe,
			} = item_on_edition;
			const item_preenchido = item_on_edition.item_preenchido || {};
			const { caracteristicas_preenchidas, item } = item_preenchido;
			const nome = !cliente_associou
				? item_on_edition.pre_nome
				: (item || {}).nome;

			const adicionado = item_on_edition.adicionado || false;

			const { sienge_liberado } = user;

			if (importado_planilha === 1) {
				return (
					<div styleName="full-content">
						<div styleName="content">
							<button
								type="button"
								className="close"
								styleName="close-x"
								data-dismiss="modal"
								aria-label="Close"
								onClick={this.closeEdition}>
								<span aria-hidden="true">&times;</span>
							</button>
							<IENaoAssociado
								item={item_on_edition}
								autoSave={autoSave} />
						</div>
					</div>
				);
			}

			/**
			 * Separa características obrigatórias
			 */
			const caracteristicas_obrigatorias = this.constructor
				.filterCaracs(true, cliente_associou, item_preenchido);
			/**
			 * Separa características não-obrigatórias
			 */
			const caracteristicas_nao_obrigatorias = this.constructor
				.filterCaracs(false, cliente_associou, item_preenchido);

			return (
				<div styleName="full-content">
					<InfoItem
						item_on_edition={item_on_edition}
						obra_id={obra_id}
						client_id={client_id}
						updateFullItemById={updateFullItemById}
						logged_sienge={logged_sienge}
						autoSave={autoSave}
					/>

					<div styleName="content">
						<button
							type="button"
							className="close"
							styleName="close-x"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.closeEdition}>
							<span aria-hidden="true">&times;</span>
						</button>
						{adicionado && (
							<div styleName="alert-comb-criada">
								<i className="fa fa-check fa-fw" aria-hidden="true" /> Item adicionado com sucesso!
							</div>
						)}
						<div styleName="nome">
							<button
								type="button"
								styleName="nome-button"
								onClick={this.closeEdition}>
								{front.key}. {nome}
							</button>
							<IEButtons
								item={item_on_edition}
								updateFullItemById={updateFullItemById}
								autoSave={autoSave}
								deleteItem={deleteItem}
								openItemEdition={openItemEdition} />
							<IETUpdateItem
								item={item_on_edition}
								updateFullItemById={updateFullItemById}
								autoSave={autoSave} />
						</div>

						{/* <IETLinkEdit /> */}

						{(
							!cliente_associou && !logged_sienge
							&& process.env.REACT_APP_CONAZ_PROJECT !== 'construtor-interno'
						) && (
							<div styleName="alert-fora-cpic">
								<i className="fa fa-exclamation-circle" aria-hidden="true" />
								Compras com itens que não estão associados ao sistema Conaz passam por um processo de validação e isso pode atrasar o envio aos fornecedores.
							</div>
						)}

						<div styleName="row-edition">
							<IEComentarios
								item_id={item_on_edition.id}
								observacoes_internas={observacoes_internas}
								updateFunction={updateItemById}
								autoSave={autoSave}
								maxLength={1000} />
						</div>

						<div styleName="row-edition">
							<IEQuantidade
								item={item_on_edition}
								updateFullItemById={updateFullItemById}
								autoSave={autoSave} />
							<IEUnidade
								item={item_on_edition}
								logged_sienge={logged_sienge}
								updateItemById={updateItemById}
								updateFullItemById={updateFullItemById}
								autoSave={autoSave} />
						</div>
						<div styleName="row-edition">
							<IEValores
								item={item_on_edition}
								updateFullItemById={updateFullItemById}
								autoSave={autoSave} />
						</div>

						{caracteristicas_obrigatorias.length > 0 && (
							<div styleName="row-edition">
								<div styleName="optional-section">
									<div styleName="title">Características obrigatórias</div>
									<div styleName="division" />
								</div>
							</div>
						)}

						{caracteristicas_obrigatorias.map(ca_id => (
							<IECaracteristica
								key={ca_id}
								item={item_on_edition}
								item_key={item_on_edition_id}
								caracteristica_id={ca_id}
								sienge_liberado={sienge_liberado}
								caracteristica_preenchida={caracteristicas_preenchidas[ca_id]}
								updateFullItemById={updateFullItemById}
								updateItemCaracteristica={updateItemCaracById}
								autoSave={autoSave} />
						))}

						<div styleName="row-edition">
							<div styleName="optional-section">
								<div styleName="title">Opcionais</div>
								<div styleName="division" />
							</div>
						</div>

						{caracteristicas_nao_obrigatorias.map(ca_id => (
							<IECaracteristica
								key={ca_id}
								item={item_on_edition}
								item_key={item_on_edition_id}
								caracteristica_id={ca_id}
								caracteristica_preenchida={caracteristicas_preenchidas[ca_id]}
								updateFullItemById={updateFullItemById}
								updateItemCaracteristica={updateItemCaracById}
								autoSave={autoSave} />
						))}

						<div styleName="row-edition">
							<div className="form-group">
								<label htmlFor={`observacoes-${item_on_edition_id}`}>Observações</label>
								<InputTextArea
									field_id={`observacoes-${item_on_edition_id}`}
									item_key={item_on_edition_id}
									field={!cliente_associou ? 'pre_detalhe' : 'informacoes_adicionais'}
									default_value={!cliente_associou ? pre_detalhe : informacoes_adicionais}
									updateFunction={updateItemById}
									autoSave={autoSave}
									maxLength={1000} />
							</div>
						</div>
					</div>
				</div>
			);
		}

		return null;
	}
}

ItemEdition.propTypes = {
	openItemEdition: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	item_on_edition_id: PropTypes.number.isRequired,
	itens: PropTypes.object.isRequired,
	obra_id: PropTypes.number.isRequired,
	client_id: PropTypes.number.isRequired,
	updateItemById: PropTypes.func.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	updateItemCaracById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
	deleteItem: PropTypes.func.isRequired,
	logged_sienge: PropTypes.bool.isRequired,
};

export default CSSModules(ItemEdition, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';

const CompraRowIcon = ({ compra }) => {
	const status = defineStatusCompra(compra);
	const icons_status = {
		cancelada: <i className="fa fa-ban" style={{ color: 'red' }} aria-hidden="true" />,
		em_aprovacao: <i className="fa fa-paper-plane" style={{ color: '#01b0cd' }} aria-hidden="true" />,
		aguardando_aprovacao_sienge: <i className="fa fa-paper-plane" style={{ color: '#01b0cd' }} aria-hidden="true" />,
		em_edicao: <i className="fa fa-pencil" style={{ color: '#666c6d' }} aria-hidden="true" />,
		rascunho: <i className="fa fa-barcode" aria-hidden="true" />,
		em_analise: <i className="fa fa-barcode" aria-hidden="true" />,
		em_refinamento: <i className="fa fa-pencil" aria-hidden="true" />,
		aguardando_confirmacao: <i className="fa fa-barcode" aria-hidden="true" />,
		confirmada: <i className="fa fa-check" style={{ color: '#5bda40' }} aria-hidden="true" />,
		recusada_fornecedor: <i className="fa fa-exclamation-triangle" style={{ color: 'red' }} aria-hidden="true" />,
		entrega_confirmada: <i className="fa fa-check" style={{ color: '#5bda40' }} aria-hidden="true" />,
		finalizada: <i className="fa fa-check" style={{ color: '#5bda40' }} aria-hidden="true" />,
	};

	return (
		<>{icons_status[status]}</>
	);
};

CompraRowIcon.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraRowIcon, styles, { allowMultiple: true });

import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
// import monitorReducersEnhancer from '../utils/enhancers/monitorReducer';
// import loggerMiddleware from '../utils/middleware/logger';

// sagas
import rootSaga from './combineSagas';

// reducers
import compras from './compras/reducers';
import avaliacoes from './avaliacoes/reducers';
import cotacao from './cotacao/reducers';
import cotacoes from './cotacoes/reducers';
import itens from './itens/reducers';
import qc from './qc/reducers';
import ui from './ui/reducers';
import user from './user/reducers';
import exportStatus from './exportStatus/reducer';

const reducers = combineReducers({
	compras,
	avaliacoes,
	cotacao,
	cotacoes,
	itens,
	qc,
	ui,
	user,
	exportStatus,
});

// const sagaMiddleware = createSagaMiddleware();
// const middlewares = [loggerMiddleware, sagaMiddleware];
// const middlewareEnhancer = applyMiddleware(...middlewares);
// const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
// const composedEnhancers = composeWithDevTools(...enhancers);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(reducers, composedEnhancers);

sagaMiddleware.run(rootSaga);

const withStore = Page => (props) => (
	<Provider store={store}>
		<Page {...props} />
	</Provider>
);

export default withStore;

// import React, { Suspense, lazy } from 'react';
// import { Route, Switch } from 'react-router-dom';

// // Loading
// import LoadingPage from './components/_loadings/LoadingPage/LoadingPage';

// // Components
// const NotFound404 = lazy(() => import('./pages/NotFound404'));
// const CotacaoQC = lazy(() => import('./pages/CotacaoQC/QcView'));
// const Home = lazy(() => import('./pages/Home'));

// const Routes = () => (
// 	<Suspense fallback={<LoadingPage />}>
// 		<Switch>
// 			<Route path="/cotacao/:id/qc" exact component={CotacaoQC} />

// 			{/* 404 e signout */}
// 			<Route path="/" exact component={Home} />
// 			<Route path="*" exact component={NotFound404} />
// 		</Switch>
// 	</Suspense>
// );

// export default Routes;


import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Hocs
import asyncComponent from './hoc/asyncComponent/asyncComponent';

// Components
import Avaliacoes from './pages/Avaliacoes';
import LogarComoUsuario from './pages/LogarComoUsuario';
import NotFound404 from './pages/NotFound404';
import Unsubscribe from './pages/Unsubscribe';
import Sair from './pages/Sair';
import Welcome from './pages/Welcome';
import Cotacoes from './pages/Cotacoes';
import Compras from './pages/Compras';
import ViewCotacao from './pages/ViewCotacao';
import EscolheCompra from './pages/EscolheCompra';
import CompraV3 from './pages/CompraV3';
import Impressao from './pages/Impressao';
import EsqueciMinhaSenha from './pages/EsqueciMinhaSenha/EsqueciMinhaSenha';
import RecuperarSenha from './pages/EsqueciMinhaSenha/RecuperarSenha';
import AlterarSenha from './pages/EsqueciMinhaSenha/AlterarSenha';
import QcView from './pages/QcOnline/QcView';
import QcCombEdition from './pages/QcOnline/QcCombEdition';
import NovaCotacaoFornecedores from './pages/NovaCotacao/Fornecedores';
// Integração Sienge
import IntegracaoInicio from './pages/Integracao/IntegracaoInicio';
import SiengeInicio from './pages/Integracao/SiengeInicio';
import SiengeLogin from './pages/Integracao/SiengeLogin';
import SiengeItens from './pages/Integracao/SiengeItens';
import SiengeObras from './pages/Integracao/SiengeObras';
import SiengeFornecedores from './pages/Integracao/SiengeFornecedores';

const triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
const Login = asyncComponent(() => {
	if (triagem) return import('./components/_parts_interno/Login/LoginContainer');
	return import('./pages/Login');
});
const AcoesInternas = asyncComponent(() => {
	if (triagem) return import('./pages_triagem/AcoesInternas');
	return import('./pages/NotFound404');
});
const CriarQcAnalise = asyncComponent(() => {
	if (triagem) return import('./components/_parts_interno/CriarQcAnalise/CriarQcAnalise');
	return import('./pages/NotFound404');
});
const AprovarComentariosAvaliacao = asyncComponent(() => {
	if (triagem) return import('./pages_triagem/AprovarComentariosAvaliacao');
	return import('./pages/NotFound404');
});
/**
 * Componentes de rascunho
 */
const NovaCotacaoItens = asyncComponent(() => {
	if (triagem) return import('./pages/NovaCotacao/Itens/_triagem');
	return import('./pages/NovaCotacao/Itens/_construtor');
});
const NovaCotacaoInfo = asyncComponent(() => {
	if (triagem) return import('./pages/NovaCotacao/Info/_triagem');
	return import('./pages/NovaCotacao/Info/_construtor');
});
const NovaCotacaoReview = asyncComponent(() => {
	if (triagem) return import('./pages/NovaCotacao/Review/_triagem');
	return import('./pages/NovaCotacao/Review/_construtor');
});

const Routes = () => (
	<Switch>
		<Route path="/cotacao/nova/:id/i/:item" component={NovaCotacaoItens} />
		<Route path="/cotacao/nova/:id/revisao" component={NovaCotacaoReview} />
		<Route path="/cotacao/nova/:id/fornecedores" component={NovaCotacaoFornecedores} />
		<Route path="/cotacao/nova/:id/info" component={NovaCotacaoInfo} />
		<Route path="/cotacao/nova/:id" component={NovaCotacaoItens} />
		<Route path="/cotacao/:id/qc/:comb_id" exact component={QcCombEdition} />
		<Route path="/cotacao/:id/qc" exact component={QcView} />
		<Route path="/cotacao/:id" component={ViewCotacao} />
		<Route path="/cotacoes" component={Cotacoes} />
		<Route path="/compra/nova/:id" exact component={CompraV3} />
		<Route path="/compra/:id" exact component={CompraV3} />
		<Route path="/compra/:id/impressao" component={Impressao} />
		<Route path="/compras/tipo" component={EscolheCompra} />
		<Route path="/compras" component={Compras} />
		<Route path="/entrar" component={Login} />
		<Route path="/esqueci-minha-senha" component={EsqueciMinhaSenha} />
		<Route path="/recuperar-senha/:token" component={RecuperarSenha} />
		<Route path="/alterar-senha" component={AlterarSenha} />
		<Route path="/avaliacoes" component={Avaliacoes} />
		<Route path="/bemvindo" component={Welcome} />

		{/* integracao */}
		<Route path="/integracao/inicio" exact component={IntegracaoInicio} />
		<Route path="/integracao/sienge" exact component={SiengeInicio} />
		<Route path="/integracao/sienge/login" exact component={SiengeLogin} />
		<Route path="/integracao/sienge/obras" exact component={SiengeObras} />
		<Route path="/integracao/sienge/itens" exact component={SiengeItens} />
		<Route path="/integracao/sienge/fornecedores" exact component={SiengeFornecedores} />

		{/* interno */}
		<Route path="/logar_como_usuario/:token" component={LogarComoUsuario} />
		<Route path="/acoes" component={AcoesInternas} />
		<Route path="/criar_qc" component={CriarQcAnalise} />
		<Route path="/aprovar/comentarios" component={AprovarComentariosAvaliacao} />

		{/* redirects */}
		<Route path="/pedido/:id" component={NotFound404} />
		<Route path="/pedido/:id/ver" component={NotFound404} />
		<Route path="/pedido/:id/imprimir" component={NotFound404} />
		<Route path="/pedido/:id/i/:item" component={NotFound404} />
		<Route path="/pedido/:id/review" component={NotFound404} />

		{/* 404 e signout */}
		<Route path="/cancelar-recebimento" component={Unsubscribe} />
		<Route path="/sair" component={Sair} />
		<Route path="/" exact component={NotFound404} />
		<Route path="*" exact component={NotFound404} />
	</Switch>
);

export default Routes;

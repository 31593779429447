import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import InputNumber from '../../../../components/_parts/_inputs/InputNumber/InputNumber';

const UnidadesCheck = ({
	item,
	updateItemById,
}) => {
	const front = item.front || {};
	const item_preenchido = item.item_preenchido || {};
	const solicitacao_sienge_id = item.pedido_no_swi || '';

	// item conaz
	const nome = (item_preenchido.item || {}).nome || '';
	const quantidade = item.quantidade || 0;
	const unidade = item.unidade || '';

	// item integrado
	const quantidade_no_swi = item.quantidade_no_swi || 0;
	const unidade_sienge = solicitacao_sienge_id === ''
		? (item.item_integrado || {}).unidade || ''
		: item.pre_unidade || '';

	return (
		<div styleName="item-check">
			<div styleName="nome">
				<div styleName="number">{front.key}.</div>
				{nome}
			</div>
			<div styleName="quantidades">
				<i className="fa fa-long-arrow-right" styleName="arrow" aria-hidden="true" />
				<div styleName="qtd-conaz">
					<label>Quantidade (Conaz)</label>
					<div styleName="qtd-value">{quantidade} {unidade}</div>
				</div>
				<div styleName="qtd-sienge">
					<label>Quantidade (Sienge)</label>
					<div styleName="qtd-input">
						<InputNumber
							type="item"
							field_id={`quantidade-sienge-${item.id}`}
							item_key={item.id}
							field="quantidade_no_swi"
							default_value={quantidade_no_swi}
							updateFunction={updateItemById}
							autoSave={() => {}}
							zerar_erros={false}
						/>
					</div>
					<div styleName="un-input">
						{unidade_sienge}
					</div>
				</div>
			</div>
		</div>
	);
};

UnidadesCheck.propTypes = {
	item: PropTypes.object.isRequired,
	updateItemById: PropTypes.func.isRequired,
};

export default CSSModules(UnidadesCheck, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const LoadingPage = ({ small_pad_top }) => {
	const loading_wrapper_class = small_pad_top
		? 'loading-wrapper small_pad_top'
		: 'loading-wrapper';

	return (
		<div styleName="page-wrapper">
			<div styleName={loading_wrapper_class}>
				<div styleName="logo">
					<img src="/images/logo-loading.png" width="100" alt="" />
				</div>
				<div styleName="progress">
					<div styleName="indeterminate" />
				</div>
			</div>
		</div>
	);
};

LoadingPage.propTypes = {
	small_pad_top: PropTypes.bool,
};

LoadingPage.defaultProps = {
	small_pad_top: false,
};

export default CSSModules(LoadingPage, styles, { allowMultiple: true });

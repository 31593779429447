import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CompraRowButtons from '../CompraRowButtons';
import CompraRowAvaliacao from './CompraRowAvaliacao';
import CompraRowIcon from './CompraRowIcon';
import CompraRowTopInfo from './CompraRowTopInfo';
import CompraRowCotacao from './CompraRowCotacao';
import CompraRowFornecedor from './CompraRowFornecedor';
import CompraRowPagamento from './CompraRowPagamento';
import CompraRowEntrega from './CompraRowEntrega';

// Functions
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';
import { setClasses } from '../../_functions/_setClasses';

// Avaliacoes
import { avaliacao_fornecedor } from '../../ModalRating/avaliacoes';
import StatusExportSienge from './StatusExportSienge';
// import StatusExportSienge from './StatusExportSienge';

const CompraRowNova = ({
	compra,
	border,
	exportStatus,
}) => {
	const {
		id,
		codigo_pedido,
		forma_de_pagamento,
		outra_forma_de_pagamento,
		outro_prazo_de_pagamento,
		prazo_de_pagamento,
		nome_fornecedor,
		transporte,
		obra_id,
	} = compra;

	const _compra = {
		...compra,
		pedido_id: codigo_pedido,
		condicao_de_pagamento: {
			forma: forma_de_pagamento,
			outra_forma: outra_forma_de_pagamento,
			outro_prazo: outro_prazo_de_pagamento,
			prazo: prazo_de_pagamento,
		},
		fornecedor: {
			nome: nome_fornecedor,
		},
		rc: {
			transporte,
		},
		obra: {
			id: obra_id,
		},
	};

	const status = defineStatusCompra(compra);
	const compra_wrapper_classes = {
		cancelada: status === 'cancelada',
		em_aprovacao: status === 'em_aprovacao',
		aguardando_aprovacao_sienge: status === 'aguardando_aprovacao_sienge',
		em_edicao: status === 'em_edicao',
		rascunho: status === 'rascunho',
		em_analise: status === 'em_analise',
		em_refinamento: status === 'em_refinamento',
		aguardando_confirmacao: status === 'aguardando_confirmacao',
		confirmada: status === 'confirmada',
		recusada_fornecedor: status === 'recusada_fornecedor',
		entrega_confirmada: status === 'entrega_confirmada',
		finalizada: status === 'finalizada',
		border,
	};
	const compra_wrapper_class = setClasses('compra-wrapper', compra_wrapper_classes);
	const qtd_anexos = compra.qtd_anexos || 0;
	const avaliacao = avaliacao_fornecedor(compra.nome_fornecedor);

	return (
		<div styleName="row-wrapper">
			<CompraRowButtons
				compra={_compra}
				avaliacao={avaliacao}
				location="listagem"
			/>
			<CompraRowAvaliacao
				compra={_compra}
				location="listagem"
			/>
			<Link to={`/compra/${id}`} styleName="compra-a">
				<div styleName={compra_wrapper_class}>
					<CompraRowIcon compra={_compra} />
					<CompraRowTopInfo compra={_compra} />
					<div styleName="divisao" />
					<CompraRowCotacao compra={_compra} />
					<CompraRowFornecedor compra={_compra} />
					<StatusExportSienge compra={_compra} exportStatus={exportStatus} />
					<CompraRowPagamento compra={_compra} />
					<CompraRowEntrega compra={_compra} />
					{qtd_anexos > 0 && (
						<i className="fa fa-paperclip fa-fw" styleName="anexos-icon" aria-hidden="true" />
					)}
				</div>
			</Link>
		</div>
	);
};

CompraRowNova.propTypes = {
	compra: PropTypes.object.isRequired,
	border: PropTypes.bool,
	exportStatus: PropTypes.object.isRequired,
};

CompraRowNova.defaultProps = {
	border: false,
};

export default memo(CSSModules(CompraRowNova, styles, { allowMultiple: true }));

import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
// import { formatNumbers } from '../../_functions/_formatNumbers';

// Content
// import { codigos_recusa } from '../../_content/_codigos_recusa';

const RCCardStatus = ({ order, rc, infoFornecedor }) => {
	const col_class = `o${order}`;
	const col_id = `rc-o${order}`;
	const { fornecedor, status, usuario, motivos_recusa, estah_resetado } = rc;
	const nome_fornecedor_view = fornecedor.nome !== '' ? fornecedor.nome : '-';
	const codigo_recusa_limpo = motivos_recusa.join('; ');

	if (estah_resetado) {
		return (
			<div className="col-xs-12 col-sm-3 col-md-3" styleName={col_class}>
				<div id={col_id} styleName="rc-status-wrapper">
					<button styleName="nome-fornecedor" onClick={() => infoFornecedor(fornecedor.id, usuario)}>{nome_fornecedor_view}</button>
					<div styleName="status vista">
						<i className="fa fa-refresh" styleName="check-1" aria-hidden="true" />
						<p>Em atualização</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="col-xs-12 col-sm-3 col-md-3" styleName={col_class}>
			<div id={col_id} styleName="rc-status-wrapper">
				<button styleName="nome-fornecedor" onClick={() => infoFornecedor(fornecedor.id, usuario)}>{nome_fornecedor_view}</button>

				{status === 0 && (
					<div styleName="status">
						<i className="fa fa-check" styleName="check-1" aria-hidden="true" />
						<i className="fa fa-check" styleName="check-2" aria-hidden="true" />
						<p>Não visualizou</p>
					</div>
				)}

				{(status === 10 || status === 20 || status === 30) && (
					<div styleName="status vista">
						<i className="fa fa-check" styleName="check-1" aria-hidden="true" />
						<i className="fa fa-check" styleName="check-2" aria-hidden="true" />
						<p>Visualizou</p>
					</div>
				)}

				{((status === 35 || status === 36 || status === 37)) && (
					<div styleName="status vista">
						<i className="fa fa-refresh" styleName="check-1" aria-hidden="true" />
						{status === 35 ? (
							<p>Em atualização</p>
						) : (
							<p>Correção foi solicitada ao fornecedor</p>
						)}
					</div>
				)}

				{status === 40 && (
					<div styleName="status respondida">
						<i className="fa fa-paper-plane" aria-hidden="true" />
						<p>Cotação respondida</p>
					</div>
				)}

				{status === -20 && (
					<div styleName="status">
						<i className="fa fa-times" aria-hidden="true" />
						<p>Não pôde participar</p>
						<div style={{ paddingBottom: '4px', color: '#999999', textAlign: 'center' }}>Motivo(s):</div>
						<p>&quot;{codigo_recusa_limpo}.&quot;</p>
					</div>
				)}
			</div>
		</div>
	);
};

RCCardStatus.propTypes = {
	order: PropTypes.number.isRequired,
	rc: PropTypes.object.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
};

export default CSSModules(RCCardStatus, styles, { allowMultiple: true });

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// class
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { _post } from '../../components/_functions/_requests';
import { getLocalstorageData } from '../../components/_functions/_getLocalstorageData';

class EsqueciMinhaSenha extends Component {
	constructor() {
		super();
		this.state = {
			request_state: 0,
			email: '',
			infoMsg: '',
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
	}

	UNSAFE_componentWillMount() {
		const token = getLocalstorageData('user', 'token');
		if (token !== '' && token !== null) this.props.history.push('/cotacoes');
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Conaz';
	}

	onChangeEmail(e) {
		const { value } = e.target;
		this.setState({ email: value });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { email } = this.state;
		this.setState({ request_state: 1 });

		_post(`/conaz/v2/auth/construtor/esqueci_minha_senha/${email}`)
			.then(() => {
				this.setState({ request_state: 2, infoMsg: 'Enviamos uma mensagem para seu email.' });
			})
			.catch(error => {
				if (!error.response || error.response.status === 500) {
					this.setState({ request_state: 3, infoMsg: 'Ops... aconteceu algo errado. \nClique em Enviar e tente novamente.' });
				} else {
					this.setState({ request_state: 3, infoMsg: error.response.data.message });
				}
			});
	}

	resetErrors() {
		this.setState({ request_state: 0 });
	}

	render() {
		const { request_state, infoMsg } = this.state;
		const error_class = request_state === 3 ? 'form-group has-error' : 'form-group';

		return (
			<div styleName="login-page-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-md-12" style={{ textAlign: 'center' }}>
							<div styleName="logo-wrapper">
								<img
									src="/images/logotipo_original.png"
									width="230"
									alt="" />
							</div>

							<div styleName="form-wrapper">
								<h3 styleName="h3-margin">Recuperar senha</h3>
								<form onSubmit={this.handleSubmit}>
									<div className={error_class}>
										<label htmlFor="email">E-mail</label>
										<input
											type="email"
											className="form-control"
											id="email"
											name="email"
											required
											value={this.state.email}
											onChange={this.onChangeEmail}
											onKeyDown={() => this.resetErrors()} />
									</div>

									{request_state > 1 && (
										<div
											className={`alert alert-${request_state === 2 ? 'success' : 'danger'}`}
											role="alert">
											<i
												className={`fa fa-${request_state === 2 ? 'check' : 'exclamation-triangle fa-fw'}`}
												aria-hidden="true" />
											{` ${infoMsg}`}
										</div>
									)}

									{(request_state === 1 || request_state === 2) ? (
										<button type="submit" className="btn btn-primary btn-lg btn-block" styleName="button-margin" disabled="disabled">Verificando</button>
									) : (
										<button type="submit" className="btn btn-primary btn-lg btn-block" styleName="button-margin">Enviar</button>
									)}

								</form>
							</div>
							<div styleName="cadastre-wrapper">
								<p><Link to="/entrar">Voltar para login</Link></p>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

EsqueciMinhaSenha.propTypes = {
	history: PropTypes.object.isRequired,
};

export default CSSModules(EsqueciMinhaSenha, styles, { allowMultiple: true });

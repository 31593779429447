import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../_loadings/LoadingButton/LoadingButton';

// Functions
import { handleRequestErrors } from '../../_functions/_handleRequestErrors';
import { _post } from '../../_functions/_requests';

class LeftMenu extends Component {
	constructor() {
		super();
		this.state = {
			action_buttons: false,
			carregando_obras: true,
		};
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.openActionButtons = this.openActionButtons.bind(this);
		this.handleLinkCompra = this.handleLinkCompra.bind(this);
		this.createPedido = this.createPedido.bind(this);
		this.clearSearch = this.clearSearch.bind(this);
	}

	UNSAFE_componentWillMount() {
		window.addEventListener('mouseup', this.handleMouseUp);
		if (this.props.obra_selected !== 0 || process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno') this.setState({ carregando_obras: false });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.obra_selected !== 0) this.setState({ carregando_obras: false });
	}

	componentWillUnmount() {
		window.removeEventListener('mouseup', this.handleMouseUp);
	}

	handleMouseUp(e) {
		if (!e.target.getAttribute('data-new-button')) this.setState({ action_buttons: false });
	}

	openActionButtons() {
		this.setState({ action_buttons: !this.state.action_buttons });
		window.ga('send', 'event', 'Menu actions button', 'click', 'Clique no botão de ações do menu lateral');
	}

	handleLinkCompra() {
		this.props.history.push('/compras/tipo');
	}

	clearSearch() {
		this.props.updateUi(['search'], '');
	}

	createPedido() {
		this.props.updateCotacao(['criando_cotacao'], true);
		const { obra_selected } = this.props;
		const params = {
			obra_id: obra_selected,
		};
		_post('/conaz/v2/pedidos/', params).then(response => {
			this.props.history.push(`/cotacao/nova/${response.data.codigo}`);
			this.props.updateCotacao(['criando_cotacao'], false);
		}).catch(error => {
			this.props.updateCotacao(['criando_cotacao'], false);
			this.props.updateModals('error', true);
			handleRequestErrors(error);
		});
	}

	render() {
		const { action_buttons, carregando_obras } = this.state;
		const { route_path } = this.props;
		const cotacoes_possibilities = ['/cotacoes', '/cotacao/:id'];

		const cotacoes_active_class = cotacoes_possibilities.filter(x => x === route_path).length > 0 ? 'active' : '';
		const cotacoes_active_icon = cotacoes_possibilities.filter(x => x === route_path).length > 0 ? '#fff' : '#94a9c9';
		// const cotacoes_active_class = cotacoes_possibilities.find(p => p === route_path) !== undefined ? 'active' : '';
		// const cotacoes_active_icon = cotacoes_possibilities.find(p => p === route_path) !== undefined ? '#fff' : '#94a9c9';

		const compras_possibilities = ['/compras', '/compras/tipo', '/compras/nova', '/compra/:id', '/compra/:id/aprovacao'];
		const compras_active_class = compras_possibilities.filter(x => x === route_path).length > 0 ? 'active' : '';
		// const compras_active_class = compras_possibilities.find(p => p === route_path) !== undefined ? 'active' : '';

		const avaliacoes_possibilities = ['/avaliacoes'];
		const avaliacoes_active_class = avaliacoes_possibilities.filter(x => x === route_path).length > 0 ? 'avaliacoes active' : 'avaliacoes';

		const action_buttons_add_class = action_buttons ? ' in' : '';
		const action_button_icon = action_buttons ? <span>&times;</span> : '+';

		const menu_top_class = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno' ? 'menu-top interno' : 'menu-top';

		return (
			<div styleName="left-menu-wrapper">
				<div styleName={menu_top_class}>
					<Link to="/cotacoes"><img src="/images/conaz-simbolo_branco_small.png" width="44" alt="" /></Link>
				</div>
				<ul styleName="menu">
					<li styleName="li-new-buttons-wrapper">
						{!carregando_obras ? (
							<button type="button" styleName="new-button" onClick={this.openActionButtons} data-new-button>{action_button_icon}</button>
						) : (
							<button type="button" styleName="new-button" data-new-button><LoadingButton /></button>
						)}
						{this.props.criando_cotacao ? (
							<button
								type="button"
								className="btn btn-primary-conaz round-border"
								styleName={`new-button-cotacao${action_buttons_add_class}`}
								style={{ color: '#15bede' }}
								data-new-button>
								<LoadingButton />Nova cotação
							</button>
						) : (
							<button
								type="button"
								className="btn btn-primary-conaz round-border"
								styleName={`new-button-cotacao${action_buttons_add_class}`}
								onClick={this.createPedido}
								data-new-button>
								Nova cotação
							</button>
						)}
						<button
							type="button"
							className="btn btn-primary-conaz round-border"
							styleName={`new-button-compra${action_buttons_add_class}`}
							onClick={this.handleLinkCompra}>
							Nova compra
						</button>
					</li>
					<li styleName="divider" />
					<li>
						<Link to="/cotacoes" styleName={cotacoes_active_class} onClick={this.clearSearch}>
							<svg x="0px" y="0px" viewBox="0 0 100 125" styleName="svg-cotacoes" style={{ enableBackground: 'new 0 0 100 100' }} xmlSpace="preserve" width="33" height="33" fill={cotacoes_active_icon}><g><path d="M83.5,23.1h-36L38,12H24.6c-4.8,0-8.6,3.9-8.6,8.7V25h-0.4C10.9,25,7,28.9,7,33.7v45.6c0,4.8,3.9,8.7,8.6,8.7h58.6   c4.8,0,8.6-3.9,8.6-8.7V75h0.4c4.8,0,8.6-3.9,8.6-8.7V31.9C91.9,27,88.1,23.1,83.5,23.1z M78.9,79.3c0,2.6-2.1,4.7-4.6,4.7H15.6   c-2.6,0-4.6-2.1-4.6-4.7V33.7c0-2.6,2.1-4.7,4.6-4.7h11.5l9.5,11.1h37.8c2.5,0,4.5,2.2,4.5,4.8V79.3z M87.9,66.3   c0,2.6-2.1,4.7-4.6,4.7h-0.4V44.9c0-4.9-3.8-8.8-8.5-8.8h-36L29,25h-9v-4.3c0-2.6,2.1-4.7,4.6-4.7h11.5l9.5,11.1h37.8   c2.5,0,4.5,2.2,4.5,4.8V66.3z" /></g></svg>
							Cotações
						</Link>
					</li>
					<li styleName="divider" />
					<li>
						<Link to="/compras" styleName={compras_active_class} onClick={this.clearSearch}>
							<svg width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" styleName="svg-compras"><rect x="1" y="4" width="22" height="16" rx="2" ry="2" /><line x1="1" y1="10" x2="23" y2="10" /></svg>
							Compras
						</Link>
					</li>
					<li styleName="divider" />
					<li>
						<Link to="/avaliacoes" styleName={avaliacoes_active_class} onClick={this.clearSearch}>
							<i className="fa fa-star-o" styleName="svg-avaliacoes" />
							Avaliações
						</Link>
					</li>
					<li styleName="divider" />
				</ul>
			</div>
		);
	}
}

LeftMenu.propTypes = {
	route_path: PropTypes.string,
	obra_selected: PropTypes.number.isRequired,
	criando_cotacao: PropTypes.bool.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

LeftMenu.defaultProps = {
	route_path: '',
};

const _LeftMenu = CSSModules(LeftMenu, styles, { allowMultiple: true });
export default withRouter(_LeftMenu);
